import {
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
  enUS,
  GridFooter,
  gridFilteredSortedRowEntriesSelector,
  GridFilterItem,
  useGridApiRef,
  useGridApiContext,
  GridEventListener,
  useGridApiEventHandler
} from "@mui/x-data-grid";
import { frFRCustom as frFR } from "../locale/frFR";
import { IconButton, Menu, MenuItem } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Users } from "../typings/interfaces";
import { useTranslation } from "react-i18next";
import { Dispatch, useEffect, useState } from "react";
import axios from "axios";
import ReportingDataGridTable from "./styled/ReportingGrid";
import { getReportingDetailsForCsv } from "../utils/csvBuilder";
import CSVDownloadLink from "./styled/CSVDownloadLink";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useReps } from "../api/reps";
import { sortDataAlpha } from "../utils/sort";

type Props = {
  editUser: (user: Users) => void,
  updateTable: () => void,
  update: boolean,
};

const UsersList = ({editUser, updateTable, update}: Props) => {
  const { t, i18n } = useTranslation();
  const email_label = t("user_table_head_email");
  const name_label = t("user_table_head_name");
  const role_label = t("user_table_head_role");
  const tools_label = t("user_table_head_tools");
  const edit_label = t("user_table_head_edit");
  const delete_label = t("user_table_head_delete");
  const current_language = i18n.language;
  const [users, setUsers] = useState<Users[]>([]);
  const apiRef = useGridApiRef();
  const [exportRowData, setExportRowData] = useState<Users[]>([]);
  const [dataGridFilters, setDataGridFilters] = useState<GridFilterItem>({} as GridFilterItem);
  const { mutateReps } = useReps(); 

  useEffect(() => {
    const visibleRows = gridFilteredSortedRowEntriesSelector(apiRef);
    visibleRows.forEach((row) => {
      const toolNames: string[] = [];
      row.model.tools.forEach((tool:any) => toolNames.push(t(`create_user_form_tools.${tool.tool}`)));
      row.model.toolNames = toolNames.join(", ");
    });
    const exportRowsArray = getReportingDetailsForCsv(visibleRows, columns, dataGridFilters);
    setExportRowData(exportRowsArray);

  },[dataGridFilters, users, current_language])

  const columns: GridColDef[] = [
    {
      field: "email",
      headerName: email_label,
      width: 350,
      editable: false,
    },
    {
      field: "name",
      headerName: name_label,
      minWidth: 200,
      editable: false,
      flex: 1,
    },
    {
      field: "role",
      headerName: role_label,
      minWidth: 200,
      editable: false,
      flex: 1,
    },
    {
      field: "toolNames",
      headerName: tools_label,
      minWidth: 200,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        if (params.row.tools.length > 0) {
          let toolList: string[] = [];
          params.row.tools.forEach((tool:any) => toolList.push(t(`create_user_form_tools.${tool.tool}`)));
          return toolList.join(", ");
        }
        return "";
      }
    },
    {
      sortable: false,
      editable: false,
      filterable: false,
      field: "edit",
      width: 100,
      headerName: "",
      headerAlign: "center",
      align:"center",
      disableColumnMenu: true,

      renderCell: (params) => (
      <strong>
          <IconButton
          aria-label="edit"
          style={{ marginLeft: 0 }}
          onClick={() => editUser(params.row as Users)}
          >
          <EditIcon />
          </IconButton>
      </strong>
      ),
    },
    {
      sortable: false,
      editable: false,
      filterable: false,
      field: "delete",
      headerName: "",
      width: 100,
      headerAlign: "center",
      disableColumnMenu: true,
      align:"center",
      renderCell: (params) => (
      <strong>
          <IconButton
          aria-label="delete"
          style={{ marginLeft: 0 }}
          onClick={() => {
            deleteUsers(params.row.email, params.row.role);
          }}
          >
            <DeleteIcon />
          </IconButton>
      </strong>
      ),
    },
  ];
  
  
  const deleteUsers = async (email: string, role: string) => {
      const confirm = window.confirm(t("delete_confirm_message"));
  
      if (!confirm) {
        return;
      }
  
      const token = localStorage.getItem("SavedToken");
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/users/delete`, {
          headers: {
            authorization: `${token}`,
          },
          params: {
            email,
          },
        });
        const filteredUsers = users.filter(
          (user) => user.email !== email
        );
        setUsers(filteredUsers);

        //refetch reps is user is a rep that was deleted
        if(role === "rep") {
          mutateReps();
        }
      } catch (error) {
        console.log(error);
        alert(t("user_delete_error"));
      }
  };

  const getAllUsers = async () => {    
    try {
        const token = localStorage.getItem("SavedToken");
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/users`,
            {
                headers: {
                    authorization: `${token}`,
                },
            }
        );
        const usersResponse = response.data.users;
        usersResponse.forEach((userResponse: Users) => {
            userResponse.id = userResponse._id
        });
        const sortedUsers = sortDataAlpha(usersResponse, "email")
        setUsers(sortedUsers);
    
    }   catch (error) {
          alert("error");
    }
  }
  
  useEffect(() => {
    getAllUsers();
  },[]);

  useEffect(() => {
    if (update){
      getAllUsers();
      updateTable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[update]);


  const CustomToolbar = (setDataGridFilters: Dispatch<any>) => {
    const [exportAnchor, setExportAnchor] = useState<null | HTMLElement>(null);
    const openExport = Boolean(exportAnchor);
    const apiRef = useGridApiContext();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setExportAnchor(event.currentTarget);
    };
    
    const handleClose = () => {
      setExportAnchor(null);
    };

    const handleFilterChange: GridEventListener<'filterModelChange'> = (params) => {
      params.items.forEach((filter) => {
        setDataGridFilters(filter);
      })
    };

    useGridApiEventHandler(apiRef, 'filterModelChange', handleFilterChange);

    return (
      <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
          <div className="columnHeader_buttons">
            <IconButton disableRipple onClick={handleClick} aria-label={t("instructions.reporting.general.export")}>
              <FileDownloadOutlinedIcon sx={{ color: (theme) => theme.palette.primary.main }} />
            </IconButton>
            <Menu
              id="export-results"
              anchorEl={exportAnchor}
              open={openExport}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <MenuItem onClick={() => {handleClose()} }>
                <CSVDownloadLink data={exportRowData} filename={`results_users_${new Date().getTime()}`}>
                  {t("download_as_csv")}
                </CSVDownloadLink>
              </MenuItem>
            <MenuItem onClick={() => { handleClose(); window.print(); }}>{ t("print") }</MenuItem>
            </Menu>
          </div>
        <div>
          <GridToolbarExport
            disableToolbarButton={true}
            csvOptions={{ disableToolbarButton: true }}
            printOptions={{ disableToolbarButton: true }}
          />
        </div>
        <GridFooter
          sx={{
            border: "none", // To delete double border.
          }}
        />
      </GridToolbarContainer>
    );
  };
    
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <ReportingDataGridTable
        apiRef={apiRef}
        className="user_grid"
        rows={users}
        columns={columns}
        disableRowSelectionOnClick
        localeText={current_language === "en" ? {...enUS.components.MuiDataGrid.defaultProps.localeText, toolbarExport:""} : {...frFR.components.MuiDataGrid.defaultProps.localeText, toolbarExport:""} }
        slots={{
          footer: () => CustomToolbar(setDataGridFilters),
        }}
      />
    </div>
  );
};

export default UsersList;