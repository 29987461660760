import {
  Box,
  Link,
  Toolbar,
  Grid,
  LinkProps,
  AppBarProps,
} from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";

const NavLink = styled(Link)<LinkProps>(({ theme }) => ({
  color: "#3D3D3D",
  margin: "0 15px",
  cursor: "pointer",
}));

const FooterBar = styled(Box)<AppBarProps>(({ theme }) => ({
  background: "#fff",
  position: "relative",
}));

const Footer = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const location = useLocation();

  function openPrivacyPolicy() {
    if (language === "en") {
      window.open('https://www.novonordisk.ca/privacy-policy.html', '_blank', 'noreferrer');  
    } else {
      window.open('https://caf.novonordisk.ca/privacy-policy.html', '_blank', 'noreferrer');
    }
    
  }

  return (
    <React.Fragment>
      { location.pathname.includes("evaluations") &&
        <FooterBar sx={{ boxShadow: 1 }}>
          <Toolbar>
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              sx={{color:"#3D3D3D"}}
            >
              <span style={{fontSize: "13px", textAlign: "center"}}>{t("evaluation.footer")}</span>
            </Grid>
          </Toolbar>
        </FooterBar>

      }
      <FooterBar sx={{ boxShadow: 1 }}>
        <Toolbar>
          <Grid container justifyContent={{xs: "center", md: "space-between"}} mt={{xs: 1, md: 0}}>
            <Grid item xs={9} md={6} sx={{alignItems: {xs: "center", md: "left"}}}>
              <Box sx={{textAlign: {xs: "center", md: "left"}}}>
                <div style={{fontSize: "12px" }}>
                  {ReactHtmlParser(t("footer_micetype"))}
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} mt={{xs: 1, md: 0}}>
              <Box justifyContent={{xs: "center", md: "flex-end"}} alignItems="center" sx={{ flexGrow: 1, display:"flex" }}>
                <NavLink
                  sx={{ marginRight: "75px", color: (theme) => theme.palette.primary.dark, textDecorationColor:(theme) => theme.palette.primary.dark}}
                  onClick={() => {
                    openPrivacyPolicy();
                  }}
                >
                  {t("private_policy")}
                </NavLink>
                <NavLink
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img
                    src="/assets/novo-nordisk-logo.jpg"
                    alt="Novo Nordisk logo"
                    style={{ maxWidth: "60px", marginBottom: 0 }}
                  />
                </NavLink>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </FooterBar>
    </React.Fragment>
  );
};

export default Footer;
