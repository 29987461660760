/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useContext, useEffect, useState, useRef } from "react";
import { Activity, RecurringActivity, Brand, Region, ActivityType, Segmentation, Holiday, RecurringActivityOccurence } from "../typings/interfaces";
import { useActivities } from "../api/activities";
import { useRecurringActivities } from "../api/activitiesRecurring";
import AppContext from "../context/Auth";
import { useRegions } from "../api/regions";
import { useBrands } from "../api/brands";
import { useActivityTypes } from "../api/activityTypes";
import { useSegmentations } from "../api/segmentations";
import { useHolidays } from "../api/holidays";
import { getOccurencesForYear } from "../utils/activitiesRecurringOccurences";

interface IActivityContext {
  filteredActivities: Activity[];
  activities: Activity[];
  isActivitiesLoading: boolean;
  isFilteredActivitiesLoading: boolean;
  recurringActivities: RecurringActivity[];
  isRecurringActivitiesLoading: boolean;
  isFilteredOccurencesLoading: boolean;
  isHolidaysLoading: boolean;
  totalPages: number;
  error: string;
  selectedProvinces: string[];
  selectedBrandIDs: string[];
  selectedActivityTypeIDs: string[];
  selectedSegmentationIds: string[];
  brands: Brand[],
  activityTypes: ActivityType[],
  segmentations: Segmentation[],
  holidays: Holiday[],
  isBrandsLoading: boolean,
  currentYear: number;
  filteredOccurences: RecurringActivityOccurence[];
  setOccurences: (occurences: RecurringActivityOccurence[]) => void;
  updateCurrentYear: (newYear: number) => void;
  setError: (error: string) => void;
  setSelectedProvinces: (id: string[]) => void;
  setSelectedBrandIDs: (id: string[]) => void;
  setSelectedActivityTypeIDs: (id: string[]) => void;
  setSelectedSegmentationIds: (id: string[]) => void;
  onActivityDelete: (id: string) => void;
  onRecurringActivityDelete: (id: string) => void;
  getActivities: (page: number, pageCount?: number) => void;
  getRecurringActivities: (page: number, pageCount?: number) => void;
  // updateRoles: (roles: Role[]) => void;
}

//Initial App Context
export const ActivityContext = createContext<IActivityContext>({
  filteredActivities: [],
  activities: [],
  isActivitiesLoading: false,
  isFilteredActivitiesLoading: true, 
  recurringActivities: [],
  isRecurringActivitiesLoading: false,
  isFilteredOccurencesLoading: true, 
  isHolidaysLoading: true,
  totalPages: 0,
  error: "",
  selectedProvinces: [],
  selectedBrandIDs: [],
  selectedActivityTypeIDs: [],
  selectedSegmentationIds: [],
  brands: [],
  activityTypes: [],
  segmentations: [],
  holidays: [],
  isBrandsLoading: true,
  currentYear: new Date().getFullYear(),
  filteredOccurences: [],
  setOccurences: function(occurences: RecurringActivityOccurence[]): void{},
  updateCurrentYear: function(): void{},
  setError: function (): void { },
  setSelectedProvinces: function (): void{ },
  setSelectedBrandIDs: function (): void{ },
  setSelectedActivityTypeIDs: function (): void{ },
  setSelectedSegmentationIds: function (): void{ },
  onActivityDelete: function (): void{ },
  onRecurringActivityDelete: function (): void{ },
  getActivities: function(page = 1, pageCount = 12): void{},
  getRecurringActivities: function(page = 1, pageCount = 12): void{},
  // updateRoles: function (): void {},
});

interface Props {
  children: React.ReactNode;
}

export const ActivityProvider: React.FC<Props> = ({ children }) => {
  const {hasRole, userId} = useContext(AppContext);
  const { activities, isActivitiesLoading, getActivities, mutateActivities } = useActivities();
  const [filteredActivities, setFilteredActivities] = useState<Activity[]>([]); //gets modified based on filters
  const [isFilteredActivitiesLoading, setIsFilteredActivitiesLoading] = useState(true);
  const { recurringActivities, isRecurringActivitiesLoading, getRecurringActivities, mutateRecurringActivities } = useRecurringActivities();
  const [occurences, setOccurences] = useState<RecurringActivityOccurence[]>([]);
  const [filteredOccurences, setFilteredOccurences] = useState<RecurringActivityOccurence[]>([]);
  const [isFilteredOccurencesLoading, setIsFilteredOccurencesLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1); //total pages used for card view
  const {brands, isBrandsLoading} = useBrands();
  const { activityTypes, isActivityTypesLoading } = useActivityTypes();
  const { segmentations, isSegmentationsLoading } = useSegmentations();
  const { holidays, isHolidaysLoading } = useHolidays();

  const [selectedProvinces, setSelectedProvinces] = useState<string[]>([]); //the event.id that is currently being edited
  const [selectedBrandIDs, setSelectedBrandIDs] = useState<string[]>([]); //the event.id that is currently being edited
  const [selectedActivityTypeIDs, setSelectedActivityTypeIDs] = useState<string[]>([]); //the event.id that is currently being edited
  const [selectedSegmentationIds, setSelectedSegmentationIds] = useState<string[]>([]);

  const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());

  function updateCurrentYear(newYear: number) {
    setCurrentYear(newYear);
  }

  const [error, setError] = useState("");

  /**
     * Applys all filtering for event related data
     *
     * @param {events} An array of events: ReportingEvent[].
     * @return {events} An array of filtered events: ReportingEvent[].
    */
  const applyAllActivityFilters = (activities: Activity[]) => {

    const allFilteredActivities = activities
    .filter((activity) => (selectedProvinces.length > 0 ? activity.provinces.some(i => selectedProvinces.includes(i)) : activity))
    .filter((activity) => (selectedBrandIDs.length > 0 ? activity.brandIds.some(i => selectedBrandIDs.includes(i)) : activity))
    .filter((activity) => (selectedActivityTypeIDs.length > 0 ? selectedActivityTypeIDs.includes(activity.activityTypeId) : activity))
    .filter((activity) => (selectedSegmentationIds.length > 0 ? activity.segmentationIds.some(i => selectedSegmentationIds.includes(i)) : activity))
    setFilteredActivities(allFilteredActivities);
    setIsFilteredActivitiesLoading(false);
  }

  const applyAllRecurringActivityFilters = (recurringActivityOccurences: RecurringActivityOccurence[]) => {

    const allFilteredOccurences = recurringActivityOccurences
    .filter((activity) => (selectedProvinces.length > 0 ? activity.provinces.some(i => selectedProvinces.includes(i)) : activity))
    .filter((activity) => (selectedBrandIDs.length > 0 ? activity.brandIds.some(i => selectedBrandIDs.includes(i)) : activity))
    .filter((activity) => (selectedActivityTypeIDs.length > 0 ? selectedActivityTypeIDs.includes(activity.activityTypeId) : activity))
    .filter((activity) => (selectedSegmentationIds.length > 0 ? activity.segmentationIds.some(i => selectedSegmentationIds.includes(i)) : activity))
    setFilteredOccurences(allFilteredOccurences);
    setIsFilteredOccurencesLoading(false);
  }


  useEffect(() => {
    if (!isActivitiesLoading && activities) {
      applyAllActivityFilters(activities);
    }
    if (!isRecurringActivitiesLoading && occurences) {
      applyAllRecurringActivityFilters(occurences);
    }
  }, [selectedProvinces, selectedBrandIDs, selectedActivityTypeIDs, selectedSegmentationIds]);

  useEffect(() => {
    if (!isRecurringActivitiesLoading && recurringActivities) {
      const formattedOccurences = getOccurencesForYear(recurringActivities, currentYear);
      setOccurences(formattedOccurences);
    }
  }, [currentYear, recurringActivities]);


  useEffect(() => {
    if (!isActivitiesLoading && activities) {
      setFilteredActivities(activities);
      applyAllActivityFilters(activities);
    }
    if (!isRecurringActivitiesLoading && occurences) {
      setFilteredOccurences(occurences);
      applyAllRecurringActivityFilters(occurences);
    }
  }, [activities, isActivitiesLoading, isRecurringActivitiesLoading, occurences]);

  const onActivityDelete = (activityId: string) => {
    const filteredActivities = activities.filter((x) => x.id !== activityId);
    setFilteredActivities(filteredActivities);
  }

  const onRecurringActivityDelete = (activityId: string) => {
    const filteredActivities = activities.filter((x) => x.id !== activityId);
    setFilteredActivities(filteredActivities);
  }

  return (
    <ActivityContext.Provider
      value={{
        filteredActivities,
        activities,
        isActivitiesLoading,
        isFilteredActivitiesLoading,
        recurringActivities,
        isRecurringActivitiesLoading,
        isFilteredOccurencesLoading,
        isHolidaysLoading,
        totalPages,
        error,
        selectedProvinces,
        selectedBrandIDs,
        selectedActivityTypeIDs,
        selectedSegmentationIds,
        brands,
        isBrandsLoading,
        activityTypes,
        segmentations,
        holidays,
        currentYear,
        filteredOccurences,
        setOccurences,
        updateCurrentYear,
        setSelectedProvinces,
        setSelectedBrandIDs,
        setSelectedActivityTypeIDs,
        setSelectedSegmentationIds,
        onActivityDelete,
        onRecurringActivityDelete,
        setError,
        getActivities,
        getRecurringActivities
      }}
    >
      {children}
    </ActivityContext.Provider>
  );
};

export default ActivityContext;
