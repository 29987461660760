import { DatePicker, Space } from 'antd';
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import { styled } from "@mui/material/styles";

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  "&.ant-picker.ant-picker-large": {
    padding: "13px 11px",
    input: {
      fontSize: "17px",
    },
    '&.ant-picker-input': {
      fontSize: "17px"
    },
    borderRadius: "4px",
    border: "2px solid #015bd2",

    "&.ant-picker-disabled": {
      border: "1px solid rgba(118, 118, 118, 0.3)"
    }
  }
}));

export {
    StyledDatePicker
}