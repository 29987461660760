import { GridColDef } from "@mui/x-data-grid";
import { IconButton, Tooltip } from "@mui/material";
import InsertCommentOutlinedIcon from '@mui/icons-material/InsertCommentOutlined';
import i18n, { TFunction } from 'i18next';

const ReportingGridColumnsFC = (column: string, language: string, updateSelectedEventId: (eventId: string) => void, updateSection: (section: string) => void, t:TFunction<"translation", undefined, "translation">) => {

  const TooltipProps = {
    tooltip: {
      sx: {
        fontSize: "0.9rem!important",
        maxWidth:"500px",
        textAlign:"center",
        marginBottom: "5px!important",
        bgcolor: "#ffffff",
        color: "primary.dark",
        boxShadow: " 0 2px 4px 0 rgba(0,0,0,0.15)",
        border: "1px solid #D8D8D8",
        "& .MuiTooltip-arrow": {
          color: "#ffffff",
          "&:before": {
            border: "1px solid #D8D8D8",
          },
        },
      },
    }
  }

  const speakerColumns: GridColDef[] = [
    {
      field: "displayName",
      headerName: t("list_view.speaker"),
      width: 350,
      minWidth: 350,
      editable: false,
      renderCell: (params) => <a href={`/reporting/speaker/${params.row._id}`}>{params.row.displayName}</a>,
    },
    {
      field: "email",
      headerName: t("reporting.event.email"),
      minWidth: 280,
      editable: false,
      flex: 1,
    },
    {
      field: "numberOfEvents",
      headerName: t("reporting.num_of_events"),
      width: 200,
      minWidth: 200,
      editable: false,
      flex: 1,
      align:"center",
      headerAlign: "center",
    },
    {
      field: "numberOfEvaluations",
      headerName: t("speaker_report.num_of_evals"),
      width: 200,
      minWidth: 200,
      editable: false,
      flex: 1,
      align:"center",
      headerAlign: "center",
    },
    {
      field: "rating",
      headerName: t("reporting.speaker_rating"),
      width: 200,
      minWidth: 200,
      editable: false,
      flex: 1,
      cellClassName: "reporting_cell",
      headerClassName: "reporting_header",
      headerAlign: "center",
    },
  ];

  const moderatorColumns: GridColDef[] = [
    {
      field: "displayName",
      headerName: t("create_event_form_moderator"),
      width: 350,
      minWidth: 350,
      editable: false,
      renderCell: (params) => <a href={`/reporting/moderator/${params.row._id}`}>{params.row.displayName}</a>,
    },
    {
      field: "email",
      headerName: t("reporting.event.email"),
      minWidth: 280,
      editable: false,
      flex: 1,
    },
    {
      field: "numberOfEvents",
      headerName: t("reporting.num_of_events"),
      width: 200,
      minWidth: 200,
      editable: false,
      flex: 1,
      align:"center",
      headerAlign: "center",
    },
    {
      field: "numberOfEvaluations",
      headerName: t("speaker_report.num_of_evals"),
      width: 200,
      minWidth: 200,
      editable: false,
      flex: 1,
      align:"center",
      headerAlign: "center",
    },
    {
      field: "rating",
      headerName: t("reporting.moderator_rating"),
      width: 200,
      minWidth: 200,
      editable: false,
      flex: 1,
      cellClassName: "reporting_cell",
      headerClassName: "reporting_header",
      headerAlign: "center",
    },
  ];

  const eventColumns: GridColDef[] = [
    {
      field: "displayName",
      headerName: t("reporting.event_name"),
      width: 350,
      editable: false,
      renderCell: (params) => {
        return (
          <a className="eventDisplayName" href={`/reporting/event/${params.row.id}`}>{params.row.displayName}</a>
        );
      }
    },
    {
      field: "language",
      headerName: t("reporting.language"),
      width: 150,
      minWidth: 150,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return <span>{t(params.row.language.toLowerCase())}</span>
      }
    },
    {
      field: "repName",
      headerName: t("reporting.rep"),
      width: 200,
      minWidth: 200,
      editable: false,
      flex: 1,
    },
    {
      field: "regionName",
      headerName: t("reporting.region"),
      width: 200,
      minWidth: 200,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return (
        <Tooltip title={params.row.regionName} placement="top"
        arrow={true}
        componentsProps={TooltipProps}
        enterNextDelay={500}
        enterDelay={500}
        leaveDelay={200}>
          <span>{params.row.regionName}</span>
        </Tooltip>)
      }
    },
    {
      field: "evaluationCount",
      headerName: t("speaker_report.num_of_evals"),
      width: 200,
      minWidth: 200,
      editable: false,
      flex: 1,
      align:"center",
      headerAlign: "center",
    },
    {
      field: "eventRating",
      headerName: t("reporting.event_rating"),
      width: 250,
      minWidth: 250,
      editable: false,
      cellClassName: "reporting_cell",
      headerClassName: "reporting_header",
      headerAlign: "center",
    },
    {
      field: "comments",
      headerName: "",
      flex: 1,
      editable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <strong>
          <IconButton edge="end" aria-label="edit" style={{ marginLeft: 0 }} onClick={() => (updateSelectedEventId(params.row.id), updateSection("comments")) }>
            <InsertCommentOutlinedIcon sx={{color:"#AAAAAA"}}/>
          </IconButton>
        </strong>
      ),
    },
  ];

  const learningObjectiveColumns: GridColDef[] = [
    {
      field: "value", //TODO change to value and translate in renderCell
      headerName: t("reporting.learning_objective"),
      flex: 1,
      editable: false,
      renderCell: (params) => {
        if (language === "en") return params.row.enName;
        return params.row.frName;
      },
    },
    {
      field: "therapeuticArea",
      headerName: t("reporting.therapeutic_area"),
      width: 240,
      minWidth: 240,
      editable: false,
      renderCell: (params) => {
        if (language === "en") return params.row.therapeuticAreaEnName;
        return params.row.therapeuticAreaFrName;
      },
      valueGetter: (params) => {
        if (language === "en") return params.row.therapeuticAreaEnName;
        return params.row.therapeuticAreaFrName;
      },
    },
    {
      field: "popularity",
      headerName: t("reporting.popularity"),
      width: 250,
      minWidth: 250,
      editable: false,
      cellClassName: "reporting_cell",
      headerClassName: "reporting_header",
      headerAlign: "center",
    },
  ];

  const commentsColumns: GridColDef[] = [
    {
      field: "conceptLearnedComment",
      headerName: `${i18n.t("evaluation.evaluation_form.comment_questions.question_one")}`,
      flex: 1,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      headerClassName: "comment_header",
      cellClassName: "comment_cell",
    },
    {
      field: "otherTopicsComment",
      headerName: `${i18n.t("evaluation.evaluation_form.comment_questions.question_two")}`,
      editable: false,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      headerClassName: "comment_header",
      cellClassName: "comment_cell",
    },
    {
      field: "additionalComment",
      headerName: `${i18n.t("evaluation.evaluation_form.comment_questions.question_three")}`,
      editable: false,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      headerClassName: "comment_header",
      cellClassName: "comment_cell",
    },
  ];

  switch (column) {
    case "speakerColumns":
      return speakerColumns;
    case "moderatorColumns":
      return moderatorColumns;
    case "eventColumns":
      return eventColumns;
    case "learningObjectiveColumns":
      return learningObjectiveColumns;
    case "commentsColumns":
      return commentsColumns;
    default:
      return speakerColumns;
  }

}

export default ReportingGridColumnsFC;
