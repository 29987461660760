import { axios } from "./axios";
import useSWR from "swr";
import { Event } from "../typings/interfaces";

export const ENDPOINT = "/events/evaluations";
const EVENT_ENDPOINT = "/events";
const EVENT_ENDPOINT_EDIT = "/events";

export async function getEventsWithEvals() {
  const token = localStorage.getItem("SavedToken");
  if (token !== null) {
    const { data } = await axios.get(ENDPOINT);
    return data.events;
  }

  return [];
}

export const useEvents = () => {
  async function createEvent(event: any) {
    const { data } = await axios.post(EVENT_ENDPOINT, event);
    mutateEventsWithEvals();

    return data;
  }

  async function editEvent(event: any) {
    const { data } = await axios.put(`${EVENT_ENDPOINT_EDIT}/${event.id}`, event);
    mutateEventsWithEvals();

    return data;
  }

  /**
   * Returns a list of all the events filtered for the card view
   * @param page The page number of the data to retreive
   * @param pageCount How many items are returned per page
   * @param filterType How to filter the data (all, future, past, happening_now)
   * @returns A page of the event data with the count of total pages available.
   */
  async function getAllCardEvents(page: number = 1, pageCount: number = 12, filterType: string = "all") {
    let cardEvents: Event[] = [];
    let totalPages = 0;
    let axiosError;
    try {
      const response = await axios.get(EVENT_ENDPOINT, {
        params: {
          type: filterType,
          page,
          pageCount,
        },
      });

      cardEvents = response.data.conferences as Event[];
      totalPages = response.data.total_pages;

      return { cardEvents, totalPages };
    } catch (err) {
      // throw new Error('Unable to establish a login session.'); // here I'd like to send the error to the user instead
      console.log("err", err);
      return { cardEvents, totalPages, err };
    }
  }

  /**
   * Get an event by their eventId
   * @param id The ID of the event to look up
   * @returns The found event
   */
  async function getById(id: string) {
    const { data } = await axios.get(`${EVENT_ENDPOINT}/event/${id}`);

    return data;
  }

  /**
   * Used to check if the attendee has already checked in for the event.
   * @param email The email of the attendee to check
   * @param id The event ID to check.
   * @returns The attendee record if it exists or a 404 code if not found
   */
  async function getAttendee(email: string, id: string) {
    const { data } = await axios.get(`${EVENT_ENDPOINT}/${id}/attendee?email=${email}`);

    return data;
  }

  async function checkIn(eventId: string, email: string, lastName: string, firstName: string, jobTitle: string, clinic: string, receiveFiles: boolean, followUp: boolean) {
    const { data } = await axios.post(`${EVENT_ENDPOINT}/${eventId}/checkin`, {
      email,
      lastName,
      firstName,
      jobTitle,
      clinic,
      receiveFiles,
      followUp,
    });

    return data;
  }

  /**
   * This will delete an event from the DB if allowed. You cannot delete events that have attendees or evaluations.
   * @param eventId the ID of the event that you want to delete
   */
  async function deleteEvent(eventId: string) {
    const response = await axios.delete(`${EVENT_ENDPOINT}/${eventId}`);
    mutateEventsWithEvals();

    return response;
  }

  const {
    isLoading: isEventsLoading,
    error: eventsError,
    data: events,
    mutate: mutateEventsWithEvals,
  } = useSWR(ENDPOINT, getEventsWithEvals, {
    onSuccess: (data) => data,
    revalidateOnFocus: false,
    refreshInterval: 120000, //refresh every 5 minutes to keep data up to date
    revalidateOnMount: true,
  });

  const data = events as Event[];

  return {
    isEventsLoading,
    eventsError,
    events: data,
    createEvent,
    editEvent,
    getAllCardEvents,
    getById,
    getAttendee,
    checkIn,
    deleteEvent,
  };
};
