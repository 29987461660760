import { Link, Grid, LinkProps } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import AppContext from "../../context/Auth";
import { useTranslation } from "react-i18next";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ToolNav from "./NavToolSelector";
import NavLanguagePicker from "./NavLanguagePicker";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/Logout";

const NavLink = styled(Link)<LinkProps>(({ theme }) => ({
  color: theme.palette.primary.dark,
  textDecoration: "none",
  margin: "0 15px",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  "& .MuiSvgIcon-root": {
    marginRight: "5px",
  },
}));

type Props = {
  mobile: boolean;
};
const NavGeneral = ({ mobile }: Props) => {
  const { isAuthenticated, hasRole, logout } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (mobile) {
    return (
      <React.Fragment>
        <List sx={{ width: "100%", maxWidth: "100%", bgcolor: "background.paper", pt: 0 }} component="nav" aria-labelledby="nested-list-subheader">
          {isAuthenticated && hasRole(["sudo", "admin"]) && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate("/users");
                }}
              >
                <ListItemIcon>
                  <PersonOutlineIcon />
                </ListItemIcon>
                <ListItemText primary={t("users_title")} />
              </ListItemButton>
            </ListItem>
          )}
          {isAuthenticated && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  logout();
                  navigate("/");
                }}
              >
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={t("logout")} />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </React.Fragment>
    );
  } else {
    return (
      <>
        <Grid item display="flex" alignItems="center" justifyContent="flex-end" sx={{ width: "100%" }}>
          <ToolNav mobile={false} />
          {isAuthenticated && hasRole(["sudo", "admin"]) && (
            <NavLink
              style={{ marginLeft: "40px" }}
              onClick={() => {
                navigate("/users");
              }}
            >
              <PersonOutlineIcon />
              {t("users_title")}
            </NavLink>
          )}

          {isAuthenticated && (
            <NavLink
              sx={{whiteSpace: "no-wrap"}}
              onClick={() => {
                logout();
                navigate("/");
              }}
            >
              {t("logout")}
            </NavLink>
          )}

          <NavLanguagePicker />
        </Grid>
      </>
    );
  }
};

export default NavGeneral;
