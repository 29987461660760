import { axios } from "./axios";
import useSWR from "swr";
import { Activity } from "../typings/interfaces";

export const ENDPOINT = "/activities";

export async function getActivities() {
  const { data } = await axios.get(ENDPOINT);
  return data.activities as Activity[];
}

export const useActivities = () => {
  /**
   * Get an activity by the activity._id
   * @param id The ID of the activity to look up
   * @returns The found activity
   */
  async function getById(id: string) {
    const { data } = await axios.get(`${ENDPOINT}/activity/${id}`);

    return data;
  }

  async function createActivity(activity: any) {
    const { data } = await axios.post(ENDPOINT, activity);
    mutateActivities();

    return data;
  }

  async function editActivity(activity: any) {
    const { data } = await axios.put(`${ENDPOINT}/${activity.id}`, activity);
    mutateActivities();

    return data;
  }

  /**
   * This will delete an activity from the DB if allowed.
   * @param id the ID of the activity that you want to delete
   */
  async function deleteActivity(id: string) {
    const response = await axios.delete(`${ENDPOINT}/${id}`);
    mutateActivities();

    return response;
  }

  const {
    isLoading: isActivitiesLoading,
    error: activitiesError,
    data: activities,
    mutate: mutateActivities,
  } = useSWR(ENDPOINT, getActivities, {
    onSuccess: (data) => data,
    revalidateOnFocus: false,
    revalidateIfStale: false,
    refreshInterval: 120000, //refresh every 5 minutes to keep data up to date
  });

  const data = activities as Activity[];

  return {
    isActivitiesLoading,
    activitiesError,
    activities: data,
    mutateActivities,
    createActivity,
    editActivity,
    getActivities,
    getById,
    deleteActivity,
  };
};
