import { axios } from "./axios";
import useSWR from "swr";
import { Users } from "../typings/interfaces";
import { sortDataAlpha } from "../utils/sort"

export const ENDPOINT = "/users/reps";

export async function getReps() {
  const { data } = await axios.get(ENDPOINT);
  const sortedReps = sortDataAlpha(data.reps, "displayName") 
  return sortedReps;
}

export const useReps = () => {
  const {
    isLoading: isRepsLoading,
    error: repsError,
    data: sortedReps,
    mutate,
  } = useSWR(ENDPOINT, getReps, {
    onSuccess: (data) => data,
    revalidateOnFocus: true,
    revalidateIfStale: false,
  });

  const data = sortedReps as Users[];

  return {
    isRepsLoading,
    repsError,
    reps: data,
    mutateReps: mutate,
  };
};
