import React, { useContext, useState } from "react";
import { styled as muiStyled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

//drawer elements used
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, Button, Checkbox, Divider, TextField, Typography } from "@mui/material";
import { FilterAlt, FilterAltOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { ActivityType, Brand, Province, Segmentation } from "../../typings/interfaces";
import i18next from "i18next";
import ActivityContext from "../../context/ActivityContext";
import { getBrandsByIds } from "../../utils/brands";
import { getActivityTypesByIds } from "../../utils/activityTypes";
import { getSegmentationsById } from "../../utils/segmentations";
import { getProvincesByValues } from "../../utils/provinces";

const AutoCompleteTextField = muiStyled(TextField)(({ theme }) => ({
    "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
      visibility: "visible",
      backgroundColor: theme.palette.primary.dark,
      color: "#FFFFFF",
  
      "& svg": {
        fontSize: "1rem",
      }
    }
}));

interface FilterProps {
    brands: Brand[];
    activityTypes: ActivityType[];
    provinces: Province[];
    segmentations: Segmentation[];
}

const MobileActivityFiltersDrawer = ({brands, activityTypes, provinces, segmentations}: FilterProps) => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const {
        selectedProvinces,
        setSelectedProvinces,
        selectedActivityTypeIDs,
        setSelectedActivityTypeIDs,
        selectedBrandIDs,
        setSelectedBrandIDs,
        selectedSegmentationIds,
        setSelectedSegmentationIds
    } = useContext(ActivityContext);

    const currentLanguage = i18next.language;

    const [filterSelectedProvinces, setFilterSelectedProvinces] = useState<string[]>(selectedProvinces);
    const [filterSelectedBrandIds, setFilterSelectedBrandIds] = useState<string[]>(selectedBrandIDs);
    const [filterSelectedActivityTypeIds, setFilterSelectedActivityTypeIds] = useState<string[]>(selectedActivityTypeIDs);
    const [filterSelectedSegmentationIds, setFilterSelectedSegmentationIds] = useState<string[]>(selectedSegmentationIds);

    const { t } = useTranslation();
    const toggleDrawer = (open: boolean) => (event: any) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        //changes the function state according to the value of open
        setFilterSelectedActivityTypeIds(selectedActivityTypeIDs);
        setFilterSelectedBrandIds(selectedBrandIDs);
        setFilterSelectedProvinces(selectedProvinces);
        setOpenDrawer(open);
    };

    const applyFilters = () => {
        setSelectedProvinces(filterSelectedProvinces);
        setSelectedBrandIDs(filterSelectedBrandIds);
        setSelectedActivityTypeIDs(filterSelectedActivityTypeIds);
        setSelectedSegmentationIds(filterSelectedSegmentationIds);
        setOpenDrawer(false);
    }

    const clearFilters = () => {
        setFilterSelectedProvinces([]);
        setFilterSelectedBrandIds([]);
        setFilterSelectedActivityTypeIds([]);
        setFilterSelectedSegmentationIds([]);
    }

    const filterByProvince = (event: React.SyntheticEvent<Element, Event>, value: Province[] | null, reason: any, details?: any | undefined) => {
        if (value && (reason === "selectOption" || reason === "removeOption")) {
          const provinceValues = value.map(i => i.value);
          //send id back up to filter events by repID on the Events Page
          setFilterSelectedProvinces(provinceValues);
        } else {
            setFilterSelectedProvinces([]);
        }
    };

    const filterByBrand = (event: React.SyntheticEvent<Element, Event>, value: Brand[] | null, reason: any, details?: any | undefined) => {
        if (value && (reason === "selectOption" || reason === "removeOption")) {
            const brandIDs = value.map(i => i._id);
            //send id back up to filter events by repID on the Events Page
            setFilterSelectedBrandIds(brandIDs); //TODO change to setBrandID
        } else {
            setFilterSelectedBrandIds([]); //TODO change to setBrandID
        }
    };

    const filterByActivityType = (event: React.SyntheticEvent<Element, Event>, value: ActivityType[] | null, reason: any, details?: any | undefined) => {
        if (value && (reason === "selectOption" || reason === "removeOption")) {
            const activityTypeIDs = value.map(i => i._id);
            //set activity ID in context to filter
            setFilterSelectedActivityTypeIds(activityTypeIDs); //TODO change to setBrandID
        } else {
            setFilterSelectedActivityTypeIds([]);//TODO change to setBrandID
        }
    };

    const filterBySegmentation = (event: React.SyntheticEvent<Element, Event>, value: Segmentation[] | null, reason: any, details?: any | undefined) => {
        if (value && (reason === "selectOption" || reason === "removeOption")) {
            const segmentationIds = value.map(i => i._id);
            //send id back up to filter events by repID on the Events Page
            setFilterSelectedSegmentationIds(segmentationIds); //TODO change to setBrandID
        } else {
            setFilterSelectedSegmentationIds([]); //TODO change to setBrandID
        }
    };

    const SelectFilterText = () => {
        const filterCount = selectedActivityTypeIDs.length + selectedBrandIDs.length + selectedProvinces.length + selectedSegmentationIds.length;
        if (filterCount > 0) {
            return `${t("filter_drawer.selected")}${filterCount} ${t("filter_drawer.filters")}`
        }
        return `${t("filter_drawer.select")}${t("filter_drawer.filters")}`
    }

    return (
    <>
    <IconButton
        edge="end"
        color="primary"
        aria-label={`${t("filter_drawer.select")}${t("filter_drawer.filters")}`}
        onClick={toggleDrawer(true)}
        sx={{
            display: "flex",
            borderRadius: 2,
        }}
    >
        {selectedActivityTypeIDs.length + selectedBrandIDs.length + selectedProvinces.length + selectedSegmentationIds.length > 0 ? <FilterAlt sx={{ p: 0, m: 0 }} /> : <FilterAltOutlined />}
        <Typography my="auto" ml={1}>{SelectFilterText()}</Typography>
    </IconButton>
    <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer(false)} >
        <Box
            sx={{
                p: 2,
                height: 1,
                backgroundColor: "#fffff",
                minWidth: "380px",
            }}
        >
            <Box sx={{ display: "flex", mb: 2, justifyContent: "flex-start" }}>
                <IconButton>
                    <CloseIcon onClick={toggleDrawer(false)} />
                </IconButton>
                <Typography variant="h6" my="auto">{t("filter_drawer.activity_hub_title")}</Typography>
            </Box>

            <Divider sx={{ mb: 2 }} />
            
            <Box sx={{ mb: 2 }}>
                <Autocomplete
                    sx={{ width:"100%", maxWidth:"475px", my: 2}}
                    onChange={(event: React.SyntheticEvent<Element, Event>, value: Brand[] | null, reason: any, details?: any | undefined) => filterByBrand(event, value, reason, details)}
                    disableListWrap
                    id="brands"
                    multiple
                    options={brands}
                    disablePortal={true}
                    value={brands ? brands.filter((i) => filterSelectedBrandIds.includes(i._id)) : []}
                    autoHighlight
                    getOptionLabel={(option) => currentLanguage === "en" ? option.enName : option.frName}
                    renderTags={() => ""}
                    isOptionEqualToValue={(option, value) => {
                        return option._id === value._id;
                    }}
                    disableCloseOnSelect
                    renderOption={(props, option, {selected}) => (
                        <Box component="li" {...props}>
                        <Checkbox
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {currentLanguage === "en" ? option.enName : option.frName}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <AutoCompleteTextField
                        {...params}
                        placeholder={t("engagement_tracker_filter_placeholder.product")}
                        label={t("filter_drawer.product_brand")}
                        inputProps={{
                            ...params.inputProps,
                            value: (!filterSelectedBrandIds || filterSelectedBrandIds.length === 0) ? params.inputProps.value : getBrandsByIds(filterSelectedBrandIds, brands, currentLanguage)
                        }}
                        />
                    )}
                />
                <Autocomplete
                    sx={{ width:"100%", maxWidth:"475px", my: 2}}
                    onChange={(event: React.SyntheticEvent<Element, Event>, value: Province[] | null, reason: any, details?: any | undefined) => filterByProvince(event, value, reason, details)}
                    disableListWrap
                    id="provinces"
                    multiple
                    options={provinces}
                    disablePortal={true}
                    value={provinces? provinces.filter((i) => filterSelectedProvinces.includes(i.value)) : []}
                    autoHighlight
                    getOptionLabel={(option) => currentLanguage === "en" ? option.name : option.frName}
                    renderTags={() => ""}
                    isOptionEqualToValue={(option, value) => {
                        return option.value === value.value;
                    }}
                    disableCloseOnSelect
                    renderOption={(props, option, {selected}) => (
                        <Box component="li" {...props}>
                        <Checkbox
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {currentLanguage === "en" ? option.name : option.frName}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <AutoCompleteTextField
                        {...params}
                        label={t("filter_drawer.province")}
                        placeholder={filterSelectedProvinces.length === 0 ? t("engagement_tracker_filter_placeholder.provinces") : ""}
                        inputProps={{
                            ...params.inputProps,
                            value: filterSelectedProvinces.length === 0 ? params.inputProps.value : getProvincesByValues(filterSelectedProvinces, currentLanguage)
                        }}
                        />
                    )}
                />

                <Autocomplete
                    sx={{width:"100%", maxWidth:"475px", my: 2}}
                    onChange={(event: React.SyntheticEvent<Element, Event>, value: ActivityType[] | null, reason: any, details?: any | undefined) => filterByActivityType(event, value, reason, details)}
                    disableListWrap
                    id="activityTypes"
                    multiple
                    options={activityTypes}
                    disablePortal={true}
                    value={activityTypes ? activityTypes.filter((i) => filterSelectedActivityTypeIds.includes(i._id)) : []}
                    autoHighlight
                    getOptionLabel={(option) => currentLanguage === "en" ? option.enName : option.frName}
                    renderTags={() => ""}
                    isOptionEqualToValue={(option, value) => {
                        return option._id === value._id;
                    }}
                    disableCloseOnSelect
                    renderOption={(props, option, {selected}) => (
                        <Box component="li" {...props}>
                        <Checkbox
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {currentLanguage === "en" ? option.enName : option.frName}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <AutoCompleteTextField
                        {...params}
                        label={t("filter_drawer.activity_type")}
                        placeholder={t("engagement_tracker_filter_placeholder.activity")}
                        inputProps={{
                            ...params.inputProps,
                            value: filterSelectedActivityTypeIds.length === 0 ? params.inputProps.value : getActivityTypesByIds(filterSelectedActivityTypeIds, activityTypes, currentLanguage)
                        }}
                        />
                    )}
                />
                
                <Autocomplete
                    sx={{ width:"100%", maxWidth:"475px", my: 2}}
                    onChange={(event: React.SyntheticEvent<Element, Event>, value: Segmentation[] | null, reason: any, details?: any | undefined) => filterBySegmentation(event, value, reason, details)}
                    disableListWrap
                    id="segmentations"
                    multiple
                    options={segmentations}
                    disablePortal={true}
                    value={segmentations ? segmentations.filter((i) => filterSelectedSegmentationIds.includes(i._id)) : []}
                    autoHighlight
                    getOptionLabel={(option) => currentLanguage === "en" ? option.enName : option.frName}
                    renderTags={() => ""}
                    isOptionEqualToValue={(option, value) => {
                        return option._id === value._id;
                    }}
                    disableCloseOnSelect
                    renderOption={(props, option, {selected}) => (
                        <Box component="li" {...props}>
                        <Checkbox
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {currentLanguage === "en" ? option.enName : option.frName}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <AutoCompleteTextField
                        {...params}
                        label={t("filter_drawer.segmentation")}
                        placeholder={t("engagement_tracker_filter_placeholder.segmentation")}
                        inputProps={{
                            ...params.inputProps,
                            value: (!filterSelectedSegmentationIds || filterSelectedSegmentationIds.length === 0) ? params.inputProps.value : getSegmentationsById(filterSelectedSegmentationIds, segmentations, currentLanguage)
                        }}
                        />
                    )}
                />
                
                <Button variant="outlined" sx={{mr: 2}} onClick={clearFilters}>{t("filter_drawer.clear_button")}</Button>
                <Button variant="contained" onClick={applyFilters}>{t("filter_drawer.apply_button")}</Button>
            </Box>
        </Box>
    </Drawer>
    </>
  );
};

export default MobileActivityFiltersDrawer;
