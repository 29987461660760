import { Container, Box } from "@mui/material";
import React from "react";
import Nav from "./Nav";
import Footer from "./Footer";

type Props = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  fullWidth?: boolean;
  genericLogo?: boolean; 
};

const Layout = ({children, style, fullWidth, genericLogo}: Props) => {
  return (
    <React.Fragment>
      <Nav genericLogo={genericLogo} />
      <Box className="background" sx={{ backgroundColor: "#FAFAFA", width: "100%", minHeight: "90vh", paddingBottom: "0px", position: "relative", overflow: "hidden" }}>
        <Container maxWidth={fullWidth ? false : "xl"} disableGutters={fullWidth} sx={style}>
          {children}
        </Container>
      </Box>
      <Footer />
    </React.Fragment>
  );
};

export default Layout;
