import { LearningObjective } from "../typings/interfaces";

/**
 * Sort an array of objects by a specific key.
 * @param {Array} array - The array of objects to be sorted.
 * @param {string} key - The key by which to sort the objects.
 * @returns {Array} - The sorted array of objects.
 */
export const sortDataAlpha = (data: any[], sortKey: string) => {
  const dataToSort = data && data.length > 0 ? [...data] : []; 
  const sortedData = dataToSort.sort((a, b) => {
    const valueA = a[sortKey].toLowerCase();
    const valueB = b[sortKey].toLowerCase();
  
    if (valueA < valueB) {
      return -1;
    }
    if (valueA > valueB) {
      return 1;
    }
    return 0;
  });
  return sortedData; 
}