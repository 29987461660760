import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { Chip } from "@mui/material";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import { format } from "date-fns";
import { Event } from "../../typings/interfaces";
import { useAnimatedCounter } from "../../hooks/useAnimatedCounter";
import GroupsIcon from "@mui/icons-material/Groups";
import { motion } from "framer-motion";
import useEventName from "../../hooks/useEventName";
import { useTranslation } from "react-i18next";
import { GetLocationString } from "../../utils/location";
import { parseEventStartTime } from "../../utils/parseDate";

const ReportHeaderWrapper = styled(Grid)(({ theme }) => ({
  width: "100%",
  padding: "20px",
  backgroundColor: "#ffffff",
  border: "1px solid #D5D5D5",
  borderRadius: "5px",
  position: "relative",
}));

const MotionReportHeaderWrapper = motion(ReportHeaderWrapper);

const ReportHeaderTitleWrapper = styled(Grid)(({ theme }) => ({
  width: "100%",
  padding: "0px 10px",
  display: "flex",
  alignItems: "baseline",
}));

const ReportHeaderTitle = styled("h1")(({ theme }) => ({
  color: theme.palette.primary.dark,
  paddingRight: "30px",
  margin: "15px 0px",
  fontFamily: "Apis",
  fontWeight: 700,
  fontSize: "26px",
}));

const ReportHeaderInfo = styled(Grid)(({ theme }) => ({
  width: "100%",
  padding: "5px 10px",
  display: "block",
  alignItems: "center",
  marginBottom: "5px",
  [theme.breakpoints.up('lg')]: {
    display: "flex"
  }
}));

type ReportHeaderInfoTextProps = {
  primary?: boolean;
};

const ReportHeaderInfoText = styled("span")<ReportHeaderInfoTextProps>(({ theme, primary }) => ({
  color: primary ? theme.palette.primary.dark : "#3D3D3D",
  display: "flex",
  alignItems: "center",
  paddingRight: "20px",
  fontSize: primary ? "18px" : "16px",
  lineHeight: "1.7rem",
  [theme.breakpoints.up('lg')]: {
    lineHeight: "auto",
  }
}));

const ReportHeaderRating = styled("span")<ReportHeaderInfoTextProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  display: "flex",
  alignItems: "center",
  paddingRight: "20px",
  fontSize: "24px",
  fontWeight: "700",
  marginLeft: "auto",
  marginTop:"20px",
  [theme.breakpoints.up('lg')]: {
    fontSize: "16px",
    fontWeight: "normal",
    marginTop:"auto",
  }
}));

const ReportHeaderRatingNumber = styled("span")<ReportHeaderInfoTextProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "24px",
  fontWeight: 700,
  paddingLeft: "20px",
  alignSelf: "flex-start",
  display: "flex",
  lineHeight: "auto",
  [theme.breakpoints.up('lg')]: {
    lineHeight: "6px",
    fontSize: "50px",
  }
}));

type ReportEventHeaderProps = {
  event: Event;
  loading: boolean;
};


const ReportEventHeader = ({ event, loading }: ReportEventHeaderProps) => {

  const [formatEventName] = useEventName({ hasSpeakers: true });
  const { t, i18n} = useTranslation();

  const eventType = event.eventType ? t(event.eventType) : t("no_type_provided")
  const eventRegion = event.region ? (i18n.language === "en") ? event.region.enName : event.region.frName : "N/A";
  const startDate = format(parseEventStartTime(event.start_time),  i18n.language === "en" ? "h:mm aa" : "H 'h 'mm")
  const speakers = event.speakers;
  const moderators = event.moderators;
  const rep = event.rep;
  const timeZoneArray = event.timeZone.split("/");
  const timeZone = timeZoneArray[1];
  const translatedTimeZone = t(`time_zones_abr.${timeZone}`);

  const reportingEvent = event as Event;
  reportingEvent.speakerIds = event.speakers?.map((i) => i.speakerId) || [];

  return (
    <>
      <MotionReportHeaderWrapper container initial={{ opacity: 0 }} animate={{ opacity: loading ? 0 : 1 }} transition={{ duration: 0.8 }}>
        <ReportHeaderTitleWrapper>
          <ReportHeaderTitle>{formatEventName(reportingEvent)}</ReportHeaderTitle>
          <Chip sx={{ backgroundColor: "primary.main", color: "#ffffff", height: "30px", fontWeight: 500, fontSize: "16px", padding: "0px 15px" }} label={t(eventType.toLowerCase())} />
        </ReportHeaderTitleWrapper>

        <ReportHeaderInfo>
          {event.title && false && 
            <ReportHeaderInfoText primary sx={{ fontStyle: "italic" }}>
              {event.title}
            </ReportHeaderInfoText>
          }
          <ReportHeaderInfoText>
            <AccessTimeOutlinedIcon sx={{ color: "#747474", paddingRight: "5px" }} /> {startDate} {translatedTimeZone}
          </ReportHeaderInfoText>
          <ReportHeaderInfoText>
            <MapOutlinedIcon sx={{ color: "#747474", paddingRight: "2px" }} /> {eventRegion}
          </ReportHeaderInfoText>
          { event.street && 
            <ReportHeaderInfoText>
              <LocationOnOutlinedIcon sx={{ color: "#747474", paddingRight: "2px" }} /> {GetLocationString(event, i18n.language)}
            </ReportHeaderInfoText>
          }
        </ReportHeaderInfo>

        <ReportHeaderInfo sx={{ mb: "0px", pb: "0px" }}>
          <ReportHeaderInfoText>
            <GroupsIcon sx={{ color: "primary.main", paddingRight: "15px", fontSize: "32px" }} /> {t("event_info_card.rep")} {rep ? rep.name : "N/A"}
          </ReportHeaderInfoText>
          <ReportHeaderInfoText>{t("evaluation.page.speakers")} {speakers && speakers.length > 0 ? speakers.map((i) => i.name).join(", ") : ""}</ReportHeaderInfoText>
          <ReportHeaderInfoText>{t("evaluation.page.moderators")} {moderators && moderators.length > 0 ? moderators.map((i) => i.name).join(", ") : "N/A"}</ReportHeaderInfoText>
          <ReportHeaderRating>
            {t("reporting.event.overall_event_rating")} <ReportHeaderRatingNumber>{useAnimatedCounter(event.eventRating, 0, 1).toFixed(1)}</ReportHeaderRatingNumber>
          </ReportHeaderRating>
        </ReportHeaderInfo>
      </MotionReportHeaderWrapper>
    </>
  );
};

export default ReportEventHeader;
