import { darken, styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";


type ReportingSpeakerGridTableProps = {
  size?: string, 
}

const ReportingSpeakerGridTable = styled(DataGrid)<ReportingSpeakerGridTableProps>(({ theme, size }) => ({
  backgroundColor: "#ffffff",
  height: "343px",

  "& a": {
    color: theme.palette.primary.dark,
  },

  "& .MuiDataGrid-columnHeader": {
    height: size === "small" ? "39px !important" : "56px",
  },
  // "& .MuiDataGrid-virtualScroller": {
  //   marginTop: size === "small" ? "39px !important" : "56px",
  // },
  "& .MuiDataGrid-cell": {
    color: theme.palette.primary.main,
    fontWeight: 900,
  },
  "& .MuiDataGrid-cell:first-child": {
    textDecoration: "underline",
    fontWeight: 500,
  },
  "& .MuiDataGrid-cell:nth-child(2)": {
    fontWeight: 500,
    color: theme.palette.primary.dark,
  },

  "& .MuiDataGrid-row--lastVisible": {
    "& .reporting_cell": {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
  },

  "& .MuiDataGrid-row:nth-of-type(odd)": {
    backgroundColor: "#ffffff",
  },
  "& .MuiDataGrid-row:nth-of-type(even)": {
    backgroundColor: "#cecece14",
  },
  "& .MuiDataGrid-row": {
    
    "& .MuiDataGrid-cell:last-child": {
        borderLeft: `1px solid ${theme.palette.primary.main}`,
        borderRight: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: darken("#005ad20d", 0.8),
    },
  },
  "& .MuiDataGrid-row:last-child": {
    
    "& .MuiDataGrid-cell:last-child": {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
    }
  },

  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: theme.palette.primary.light,
    textTransform: "uppercase",
    fontSize: size === "small" ? "13px" : "16px",
    minHeight: size === "small" ? "39px !important" : "56px",
    maxHeight: size === "small" ? "39px !important" : "56px",
    lineHeight: size === "small" ? "39px !important" : "56px",
    borderRadius: "0px",

    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },

    "& .reporting_header": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiDataGrid-columnHeaderTitle": {
        color: "#ffffff",
      },
      "& .MuiButtonBase-root": {
        color: "#ffffff",
      }
    },

    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold",
      color: theme.palette.primary.dark,
    },

    "& .MuiDataGrid-columnHeader:last-child": {
        fontWeight: "bold",
        backgroundColor: theme.palette.primary.main,
        borderLeft: `1px solid ${theme.palette.primary.main}`,
        borderRight: `1px solid ${theme.palette.primary.main}`,

        "& .MuiDataGrid-columnHeaderTitle": {
            color: "#ffffff",
        }
    },
  },

}));


export default ReportingSpeakerGridTable;