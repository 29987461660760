import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

//drawer elements used
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { Link, LinkProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import NavGeneral from "./NavGeneral";
import NavToolSelector from "./NavToolSelector";
import NavLanguagePicker from "./NavLanguagePicker";

const NavLink = styled(Link)<LinkProps>(({ theme }) => ({
  color: "#3D3D3D",
  margin: "0 15px",
  textDecoration: "none",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  "& .MuiSvgIcon-root": {
    marginRight: "5px",
  },
}));

interface NavLogoProps {
  currentTool: string;
}

const NavLogo = ({ currentTool }: NavLogoProps) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  if (currentTool === "activity-calendar") {
    return (
      <img
        src={currentLanguage === "en" ? "/assets/en/engagement_tracker_logo_en.png" : "/assets/en/engagement_tracker_logo_en.png"}
        alt={currentLanguage === "en" ? "NNCI Engagement Tracker" : "NNCI Engagement Tracker FR"}
        style={{
          width: "auto",
          marginBottom: 0,
          color: "#3D3D3D",
          height: "52px",
        }}
      />
    );
  } else {
    return (
      <img
        src={currentLanguage === "en" ? "/assets/en/eventhub_logo_en.png" : "/assets/en/eventhub_logo_en.png"}
        alt={currentLanguage === "en" ? "NNCI Event Hub" : "Centre des événements de Novo Nordisk"}
        style={{
          width: "auto",
          marginBottom: 0,
          color: "#3D3D3D",
          height: "52px",
        }}
      />
    );
  }
};

interface ToolNavigationProps {
  mobile: boolean;
}

const NavMobileMenu = ({ ToolNavigation, currentTool }: { ToolNavigation: React.FC<ToolNavigationProps>; currentTool: string }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();
  const toggleDrawer = (open: boolean) => (event: any) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    //changes the function state according to the value of open
    setOpenDrawer(open);
  };

  return (
    <AppBar position="static" elevation={0} sx={{ backgroundColor: "#FFFFFF" }}>
      <Container maxWidth={false} disableGutters={true}>
        <Grid item display={"flex"} alignItems="center">
          <NavLink sx={{ height: { xs: "52px", sm: "52px" } }} onClick={() => navigate("/")}>
            <NavLogo currentTool={currentTool} />
          </NavLink>
          <Toolbar sx={{ width: "100%", paddingLeft: "0px", paddingRight: "0px", justifyContent: "flex-end" }} disableGutters={true}>
            <Box
              component="div"
              sx={{
                display: {
                  width: "100%",
                  xs: "none",
                  lg: "flex",
                },
              }}
            >
              <ToolNavigation mobile={false} />
              <NavGeneral mobile={false} />
            </Box>

            <IconButton
              edge="end"
              color="primary"
              aria-label="open drawer"
              onClick={toggleDrawer(true)}
              sx={{
                mr: 2,
                display: {
                  md: "block",
                  lg: "none",
                },
              }}
            >
              <MenuIcon />
            </IconButton>

            <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer(false)}>
              <Box
                sx={{
                  p: 2,
                  height: 1,
                  backgroundColor: "#fffff",
                  minWidth: "380px",
                }}
              >
                <Box sx={{ display: "flex", mb: 2, justifyContent: "space-between" }}>
                  <IconButton onClick={toggleDrawer(false)} >
                    <CloseIcon />
                  </IconButton>
                  <NavLanguagePicker />
                </Box>

                <NavToolSelector mobile={true} />

                <Divider sx={{ mb: 2 }} />

                <Box sx={{ mb: 2 }}>
                  <ToolNavigation mobile={true} />
                  <Divider sx={{ mb: 2 }} />
                  <NavGeneral mobile={true} />
                </Box>
              </Box>
            </Drawer>
            
          </Toolbar>
        </Grid>
      </Container>
    </AppBar>
  );
};

export default NavMobileMenu;
