import { useState, useContext, useRef } from "react";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import { Event } from "../typings/interfaces";
import { formatInTimeZone } from "date-fns-tz";
import frenchLocale from "date-fns/locale/fr-CA";
import { Alert, Box, CardActions, IconButton, Snackbar, styled, Tooltip } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import BarChartIcon from "@mui/icons-material/BarChart";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import GroupsIcon from "@mui/icons-material/Groups";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined";

import AppContext from "../context/Auth";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import { convertFromTimezoneDisplay } from "../utils/timezones";
import { getRepById } from "../utils/reps";
import { useReps } from "../api/reps";
import { useRegions } from "../api/regions";
import { getRegionsByIds } from "../utils/regions";
import { useSpeakers } from "../api/speakers";
import { getSpeakersByIds } from "../utils/speakers";
import { getModeratorsByIds } from "../utils/moderators";
import useEventName from "../hooks/useEventName";
import { QRCodeCanvas } from "qrcode.react";
import { canCopyImagesToClipboard, copyBlobToClipboard } from "copy-image-clipboard";
import { useEvents } from "../api/events";
import { format, isAfter } from "date-fns";
import { GetLocationString } from "../utils/location";
import InstructionsPopover from "./styled/InstructionsPopover";
import { parseEventStartTime } from "../utils/parseDate";
import { provinceList } from "../utils/provinces";

const InfoCardTitle = styled(CardHeader)(({ theme }) => ({
  padding: "10px 0px",
  "& .MuiTypography-root": {
    color: theme.palette.primary.dark,
    fontSize: "22px",
    fontWeight: "bold",
  },
}));

const InfoCardSubTitle = styled(CardHeader)(({ theme }) => ({
  padding: "0px",
  paddingBottom: "10px",
  "& .MuiTypography-root": {
    color: theme.palette.primary.dark,
    fontSize: "18px",
    fontWeight: "normal",
  },
}));

const InfoCardEvaluationTitle = styled("h2")(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: "22px",
  fontWeight: "bold",
  textTransform: "uppercase",
  marginBottom: "10px",
  marginTop: 20,
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "#ffffff", 
  borderRadius: "4px",
  border: "1px solid #E5E5E5",
  boxShadow: "1px 2px 6px 0 rgba(0,0,0,0.08)",
}));

const QrCodeWrapper = styled("div")(({ theme }) => ({
  // margin: "20px",
  // backgroundColor: "#ffffff"
}));

type Props = {
  event: Event;
  toggleEditModal: (event: Event) => void;
  closeModal: VoidFunction;
};

const TooltipProps = {
  tooltip: {
    sx: {
      fontSize: "0.9rem!important",
      maxWidth:"500px",
      textAlign:"center",
      marginBottom: "5px!important",
      bgcolor: "#ffffff",
      color: "primary.dark",
      boxShadow: " 0 2px 4px 0 rgba(0,0,0,0.15)",
      border: "1px solid #D8D8D8",
      "& .MuiTooltip-arrow": {
        color: "#ffffff",
        "&:before": {
          border: "1px solid #D8D8D8",
        },
      },
    },
  }
}

export default function CalendarEventInfoCard({ event, toggleEditModal, closeModal }: Props) {
  const navigate = useNavigate();
  const { hasRole } = useContext(AppContext);
  const { deleteEvent } = useEvents();
  const { t, i18n } = useTranslation();
  const qrRef = useRef<HTMLDivElement>(null);
  const [copied, setCopied] = useState(false);
  const { reps } = useReps();
  const rep = getRepById(event.repId, reps);
  const { speakers } = useSpeakers();
  const { regions } = useRegions();
  const [formatEventName] = useEventName();
  const evaluationURL = `${window.location.origin}/evaluations/${event.id}`;
  const speakerNames = getSpeakersByIds(event.speakerIds, speakers);
  const moderatorNames = getModeratorsByIds(event.moderatorIds, speakers);
  const regionName = getRegionsByIds([event.regionId], regions, i18n.language);
  const [evalActive, setEvalActive] = useState(event.isEvaluationsEnabled);
  const currentDateTime = new Date(); 
  //const usersTimeZone = format(currentDateTime, "yyyy-MM-dd HH:mm z"); - gets the offset
  const canModifyEvent = isAfter(currentDateTime, parseEventStartTime(event.start_time)) || event.evaluationCount > 0 || (event.attendeeCount ? event.attendeeCount > 0 : false);
  const timeZoneArray = event.timeZone.split("/");
  const timeZone = timeZoneArray[1];
  const translatedTimeZone = t(`time_zones_abr.${timeZone}`);

  const repLabel = t("event_info_card.rep");
  const speakersLabel = t("event_info_card.speakers");
  const moderatorsLabel = t("event_info_card.moderators");
  const resultsLabel = t("event_info_card.results_attendance");

  const copyOrDownloadQRCode = async (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.preventDefault();
    let canvas = qrRef.current && qrRef.current.querySelector("canvas");
    if (canvas) {
      let image = canvas.toDataURL("image/png");
      const blob: any = await new Promise((resolve) => canvas && canvas.toBlob(resolve));
      const canCopy = canCopyImagesToClipboard();
      if (canCopy) {
        setCopied(true);
        return copyBlobToClipboard(blob);
      } else {
        setCopied(false);
        let anchor = document.createElement("a");
        anchor.href = image;
        anchor.download = `qr-code.png`;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      }
    }
  };

  const copyEvalLink = () => {
    navigator.clipboard
      .writeText(evaluationURL)
      .then(() => {
        setCopied(true);
      })
      .catch((err) => {
        console.log("error", err);
        setCopied(false); //error
      });
  };

  const qrCode = <QRCodeCanvas style={{ padding: "20px", backgroundColor: "#ffffff" }} id="qrCode" value={evaluationURL} size={180} level={"H"} />;

  const styles = {
    evalIcon: {
      color: "var(--primary-blue)",
      fontSize: "40px",
      marginRight: 10,
      alignSelf: "flex-start",
    },
    groupIcon: {
      color: "var(--primary-blue)",
      fontSize: "35px",
      marginRight: 10,
      alignSelf: "flex-start",
    },
    evalLinks: {
      fontSize: "16px",
      color: "#3D3D3D",
      textDecorationColor: "#3D3D3D",
    },
    footerIcons: {
      backgroundColor: "#ffffff",
      borderRadius: "0px",
      boxShadow: 2,
    },
    copyIcon: {
      color: "#858080",
      cursor: "pointer",
      backgroundColor: "#ffffff",
      borderTop: "1px solid #dbdbdb",
    },
  };


  const tryDelete = async (id: string) => {
    const confirm = window.confirm(t("delete_confirm_message"));

    if (!confirm) {
      return;
    }

    try {
      const response = await deleteEvent(id);
      closeModal();
    } catch (error) {
      alert(t("delete_event_fail_messsage"));
    }
  };

  const updateEvalActive = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const token = localStorage.getItem("SavedToken");
      await axios.put(`${process.env.REACT_APP_API_URL}/events/${event.id}/toggleEvaluations`, "", {
        headers: {
          authorization: `${token}`,
        },
      });

      setEvalActive(e.target.checked);
    } catch (error) {
      alert(t("Error toggling evaluation access."));
    }
  };

  const mailTo = () => {
    const to = "";
    const eventName = formatEventName(event, false, true);
    const eventTimeZone = t(convertFromTimezoneDisplay(event.timeZone));
    const eventDateTime = `${format(parseEventStartTime(event.start_time), i18next.language === "en" ? "h:mm aa" : "H 'h 'mm")} (${eventTimeZone})`;
    const title = `${t("evaluation.share_email.title")} ${t(event.eventType)} ${i18next.language === "en" ? "event" : ""}: ${eventName} ${t("evaluation.share_email.at")} ${eventDateTime}`;
    const province = provinceList.find((i) => i.value === event.province);
    const location = event.eventType.toLowerCase() !== "virtual" && province ? `${t("evaluation.page.location")} ${event.street}, ${event.city}, ${i18next.language === "en" ? province.name : province.frName}, ${event.postal}` : undefined;

    const subject = encodeURIComponent(`${t("evaluation.share_email.subject")}: ${eventName}`);
    const evaluationText = `${t("evaluation.share_email.body")}: ${evaluationURL}`;
    const outro = t("evaluation.share_email.outro");
    const copy = t("evaluation.share_email.copy");
    if (location) {
      let bodyContent = title + '.\r\n\r\n' + location + '.\r\n\r\n' + evaluationText + '\r\n\r\n' + outro + '\r\n\r\n' + copy;
      const body = encodeURIComponent(bodyContent);
      window.open(`mailto:${to}?subject=${subject}&body=${body}`)  
    } else {
      let bodyContent = title + '.\r\n\r\n' + evaluationText + '\r\n\r\n' + outro + '\r\n\r\n' + copy;
      const body = encodeURIComponent(bodyContent);
      window.open(`mailto:${to}?subject=${subject}&body=${body}`)  
    }
  }

  return (
    <>
      <InstructionsPopover isModal={true}>
        <>
          <div style={{display: "flex", marginBottom:10, lineHeight:0, alignItems:"center"}}>
            <div style={{marginRight:"10px"}}>
              <CustomIconButton disableFocusRipple disableRipple><CreateOutlinedIcon color="warning" sx={{fontSize: "1.4rem"}}/></CustomIconButton>
            </div> 
              <span style={{fontWeight:"600"}}>{t("instructions.calendar_event_info_card.edit")}</span>
            </div>
          <div style={{display: "flex", marginBottom:10, lineHeight:0, alignItems:"center"}}>
            <div style={{marginRight:"10px"}}>
              <CustomIconButton disableFocusRipple disableRipple><DeleteOutlinedIcon color="error" sx={{fontSize: "1.4rem"}}/></CustomIconButton>
            </div> 
              <span style={{fontWeight:"600"}}>{t("instructions.calendar_event_info_card.delete")}</span>
            </div>
          <div style={{display: "flex", marginBottom:10, lineHeight:0, alignItems:"center"}}>
            <div style={{marginRight:"10px"}}>
              <CustomIconButton disableFocusRipple disableRipple><IosShareOutlinedIcon sx={{fontSize:"1.4rem"}} color="primary" /></CustomIconButton>
            </div>
              <span style={{fontWeight:"600"}}>{t("instructions.calendar_event_info_card.share")}</span>
            </div>
        </>
      </InstructionsPopover>
      <Box my={2}>
        <Box className="info_card_header" mx={1}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <InfoCardTitle title={formatEventName(event)} />
          </Box>

          <Box display="flex" sx={{marginBottom: "10px"}}>
            <Chip sx={{ backgroundColor: "var(--primary-blue)", color: "#ffffff", height: "30px", marginRight: "20px", padding: "0px 5px", fontSize: "16px" }} label={event.eventType ? t(event.eventType.toLowerCase()) : t("no_type_provided")} />
            {false && <InfoCardSubTitle title={event.title} sx={{ marginBottom: 0 }} />}
          </Box>

          <Box display="flex" alignItems="center" sx={{ marginBottom: "10px", color: "#3D3D3D", alignItems:"center" }}>
            <span style={{display:"flex", alignItems:"center", marginRight:"5px"}}>
            <AccessTimeIcon sx={{ marginRight: "5px", fontSize: "18px" }} />
              {format(parseEventStartTime(event.start_time), i18next.language === "en" ? "h:mm aa" : "H 'h 'mm")}
            </span>
            <span style={{display:"flex", alignItems:"center", marginRight:"10px"}}>
  
              {translatedTimeZone} {/* TODO: match these in our db sync script */}
            </span>
            <span style={{display:"flex", alignItems:"center"}}>
              <MapOutlinedIcon sx={{ mr: 1, marginRight: "2px", fontSize: "18px" }} />
              {regionName}
            </span>
          </Box>

          { event.street && 
          <Box display="flex" alignItems="center" sx={{ marginBottom: "10px", color: "#3D3D3D" }}>
            <LocationOnOutlinedIcon sx={{ mr: 1, marginRight: "2px", fontSize: "18px", alignSelf: "flex-start", marginTop: "2px" }} /> {GetLocationString(event, i18n.language)}
          </Box>
          }

          <Box display="flex" color="text.secondary">
            <GroupsIcon style={styles.groupIcon} sx={{ mr: 1 }} />
            <Box sx={{ mt: 1 }}>
              <Typography variant="body1" color="text.secondary">
                {repLabel} <span> {rep}</span>
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {speakersLabel} <span> {speakerNames}</span>
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {moderatorsLabel} <span> {moderatorNames}</span>
              </Typography>
            </Box>
          </Box>
        </Box>

        <Divider variant="middle" sx={{ margin: "20px 0px" }} />

        <Box className="info_card_evaluation" mx={1}>
          <Box display="flex" justifyContent="space-between" alignItems="flex-start">
            <Box sx={{ flex: 1 }}>
              <InfoCardEvaluationTitle>
              {t("event_info_card.evaluations")}
              </InfoCardEvaluationTitle>
              <Typography display="flex" alignItems="flex-end">
                <BarChartIcon style={styles.evalIcon} />
                <Link sx={{ mb: "4px", fontSize: "16px" }} style={styles.evalLinks} href={`/reporting/event/${event.id}`}>
                  {resultsLabel}
                </Link>
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end" alignItems="baseline" sx={{ position: "relative", flex: 1, filter: "drop-shadow(0px 0px 3px rgba(000,000,000,0.2))" }}>
              <QrCodeWrapper ref={qrRef}>{qrCode}</QrCodeWrapper>
              <ContentCopyIcon style={styles.copyIcon} sx={{ padding: "10px", zIndex: "2", top: "75px", right: "10px" }} onClick={(e) => copyOrDownloadQRCode(e)} />
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" sx={{ boxShadow: 2, padding: "10px", margin: "20px 0px" }}>
            <Typography display="flex" alignItems="center" sx={{ fontSize: "13px", textAlign: "center", color: "var(--secondary-blue)" }}>
              <a target="__blank" style={{ color: "var(--secondary-blue)" }} href={evaluationURL}>
                {evaluationURL}
              </a>
            </Typography>
            <ContentCopyIcon style={{ cursor: "pointer", color: "#858080" }} onClick={copyEvalLink} />
          </Box>
        </Box>
      </Box>

      <Box className="info_card_actions" mx={1}>
        <CardActions sx={{ padding: "5px 0px" }}>
          {hasRole(["sudo", "admin", "msl", "rep"]) && (
            <Tooltip title={t("instructions.calendar_event_info_card.edit")} placement="top" 
            arrow={true} 
            componentsProps={TooltipProps} 
            enterNextDelay={500}
            enterDelay={500}
            leaveDelay={200}>
              <CustomIconButton
                size="small"
                color="warning"
                disabled={canModifyEvent}
                onClick={() => {
                  toggleEditModal(event);
                }}
              >
                <CreateOutlinedIcon />
              </CustomIconButton>
            </Tooltip>
          )}
          {hasRole(["sudo", "admin", "msl", "rep"]) && (
            <Tooltip title={t("instructions.calendar_event_info_card.delete")} placement="top" 
              arrow={true} 
              componentsProps={TooltipProps} 
              enterNextDelay={500}
              enterDelay={500}
              leaveDelay={200}>
              <CustomIconButton
                size="small"
                color="error"
                disabled={canModifyEvent}
                onClick={() => {
                  tryDelete(event.id);
                }}
              >
                <DeleteOutlinedIcon />
              </CustomIconButton>
            </Tooltip>
          )}
          <Tooltip title={t("instructions.calendar_event_info_card.share")} placement="top" 
            arrow={true} 
            componentsProps={TooltipProps} 
            enterNextDelay={500}
            enterDelay={500}
            leaveDelay={200}
          >
            <CustomIconButton
              size="small"
              color="primary"
              sx={{ marginLeft: "auto !important" }}
              onClick={mailTo}
            >
              <IosShareOutlinedIcon />
            </CustomIconButton>
          </Tooltip>
        </CardActions>
      </Box>

      <Snackbar open={copied} sx={{ left: "0px!important", bottom: "0px!important", width: "100%" }} autoHideDuration={4000} onClose={() => setCopied(false)}>
        <Alert onClose={() => setCopied(false)} severity="success" sx={{ width: "100%" }}>
          {t("event_info_card.copied_to_clipboard")}
        </Alert>
      </Snackbar>
    </>
  );
}
