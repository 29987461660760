import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Layout from "../components/Layout";
import { useContext, useState } from "react";
import NoAccess from "../components/NoAccess";
import AppContext from "../context/Auth";
import Loading from "../components/Loading";
import AddIcon from "@mui/icons-material/Add";
import SegmentationList from "../components/SegmentationList";

const SegmentationManagementPage = () => {
  const { t } = useTranslation();
  const [update, setUpdate] = useState(false);
  const { hasTool, hasRole, userLoading, loading } = useContext(AppContext);

  const [openSegmentationModal, setOpenSegmentationModal] = useState(false);

  const toggleOpenSegmentationModal = () => {
    setOpenSegmentationModal((old) => !old);
  };

  const updateTable = () => {
    setUpdate(!update);
  };

  if (userLoading || loading) {
    return (
      <Layout>
        <Loading />
      </Layout>
    );
  }

  return (
    <Layout>
      {hasTool(["activity-calendar"]) && hasRole(["sudo", "admin"]) ? (
        <Box my={5}>
          <Grid container display="flex" justifyContent="space-between">
            <Grid item xs={6}>
              <Typography
                variant="h4"
                color={(theme) => theme.palette.primary.dark}
                sx={{
                  marginTop: "15px",
                  fontWeight: "600",
                }}
              >
                {t("calendar_nav.segmentation_management")}
              </Typography>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="flex-end" mt="auto">
              <IconButton sx={{ backgroundColor: "primary.main", color: "white", borderRadius: "44px", "&:hover": { backgroundColor: "primary.main" } }} onClick={toggleOpenSegmentationModal} aria-label={t("activity_form.add_btn")}>
                <AddIcon sx={{ color: "#FFFFFF", fontSize: "1.5rem" }}></AddIcon>
              </IconButton>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}
              sx={{
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              <SegmentationList updateTable={updateTable} update={update} open={openSegmentationModal} toggleOpen={toggleOpenSegmentationModal} />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <NoAccess />
      )}
    </Layout>
  );
};

export default SegmentationManagementPage;
