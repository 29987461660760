import { BrowserRouter, Route, Routes as Router } from "react-router-dom";
import EventsPage from "./pages/Events";
import ActivitiesPage from "./pages/Activities";
import ActivityManagementPage from "./pages/ActivityManagementPage"
import LoginPage from "./pages/LoginPage";
import React, { useContext, useEffect } from "react";
import AppContext from "./context/Auth";
import RegistrationPage from "./pages/RegistrationPage";
import RepInviteRegistrationPage from "./pages/RepInviteRegistrationPage";
import LearningObjectivesManagementPage from "./pages/LearningObjectivesManagementPage";
import TherapeuticAreasManagementPage from "./pages/TherapeuticAreasManagementPage";
import ViewUsersPage from "./pages/ViewUsers";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword";
import Reporting from "./pages/Reporting";
import ReportingEvent from "./pages/ReportingEvent";
import ReportingSpeaker from "./pages/ReportingSpeaker";
import CheckInPage from "./pages/CheckInPage";
import ActivityContextLayout from "./context/layout/ActivityContextLayout";
import EventContextLayout from "./context/layout/EventContextLayout";
import ReportingContextLayout from "./context/layout/ReportingContextLayout";
import NoAccessPage from "./pages/NoAccessPage";
import SegmentationManagementPage from "./pages/SegmentationManagementPage";
import HolidayManagementPage from "./pages/HolidayManagementPage";
import ReportingModerator from "./pages/ReportingModerator";

const Routes = (): JSX.Element => {
  const { hasTool, hasRole, loading, isAuthenticated, currentTool } = useContext(AppContext);

  useEffect(() => {
    HomeElement();
  }, [currentTool])

  function HomeElement() {
    if (!loading) {
      if (currentTool === "activity-calendar") {
        return (
          <Route element={<ActivityContextLayout />}>
            <Route path="/" element={<ActivitiesPage />} />
          </Route>
        );
      } else {
        return (
          <Route element={<EventContextLayout />}>
            <Route path="/" element={<EventsPage />} />
            <Route path="/learning-objectives" element={<LearningObjectivesManagementPage />} />
            <Route path="/therapeutic-areas" element={<TherapeuticAreasManagementPage />} />
          </Route>
        );
      }
    } else {
      return <></>
    }
  }

  function ActivityRoutes() {
    if (!loading) {
      if (hasTool(["activity-calendar"]) || hasRole(["sudo", "admin"])) {
        return (
        <Route element={<ActivityContextLayout />}>
          <Route path="/engagement-tracker" element={<ActivitiesPage />} />
          <Route path="/engagement-tracker-management" element={<ActivityManagementPage />} />
          <Route path="/segmentation-management" element={<SegmentationManagementPage />} />
          <Route path="/holiday-management" element={<HolidayManagementPage />} />
        </Route>);
      } else {
        return (
          <>
          <Route path="/engagement-tracker" element={<NoAccessPage />} />
          <Route path="/engagement-tracker-management" element={<NoAccessPage />} />
          <Route path="/segmentation-management" element={<NoAccessPage />} />
          <Route path="/holiday-management" element={<NoAccessPage />} />
          </>
        );
      }
    } else return <></>
  }

  function ReportingRoutes() {
    if (!loading) {
      if (hasTool(["event-evaluations"]) || hasRole(["sudo", "admin"])) {
        return (
          <Route element={<ReportingContextLayout />}>
            <Route path="/reporting" element={<Reporting />} />
            <Route path="/reporting/:section" element={<Reporting />} />
            <Route path="/reporting/:section/:eventId" element={<Reporting />} />
          </Route>
        );
      } else {
        return (
          <>
            <Route path="/reporting" element={<NoAccessPage />} />
            <Route path="/reporting/:section" element={<NoAccessPage />} />
          <Route path="/reporting/:section/:eventId" element={<NoAccessPage />} />
          </>
        )
      }
    }
  }


  return (
    <BrowserRouter>
      <Router>
        {isAuthenticated ? (
          <>
            {HomeElement()}
            <Route path="/users" element={<ViewUsersPage />} />
            {ActivityRoutes()}
            {ReportingRoutes()}
            <Route path="/reporting/event/:eventId" element={<ReportingEvent />} />
            <Route path="/reporting/speaker/:speakerId" element={<ReportingSpeaker />} />
            <Route path="/reporting/moderator/:moderatorId" element={<ReportingModerator />} />
            <Route path="/evaluations/:eventId" element={<CheckInPage/>} />
          </>
        ) : loading ? (
            <Route path="/" element={<></>} /> //show nothing if state is loading to verify auth
        ) : (
            <Route path="*" element={<LoginPage />} />
        )}
        <Route path="/evaluations/:eventId" element={<CheckInPage/>} />
        <Route path="/invite/:jwt" element={<RegistrationPage />} />
        <Route path="/invite-rep/:jwt" element={<RepInviteRegistrationPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:jwt" element={<ResetPassword />} />
        <Route path="/set-password/:jwt" element={<ResetPassword />} />
      </Router>
    </BrowserRouter>
  );
};

export default Routes;
