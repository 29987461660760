import { Box, styled, Typography } from "@mui/material"
import { useTranslation } from "react-i18next";
import Layout from "../components/Layout";

const LargeText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontWeight: 700,
  fontSize: "28px",
  marginBottom: "10px",
}));

const NoAccessPage = () => {
    const { t } = useTranslation();

    return (
        <Layout fullWidth>
            <Box sx={{ textAlign: "center", my: 4 }}>
                <LargeText>{t("access_denied")}</LargeText>
                <Typography>{t("tool_access")}</Typography>
            </Box>
        </Layout>
    );
}

export default NoAccessPage;