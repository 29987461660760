import { Dialog, DialogContent, DialogProps, DialogTitle, DialogTitleProps, IconButton, Paper, PaperProps, styled } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import Draggable from 'react-draggable';
import InstructionsPopover from "./InstructionsPopover";

const Draggable1: any = Draggable;

function PaperComponent(props: PaperProps) {
  return (
    <Draggable1
      axis="both"
      defaultPosition={{x: 0, y: 0}}
      cancel={'[class*="MuiDialogContent-root"]'}
      handle=".handle"
    >
      <Paper {...props} />
    </Draggable1>
  );
}
type CustomDialogProps = {
  size?: string;
}
const CustomDialog = styled(Dialog)<CustomDialogProps>(({ size, theme }) => ({
  padding: "0px",
  zIndex: 10,

  "& .MuiBackdrop-root": {
    backgroundColor: "rgb(226 240 250 / 40%)",
  },
  "& .MuiDialog-paper": {
    width: size ? size : "650px",
    maxWidth: size ? size : "650px",

  }

}));


const CustomDialogTitle = styled(DialogTitle)<DialogTitleProps>(({ theme }) => ({
  cursor: 'move', 
  padding:"8px 10px", 
  backgroundColor: "#EEEEEE", 
  display:"flex", 
  justifyContent:"space-between", 
  alignItems: "center"
}));

type Props = {
  children: JSX.Element;
  open: boolean;
  closeModal: (e: any) => void;
  size?: string;
  showExitButton?: boolean;
}

export default function DraggableDialog({children, open, closeModal, size, showExitButton = true}: Props) {

  return (
    <CustomDialog size={size} open={open} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-handle">
      <CustomDialogTitle className="handle" id="draggable-dialog-title" sx={{height: "38px"}}>
        <DragHandleIcon sx={{ color: "#797979" }} />
        {showExitButton ?
          <IconButton aria-label="Close" onClick={closeModal} onTouchEnd={closeModal}>
            <CloseIcon sx={{ cursor: "pointer" }} />
          </IconButton>
        : <></>}
      </CustomDialogTitle>

      <DialogContent>
        {children}
      </DialogContent>
    </CustomDialog>
  );
}
