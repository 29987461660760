import {useMemo, useRef, useState, useEffect, useContext} from "react";
import AppContext from "../../context/Auth";
import { Activity } from "../../typings/interfaces";
import ActivityCalendarToolBar from "./ActivityCalendarToolBar";
import CalendarView from "./CalendarView";
import ActivityContext from "../../context/ActivityContext";
import Loading from "../Loading";

type Props = {
  toggleCreateModal: (date: Date) => void;
  toggleEditModal: (activity: Activity) => void;
  toggleCreateDuplicateModal: (activity: Activity) => void;
};

//we provide events here to ensure we have them as the Event page handles waiting for the data to load
const ActivitiesContainer = ({toggleCreateModal, toggleEditModal, toggleCreateDuplicateModal}: Props) => {
  const calendarRef = useRef<any>(null);
  const [title, setTitle] = useState("");
  const { filteredActivities: activities, isFilteredActivitiesLoading, holidays, isHolidaysLoading, updateCurrentYear, currentYear, filteredOccurences: occurences, recurringActivities} = useContext(ActivityContext);

  if(isFilteredActivitiesLoading || isHolidaysLoading ) {
    return <Loading />
  } else {
    return (
      <>
        <ActivityCalendarToolBar calendarRef={calendarRef} setTitle={setTitle} title={title} updateCurrentYear={updateCurrentYear} currentYear={currentYear} />
        <CalendarView ref={calendarRef} toggleCreateModal={toggleCreateModal} toggleEditModal={toggleEditModal} toggleCreateDuplicateModal={toggleCreateDuplicateModal} activities={activities} holidays={holidays} currentYear={currentYear} occurences={occurences} recurringActivities={recurringActivities} />
      </>
    )
  }

};

export default ActivitiesContainer;