import React, { useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

//drawer elements used
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, Button, Divider, TextField, Typography } from "@mui/material";
import { FilterAlt, FilterAltOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import AppContext from "../../context/Auth";
import EventContext from "../../context/EventContext";
import { Users } from "../../typings/interfaces";

interface FilterProps {
    reps: Users[];
}

const MobileEventFiltersDrawer = ({reps}:FilterProps) => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const { hasRole } = useContext(AppContext);
    const { repID, setRepID } = useContext(EventContext);

    const [filterRepId, setFilterRepId] = useState<string>(repID);

    const { t } = useTranslation();
    const toggleDrawer = (open: boolean) => (event: any) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        //changes the function state according to the value of open
        setOpenDrawer(open);
    };

    const filterByRep = (event: React.SyntheticEvent<Element, Event>, value: Users | null, reason: any, details?: any | undefined) => {
        if (value && reason === "selectOption"){
            const userID = value.id
            //send id back up to filter events by repID on the Events Page
            setFilterRepId(userID)
        }
        else {
            setFilterRepId("");
        }
    }

    const applyRepFilter = () => {
        setRepID(filterRepId);
    }

    const clearFilters = () => {
        setRepID("");
        setFilterRepId("");
    }

    const SelectFilterText = () => {
        const filtered = repID !== "";
        if (filtered) {
            return `${t("filter_drawer.selected")}1 ${t("filter_drawer.filters")}`
        }
        return `${t("filter_drawer.select")}${t("filter_drawer.filters")}`
    }

    return (
    <>
    <IconButton
        edge="end"
        color="primary"
        aria-label="open filters"
        onClick={toggleDrawer(true)}
        sx={{
        mr: 2,
        display: {
            md: "flex",
            lg: "none",
            borderRadius: 2
        },
        }}
    >
        {repID !== "" ? <FilterAlt /> : <FilterAltOutlined /> }
        <Typography my="auto" ml={1}>{SelectFilterText()}</Typography>
    </IconButton>
    <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer(false)} >
        <Box
            sx={{
                p: 2,
                height: 1,
                backgroundColor: "#fffff",
                minWidth: "380px",
            }}
        >
            <Box sx={{ display: "flex", mb: 2, justifyContent: "space-between" }}>
                <IconButton onClick={toggleDrawer(false)}>
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" my="auto">{t("filter_drawer.event_hub_title")}</Typography>
            </Box>

            <Divider sx={{ mb: 2 }} />
            
            <Box sx={{ mb: 2 }}>
                <Autocomplete
                    // disablePortal
                    hidden={hasRole(["rep"])}
                    sx={{width:"100%", maxWidth:"475px", my:2}}
                    onChange={(event: React.SyntheticEvent<Element, Event>, value: Users | null, reason: any, details?: any | undefined) => filterByRep(event, value, reason, details)}
                    disableListWrap
                    id="reps"
                    options={reps}
                    value={reps?.find(i => i.id === filterRepId) || null}
                    autoHighlight
                    getOptionLabel={(option) => `${option.name} (${option.email})`}
                    renderOption={(props, option) => (
                        <Box component="li" {...props}>
                        {option.name} ({option.email})
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            placeholder={t("toolbar.all_reps_placeholder")}
                            {...params}
                            label={t("toolbar.all_reps_label")}
                            inputProps={{
                                ...params.inputProps,
                            }}
                        />
                    )}
                />  
                
                <Button variant="outlined" sx={{mr: 2}} onClick={clearFilters}>{t("filter_drawer.clear_button")}</Button>
                <Button variant="contained" onClick={applyRepFilter}>{t("filter_drawer.apply_button")}</Button>
            </Box>
        </Box>
    </Drawer>
    </>
  );
};

export default MobileEventFiltersDrawer;
