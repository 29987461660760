import { createTheme } from "@mui/material";

const muiTheme = createTheme({
  typography: {
    fontFamily: ["Apis", "Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#005AD2",
      dark: "#001965",
      light: "#E2F0FA",
    },
    secondary: { main: "#3B97DE" },
    error: { main: "#E6553F" },
    success: { main: "#3F9C35" }
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        // root: {
        //   color: "#005AD2",
        // },
        shrink: {
          color: "#005AD2",
        },
      },
    },
  },
});

export default muiTheme;
