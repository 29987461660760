import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Alert, Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Event, Moderator } from "../typings/interfaces";
import { motion } from "framer-motion";
import Loading from "../components/Loading";
import { BackButton } from "../components/styled/Buttons/StyledButtons";
import { useNavigate } from "react-router-dom";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ReportModeratorHeader from "../components/reportingModerator/ReportModeratorHeader";
import ReportModeratorStats from "../components/reportingModerator/ReportModeratorStats";
import { useTranslation } from "react-i18next";

const ReportContentWrapper = styled(Grid)(({ theme }) => ({
  width: "100%",
  padding: "0px",
  backgroundColor: "#FAFAFA",
  margin: 0,
  marginTop: "20px",
  position: "relative",
}));

const MotionReportContentWrapper = motion(ReportContentWrapper);

const initialEvent = {} as Event;

const initalModerator= {
  _id: "",
  speakerId: "",
  displayName: "Lorem ipsum",
  email: "email@email.com",
  isBigMarker: true,
  hasBeenModerator: false,
  events: [],
  therapeuticAreas: [
    { value: "uncategorized", enName: "uncategorized", frName: "uncategorized" },
    { value: "obesity", enName: "Obesity", frName: "FR Obesity" },
  ],
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  __v: 1,
};

export default function ReportingModerator() {
  const { moderatorId } = useParams();
  const [events, setEvents] = useState<Event[]>([initialEvent]);
  const [moderator, setModerator] = useState<Moderator>(initalModerator);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const backButtonColumnWidth = "60px";
  const navigate = useNavigate();

  useEffect(() => {
    if (moderatorId !== "" && moderatorId) {
      getModeratorReport(moderatorId);
    }
  }, [moderatorId]);

  const getModeratorReport = async (moderatorId: string) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("SavedToken");
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/reporting/moderator/${moderatorId}`, {
        headers: {
          authorization: `${token}`,
        },
      });

      if (response.data) {
        setEvents(response.data.events);
        setModerator(response.data.moderator);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert("error");
    }
  };

  const goBack = () => {
    navigate("/reporting/moderators");
  };

  if (loading) {
    return (
      <Layout>
        <Loading />
      </Layout>
    );
  }

  return (
    <Layout>
      <Grid my={{ xs: 2, lg: 6 }} container>
        <Grid container item width={{ sx: backButtonColumnWidth, lg: backButtonColumnWidth }} justifyContent={{ xs: "flex-start", lg: "center" }}>
          <BackButton
            onClick={goBack}
            sx={{
              position: { xs: "absolute", lg: "absolute" },
              top: { xs: "20px", lg: "inherit" },
              left: { xs: "inherit", lg: "inherit" },
            }}
          >
            <ArrowBack />
          </BackButton>
        </Grid>
        <Grid item width={{ xs: "100%", lg: `calc(100% - ${backButtonColumnWidth})` }}>
          <ReportModeratorHeader moderator={moderator} events={events} />

          <Box sx={{ flexGrow: 1 }}>
            {moderator.therapeuticAreas && moderator.therapeuticAreas.length > 0 
              ? moderator.therapeuticAreas.map((i, index) => {
                  const therapeuticAreaEvents = events.filter((event) => {
                    if (event.therapeuticArea && event.therapeuticArea === i.value) return event;
                  });

                  return (
                    <MotionReportContentWrapper container initial={{ x: index % 2 === 0 ? "-130%" : "130%" }} animate={{ x: 0 }} transition={{ type: "spring", damping: 10, mass: 0.75, stiffness: 100, delay: 0.3 + index * 0.3 }}>
                      <ReportModeratorStats category={i} events={therapeuticAreaEvents} moderatorName={moderator.displayName} speakerId={moderator.speakerId} />
                    </MotionReportContentWrapper>
                  );
                })
              :
              <Alert severity="info" sx={{marginTop: "20px"}}>{moderator.displayName} {t("moderator_report.no_events")}</Alert>
              }
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
}
