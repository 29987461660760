import { Event, TherapeuticArea } from "../typings/interfaces";
import { format } from "date-fns";
import { enUS, fr } from 'date-fns/esm/locale'
import React from "react";
import { CapitalizeFirstChar } from "../utils/capitalize";
import { useSpeakers } from "../api/speakers";
import { useTherapeuticAreas } from "../api/therapeuticAreas";
import { useTranslation } from "react-i18next";
import { parseEventStartTime } from "../utils/parseDate";

type Props = {
  hasSpeakers: boolean;
  includeTime: boolean;
};

const getTherapeuticArea = (therapeuticAreas: TherapeuticArea[], therapeuticAreaValue: string | undefined, language: string) => {
  const therapeuticAreaRecord = therapeuticAreas && therapeuticAreaValue && therapeuticAreas.find((therapeuticArea) => therapeuticArea.value === therapeuticAreaValue);
  if (therapeuticAreaRecord) {
    return language === "en" ? therapeuticAreaRecord.enName : therapeuticAreaRecord.frName;
  } else {
    return "";
  }
};

export const useEventName = ({ hasSpeakers = false, includeTime = true }: Partial<Props> = {}) => {
  const { speakers, isSpeakersLoading } = useSpeakers();
  const { therapeuticAreas, isTherapeuticAreasLoading } = useTherapeuticAreas();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const formatEventName = React.useCallback(
    (event: Event, bracketsAroundDate?: boolean, handleCustomName?: boolean) => {
      if (event) {
        let speakerNames = "";
        const eventDate = event.start_time ? CapitalizeFirstChar(format(parseEventStartTime(event.start_time), i18n.language === "en" ? "MMMM d, yyyy" : "MMMM d, yyyy", i18n.language === "en" ? {locale: enUS} : {locale: fr})) : "";
        var therapeuticAreaName = getTherapeuticArea(therapeuticAreas, event.therapeuticArea, currentLanguage);
        if (handleCustomName && event.title.length > 0) {
          therapeuticAreaName = event.title;
        }

        if (speakers) {
          const speakerRecords = event.speakerIds.map((speakerId) => {
            return speakers.find((speaker) => speaker.speakerId === speakerId);
          });
          speakerRecords &&
            speakerRecords.length > 0 &&
            speakerRecords.forEach((speakerInfo, index) => {
              if (speakerInfo) {
                if (index === 0) {
                  speakerNames = `${speakerInfo.displayName}`;
                }
              }
            });
        }

        let withText = currentLanguage === "en" ? "w/" : "avec";

        if (includeTime) {
          if (bracketsAroundDate) {
            const formattedName = CapitalizeFirstChar(`${therapeuticAreaName} ${withText} ${speakerNames} (${eventDate})`);
            return formattedName;
          } else {
            const formattedName = CapitalizeFirstChar(`${therapeuticAreaName} ${withText} ${speakerNames} - ${eventDate}`);
            return formattedName;
          }
        } else {
          const formattedName = CapitalizeFirstChar(`${therapeuticAreaName} ${withText} ${speakerNames}`);
          return formattedName;
        }
      }
      return "";
    },
    [isSpeakersLoading, isTherapeuticAreasLoading, currentLanguage, speakers]
  );

  return [formatEventName] as const;
};
export default useEventName;
