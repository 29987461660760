const GetTimeInputFieldFormat = (language: string) => {
    if (language === "en") {
        return "MM/DD/YYYY hh:mm a";
    } else {
        return "MM/DD/YYYY HH [h] mm"
    }
}

const GetTimeDisplayFormat = (language: string) => {
    if (language === "en") {
        return "hh:mm a";
    } else {
        return "HH [h] mm";
    }
}

const GetDateInputFieldFormat = (language: string) => {
    if (language === "en") {
        return "MM/DD/YYYY";
    } else {
        return "MM/DD/YYYY"
    }
}

export {
    GetTimeInputFieldFormat,
    GetTimeDisplayFormat,
    GetDateInputFieldFormat
}