import { Link, Grid, LinkProps, Menu, MenuItem, Collapse } from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import AppContext from "../../context/Auth";
import { useTranslation } from "react-i18next";
import EventIcon from "@mui/icons-material/Event";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const NavLink = styled(Link)<LinkProps>(({ theme }) => ({
  color: "#3D3D3D",
  margin: "0 15px",
  textDecoration: "none",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  "& .MuiSvgIcon-root": {
    marginRight: "5px",
  },
}));

type Props = {
  mobile: boolean;
};

const NavActivityHub = ({ mobile }: Props) => {
  const { isAuthenticated, hasRole, hasTool } = useContext(AppContext);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openMobileManagement, setOpenMobileManagement] = useState(false);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const mobileManagementClick = () => {
    setOpenMobileManagement(!openMobileManagement);
  };

  if (mobile) {
    return (
      <React.Fragment>
        <Grid container>
          <List sx={{ width: "100%", maxWidth: "100%", bgcolor: "background.paper", pb: 2 }} component="nav" aria-labelledby="nested-list-subheader">
            {isAuthenticated && hasRole(["sudo", "admin", "msl", "rep"]) && hasTool(["activity-calendar"]) && (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <ListItemIcon>
                    <EventIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("calendar_nav.calendar")} />
                </ListItemButton>
              </ListItem>
            )}

            {isAuthenticated && hasRole(["sudo", "admin"]) && (
              <>
                <ListItem disablePadding>
                  <ListItemButton onClick={mobileManagementClick}>
                    <ListItemIcon>
                      <SettingsOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("calendar_nav.management")} />
                    {openMobileManagement ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                </ListItem>
                <Collapse in={openMobileManagement} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton onClick={() => navigate("/engagement-tracker-management")} sx={{ pl: 11 }}>
                      <ListItemText primary={t("calendar_nav.product_brand")} />
                    </ListItemButton>
                    <ListItemButton onClick={() => navigate("/segmentation-management")} sx={{ pl: 11 }}>
                      <ListItemText primary={t("calendar_nav.segmentations")} />
                    </ListItemButton>
                    <ListItemButton onClick={() => navigate("/holiday-management")} sx={{ pl: 11 }}>
                      <ListItemText primary={t("calendar_nav.holidays")} />
                    </ListItemButton>
                  </List>
                </Collapse>
              </>
            )}
          </List>
        </Grid>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Grid container>
          {isAuthenticated && hasRole(["sudo", "admin", "msl", "rep"]) && hasTool(["activity-calendar"]) && (
            <>
              <NavLink
                sx={{ ml: "3%" }}
                onClick={() => {
                  navigate("/");
                }}
              >
                <EventIcon /> {t("calendar_nav.calendar")}
              </NavLink>

              {hasRole(["sudo", "admin"]) && (
                <>
                  <NavLink sx={{ ml: "3%" }} onClick={handleClick}>
                    <SettingsOutlinedIcon /> {t("calendar_nav.management")}
                  </NavLink>
                  <Menu
                    anchorEl={anchorEl}
                    id="settings-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          left: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                    anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                  >
                    <MenuItem onClick={() => navigate("/engagement-tracker-management")}> {t("calendar_nav.product_brand")}</MenuItem>
                    <MenuItem onClick={() => navigate("/segmentation-management")}>{t("calendar_nav.segmentations")}</MenuItem>
                    <MenuItem onClick={() => navigate("/holiday-management")}>{t("calendar_nav.holidays")}</MenuItem>
                  </Menu>
                </>
              )}
            </>
          )}
        </Grid>
      </React.Fragment>
    );
  }
};

export default NavActivityHub;
