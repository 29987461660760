import { useState, useContext, Dispatch, useEffect } from "react";
import { GridColDef, GridToolbarContainer, GridToolbarExport, enUS, GridFooter, useGridApiEventHandler, GridEventListener, useGridApiRef, GridFilterItem, gridFilteredSortedRowEntriesSelector } from "@mui/x-data-grid";
import { frFRCustom as frFR } from "../locale/frFR";
import { Grid, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useTranslation } from "react-i18next";
import ReportingDataGridTable from "./styled/ReportingGrid";
import EventContext from "../context/EventContext";
import LearningObjectiveForm from "./LearningObjectiveForm";
import DraggableDialog from "./styled/DraggableDialog";
import { LearningObjective } from "../typings/interfaces";
import Loading from "./Loading";
import AddIcon from "@mui/icons-material/Add";
import { useLearningObjectives } from "../api/learningObjectives";
import CSVDownloadLink from "./styled/CSVDownloadLink";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { getReportingDetailsForCsv } from "../utils/csvBuilder";

const initialLearningObjective = { _id: "", enName: "", frName: "", therapeuticArea: "", showInEval: true, value: "" };
const LearningObjectivesList = () => {
  const { t, i18n } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const learning_objective_label = t("learning_objectives_management.list.learning_objective_label");
  const therapeutic_area_label = t("learning_objectives_management.list.therapeutic_area_label");
  const edit_label = t("edit");
  const delete_label = t("delete");
  const current_language = i18n.language;
  const [learningObjective, setLearningObjective] = useState<LearningObjective>(initialLearningObjective);
  const { learningObjectives, therapeuticAreas } = useContext(EventContext);
  const { deleteById, mutateLearningObjectives } = useLearningObjectives();
  const [exportRowData, setExportRowData] = useState<LearningObjective[]>([]);
  const [dataGridFilters, setDataGridFilters] = useState<GridFilterItem>({} as GridFilterItem);
  const apiRef = useGridApiRef();
  

  //used for data export
  useEffect(() => {
    if(apiRef.current.instanceId) {
      const visibleRows = gridFilteredSortedRowEntriesSelector(apiRef);

      visibleRows.forEach((row) => {
        row.model.learningObjectiveName = current_language === "en" ? row.model.enName : row.model.frName;
        const area = therapeuticAreas.find((area) => {
          return area.value === row.model.therapeuticArea;
        });
        if (area) {
          row.model.therapeuticAreaName = current_language === "en" ? area.enName : area.frName;
        }
      })

      const exportRowsArray = getReportingDetailsForCsv(visibleRows, columns, dataGridFilters);
      setExportRowData(exportRowsArray);
  
    }
  },[dataGridFilters, learningObjectives, therapeuticAreas, current_language, apiRef])

  const editLearningObjective = (learningObjective: LearningObjective) => {
    const { _id, enName, frName, value, therapeuticArea } = learningObjective;
    setEdit(true);
    setLearningObjective({ _id, enName, frName, therapeuticArea, showInEval: true, value });
    setOpen(!open);
  };

  const createLearningObjective = () => {
    setEdit(false);
    setLearningObjective(initialLearningObjective);
    setOpen(!open);
  };

  const toggleOpen = () => setOpen(!open);

  const deleteLearningObjective = async (_id: string) => {
    const confirm = window.confirm(t("delete_confirm_message"));

    if (!confirm) {
      return;
    }
    try {
      await deleteById(_id);
      mutateLearningObjectives();
    } catch (error: any) {
      if (error.response) {
        console.log("error", error.response);
      }
    }
  };

  const columns: GridColDef[] = [
    {
      field: "learningObjectiveName",
      headerName: learning_objective_label,
      flex: 1,
      minWidth: 200,
      editable: false,
      hideable: false,
      renderCell: (params) => {
        if (current_language === "en") return params.row.enName;
        return params.row.frName;
      },
      valueGetter: (params) => {
        if (current_language === "en") return params.row.enName;
        return params.row.frName;
      },
    },
    {
      field: "therapeuticAreaName",
      headerName: therapeutic_area_label,
      flex: 1,
      maxWidth: 350,
      editable: false,
      hideable: false,
      renderCell: (params) => {
        const area = therapeuticAreas.find((area) => {
          return area.value === params.row.therapeuticArea;
        });
        if (area) {
          if (current_language === "en") return area.enName;
          return area.frName;
        }
      },
      valueGetter: (params) => {
        const area = therapeuticAreas.find((area) => {
          return area.value === params.row.therapeuticArea;
        });
        if (area) {
          if (current_language === "en") return area.enName;
          return area.frName;
        }
      },
    },
    {
      sortable: false,
      editable: false,
      filterable: false,
      hideable: false,
      field: "edit",
      width: 50,
      headerName: "",
      renderHeader: () => (null),
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,

      renderCell: (params) => (
        <strong>
          <IconButton aria-label="edit" color="warning" style={{ marginLeft: 0 }} onClick={() => editLearningObjective(params.row)}>
            <EditOutlinedIcon />
          </IconButton>
        </strong>
      ),
    },
    {
      sortable: false,
      editable: false,
      filterable: false,
      hideable: false,
      field: "delete",
      headerName: "",
      renderHeader: () => (null),
      width: 50,
      headerAlign: "center",
      disableColumnMenu: true,
      align: "center",
      renderCell: (params) => (
        <strong>
          <IconButton
            aria-label="delete"
            color="error"
            style={{ marginLeft: 0 }}
            onClick={() => {
              deleteLearningObjective(params.row._id);
            }}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </strong>
      ),
    },
  ];

  const CustomToolbar = (setDataGridFilters: Dispatch<any>) => {
    const [exportAnchor, setExportAnchor] = useState<null | HTMLElement>(null);
    const openExport = Boolean(exportAnchor);
    
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setExportAnchor(event.currentTarget);
    };
    
    const handleClose = () => {
      setExportAnchor(null);
    };

    const handleFilterChange: GridEventListener<'filterModelChange'> = (params) => {
      params.items.forEach((filter) => {
        setDataGridFilters(filter);
      })
    };

    useGridApiEventHandler(apiRef, 'filterModelChange', handleFilterChange);

    return (
      <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
          <div className="columnHeader_buttons">
            <IconButton disableRipple onClick={handleClick}>
              <FileDownloadOutlinedIcon sx={{ color: (theme) => theme.palette.primary.main }} />
            </IconButton>
            <Menu
              id="export-results"
              anchorEl={exportAnchor}
              open={openExport}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <MenuItem onClick={() => {handleClose()} }>
                <CSVDownloadLink data={exportRowData} filename={`results_learning_objectives_${new Date().getTime()}`}>
                  {t("download_as_csv")}
                </CSVDownloadLink>
              </MenuItem>
              <MenuItem onClick={() => {handleClose();window.print();}}>{t("print")}</MenuItem>
            </Menu>
          </div>
        <div>
          <GridToolbarExport
            disableToolbarButton={true}
            csvOptions={{ disableToolbarButton: true }}
            printOptions={{ disableToolbarButton: true }}
          />
        </div>
        <GridFooter
          sx={{
            border: "none", // To delete double border.
          }}
        />
      </GridToolbarContainer>
    );
  };

  if (!learningObjectives || !therapeuticAreas) {
    return <Loading />;
  } else {
    return (
      <>
        <Grid container justifyContent="space-between" alignItems="flex-end">
          <Grid item>
            <Typography variant="h4" color={(theme) => theme.palette.primary.dark} sx={{ marginTop: "15px", fontWeight: "600" }}>
              {t("learning_objectives_management.title")}
            </Typography>
          </Grid>
          <Grid style={{ justifyContent: "flex-end" }}>
            <IconButton sx={{ backgroundColor: "primary.main", color: "white", borderRadius: "44px", "&:hover": { backgroundColor: "primary.main" } }} onClick={createLearningObjective}>
              <AddIcon sx={{ color: "#FFFFFF", fontSize: "1.5rem" }}></AddIcon>
            </IconButton>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item xs={12} sx={{ marginTop: "30px", marginBottom: "20px" }}>
            <div style={{ height: "100%", width: "100%" }}>
              <ReportingDataGridTable
                apiRef={apiRef}
                getRowId={(row: any) => row._id}
                className="activity_brand_grid"
                pageSizeOptions={[200]}
                disableColumnSelector
                // hideFooterPagination
                rows={learningObjectives.filter((i) => i.showInEval)}
                columns={columns}
                disableRowSelectionOnClick
                localeText={current_language === "en" ? { ...enUS.components.MuiDataGrid.defaultProps.localeText, toolbarExport: "" } : { ...frFR.components.MuiDataGrid.defaultProps.localeText, toolbarExport: "" }}
                components={{
                  Footer: () => CustomToolbar(setDataGridFilters),
                }}
              />
              <DraggableDialog open={open} closeModal={toggleOpen}>
                <LearningObjectiveForm edit={edit} toggleOpen={toggleOpen} learningObjective={learningObjective} therapeuticAreas={therapeuticAreas} />
              </DraggableDialog>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
};

export default LearningObjectivesList;
