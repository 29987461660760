import { Alert, Box, ButtonBase, FormControl, FormLabel, Grid, styled, TextField, FormHelperText } from "@mui/material";
import i18next from "i18next";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Holiday } from "../../typings/interfaces";
import { format } from "date-fns";
import * as yup from "yup";
import { formatFormErrors } from "../../utils/forms";
import { useActivities } from "../../api/activities";
import { useHolidays } from "../../api/holidays";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StyledDatePicker } from "../styled/Inputs/StyledDatePicker";
import { Event as EventIcon } from "@mui/icons-material";
import dayjs from "dayjs";

const ModalTitle = styled("h1")(({ theme }) => ({
  color: "#3D3D3D",
  textAlign: "center",
  marginTop: 24,
  fontSize: 24,
  fontWeight: "bold",
  textTransform: "uppercase",
}));

const NextButton = styled(ButtonBase)<{ disabled?: boolean }>(({ disabled, theme }) => ({
  backgroundColor: theme.palette.primary.main,
  textTransform: "uppercase",
  padding: "14px 60px 14px 60px",
  color: "white",
  fontSize: 18,
  borderRadius: "5px",
  opacity: disabled ? 0.6 : 1,
}));

interface HolidayFormProps {
  edit?: boolean;
  holiday?: Holiday;
  closeModal: VoidFunction;
}

const HolidayForm = ({ edit, holiday, closeModal }: HolidayFormProps) => {
  const { t } = useTranslation();
  const [displayError, setDisplayError] = useState("");
  const [displaySuccess, setDisplaySuccess] = useState("");
  const [created, setCreated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [eventExistsOnHoliday, setEventExistsOnHoliday] = useState(false);

  const { create, update } = useHolidays();
  const { mutateActivities } = useActivities();

  const validationSchema = yup.object({
    enName: yup.string().required(t("activity_management.holiday_form.english_name_error")),
    frName: yup.string().required(t("activity_management.holiday_form.french_name_error")),
    date: yup.string().required(t("activity_management.holiday_form.date_error")),
  });

  const createSubmit = async (values: any) => {
    setLoading(true);
    try {
      if(values.date instanceof Date) {
        values.date = format(values.date, "yyyy-MM-dd HH:mm");
      }
      await create(values);
      setDisplayError("");
      setDisplaySuccess(t("activity_management.holiday_form.create_success_msg"));
      setCreated(true);
      setLoading(false);
      closeModal();
    } catch (error: any) {
      if (error.response) {
        console.log(error.response)
        if(error.response.status === 409) {
          eventExistOnHoliday(); 
        } else if (error.response.status === 400 && error.response.data.message === "Holiday Exists") {
          setDisplayError(t("activity_management.holiday_form.holiday_already_exists"));
        } else {
          setDisplayError(error.response.data.message);
        }
        if (error.response.data.errorsValidation) {
          const formattedErrors = formatFormErrors(error.response.data.errorsValidation);
          holidayFormik.setErrors(formattedErrors);
          return;
        }
      }
      setDisplaySuccess("");
      
      setLoading(false);
    }
  };

  const editSubmit = async (values: any) => {
    setLoading(true);
    try {
      if (holiday) {
        if(values.date instanceof Date) {
          values.date = format(values.date, "yyyy-MM-dd HH:mm");
        }
        await update(holiday._id, values);
        await mutateActivities();
        setDisplayError("");
        setDisplaySuccess(t("activity_management.holiday_form.update_success_msg"));
        setCreated(true);
        setLoading(false);
        closeModal();
      } else {
        setDisplaySuccess("");
        setDisplayError(t("activity_management.holiday_form.no_segmentation_error"));
      }
    } catch (error: any) {
      if (error.response) {
        console.log(error.response);
        if(error.response.status === 409) {
          eventExistOnHoliday(); 
        } else if (error.response.status === 400 && error.response.data.message === "Holiday Exists") {
          setDisplayError(t("activity_management.holiday_form.holiday_already_exists"));
        } else {
          setDisplayError(error.response.data.message);
        }
        
        if (error.response.data.errorsValidation) {
          const formattedErrors = formatFormErrors(error.response.data.errorsValidation);
          holidayFormik.setErrors(formattedErrors);
          return;
        }
      }
      setLoading(false);
    }
  };

  const initialValues = edit
    ? {
        enName: holiday?.enName,
        frName: holiday?.frName,
        date: holiday && holiday.date ? new Date(holiday.date) : new Date(),
        forceHoliday: false,
      }
    : {
        enName: "",
        frName: "",
        date: new Date(new Date().getTime() + 30 * 60000),
        forceHoliday: false,
      };

  const holidayFormik = useFormik({
    initialValues: initialValues,
    onSubmit: edit ? editSubmit : createSubmit,
    validationSchema: validationSchema,
    validateOnChange: false,
  });

  function DatePickerHasError(field: string) {
    return (Boolean(holidayFormik.errors.date) && holidayFormik.touched.date);
  }

  const onChangeDate = async (field: "date", value: dayjs.Dayjs | null) => {
    holidayFormik.setFieldValue("forceHoliday", false);
    setDisplayError("");
    setEventExistsOnHoliday(false); 

    if (value) {
      holidayFormik.setFieldValue(field, dayjs(value).set('hour', 0).set('minute', 0).set('second', 0).toDate());
    } else {
      holidayFormik.setFieldValue(field, "");
    }
  };

  const forceCreate = () => {
    holidayFormik.setFieldValue("forceHoliday", true);
    holidayFormik.submitForm();
  }

  const eventExistOnHoliday = () => {
    setDisplayError(t("activity_management.holiday_form.event_exists_on_holiday"));
    setEventExistsOnHoliday(true); 
  }

  const FormButtons = () => {
    
    if(eventExistsOnHoliday) {
      return (
        <>
          <div style={{width: "100%"}}><Alert sx={{marginTop: "30px"}} severity="warning">{displayError}</Alert></div><br/>
          
          <NextButton sx={{ marginTop: "30px", marginRight: "20px" }} onClick={forceCreate}>
            {t("activity_management.holiday_form.continue_btn")}
          </NextButton>
          <NextButton sx={{ marginTop: "30px" }} onClick={closeModal}>
            {t("activity_management.holiday_form.cancel_btn")}
          </NextButton>
        </>
      )
    }
    return (
      <NextButton type="submit" sx={{ marginTop: "30px" }} disabled={loading}>
        {edit ? t("activity_management.holiday_form.save_btn") : t("activity_management.holiday_form.add_btn")}
      </NextButton>
    )
  }

  return (
    <>
      <Box my={0}>
        <ModalTitle>{edit ? t("activity_management.holiday_form.edit_title") : t("activity_management.holiday_form.create_title")}</ModalTitle>
        <div>
          {displayError && !eventExistsOnHoliday && <Alert severity="error">{displayError}</Alert>}
          {displaySuccess && <Alert severity="success">{displaySuccess}</Alert>}
        </div>
        <form onSubmit={holidayFormik.handleSubmit}>
          <Box hidden={created}>
            <Grid container spacing={3} style={{ marginTop: "10px" }} justifyContent="center">
              <Grid item xs={10}>
                <TextField id="enName" name="enName" value={holidayFormik.values.enName} variant="outlined" focused fullWidth size="medium" label={t("activity_management.holiday_form.english_name_label")} onChange={holidayFormik.handleChange} error={holidayFormik.touched.enName && Boolean(holidayFormik.errors.enName)} helperText={holidayFormik.errors.enName} />
              </Grid>
              <Grid item xs={10}>
                <TextField id="frName" name="frName" value={holidayFormik.values.frName} variant="outlined" focused fullWidth size="medium" label={t("activity_management.holiday_form.french_name_label")} onChange={holidayFormik.handleChange} error={holidayFormik.touched.frName && Boolean(holidayFormik.errors.frName)} helperText={holidayFormik.errors.frName} />
              </Grid>
              <Grid item xs={10}>
                <FormControl id="date" fullWidth focused variant="filled" error={holidayFormik.touched.date && Boolean(holidayFormik.errors.date)}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <FormLabel sx={{position: "absolute", top: -10, left: 10, zIndex: 10, fontSize: "13px", background: "white", px: "6px"}}>{t("activity_management.holiday_form.date_label")}</FormLabel>
                      <StyledDatePicker value={dayjs(holidayFormik.values.date)} size="large" suffixIcon={<EventIcon />} allowClear={false} onChange={(value) => onChangeDate("date", value ? value : null)} status={DatePickerHasError("date") ? "error" : undefined} />
                    </LocalizationProvider>
                    {holidayFormik.touched.date && holidayFormik.errors.date && (
                      <FormHelperText>
                        <>{holidayFormik.errors.date}</>
                      </FormHelperText>
                    )}
                  </FormControl>
              </Grid>

              <Grid item xs={10} sx={{ paddingTop: "10px!important", display: "flex", justifyContent: "center", flexWrap: "wrap"}}>
                <FormButtons />
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default HolidayForm;
