import { axios } from "./axios";
import { TherapeuticArea } from "../typings/interfaces";
import useSWR from "swr";

export const ENDPOINT = "/therapeuticAreas";
interface CreateEditProps {
  enName: string;
  frName: string;
  therapeuticArea: string;
}

export const getTherapeuticAreas = async () => {
  const { data } = await axios.get(ENDPOINT);
  return data;
};

export function useTherapeuticAreas() {
  const {
    data,
    error: isTherapeuticAreasError,
    isLoading: isTherapeuticAreasLoading,
    mutate: mutateTherapeuticAreas,
  } = useSWR(ENDPOINT, getTherapeuticAreas, {
    revalidateOnFocus: false,
  });

  const create = async ({ enName, frName, therapeuticArea }: CreateEditProps) => {
    const { data } = await axios.post(ENDPOINT, { enName, frName, therapeuticArea });
    mutateTherapeuticAreas();

    return data;
  };

  const update = async (id: string, { enName, frName, therapeuticArea }: CreateEditProps) => {
    const { data } = await axios.put(`${ENDPOINT}/${id}`, { enName, frName, therapeuticArea });
    mutateTherapeuticAreas();

    return data;
  };

  const deleteById = async (id: string) => {
    const { data } = await axios.delete(`${ENDPOINT}/${id}`);
    mutateTherapeuticAreas();

    return data;
  };

  return {
    isTherapeuticAreasLoading,
    therapeuticAreas: data?.therapeuticAreas.sort((a: TherapeuticArea, b: TherapeuticArea) => Number(b.showInEval) - Number(a.showInEval) || a.enName.localeCompare(b.enName)) as TherapeuticArea[],
    isTherapeuticAreasError: isTherapeuticAreasError?.statusText,
    mutateTherapeuticAreas,
    create,
    update,
    deleteById,
  };
}
