import { Moderator } from "../typings/interfaces";

const getModeratorsByIds = (moderatorIds: string[], moderators: Moderator[]) => {
  const moderatorNames = moderatorIds
    .map((moderatorId: string) => (moderators.find((moderator) => moderator.speakerId === moderatorId) || {}).displayName)
    .filter(Boolean)
    .join(", ");
  return moderatorNames;
};

const getEventModeratorNames = (moderators: any) => {
  const moderatorNames = moderators.map((moderator: any) => moderator.name).join(", ");
  return moderatorNames ? moderatorNames : "N/A";
};

export { getModeratorsByIds, getEventModeratorNames };
