import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { Evaluation, Event, Moderator } from "../../typings/interfaces";
import { useAnimatedCounter } from "../../hooks/useAnimatedCounter";
import { useState, useEffect } from "react";
import { AverageNumbers } from "../../utils/average";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ReportHeaderWrapper = styled(Grid)(({ theme }) => ({
  width: "100%",
  padding: "20px",
  backgroundColor: "#ffffff",
  border: "1px solid #D5D5D5",
  borderRadius: "5px",
  position: "relative",
  marginTop: "60px",
  [theme.breakpoints.up("lg")]: {
    marginTop: "0px"
  }
}));

const ReportHeaderTitleWrapper = styled(Grid)(({ theme }) => ({
  width: "100%",
  padding: "0px 10px",
  display: "flex",
  alignItems: "center",
}));

const ReportHeaderTitle = styled("h1")(({ theme }) => ({
  color: theme.palette.primary.dark,
  paddingRight: "40px",
  margin: "15px 0px",
  fontSize: "28px",
  marginBottom: theme.spacing(1)
}));

const ReportHeaderInfo = styled(Grid)(({ theme }) => ({
  width: "100%",
  padding: "5px 10px",
  display: "flex",
  alignItems: "center",
  marginBottom: "5px",
}));

const ReportHeaderInfoText = styled("span")(
  ({ theme }) => ({
    color: "#3D3D3D",
    display: "flex",
    alignItems: "center",
    paddingRight: "20px",
    fontSize: "18px"
  })
);

const ReportHeaderRating = styled("span")(
  ({ theme }) => ({
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    paddingRight: "20px",
    fontSize: "16px",
    marginLeft: "auto",
  })
);

const ReportHeaderRatingNumber = styled("span")(
  ({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: "50px",
    fontWeight: 700,
    paddingLeft: "20px",
    alignSelf: "flex-start",
    display: "flex",
    lineHeight: "6px",
  })
);

type ReportModeratorHeaderProps = {
  moderator: Moderator;
  events: Event[];
};
const ReportModeratorHeader = ({ moderator, events }: ReportModeratorHeaderProps) => {
  const navigate = useNavigate();
  const [overallRating, setOverallRating] = useState<number>(0);
  const { t } = useTranslation();
  
  const goBack = () => {
    navigate("/reporting");
  }

  useEffect(() => {
    if (events.length > 0) {
      const allEvals = events.map((i) => i.evaluations).flat(1) as Evaluation[];
      const speakerRatings = allEvals
        .map((i) =>
          i?.moderators
            .filter((j) => j.moderatorId === moderator.speakerId)
            .map((j) => j.rating)
        )
        .flat(1);
      const speakerAverage = AverageNumbers(speakerRatings);
      setOverallRating(speakerAverage);
    }
  }, [events]);

  return (
    <>
      <Grid container>
      <ReportHeaderWrapper container>
        <ReportHeaderTitleWrapper>
          <ReportHeaderTitle>{moderator.displayName}</ReportHeaderTitle>
        </ReportHeaderTitleWrapper>

        <ReportHeaderInfo>
          <ReportHeaderInfoText>{moderator.email}</ReportHeaderInfoText>
          <ReportHeaderRating>
            {t("moderator_report.overall_rating")}{" "}
            <ReportHeaderRatingNumber>
              {useAnimatedCounter(overallRating, 0, 1).toFixed(1)}
            </ReportHeaderRatingNumber>
          </ReportHeaderRating>
        </ReportHeaderInfo>
      </ReportHeaderWrapper>
      </Grid>
    </>
  );
};

export default ReportModeratorHeader;
