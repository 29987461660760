import React from "react";
import { MoreLinkArg, EventSegment } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction"; // needed for dayClick
import { format, isToday, addMinutes, isSameDay } from "date-fns";
import frLocale from "@fullcalendar/core/locales/fr";
import emotionStyled from "@emotion/styled"; //emotion styled components
import { Activity, ActivityType, Brand, Holiday, RecurringActivity, RecurringActivityOccurence } from "../../typings/interfaces";
import { useContext, useState } from "react";
import { Tooltip, IconButton, styled } from "@mui/material";
import CalendarActivityInfoCard from "./CalendarActivityInfoCard";
import { EventApi } from "@fullcalendar/core";
import { useTranslation } from "react-i18next";
import DraggableDialog from "../styled/DraggableDialog";
import CustomAlertToast from "../styled/CustomAlertToast";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { formatActivityName, formatActivityNameCalendarEvent } from "../../utils/activities";
import AppContext from "../../context/Auth";
import muiTheme from "../../muiTheme";
import Color from "color";
import CalendarRecurringActivityInfoCard from "./CalendarRecurringActivityInfoCard";

//Global vars
const brandBarWidth = 10;

const initialActivity = {} as Activity;
const initialRecurringActivity = {} as RecurringActivity;
const initialRecurringActivityOccurence = {} as RecurringActivityOccurence;

type EventRowProps = {
  ellipsis: boolean;
};

const EventRow = emotionStyled.div<EventRowProps>`
  cusor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  white-space: ${(props) => (props.ellipsis ? "nowrap" : "wrap")};
  overflow: hidden;
  text-overflow: ${(props) => (props.ellipsis ? "ellipsis" : "unset")};

  & > i {
    font-weight: 500;
  }
`;

const EventRowTitle = emotionStyled.div`
  justify-content: flex-start;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 7px 10px;
  text-overflow: ellipsis;
  padding-right: 0px;

  &:-webkit-scrollbar {
    width: 0.5em;
  }

  &:-webkit-scrollbar-thumb {
    background-color: darkgrey;
  }

  &:-webkit-scrollbar-track {
    background-color: lightgrey;
  }

  & i {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const EventRowBrandBar = emotionStyled.div`
  width: ${brandBarWidth}px;
  height: auto;

  &:last-of-type {
    border-radius: 0px 1px 1px 0px;
  }

`;

const EventRowList = emotionStyled.p`
  cursor: pointer;
  border-bottom: 1px solid #e1e1e1;
  padding: 14px 0px;
  margin: 0;
  display: flex; 
  align-items: center;

  &:last-of-type {
    border-bottom: 0px; 
  }

  & > i {
    font-weight: 500;
  }
`;

type Props = {
  toggleCreateModal: (date: Date) => void;
  toggleEditModal: (activity: Activity) => void;
  toggleCreateDuplicateModal: (activity: Activity) => void;
  activities: Activity[];
  holidays: Holiday[];
  currentYear: number;
  occurences: RecurringActivityOccurence[];
  recurringActivities: RecurringActivity[];
};

//we provide events here to ensure we have them as the Event page handles waiting for the data to load
const CalendarView = React.forwardRef(({ toggleCreateModal, toggleEditModal, toggleCreateDuplicateModal, activities, occurences, recurringActivities, holidays, currentYear }: Props, ref: any) => {
  const { readOnlyEngagementTracker } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [activity, setActivity] = useState<Activity>(initialActivity);
  const [recurringActivity, setRecurringActivity] = useState<RecurringActivity>(initialRecurringActivity);
  const [occurence, setOccurence] = useState<RecurringActivityOccurence>(initialRecurringActivityOccurence);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [moreLinkModal, setMoreLinkModal] = useState(false);
  const [recurringInfoModelOpen, setRecurringInfoModelOpen] = useState(false);
  const [moreEvents, setMoreEvents] = useState<EventSegment[]>([]);
  const [cameFromMoreList, setCameFromMoreList] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openHolidayAlert, setOpenHolidayAlert] = useState(false);

  //Runs when an event is clicked on from the calendar
  const activityClickHandler = (event: any, view?: string) => {
    const { id, extendedProps } = event;
    const { isHoliday, isRecurring } = extendedProps;

    //if this is a holiday event - do nothing
    if (isHoliday) {
      return;
    } else if (isRecurring) {
      const foundOccurence = occurences.find((activity) => id === activity.id);
      if (foundOccurence) {
        const parentRecurringActivity = recurringActivities.find((recurringActivity) => recurringActivity.id === foundOccurence.parentId);
        if (parentRecurringActivity) {
          setRecurringActivity(parentRecurringActivity);  
        }
        setOccurence(foundOccurence);
      }
    } else {
      const foundActivity = activities.find((activity) => id === activity.id);

      if (foundActivity) {
        setActivity(foundActivity);
      }
    }

    //if the user clicked on an event from the More Link - Event List Modal
    if (view === "moreList") {
      setCameFromMoreList(true);
    } else {
      setCameFromMoreList(false);
    }

    if (isRecurring) {
      setRecurringInfoModelOpen(true);
    } else {
      setInfoModalOpen(true);
    }
    setMoreLinkModal(false);
  };

  //Formats events for full calendar
  const formatActivitiesForCalendar = (activities: Activity[], holidays: Holiday[], occurences: RecurringActivityOccurence[]) => {
    let holidayEvents: any[] = [];
    if (holidays && holidays.length > 0) {
      holidayEvents = holidays.map((holiday, index) => {
        const { _id, date, enName, frName } = holiday;
        return {
          id: _id + `-holiday`,
          title: currentLanguage === "en" ? enName : frName,
          start: date,
          end: date,
          classNames: ["nnci_activity", `holiday_event`],
          isHiddenDay: false,
          backgroundColor: "#D3DEED",
          textColor: muiTheme.palette.primary.dark,
          // display: 'background',
          allDay: false,
          isHoliday: true,
          isRecurring: false,
          startString: date
        };
      });
    }
    const calendarEvents = activities.map((activity, index) => {
      const { id, startTime, endTime } = activity;
      const backgroundLuminosity = Color(activity.brands[0].color).luminosity();

      return {
        id,
        title: formatActivityNameCalendarEvent(activity, currentLanguage),
        start: startTime,
        end: endTime,
        classNames: ["nnci_activity", `brand_${activity.brands[0].value}, activity_${activity.activityType[0].value}`, `${id}`],
        activityType: activity.activityType,
        isHiddenDay: false,
        backgroundColor: "#ffffff",
        borderColor: "#185ad2",
        textColor: "#051965",
        isHoliday: false,
        brands: activity.brands,
        isRecurring: false,
        startString: startTime
      };
    });

    const recurringEvents = occurences.map((occurence) => {
      const { id, startTime, endTime } = occurence;

      return {
        id,
        title: formatActivityNameCalendarEvent(occurence, currentLanguage),
        start: startTime,
        end: endTime,
        classNames: ["nnci_activity", `brand_${occurence.brands[0].value}, activity_${occurence.activityType[0].value}`, `${id}`],
        activityType: occurence.activityType,
        isHiddenDay: false,
        backgroundColor: "#ffffff",
        borderColor: "#185ad2",
        textColor: "#051965",
        isHoliday: false,
        brands: occurence.brands,
        isRecurring: true,
        startString: startTime
      };
    });

    return [...calendarEvents, ...holidayEvents, ...recurringEvents];
  };

  //Runs when new events get pushed to the calendar
  const handleEvents = (events: EventApi[]) => {
    //console.log("new events loaded to calendar", events);
  };

  const dayClicked = (arg: DateClickArg) => {
    // const isHoliday = holidays.find((holiday) => {
    //   return isSameDay(arg.date, new Date(holiday.date) )
    // })

    // if(isHoliday) {
    //   setOpenHolidayAlert(true);
    //   return;
    // }

    if (readOnlyEngagementTracker === false) {
      if (isToday(arg.date)) {
        let newDate = addMinutes(new Date(), 30);
        toggleCreateModal(newDate);
        return;
      }
      toggleCreateModal(arg.date);
    } else {
      setOpenAlert(true);
    }
  };

  const closeRecurringInfoModal = () => {
    setRecurringInfoModelOpen(false);
  }

  const closeInfoModal = () => {
    setInfoModalOpen(false);
  };

  const showMoreLinkModal = (info: MoreLinkArg) => {
    setMoreLinkModal(true);
    setMoreEvents(info.allSegs);
  };

  const closeMoreLinkModal = () => {
    setMoreLinkModal(false);
  };

  //When the edit button is clicked from the EventInfoCard modal
  const switchToEdit = (activity: Activity) => {
    //close the info card
    closeInfoModal();
    closeRecurringInfoModal();

    //open the edit modal
    toggleEditModal(activity);
  };

  //When the edit button is clicked from the EventInfoCard modal
  const duplicateForCreate = (activity: Activity) => {
    //close the info card
    closeInfoModal();
    closeRecurringInfoModal();
    //open the edit modal
    toggleCreateDuplicateModal(activity);
  };

  const memoFullCalendar = React.useMemo(() => {
    return (
      <div style={{ height: "105vh", minHeight: "950px", width: "100%", minWidth: "1024px", overflow: "scroll", scrollbarWidth: "none" }}>
        <FullCalendar
          dayMaxEvents={true} //true will add as many events into the day that can fit and then show a more link
          fixedWeekCount={false}
          showNonCurrentDates={true} //whether to show the past or future month dates
          locales={[frLocale]}
          firstDay={0}
          locale={currentLanguage}
          headerToolbar={false} //turns off the default calendar header
          ref={ref}
          aspectRatio={1.38}
          height="100%"
          // contentHeight="110vh"
          eventDisplay="block"
          eventColor="var(--primary-blue)" //used for all day events and multiple day events
          eventContent={renderActivityContent}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={formatActivitiesForCalendar(activities, holidays, occurences)}
          dateClick={(arg: DateClickArg) => dayClicked(arg)}
          eventClick={(info) => activityClickHandler(info.event)}
          eventsSet={handleEvents}
          moreLinkClick={(info) => showMoreLinkModal(info)}
          dayHeaderFormat={{
            weekday: "long",
          }}
          eventTimeFormat={{
            hour: "numeric",
            minute: "2-digit",
          }}
        />
      </div>
    );
  }, [activities, occurences, currentLanguage, currentYear]);

  return (
    <div className="nnci_calendar nnci_activities_calendar" style={{ overflow: "scroll", scrollbarWidth: "none" }}>
      {activities && holidays && <>{memoFullCalendar}</>}

      {/* Activity info card modal */}
      <DraggableDialog size="475px" open={infoModalOpen} closeModal={closeInfoModal}>
        <>
          {cameFromMoreList && (
            <IconButton sx={{ padding: "0", margin: "0", marginLeft: "-8px", marginTop: "10px" }} onClick={() => (setInfoModalOpen(false), setMoreLinkModal(true))}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <CalendarActivityInfoCard activity={activity} toggleEditModal={switchToEdit} closeModal={closeInfoModal} duplicateForCreate={duplicateForCreate} />
        </>
      </DraggableDialog>

      {/* Recurring Activity ocurrence info card modal */}
      <DraggableDialog size="475px" open={recurringInfoModelOpen} closeModal={closeRecurringInfoModal}>
        <>
          {cameFromMoreList && (
            <IconButton sx={{ padding: "0", margin: "0", marginLeft: "-8px", marginTop: "10px" }} onClick={() => (setRecurringInfoModelOpen(false), setMoreLinkModal(true))}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <CalendarRecurringActivityInfoCard ocurrence={occurence} recurringActivity={recurringActivity} toggleEditModal={switchToEdit} closeModal={closeRecurringInfoModal} duplicateForCreate={duplicateForCreate} />
        </>
      </DraggableDialog>

      {/* More Activity Modal - shows when clicking the more events link in the full calendar day */}
      <DraggableDialog open={moreLinkModal} closeModal={closeMoreLinkModal}>
        <>
          {moreEvents.map((calendarEvent) => {
            return (
              <EventRowList onClick={() => activityClickHandler(calendarEvent.event, "moreList")}>
                <AccessTimeIcon sx={{ paddingRight: "5px" }} /> <strong style={{ paddingRight: "8px" }}>{calendarEvent.event.start && format(calendarEvent.event.start, "h:mm a")}</strong>
                <span>{calendarEvent.event.title}</span>
              </EventRowList>
            );
          })}
        </>
      </DraggableDialog>
      <CustomAlertToast setOpenAlert={setOpenAlert} openAlert={openAlert} textTranslationKey="readonly_error" severity="error" autoHideDuration={6000} />
      <CustomAlertToast setOpenAlert={setOpenHolidayAlert} openAlert={openHolidayAlert} textTranslationKey="holiday_day_error" severity="error" autoHideDuration={6000} />
    </div>
  );

  function renderActivityContent(eventInfo: { activityType: ActivityType; timeText: string; view: any; event: { title: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; extendedProps: any; start: any; id: string } }) {
    // Get the event day of week to determine the position of the tooltip
    const dayOfweek = format(eventInfo.event.start, "EEEE");

    const toolTipPosition = dayOfweek === "Saturday" ? "left-start" : "right-start"; //if saturday event then show tooltip on left

    const getBrandEventBars = (brands: Brand[]) => {
      return brands.map((brand) => <EventRowBrandBar style={{ backgroundColor: brand.color }}></EventRowBrandBar>);
    };

    const content = (ellipsis: boolean, isToolTip: boolean) => {
      const brandsBarTotalWidth = eventInfo.event.extendedProps.brands ? eventInfo.event.extendedProps.brands.length * brandBarWidth : 0;
      const EventRowTitleWidth = isToolTip ? "100%" : `${brandsBarTotalWidth}px`;
      return (
        <EventRow ellipsis={ellipsis}>
          <EventRowTitle style={!isToolTip ? { width: `calc(100% - ${EventRowTitleWidth}` } : { width: "100%" }}>
            <i>{eventInfo.event.title}</i>
          </EventRowTitle>
          {!isToolTip && eventInfo.event.extendedProps.brands && eventInfo.event.extendedProps.brands.length > 0 ? (
            <div className="eventBarWrapper" style={{ justifyContent: "flex-end", display: "flex" }}>
              {getBrandEventBars(eventInfo.event.extendedProps.brands)}
            </div>
          ) : null}
        </EventRow>
      );
    };

    if (eventInfo.event.extendedProps.isHoliday) {
      return <> {content(true, false)}</>;
    }

    return (
      <Tooltip
        key={eventInfo.event.id}
        sx={{ fontSize: "20px", color: "red" }}
        title={content(false, true)}
        enterDelay={300}
        enterNextDelay={1000}
        placement={toolTipPosition}
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              fontSize: "14px",
            },
          },
        }}
      >
        {content(true, false)}
      </Tooltip>
    );
  }
});

export default CalendarView;
