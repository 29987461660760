import { Button, ButtonProps, Container, Divider, Grid, styled, Tooltip, Typography, Zoom } from "@mui/material";
import Layout from "../components/Layout";
import muiTheme from "../muiTheme";
import { format } from "date-fns";
import { enUS, fr } from 'date-fns/esm/locale'
import { useEffect, useState } from "react";
import CheckInForm from "../components/evaluation/CheckInForm";
import EvaluationForm from "../components/evaluation/EvaluationForm";
import { useParams } from "react-router-dom";
import { CheckinEvent } from "../typings/interfaces";
import { useEventCheckin } from "../api/eventCheckin";
import { getEventSpeakerNames } from "../utils/speakers";
import { getEventModeratorNames } from "../utils/moderators";
import useEventNameCheckin from "../hooks/nonauthenticated/useEventNameCheckin";
import { t } from "i18next";
import Loading from "../components/Loading";
import { parseEventStartTime } from "../utils/parseDate";
import { convertFromTimezoneDisplay } from "../utils/timezones";
import { useTranslation } from "react-i18next";
import { GetProvinceName } from "../utils/provinces";

const FormButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  padding: "8px",
  fontSize: "20px",
  boxShadow: "unset",
  borderRadius: "4px",
  marginBottom: "20px",
  textTransform: "uppercase",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: "unset",
    color: "#fff",
  },
}));

const LargeText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontWeight: 700,
  fontSize: "28px",
  marginBottom: "10px",
}));

const StyledGeneralText = styled(Typography)(({theme}) => ({
  color: "#3D3D3D",
}))

const CheckInPage = () => {
  const { i18n } = useTranslation();
  const [isCheckedIn, setIsCheckedIn] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [event, setEvent] = useState<CheckinEvent | null>(null);
  const { eventId } = useParams();
  const { getById } = useEventCheckin(eventId ? eventId : "");
  const [attendeeId, setAttendeeId] = useState("");
  const [attendeeName, setAttendeeName] = useState("");
  const [formatEventNameCheckin] = useEventNameCheckin();

  // state variable to enable/disable the Program Evaluation section
  const [canEvaluate, setCanEvaluate] = useState<boolean>(false);

  enum STEPS {
    LANDING = 0,
    CHECKIN = 1,
    EVALUATION = 2,
    COMPLETED = 3,
  }
  const [currentStep, setCurrentStep] = useState<number>(STEPS.LANDING);

  function checkIn() {
    if (currentStep === STEPS.LANDING || currentStep === STEPS.COMPLETED) {
      setCurrentStep(STEPS.CHECKIN);
    }
  }

  function completed() {
    setCurrentStep(STEPS.COMPLETED);
  }

  function beginEvaluation() {
    if (canEvaluate) {
      setCurrentStep(STEPS.EVALUATION);
    }
  }

  function goToLanding() {
    setCurrentStep(STEPS.LANDING);
  }

  async function getEvent(id: string) {
    try {
      const data = await getById(id);
      setEvent(data);
    } catch (error) {
      setNotFound(true);
    }
  }
  useEffect(() => {
    var attendeeIdCookie = localStorage.getItem("attendeeId");
    var attendeeNameCookie = localStorage.getItem("attendeeName");
    var eventIdCookie = localStorage.getItem("eventId");
    var completedEvaluationCookie = localStorage.getItem("completedEvaluation");
    if (attendeeNameCookie && attendeeIdCookie && eventIdCookie) {
        if (eventIdCookie === eventId) {
            setAttendeeName(attendeeNameCookie);
            setAttendeeId(attendeeIdCookie); 
            setIsCheckedIn(true);
            if (completedEvaluationCookie) {
                setCurrentStep(STEPS.COMPLETED);
            }
        } else {
            localStorage.removeItem("attendeeId");
            localStorage.removeItem("attendeeName");
            localStorage.removeItem("eventId");
            localStorage.removeItem("completedEvaluation");
        }
    }
  }, [STEPS.COMPLETED, eventId])
  
  useEffect(() => {
    if (isCheckedIn) {
      // 30 second delay to active Program Evaluation section
      setTimeout(() => {
        setCanEvaluate(true);
      }, 30000)
    }
  }, [isCheckedIn])

  useEffect(() => {
    getEvent(eventId || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  if (notFound || !eventId || eventId.length === 0) {
    return <Typography variant="h4">Not found.</Typography>;
  } else if (event && event._id) {
    return (
      <Layout fullWidth={true}>
        <Container sx={{paddingBottom: "40px"}}>
          <Grid container sx={{ display: currentStep === STEPS.LANDING || currentStep === STEPS.COMPLETED ? "block" : "none" }}>
            <Grid item xs={12} sx={{ textAlign: "center", marginTop: "40px" }}>
              <Typography sx={{ fontSize: "20px", color: muiTheme.palette.primary.dark, marginBottom: "10px", display: isCheckedIn ? "block" : "none" }}>
                {t("evaluation.page.greeting")}
                <span style={{color:"#001965", textDecoration: "underline"}}>
                  {attendeeName}
                </span>
                !
              </Typography>
              <Typography sx={{ fontSize: "20px", color: muiTheme.palette.primary.dark }}>{t("evaluation.page.welcome")}</Typography>
              <LargeText>{formatEventNameCheckin(event, true)}</LargeText>
              <Typography sx={{ marginBottom: "10px", fontSize: "20px", color: muiTheme.palette.primary.dark }}>{t("evaluation.page.with")}</Typography>
              <StyledGeneralText sx={{ fontSize: "18px", marginBottom:"5px" }}>{t("evaluation.page.speakers")} {event.speakers && getEventSpeakerNames(event.speakers)} </StyledGeneralText>
              {event.moderators && <StyledGeneralText sx={{ fontSize: "18px" }}>{t("evaluation.page.moderators")} {event.moderators && getEventModeratorNames(event.moderators)}</StyledGeneralText>}
              <Typography sx={{ fontSize: "19px", fontWeight: "bold", color: muiTheme.palette.primary.dark, marginTop: "10px", marginBottom: event.eventType.toLowerCase() !== "virtual" ? "0px" : "15px",':first-letter': {textTransform: "uppercase"}}}>{format(parseEventStartTime(event.start_time), i18n.language === "en" ? "MMMM d, yyyy" : "MMMM d, yyyy", i18n.language === "en" ? {locale: enUS} : {locale: fr})} {i18n.language === "en" ? "at" : "à"} {format(parseEventStartTime(event.start_time), i18n.language === "en" ? "h:mma" : "H 'h 'mm")} ({t(convertFromTimezoneDisplay(event.timeZone))})</Typography>
              {/* {event.eventType.toLowerCase() !== "virtual" &&
              <Typography sx={{ fontSize: "19px", fontWeight: "bold", color: muiTheme.palette.primary.dark, marginTop: "0px", marginBottom: "15px" }}>
                  {`${event.street}, ${event.city}, ${GetProvinceName(event.province, i18n.language)}, ${event.postal}`}
              </Typography>} */}
              {event.isEvaluationsEnabled &&
              <div style={{ display: isCheckedIn ? "none" : "block", marginTop: "20px" }}>
                <FormButton sx={{width: {xs: "80%!important", md: "45%!important"}}} onClick={checkIn}>{t("evaluation.page.checkin_btn")}</FormButton>
                <StyledGeneralText sx={{ fontSize: "16px" }}><strong>{t("evaluation.page.note")}</strong> {t("evaluation.page.checkin_note")}</StyledGeneralText>
              </div>
              }
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ margin: "30px 0px 30px 0px", backgroundColor: "#005AD2" }} />
            </Grid>
            {event.isEvaluationsEnabled &&
            <Grid item xs={12} sx={{ textAlign: "center", opacity: canEvaluate ? 1 : 0.3 }}>
              <LargeText>{t("evaluation.page.evaluation_title")}</LargeText>
              {currentStep === STEPS.LANDING ? (
                <>
                  <StyledGeneralText sx={{ fontSize: "18px", width: {sm: "90%", md: "60%"}, margin: "auto", marginTop: "10px" }}>{t("evaluation.page.evaluation_subhead")}</StyledGeneralText>
                  <Tooltip
                    title={!attendeeId ? t("evaluation.page.tooltip") : !canEvaluate ? t("evaluation.page.evaluation_wait") : ""}
                    TransitionComponent={Zoom}
                    enterNextDelay={500}
                    enterDelay={500}
                    leaveDelay={200}
                    placement="top"
                    arrow={true}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          fontSize: "1.1rem!important",
                          maxWidth: "500px",
                          textAlign: "center",
                          marginBottom: "5px!important",
                          padding: "5px 30px 5px 30px",
                          bgcolor: "#ffffff",
                          color: "primary.dark",
                          boxShadow: " 0 2px 4px 0 rgba(0,0,0,0.15)",
                          border: "1px solid #D8D8D8",
                          "& .MuiTooltip-arrow": {
                            color: "#ffffff",
                            "&:before": {
                              border: "1px solid #D8D8D8",
                            },
                          },
                        },
                      },
                    }}
                  >
                    <FormButton onClick={beginEvaluation} sx={{ marginTop: "20px", width: "45%" }}>
                      {t("evaluation.page.begin_btn")}
                    </FormButton>
                  </Tooltip>
                  <StyledGeneralText sx={{ fontSize: "18px" }}>
                    <strong>{t("evaluation.page.note")}</strong> {t("evaluation.page.evaluation_note")}
                  </StyledGeneralText>
                </>
              ) : (
                <>
                  <StyledGeneralText sx={{ fontSize: "18px", marginTop: "10px" }}>{t("evaluation.page.evaluation_thank_you_head")}</StyledGeneralText>
                </>
              )}
            </Grid>
            }
            {!event.isEvaluationsEnabled &&
            <Grid item xs={12} sx={{textAlign: "center"}}>
              <LargeText>{t("evaluation.page.evaluation_disabled")}</LargeText>
              <StyledGeneralText sx={{ fontSize: "18px" }}>
                {t("evaluation.page.evaluation_disabled_sub_text")}
              </StyledGeneralText>
            </Grid>}
          </Grid>

          <Grid container sx={{ display: currentStep === STEPS.CHECKIN ? "block" : "none" }}>
            <Grid item xs={12}>
              <CheckInForm setCheckedIn={setIsCheckedIn} exit={goToLanding} completed={completed} eventId={eventId} attendeeId={attendeeId} setAttendeeId={setAttendeeId} setAttendeeName={setAttendeeName} />
            </Grid>
          </Grid>

          <Grid container sx={{ display: currentStep === STEPS.EVALUATION ? "block" : "none" }}>
            {event.speakers && <EvaluationForm exit={completed} event={event} attendeeId={attendeeId} />}
          </Grid>
        </Container>
      </Layout>
    );
  } else {
    return (
      <Layout fullWidth={true}>
        <Loading />
      </Layout>
    );
  }
};

export default CheckInPage;
