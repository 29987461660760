import { axios } from "./axios";
import { Brand } from "../typings/interfaces";
import useSWR from "swr";

export const ENDPOINT = "/brands";

interface CreateEditProps {
  enName: string;
  frName: string;
  color: string;
}

export const getBrands = async () => {
  const { data } = await axios.get(ENDPOINT);
  return data;
};

export function useBrands() {
  const {
    isLoading: isBrandsLoading,
    data,
    error: brandError,
    mutate,
  } = useSWR(ENDPOINT, getBrands, {
    onSuccess: (data) => data,
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });

  const create = async ({ enName, frName, color }: CreateEditProps) => {
    const { data } = await axios.post(ENDPOINT, { enName, frName, color });
    mutate();

    return data;
  };

  const update = async (id: string, { enName, frName, color }: CreateEditProps) => {
    const { data } = await axios.put(`${ENDPOINT}/${id}`, { enName, frName, color });
    mutate();

    return data;
  };

  const deleteById = async (id: string) => {
    const { data } = await axios.delete(`${ENDPOINT}/${id}`);
    mutate();

    return data;
  };

  return {
    isBrandsLoading,
    brands: data?.brands as Brand[],
    brandError,
    mutate,
    create,
    update,
    deleteById,
  };
}
