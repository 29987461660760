import { axios } from "./axios";
import useSWR from "swr";
import { Users } from "../typings/interfaces";

export const ENDPOINT = "/users";

export const whoAmI = async () => {
    const { data } = await axios.get(`${ENDPOINT}/whoAmI`);
    localStorage.setItem("SavedToken", "Bearer " + data.token);

    return data.user;
}
