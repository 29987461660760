import { useContext } from "react";
import CardHeader from "@mui/material/CardHeader";
import { Activity, RecurringActivity } from "../../typings/interfaces";
import { Box, CardActions, Chip, IconButton, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EventIcon from "@mui/icons-material/Event";
import AppContext from "../../context/Auth";
import { format, isAfter } from "date-fns";
import InstructionsPopover from "../styled/InstructionsPopover";
import { formatActivityName } from "../../utils/activities";
import { useActivities } from "../../api/activities";
import { MedicalInformation, Segment } from "@mui/icons-material";
import { GetProvinceName } from "../../utils/provinces";
import { useRecurringActivities } from "../../api/activitiesRecurring";

const InfoCardTitle = styled(CardHeader)(({ theme }) => ({
  padding: "10px 0px",
  "& .MuiTypography-root": {
    color: theme.palette.primary.dark,
    fontSize: "24px",
    fontWeight: "bold",
  },
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "#ffffff",
  borderRadius: "4px",
  border: "1px solid #E5E5E5",
  boxShadow: "1px 2px 6px 0 rgba(0,0,0,0.08)",
  marginRight: "10px",
  
  
  "&:last-child": {
    marginRight: "0px"
  }
}));

const CardContent = styled("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginRight: "0px",
  color: "#3D3D3D",
}));

const CardContentProvinces = styled("span")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  marginRight: "0px",
  color: "#3D3D3D",
}));

type Props = {
  activity: Activity | RecurringActivity;
  toggleEditModal: (activity: Activity) => void;
  duplicateForCreate: (activity: Activity) => void;
  closeModal: VoidFunction;
};

// Define a function to check if an object is of type RecurringActivity
function isRecurringActivity(obj: any): obj is RecurringActivity {
  return 'activityInfo' in obj;
}

const CalendarActivityInfoCard = ({ activity, toggleEditModal, closeModal, duplicateForCreate }: Props) => {
  const { hasRole, readOnlyEngagementTracker } = useContext(AppContext);
  const { deleteActivity } = useActivities();
  const { deleteRecurringActivity } = useRecurringActivities();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const canModifyEvent = true;

  const data = isRecurringActivity(activity) ? {...activity.activityInfo, id:activity.id, userId: activity.userId } : {...activity}

  const tryDelete = async (id: string) => {
    const confirm = window.confirm(t("delete_confirm_message"));

    if (!confirm) {
      return;
    }

    try {
      await deleteActivity(id);
      closeModal();
    } catch (error) {
      alert(t("delete_event_fail_messsage"));
    }
  };

  return (
    <>
      <InstructionsPopover isModal={true} width="220px">
        <>
          <div style={{ display: "flex", marginBottom: 10, lineHeight: 0, alignItems: "center" }}>
            <div style={{ marginRight: "10px" }}>
              <CustomIconButton disableFocusRipple disableRipple>
                <CreateOutlinedIcon color="warning" sx={{ fontSize: "1.4rem" }} />
              </CustomIconButton>
            </div>
            <span style={{ fontWeight: "600" }}>{t("instructions.activity_info_card.edit")}</span>
          </div>
          <div style={{ display: "flex", marginBottom: 10, lineHeight: 0, alignItems: "center" }}>
            <div style={{ marginRight: "10px" }}>
              <CustomIconButton disableFocusRipple disableRipple>
                <DeleteOutlinedIcon color="error" sx={{ fontSize: "1.4rem" }} />
              </CustomIconButton>
            </div>
            <span style={{ fontWeight: "600" }}>{t("instructions.activity_info_card.delete")}</span>
          </div>
        </>
      </InstructionsPopover>
      
      <Box my={2} sx={{ color: "#3D3D3D" }}>
        <Box className="info_card_header" mx={1}>
          <Box sx={{ mb: 1, display: "flex", justifyContent: "space-between" }}>
            <InfoCardTitle title={formatActivityName(data, currentLanguage)} />
          </Box>

          <Box display="flex" alignItems="center" sx={{ mb: 2, color: "#3D3D3D", alignItems: "center" }}>
            <CardContent>
              <EventIcon sx={{ marginRight: "5px", fontSize: "22px", color: "#747474" }} />
              {format(new Date(data.startTime), i18next.language === "en" ? "MM/dd/yyyy h:mm aa" : "MM/dd/yyyy H 'h 'mm")}
              {data.activityType[0].value !== "email" && " - " + format(new Date(data.endTime), i18next.language === "en" ? "MM/dd/yyyy h:mm aa" : "MM/dd/yyyy H 'h 'mm")}
            </CardContent>
          </Box>

          <Box display="flex" alignItems="center" sx={{ mb: 2, color: "#3D3D3D", alignItems: "center" }}>
            <CardContentProvinces>
              <LocationOnOutlinedIcon sx={{ mr: 1, marginRight: "2px", fontSize: "22px", color: "#747474" }} />
              {data.provinces.filter((province) => province !== 'all').map((province, index, array) => {
                return  <> {GetProvinceName(province, currentLanguage)}{index < (array.length - 1) ? "," : ""} </>
              })}
            </CardContentProvinces>
          </Box>

          {data.segmentationIds.length > 0 && 
          <Box display="flex" flexWrap={"wrap"} rowGap={1} alignItems="center" sx={{ mb: 2, color: "#3D3D3D", alignItems: "center" }}>
              <Segment sx={{ mr: 1, marginRight: "2px", fontSize: "22px", color: "#747474" }} />
              {data.segmentations.map(i => {
                return <Chip label={currentLanguage === "en" ? i.enName : i.frName} sx={{mx: "4px"}} />
              })}
          </Box>}

          <Box display="flex" flexWrap={"wrap"} rowGap={1} alignItems="center" sx={{ mb: 2, color: "#3D3D3D", alignItems: "center" }}>
              <MedicalInformation sx={{ mr: 1, marginRight: "2px", fontSize: "22px", color: "#747474" }} />
              {data.specialties.map(i => {
                return <Chip label={t(i)} sx={{mx: "4px"}} />
              })}
          </Box>
          <Box display="flex" sx={{ mt: 2, marginLeft: "5px" }}>
            <CardContent>{data.notes}</CardContent>
          </Box>
        </Box>
      </Box>
      {readOnlyEngagementTracker === false && (
        <Box className="info_card_actions" mx={1}>
          <CardActions sx={{ padding: "5px 0px", display: "flex", justifyContent: "space-between" }}>
            {hasRole(["sudo", "admin", "msl", "rep"]) && (
              <CustomIconButton
                size="small"
                color="error"
                disabled={!canModifyEvent}
                onClick={() => {
                  tryDelete(data.id);
                }}
              >
                <DeleteOutlinedIcon />
              </CustomIconButton>
            )}
            {hasRole(["sudo", "admin", "msl", "rep"]) && (
              <div>
              <CustomIconButton
                size="small"
                color="primary"
                disabled={!canModifyEvent}
                onClick={() => {
                  duplicateForCreate(data);
                }}
              >
                <ContentCopyIcon />
              </CustomIconButton>

              <CustomIconButton
                size="small"
                color="warning"
                disabled={!canModifyEvent}
                onClick={() => {
                  toggleEditModal(data);
                }}
              >
                <CreateOutlinedIcon />
              </CustomIconButton>
              </div>
            )}

            
          </CardActions>
        </Box>
      )}
    </>
  );
};

export default CalendarActivityInfoCard;
