import React, {Suspense} from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppProvider } from "./context/Auth";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { ThemeProvider } from "@mui/material";
import muiTheme from "./muiTheme";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['en', 'fr'],
    fallbackLng: "en",
    detection: {
      order: ['path', 'cookie', 'localStorage', 'subdomain', 'htmlTag',],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/assets/{{lng}}/translation.json',
    },
  });

const loadingMarkup = (
  <></>
)

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <Suspense fallback={loadingMarkup}>
  {/* <React.StrictMode> */}
    <ThemeProvider theme={muiTheme}>
    <AppProvider>
      <App />
    </AppProvider>
    </ThemeProvider>
  {/* </React.StrictMode> */}
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
