import { Province } from "../typings/interfaces";

const provinceList: Province[] = [
  {
    name: "Alberta",
    frName: "Alberta",
    value: "alberta",
    abbreviation: "AB",
  },
  {
    name: "British Columbia",
    frName: "Colombie-Britannique",
    value: "british-columbia",
    abbreviation: "BC",
  },
  {
    name: "Manitoba",
    frName: "Manitoba",
    value: "manitoba",
    abbreviation: "MB",
  },
  {
    name: "New Brunswick",
    frName: "Nouveau-Brunswick",
    value: "new-brunswick",
    abbreviation: "NB",
  },
  {
    name: "Newfoundland and Labrador",
    frName: "Terre-Neuve-et-Labrador",
    value: "newfoundland-labrador",
    abbreviation: "NL",
  },
  {
    name: "Northwest Territories",
    frName: "Territoires du Nord-Ouest",
    value: "northwest-territories",
    abbreviation: "NT",
  },
  {
    name: "Nova Scotia",
    frName: "Nouvelle-Écosse",
    value: "nova-scotia",
    abbreviation: "NS",
  },
  {
    name: "Nunavut",
    frName: "Nunavut",
    value: "nunavut",
    abbreviation: "NU",
  },
  {
    name: "Ontario",
    frName: "Ontario",
    value: "ontario",
    abbreviation: "ON",
  },
  {
    name: "Prince Edward Island",
    frName: "Île-du-Prince-Édouard",
    value: "pei",
    abbreviation: "PEI",
  },
  {
    name: "Quebec",
    frName: "Québec",
    value: "quebec",
    abbreviation: "QC",
  },
  {
    name: "Saskatchewan",
    frName: "Saskatchewan",
    value: "saskatchewan",
    abbreviation: "SK",
  },
  {
    name: "Yukon Territory",
    frName: "Yukon",
    value: "yukon",
    abbreviation: "YT",
  },
];

function GetProvinceName(value: string | undefined, language: string) {
  if (!value) return "";

  const province = provinceList.find((i) => i.value === value);

  if (!province) return "";

  if (language === "fr") {
    return province.frName;
  }

  return province.name;
}

const getProvincesByValues = (values: string[], language: string) => {
  const provinces = provinceList.filter((province) => values.includes(province.value));
  if (provinces.length > 0) {
    return language === "en" ? provinces.map(i => i.name).join(', ') : provinces.map(i => i.frName).join(', ');
  }
  return "";
}



export { provinceList, GetProvinceName, getProvincesByValues };
