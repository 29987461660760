
import { axios } from "./axios";

export const ENDPOINT = "/attendees";

export async function updateAttendee(attendeeId: string, email: string, firstName: string, lastName: string,
    jobTitle: string, clinic: string, receiveFiles: boolean, followUp: boolean) {
    const { data } = await axios.put(`${ENDPOINT}/${attendeeId}`, {
        email,
        firstName,
        lastName,
        jobTitle,
        clinic,
        followUp,
        receiveFiles
    });

    return data;
}