import { Activity, Weekly } from "../typings/interfaces";

export const formatActivityName = (activity: Activity, language: string) => {
  if (activity.brands.length > 0 && activity.activityType.length > 0) {

    const brands = activity.brands.map((brand, index) => {
      return (
        <><span style={{display: "inline-block"}}>{language === 'en' ? brand.enName : brand.frName}</span>{index < (activity.brands.length - 1) ? "/" : null}</>
      )
    })

    if(language === "en") {
      return (
      <div>
        {brands.map((brand) => brand)} {activity.activityType[0].enName}
      </div>
      )
    } else {
      return (
        <div>
          {activity.activityType[0].frName} {brands} 
        </div>
      );
    }
  } else {
    return "No brand or activity type selected";
  }
};


export const formatActivityNameCalendarEvent = (activity: Activity, language: string) => {
  let joinedBrands = "";
  if (activity.brands.length > 0 && activity.activityType.length > 0) {
    joinedBrands = activity.brands.map((brand) => brand.enName).join("/");
    if (language === "en") {
      return `${joinedBrands} ${activity.activityType[0].enName}`;
    }
    joinedBrands = activity.brands.map((brand) => brand.frName).join("/");
    return `${activity.activityType[0].frName} ${joinedBrands}`;
  } else {
    return "No brand or activity type selected";
  }
};

enum WeekDay {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export const getDayOfWeekName = (day: number): string | null => {
  switch (day) {
      case WeekDay.Sunday:
          return 'sunday';
      case WeekDay.Monday:
          return 'monday';
      case WeekDay.Tuesday:
          return 'tuesday';
      case WeekDay.Wednesday:
          return 'wednesday';
      case WeekDay.Thursday:
          return 'thursday';
      case WeekDay.Friday:
          return 'friday';
      case WeekDay.Saturday:
          return 'saturday';
      default:
          return null; // Invalid day
  }
}

interface DayObject {
  label: string;
  selected: boolean;
  value: number;
}

export const updateWeeklyFromArray = (daysArray: DayObject[]): Weekly => {
  let weekly: Weekly = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false
  };

  daysArray.forEach(day => {
      switch (day.value) {
          case 0:
            weekly.sunday = day.selected;
              break;
          case 1:
            weekly.monday = day.selected;
              break;
          case 2:
            weekly.tuesday = day.selected;
              break;
          case 3:
            weekly.wednesday = day.selected;
              break;
          case 4:
            weekly.thursday = day.selected;
              break;
          case 5:
            weekly.friday = day.selected;
              break;
          case 6:
            weekly.saturday = day.selected;
              break;
          default:
              break;
      }
  });

  return weekly;
}