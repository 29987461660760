import { Box, IconButton, Menu, Paper, Popper, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import React, { useState } from "react";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import Fade from "@mui/material/Fade";
import { useTranslation } from "react-i18next";

const PopoverContentWrapper = styled("div")(({ theme }) => ({
  padding: "0px 10px 0px 0px",
  "& p:last-of-type": {
    margin: "0px",
    padding: "0px",
  },
  "& p:first-of-type": {
    marginBottom: "16px",
    padding: "0px",
  },
  "& p:only-child": {
    marginBottom: "0px",
    padding: "0px",
  },
  "& *": {
    fontSize: "16px",
  },
  color: theme.palette.primary.dark,
}));

const PopoverContentTitle = styled("h3")(({ theme }) => ({
  fontSize: "16px",
  color: theme.palette.primary.dark,
  marginBottom: "8px",
  textTransform: "uppercase",
  marginTop: "0px",
}));

type InstructionsWrapperProps = {
  isModal?: boolean;
};

const InstructionsWrapper = styled("div")<InstructionsWrapperProps>(({ theme, isModal }) => ({
  ...(isModal
    ? {
        display: "inlineBlock",
        position: "absolute",
        top: "7px",
        right: "50px",
      }
    : {
        position: "relative",
        display: "inline",
      }),
}));

type InstructionsIconButtonProps = {
  variant?: string;
};

const InstructionsIconButton = styled(IconButton)<InstructionsIconButtonProps>(({ theme, variant }) => ({
  zIndex: 10,
  ...(variant === "secondary"
    ? {
        backgroundColor: theme.palette.primary.light,
        padding: "2px",
      }
    : {
        backgroundColor: "transparent",
      }),
  "& .MuiSvgIcon-root": {
    stroke: theme.palette.primary.light,
    strokeWidth: "0.5px",
    fontSize: variant === "secondary" ? "2.2rem" : "1.6rem",
  },
  "&.opened": {
    background: "transparent",
  },
}));

type Props = {
  children: JSX.Element;
  title?: string;
  isModal?: boolean;
  popoverPosition?: string;
  isPage?: boolean;
  width?: string;
  variant?: string;
};

export default function InstructionsPopover({ children, title, isModal, popoverPosition, isPage, width, variant }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { t } = useTranslation();

  const handleClick = (event: any) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const modifiers = [
    {
      name: "flip",
      enabled: false,
    },
    {
      name: "preventOverflow",
      enabled: false,
    },
  ];

  return (
    <InstructionsWrapper isModal={isModal}>
      <InstructionsIconButton className={anchorEl ? "opened" : "closed"} variant={variant} disableFocusRipple={variant === "secondary" ? false : true} disableRipple={variant === "secondary" ? false : true} aria-describedby={id} aria-label={t("instructions.activity_page_instructions_label")} onClick={(e) => handleClick(e)}>
        <HelpOutlineOutlinedIcon
          sx={{
            color: (theme) => "primary.dark",
          }}
        />
      </InstructionsIconButton>
      <Popper id={id} disablePortal={true} open={open} anchorEl={anchorEl} placement={popoverPosition === "right" ? "bottom-start" : "bottom-end"} modifiers={modifiers} sx={{ zIndex: 9 }}>
        <ClickAwayListener onClickAway={handleClose}>
          <Box>
            <Paper
              sx={{
                marginTop: variant === "secondary" ? "-45px" : "-40px",
                marginRight: isModal ? "0px" : "40px",
                marginLeft: variant === "secondary" ? "-10px" : "0px",
                padding: popoverPosition === "right" ? "20px 20px 20px 20px" : "20px 45px 20px 20px",
                paddingTop: variant === "secondary" ? "30px" : "20px",
                color: "#001965",
                width: width ? width : "auto",
                minWidth: 300,
                maxWidth: 450,
                textAlign: "left",
                zIndex: 9,
              }}
            >
              <PopoverContentWrapper>
                {title && <PopoverContentTitle>{title}</PopoverContentTitle>}
                {children}
              </PopoverContentWrapper>
            </Paper>
          </Box>
        </ClickAwayListener>
      </Popper>
    </InstructionsWrapper>
  );
}
