import { axios } from "./axios";
import useSWR from "swr";
import { Attendee, EventEvaluation, Event } from "../typings/interfaces";

export const ENDPOINT = "/reporting/event";

const initReportingEvent = {
  event: {} as Event,
  attendees: [] as Attendee[],
  evaluations: [] as EventEvaluation[],
};

export async function getEventReport(eventId: string | undefined) {
  const { data } = await axios.get(`${ENDPOINT}/${eventId}`);
  if (data) {
    return {
      message: data.message,
      event: data.event as Event,
      attendees: data.attendees as Attendee[],
      evaluations: data.event.evaluations as EventEvaluation[],
    };
  }
  return {
    message: "No event found",
    event: data.event as Event,
    attendees: data.attendees as Attendee[],
    evaluations: data.event.evaluations as EventEvaluation[],
  };
}

export const useEventReport = (eventId: string) => {
  const {
    isLoading: isEventReportLoading,
    error: eventReportError,
    data: eventReport,
    mutate,
  } = useSWR(`${ENDPOINT}/${eventId}`, () => getEventReport(eventId), {
    onSuccess: (data) => data,
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });

  return {
    isEventReportLoading,
    eventReportError,
    eventReport: eventReport ? eventReport : initReportingEvent,
  };
};
