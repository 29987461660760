import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import { Fab } from "@mui/material";

const FormButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  padding: "10px",
  boxShadow: "unset",
  borderRadius: "4px",
  width: "100%",
  textTransform: "uppercase",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: "unset",
    color: "#fff",
  },
}));

const BackButton = styled(Fab)(({ theme }) => ({
  width: "40px",
  height: "40px",
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.dark,
  position: "absolute",
  top: 0,
  left: "-60px",
  boxShadow: "none",
}));

const ReportingBackButton = styled(Fab)(({ theme }) => ({
  width: "40px",
  height: "40px",
  marginLeft: "0px",
  marginRight: "10px",
  marginTop: "0px",
  marginBottom: "10px",
  boxShadow: "none",
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.dark,
  [theme.breakpoints.up('lg')]: {
    margin: "0px 0px 20px 0px"
  }
}));


export {
  FormButton,
  BackButton,
  ReportingBackButton
}