import { useState, useContext } from "react";
import Layout from "../components/Layout";
import ActivityForm from "../components/activityViews/form/ActivityForm";
import DraggableDialog from "../components/styled/DraggableDialog";
import { Activity } from "../typings/interfaces";
import AppContext from "../context/Auth";
import { addMinutes } from "date-fns";
import ActivitiesContainer from "../components/activityViews/ActivityContainer";
import Loading from "../components/Loading";
import { useRegions } from "../api/regions";
import { useBrands } from "../api/brands";
import { useActivityTypes } from "../api/activityTypes";

const ActivitiesPage = (): JSX.Element => {
  const { loading, userLoading } = useContext(AppContext);
  const { isRegionsLoading } = useRegions();
  const { isBrandsLoading } = useBrands();
  const { isActivityTypesLoading } = useActivityTypes();
  const [createDuplicateModalOpen, setCreateDuplicateModalOpen] = useState(false); //open state for create event modal
  const [createModalOpen, setCreateModalOpen] = useState(false); //open state for create event modal
  const [createModalDate, setCreateModalDate] = useState(addMinutes(new Date(), 30)); //sets the date of event based on the calendar date that was clicked
  const [editModalOpen, setEditModalOpen] = useState(false); //open state for the Edit Modal
  const [editActivity, setEditActivity] = useState({} as Activity); //the activity object that is currently being edited
  const [duplicateActivity, setDuplicateActivity] = useState({} as Activity); //the activity object that is currently being edited

  const closeCreateModal = (e?: any) => {
    if(e){
      e.preventDefault();
    }
    setCreateModalOpen(false);
  };

  const toggleCreateModal = (date?: Date) => {
    if(date) {
      setCreateModalDate(date);
    }
    setCreateModalOpen(!createModalOpen);
  };

  const closeCreateDuplicateModal = (e?: any) => {
    if(e){
      e.preventDefault();
    }
    setCreateDuplicateModalOpen(false);
  };

  const toggleCreateDuplicateModal = (activity: Activity) => {
    setDuplicateActivity({ ...activity });
    setCreateDuplicateModalOpen(!createModalOpen);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  const toggleEditModal = (activity: Activity) => {
    setEditActivity({ ...activity });
    setEditModalOpen(!editModalOpen);
  };

  if (loading || userLoading || isRegionsLoading || isBrandsLoading || isActivityTypesLoading) {
    return (
      <Layout style={{ maxWidth: "100%" }} fullWidth={true}>
        <Loading />
      </Layout>
    );
  }

  return (
    <Layout style={{ maxWidth: "100%" }} fullWidth={true}>
      <ActivitiesContainer toggleCreateModal={toggleCreateModal} toggleEditModal={toggleEditModal} toggleCreateDuplicateModal={toggleCreateDuplicateModal} />

      <DraggableDialog open={createDuplicateModalOpen} closeModal={closeCreateDuplicateModal}>
        <ActivityForm duplicate={true} activity={duplicateActivity} date={createModalDate} closeModal={closeCreateDuplicateModal}  />
      </DraggableDialog>

      <DraggableDialog open={createModalOpen} closeModal={closeCreateModal}>
        <ActivityForm date={createModalDate} closeModal={closeCreateModal} />
      </DraggableDialog>

      <DraggableDialog open={editModalOpen} closeModal={closeEditModal}>
        <ActivityForm edit={true} activity={editActivity} closeModal={closeEditModal} />
      </DraggableDialog>
    </Layout>
  );
};

export default ActivitiesPage;
