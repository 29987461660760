import React, { useState, useContext } from "react";
import { styled, Box, Tabs, Tab, Typography } from "@mui/material";
import ReportingContext from "../../context/ReportingContext";
import { useTranslation } from "react-i18next";
import i18n from "i18next"

const ReportTabs = styled(Tabs)(({ theme }) => ({

  "& .MuiTabs-flexContainer": {
    justifyContent: "space-evenly",
    flexWrap: "wrap",
    alignContent: "center",
    alignItems: "center",
  }

}));

const ReportTab = styled(Tab)(({ theme }) => ({
  fontSize: i18n.language === "en" ? "19px" : "18px;",
  width: "20%",

  "&.Mui-selected": {
    fontWeight: "bold",
  }

}));


const ReportingTabs = () => {
  const { t } = useTranslation();
  const { updateSection, section} = useContext(ReportingContext);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    updateSection(newValue);
  };

  const tabs = [
    {
      label: t("reporting.speakers"),
      value: "speakers",
    },
    {
      label: t("reporting.moderators"),
      value: "moderators"
    },
    {
      label: t("reporting.events"),
      value: "events"
    },
    {
      label: t("reporting.learning_objectives"),
      value: "learning-objectives"
    },
    {
      label: t("reporting.comments"),
      value: "comments"
    },
  ];

  return (
    <div>
      <Box sx={{ margin: "0px 5px", }}>
        <ReportTabs value={section} onChange={handleChange} aria-label="Reporting Tabs">
          {tabs.map((tab, index) => (
            <ReportTab disableFocusRipple={true} disableRipple={true} key={tab.label+index} value={tab.value} label={tab.label} id={`reporting-tab-${index}`} aria-controls={`reporting-tabpanel-${index}`} />
          ))}
        </ReportTabs>
      </Box>
    </div>
  );
}
export default ReportingTabs;