import { CheckinEvent, TherapeuticArea } from "../../typings/interfaces";
import { format } from "date-fns";
import { enUS, fr } from 'date-fns/esm/locale'
import React, { useCallback } from "react";
import { CapitalizeFirstChar } from "../../utils/capitalize";
import { useTranslation } from "react-i18next";
import { parseEventStartTime } from "../../utils/parseDate";

type Props = {
  hasSpeakers: boolean;
};

const getTherapeuticArea = (therapeuticAreaObject: TherapeuticArea, language: string) => {
  if (therapeuticAreaObject) {
    return language === "en" ? therapeuticAreaObject.enName : therapeuticAreaObject.frName;
  } else {
    return "";
  }
};

export const useEventNameCheckin = ({ hasSpeakers = false }: Partial<Props> = {}) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const formatEventNameCheckin = useCallback(
    (event: CheckinEvent, supportCustomName?: boolean) => {
      let speakerNames = "";
      const eventDate = CapitalizeFirstChar(format(parseEventStartTime(event.start_time), i18n.language === "en" ? "MMMM d, yyyy" : "MMMM d, yyyy", i18n.language === "en" ? {locale: enUS} : {locale: fr}));
      var therapeuticAreaName = getTherapeuticArea(event.therapeuticAreaObject, currentLanguage);
      if (supportCustomName && event.title.length > 0) {
        therapeuticAreaName = event.title;
      }

      if (event.speakers && event.speakers.length > 0) {
        const numberOfSpeakers = event.speakers.length;
        event.speakers.forEach((speakerInfo, index) => {
          if (index === 0) {
            speakerNames = `${speakerInfo.name}`;
          }
        });
      }

      let withText = currentLanguage === "en" ? "w/" : "avec";

      const formattedName = CapitalizeFirstChar(`${therapeuticAreaName} ${withText} ${speakerNames} - ${eventDate}`);

      return formattedName;
    },
    [currentLanguage]
  );

  return [formatEventNameCheckin] as const;
};
export default useEventNameCheckin;
