import { axios } from "./axios";
import useSWR from "swr";
import { Speaker } from "../typings/interfaces";
import { sortDataAlpha } from "../utils/sort";

export const ENDPOINT = "/speakers";

// export the fetch call to be used with SWR preload once the user has authed
export async function getSpeakers() {
  const { data } = await axios.get(ENDPOINT);
  const sortedSpeakers = sortDataAlpha(data, "displayName")
  return sortedSpeakers;
}

export const useSpeakers = () => {
  async function createSpeaker(displayName: string, email: string, isModerator: boolean) {
    const { data } = await axios.post(ENDPOINT, {
      displayName,
      email,
      isModerator,
    });
    mutateSpeakers();

    return data.data as Speaker;
  }

  const {
    isLoading: isSpeakersLoading,
    error: speakersError,
    data: speakers,
    mutate: mutateSpeakers,
  } = useSWR(ENDPOINT, getSpeakers, {
    onSuccess: (data) => data,
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });

  const data = speakers as Speaker[];

  return {
    isSpeakersLoading,
    speakersError,
    speakers: data,
    createSpeaker,
  };
};
