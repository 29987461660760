import React, { useState, useEffect, useContext } from "react";
import { Box, styled, Autocomplete, TextField, Popper, AutocompleteChangeReason, AutocompleteChangeDetails, Button, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Speaker, Moderator, Event, Region, Users, TherapeuticArea, LearningObjective, LanguageOption } from "../../typings/interfaces";
import ReportingContext from "../../context/ReportingContext";
import { useTranslation } from "react-i18next";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange, RangeKeyDict } from "react-date-range";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import InputAdornment from '@mui/material/InputAdornment';
import Popover from "@mui/material/Popover";
import { format } from "date-fns";
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import useEventName from "../../hooks/useEventName";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { frCA } from "date-fns/locale";
import { sortDataAlpha } from "../../utils/sort";

const defaultRange = {
  startDate: new Date(),
  endDate: new Date(),
  key: "selection",
};


const FilterGrid = styled(Grid)(({ theme }) => ({
  width: "auto",


  "&.MuiGrid-item": {
    paddingRight: "15px",
    marginTop: "7px",
    marginBottom: "7px",
    flex: "inherit",
    width: "100%",
    alignItems: "center",
    [theme.breakpoints.up('lg')]: {
      flex: "1"
    },
  },

  "& .MuiAutocomplete-root": {
    backgroundColor: "#f0f7fc",
    width: "100%",
    border: "none",
    margin: "0px",

    "&.learningObjectivesDropdown": {
      width: "100%",
    },
    "&.moderatorNameDropdown": {
      width: "100%",
    },
    "&.eventNameDropdown": {
      width: "100%",
    },
    "&.regionDropdown": {
      width: "100%",
    },
    "&.therapeuticAreaDropdown": {
      width: "100%",
    },
    "&.languageDropdown": {
      width: "100%",
    },
    "&.eventTypeDropdown": {
      width: "100%",
    },
    "&.repDropdown": {
      width: "100%",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },

    "& .MuiFormLabel-root": {
      color: "#9eaac8",
      textTransform: "uppercase",
      fontWeight: 400,
      fontSize: "0.9rem",
      lineHeight: "27px",
    },
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        fontSize: "13px!important"
      },
    },

    "& .MuiButtonBase-root": {
      color: theme.palette.primary.dark,
    },
  },
}));

const FilterInputField = styled(TextField)(({ theme }) => ({
"& .MuiInputBase-root":{
  paddingRight: "0px",
  backgroundColor: "#f0f7fc",

  "&:hover":{
    // backgroundColor: "black",
    "& .MuiInputAdornment-root": {
      visibility: "visible",
    },
  },

  "& .MuiInputAdornment-root": {
    visibility: "hidden",
    "& .MuiButtonBase-root": {
      padding: "4px",
    },
  },

  "& .MuiInputBase-input": {
    color: "#9eaac8",
    textTransform: "uppercase",
    fontWeight: 400,
    fontSize: "13px",
    backgroundColor: "#f0f7fc",
    border: "none",

    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "#9eaac8",
      opacity: 1,
    },
  },
},
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
}));

const CustomPopper = styled(Popper)({
  width: "300px !important",
});
const FullPopper = styled(Popper)({
  width: "fit-content !important"
})

const FilterGridWrapper = styled(Grid)({
  width: "100%",
  backgroundColor: "#ffffff",
  borderRadius: "5px",
  alignItems: "center"
});

const FilterGridOutsideWrapper = styled(Grid)({
  width: "100%",
  padding: "15px",
  backgroundColor: "#ffffff",
  border: "1px solid #D5D5D5",
  borderRadius: "5px",
});

const availableFilters = [
  {
    section: "Speakers",
    value: "speakers",
    filters: ["speakers", "region", "therapeuticArea", "eventType", "language", "rep", "dateRange"],
  },
  {
    section: "Moderators",
    value: "moderators",
    filters: ["moderators", "region", "therapeuticArea", "eventType", "language", "rep", "dateRange"],
  },
  {
    section: "Events",
    value: "events",
    filters: ["eventName", "region", "therapeuticArea", "eventType", "language", "rep", "dateRange"],
  },
  {
    section: "Learning Objectives",
    value: "learning-objectives",
    filters: ["learningObjectives", "region", "therapeuticArea", "eventType", "language", "dateRange"],
  },
  {
    section: "Comments",
    value: "comments",
    filters: ["eventName", "region", "therapeuticArea", "language", "dateRange"],
  },
];

//if the section

type Props = {};
//reporting toolbar module component
const ReportingFilters = (props: Props) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const {
    section,
    speakers,
    moderators,
    events,
    learningObjectives,
    regions,
    therapeuticAreas,
    languages,
    reps,
    selectedEventId,
    selectedSpeakerId,
    selectedModeratorId,
    selectedLearningObjective,
    dateRange,
    updateSelectedSpeakerId,
    updateSelectedModeratorId,
    updateSelectedLearningObjective,
    updateSelectedRegion,
    updateSelectedTherapeuticArea,
    updateSelectedEventType,
    updateSelectedLanguage,
    updateSelectedRep,
    updateSelectedEventId,
    updateDateRange,
  } = useContext(ReportingContext);

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [showFilters, setShowFilters] = useState(false);
  const [formatEventName] = useEventName();
  

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [filters, setFilters] = useState<string[]>([]);

  const onChangeSpeaker = (event: React.SyntheticEvent<Element, globalThis.Event>, value: Speaker | null, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<Speaker> | undefined) => {
    if (value) {
      updateSelectedSpeakerId(value.speakerId);
    } else {
      updateSelectedSpeakerId("");
    }
  };

  const onChangeModerator = (event: React.SyntheticEvent<Element, globalThis.Event>, value: Moderator | null, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<Moderator> | undefined) => {
    if (value) {
      updateSelectedModeratorId(value.speakerId);
    } else {
      updateSelectedModeratorId("");
    }
  };

  const onChangeEvent = (event: React.SyntheticEvent<Element, globalThis.Event>, value: Event | null, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<Event> | undefined) => {
    if (value) {
      updateSelectedEventId(value.id);
    } else {
      updateSelectedEventId("");
    }
  };

  const onChangeLearningObjective = (event: React.SyntheticEvent<Element, globalThis.Event>, value: LearningObjective | null, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<LearningObjective> | undefined) => {
    if (value) {
      updateSelectedLearningObjective(value._id);
    } else {
      updateSelectedLearningObjective("");
    }
  };

  const onChangeRegion = (event: React.SyntheticEvent<Element, globalThis.Event>, value: Region | null, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<Region> | undefined) => {
    if (value) {
      updateSelectedRegion(value._id);
    } else {
      updateSelectedRegion("");
    }
  };

  const onChangeTherapeuticArea = (event: React.SyntheticEvent<Element, globalThis.Event>, value: TherapeuticArea | null, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<TherapeuticArea> | undefined) => {
    if (value) {
      updateSelectedTherapeuticArea(value.value);
    } else {
      updateSelectedTherapeuticArea("");
    }
  };

  const onChangeEventType = (event: React.SyntheticEvent<Element, globalThis.Event>, value: {name: string, value: string} | null, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<string> | undefined) => {
    if (value) {
      updateSelectedEventType(value.value);
    } else {
      updateSelectedEventType("");
    }
  };

  const onChangeLanguage = (event: React.SyntheticEvent<Element, globalThis.Event>, value: LanguageOption | null, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<LanguageOption> | undefined) => {
    //updateSelectedLanguage()
    if (value) {
      updateSelectedLanguage(value.name);
    } else {
      updateSelectedLanguage("");
    }
  };

  const onChangeRep = (event: React.SyntheticEvent<Element, globalThis.Event>, value: Users | null, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<Users> | undefined) => {
    if (value) {
      updateSelectedRep(value.id);
    } else {
      updateSelectedRep("");
    }
  };

  const onDateFilterChange = (ranges: RangeKeyDict) => {
    const { selection } = ranges;
    if (selection) {
      updateDateRange(ranges);
    }
  };

  const clearDateRange = () => {
    updateDateRange({});
  }

  const eventTypes = [{name: t("hybrid"), value: "Hybrid"}, {name: t("live"), value: "Live"}, {name: t("virtual"), value: "Virtual"}];


  const getAvailableFilters = (section: string) => {
    availableFilters.forEach((object) => {
      if (section === object.value) {
        setFilters(object.filters);
      }
    });
  };

  const getTherapeuticAreaOrLearningObjectiveName = (option: TherapeuticArea | LearningObjective) => {
    const removed = language === "en" ? " [REMOVED]" : " [SUPPRIMÉ]";

    const removedText = option.showInEval ? "" : removed;

    if (language === "fr") {
      return option.frName + removedText;
    }

    return option.enName + removedText;
  }


  // if the section is not the Events or Comments section - ensure and Event Id is not set as we dont filter by events on the other sections
  useEffect(() => {
    getAvailableFilters(section);
    const resetEventIdForSections = ["speakers", "moderators", "learning-objectives"];
    if (resetEventIdForSections.includes(section)) {
      updateSelectedEventId(""); //ensure event ID is unset if not on comments or events section
    }
  }, [section]);

  const DateFilterEndorment = () => {
    if(dateRange.hasOwnProperty("startDate") && dateRange.hasOwnProperty("endDate")){
      return (
        <InputAdornment position="start" onClick={(e) => e.stopPropagation()}>
        <IconButton aria-label="Clear" onClick={clearDateRange}>
          <ClearIcon sx={{color: "primary.dark", fontSize:"1.25rem"}}/>
        </IconButton>
      </InputAdornment>
      )
    } else {
    return  <ArrowDropDownIcon sx={{padding:"0px 10px"}}/>
    }
  }

  return (
    <FilterGridOutsideWrapper>
      <Box>
        <Button variant="text" startIcon={<FilterAltIcon />} onClick={() => setShowFilters(!showFilters)} sx={{
          display: {xs:  'flex', lg: 'none'}
        }}>
         { !showFilters ? t("show_filters") : t("hide_filters") }
        </Button>
      </Box>
      <FilterGridWrapper container sx={{
         display: {xs:  !showFilters ? 'none' : 'flex', lg: 'flex'},
         marginTop: {xs: '10px', lg: '0px'}
      }}>
      <FilterGrid item xs={6} lg="auto">
        {filters.includes("speakers") && <Autocomplete value={selectedSpeakerId ? speakers.find((speaker) => speaker.speakerId === selectedSpeakerId) : null} PopperComponent={CustomPopper} slotProps={{ popper: { placement: "bottom-start" } }} size="small" disablePortal id="speakers" options={speakers} getOptionLabel={(option) => option.displayName} renderInput={(params) => <FilterInputField placeholder={t("reporting.speaker_name")} variant="outlined" {...params} />} onChange={(e, value, reason, details) => onChangeSpeaker(e, value, reason, details)} />}
        {filters.includes("moderators") && (
          <Autocomplete
            value={selectedModeratorId ? moderators.find((moderator) => moderator.speakerId === selectedModeratorId) : null}
            className="moderatorNameDropdown"
            PopperComponent={CustomPopper}
            slotProps={{ popper: { placement: "bottom-start" } }}
            size="small"
            disablePortal
            id="moderators"
            options={moderators}
            getOptionLabel={(option) => option.displayName}
            renderInput={(params) => <FilterInputField variant="outlined" {...params} placeholder={t("reporting.moderator_name")} />}
            onChange={(e, value, reason, details) => onChangeModerator(e, value, reason, details)}
          />
        )}
        {filters.includes("eventName") && (
          <Autocomplete value={selectedEventId ? events.find((event) => event.id === selectedEventId) : null} className="eventNameDropdown" PopperComponent={CustomPopper} slotProps={{ popper: { placement: "bottom-start" } }} size="small" disablePortal id="eventName" options={[...events].reverse()} getOptionLabel={(option) => formatEventName(option)} renderInput={(params) => <FilterInputField variant="outlined" {...params} placeholder={t("reporting.event_name")} />} onChange={(e, value, reason, details) => onChangeEvent(e, value, reason, details)} />
        )}
        {filters.includes("learningObjectives") && (
          <Autocomplete
            value={selectedLearningObjective ? learningObjectives.find((learningObjective) => learningObjective._id === selectedLearningObjective) : null}
            className="learningObjectivesDropdown"
            PopperComponent={FullPopper}
            slotProps={{ popper: { placement: "bottom-start" } }}
            size="small"
            disablePortal
            id="learningObjectives"
            options={language === "en" ? sortDataAlpha(learningObjectives, "enName") : sortDataAlpha(learningObjectives, "frName")}
            getOptionLabel={(option) => getTherapeuticAreaOrLearningObjectiveName(option)}
            renderInput={(params) => <FilterInputField variant="outlined" {...params} placeholder={t("reporting.learning_objective_name")} />}
            onChange={(e, value, reason, details) => onChangeLearningObjective(e, value, reason, details)}
          />
        )}
      </FilterGrid>

      {filters.includes("region") && (
        <FilterGrid item xs={6} lg="auto">
          <Autocomplete className="regionDropdown" key={section + "_region"} PopperComponent={CustomPopper} slotProps={{ popper: { placement: "bottom-start" } }} size="small" disablePortal id="region" options={regions} renderInput={(params) => <FilterInputField {...params} placeholder={t("create_event_form_region_label")} />} getOptionLabel={(option) => (option && language === "en" ? option.enName : option.frName)} onChange={(e, value, reason, details) => onChangeRegion(e, value, reason, details)} />
        </FilterGrid>
      )}
      {therapeuticAreas && filters.includes("therapeuticArea") && (
        <FilterGrid item xs={6} lg="auto">
          <Autocomplete className="therapeuticAreaDropdown" key={section + "_theraputicArea"} PopperComponent={CustomPopper} slotProps={{ popper: { placement: "bottom-start" } }} size="small" disablePortal id="therapeuticArea" options={language === "en" ? sortDataAlpha(therapeuticAreas, "enName") : sortDataAlpha(therapeuticAreas, "frName")} renderInput={(params) => <FilterInputField {...params} placeholder={t("list_view.therapeutic_area")} />} getOptionLabel={(option) => getTherapeuticAreaOrLearningObjectiveName(option)} onChange={(e, value, reason, details) => onChangeTherapeuticArea(e, value, reason, details)} />
        </FilterGrid>
      )}

      {filters.includes("eventType") && (
        <FilterGrid item xs={6} lg="auto">
          <Autocomplete className="eventTypeDropdown" key={section + "_eventType"} PopperComponent={CustomPopper} slotProps={{ popper: { placement: "bottom-start" } }} size="small" disablePortal id="eventType" options={sortDataAlpha(eventTypes, "name")} renderInput={(params) => <FilterInputField {...params} placeholder={t("list_view.event_type")} />} getOptionLabel={(option) => option && option.name} onChange={(e, value, reason, details) => onChangeEventType(e, value, reason, details)} />
        </FilterGrid>
      )}

      {filters.includes("language") && (
        <FilterGrid item xs={6} lg="auto">
          <Autocomplete className="languageDropdown" key={section + "_language"} PopperComponent={CustomPopper} slotProps={{ popper: { placement: "bottom-start" } }} size="small" disablePortal id="language" options={languages} getOptionLabel={(option) => language === "en" ? option.name : option.frName} renderInput={(params) => <FilterInputField {...params} placeholder={t("create_event_form_language_label")} />} onChange={(e, value, reason, details) => onChangeLanguage(e, value, reason, details)} />
        </FilterGrid>
      )}
      {filters.includes("rep") && (
        <FilterGrid item xs={6} lg="auto">
          <Autocomplete className="repDropdown" key={section + "_rep"} PopperComponent={CustomPopper} slotProps={{ popper: { placement: "bottom-start" } }} size="small" disablePortal id="rep" options={reps && reps.length > 0 ? sortDataAlpha(reps, "email") : []} renderInput={(params) => <FilterInputField {...params} placeholder={t("create_user_form_role_rep")} />} getOptionLabel={(option) => option && option.email} onChange={(e, value, reason, details) => onChangeRep(e, value, reason, details)} />
        </FilterGrid>
      )}
      {filters.includes("dateRange") && (
        <div style={{ display: "flex", justifyContent: "flex-end", flex: 1 }}>
          <FilterGrid item sx={{ width: "100%", maxWidth: "250px" }} xs={12} lg="auto">
            <FormControl sx={{ width: "100%", maxWidth: "100%" }}>
              <FilterInputField
                autoComplete="off"
                value={ dateRange.hasOwnProperty("startDate") && dateRange.hasOwnProperty("endDate") ? `${format(dateRange.startDate, "MMM dd, y")} - ${format(dateRange.endDate, "MMM dd, y")}`: ""}
                size="small" id="component-outlined-date-range"
                placeholder={t("reporting.date_range")}
                onClick={(event) => handleClick(event)}
                InputProps={{
                  endAdornment:
                    DateFilterEndorment()
                }}
              />

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <DateRange ranges={dateRange.hasOwnProperty("startDate") ? [{...dateRange}] : [{...defaultRange}]} onChange={(e) => onDateFilterChange(e)} locale={language === "en" ? undefined : frCA} />
                <div style={{display:"flex", justifyContent: "flex-end"}}>
                  <Button variant="outlined" sx={{borderRadius: "0px"}} onClick={handleClose}>{t("filter_drawer.apply_button")}</Button>
                </div>
              </Popover>
            </FormControl>
          </FilterGrid>
        </div>
      )}
    </FilterGridWrapper>
    </FilterGridOutsideWrapper>
  );
};

export default ReportingFilters;
