import { axios } from "./axios";
import useSWR from "swr";
import { Evaluation } from "../typings/interfaces";

export const useEventCheckin = (eventId: string) => {
  const ENDPOINT = `/checkin/event/${eventId}`;

  async function create(eventId: string, attendeeId: string, relevant: number, credible: number, organized: number, timeAllotted: number, interaction: number, learningObjectives: any[], speakers: any[], moderators: any[], conceptLearnedComment: string, otherTopicsComment: string, additionalComment: string) {
    const { data } = await axios.post(`${ENDPOINT}/event/${eventId}`, {
      attendeeId,
      relevant,
      credible,
      organized,
      timeAllotted,
      interaction,
      learningObjectives,
      speakers,
      moderators,
      conceptLearnedComment,
      otherTopicsComment,
      additionalComment,
    });

    return data;
  }

  /**
   * Get an event by the eventId
   * @param id The ID of the event to look up
   * @returns The found event
   */
  async function getById(id: string) {
    const { data } = await axios.get(ENDPOINT);
    return data;
  }

  const {
    isLoading: isEvaluationsLoading,
    error: evaluationsError,
    data: data,
    mutate,
  } = useSWR(ENDPOINT, getById, {
    onSuccess: (data) => data,
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });

  return {
    isEvaluationsLoading,
    evaluationsError,
    event: data as Event,
    create,
    getById,
  };
};
