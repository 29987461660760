import { Outlet } from 'react-router-dom';
import { EventProvider } from '../EventContext';

const EventContextLayout = () => {
  return (
    <EventProvider>
      <Outlet />
    </EventProvider>
  );
};

export default EventContextLayout;