import { useState } from "react";
import Layout from "../components/Layout";
import * as yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const success_message = t("forgot_password.success_message");
  const error_message = t("attendee_invite_reg_fail");
  const language = i18next.language;

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(t("create_user_form_email_error_2"))
      .required(t("create_user_form_email_error")),
  });

  const [state, setState] = useState({
    error: "",
    success: "",
    loading: false,
  });

  const handleSubmit = async (values: {
    email: string;
  }) => {
    try {
      setState({ ...state, loading: true, error: "" });
      axios.post(
        `${process.env.REACT_APP_API_URL}/forgot-password`,
        {
          email: values.email,
          language: language,
        },
      )
      .then(function (response) {
        setState({
          ...state,
          loading: false,
          success: t("forgot_password.success_message"),
        });
      })
      .catch(function (error) {
        setState({ ...state, loading: false, error: error });
        console.log(error);
      });

      formik.resetForm();

    } catch (error: any) {
      if (error.response) {
        setState({
          ...state,
          error: error.response.data.message,
          loading: false,
        });
        return;
      }
      setState({ ...state, error: error_message, loading: false });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Layout style={{display:"flex"}} genericLogo={true}>
      <Container
      maxWidth={"md"}
      sx={{zIndex:"999", backgroundColor:"#ffffff", boxShadow: "0px 0px 3px 0px rgba(131,129,129,0.50), 0px 7px 10px 0px rgba(226,226,226,0.50)", mt: 3}}
      >
        <Typography align="center" variant="h3" sx={{ fontWeight:500, py:"30px", fontSize: "2rem"}}>
          {t("forgot_password.title")}
        </Typography>

        <Box my={2}>
          {state.error &&
            <Box my={2}>
              <Alert severity="error">{state.error}</Alert>
            </Box>
          }
          {!state.success && !state.error  ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="email"
                  name="email"
                  label={t("forgot_password.email_label")}
                  placeholder="email@example.com"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} alignItems="center" display="flex" flexDirection="column">
                <Button
                  disabled={state.loading}
                  onClick={() => {
                    formik.submitForm();
                  }}
                  variant="contained"
                  sx={{ mt: 2, width:"auto", p:"10px 20px"}}
                  startIcon={<SendIcon />}
                >
                  {t("forgot_password.submit_btn")}
                </Button>
                <Link sx={{my:2}} href="/">{t("signin_forgotpassword")}</Link>
              </Grid>
            </Grid>
          ) : (
            <div style={{textAlign:"center"}}>
              <Typography align="center" variant="body1" sx={{ my: 3, fontWeight:500, p:"0px 40px 30px 40px", fontSize:"1.2em"}}>
                {state.success && state.success} <br/><br/>
                <Link sx={{my:2}} href="/">{t("signin_forgotpassword")}</Link>
              </Typography>
            </div>
          )}
        </Box>
      </Container>
    </Layout>
  );
};

export default ForgotPassword;
