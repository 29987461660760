import { RadioGroup, Typography, FormControlLabel, Radio } from "@mui/material";
import { SentimentDissatisfied, SentimentNeutral, SentimentSatisfied, SentimentVeryDissatisfied, SentimentVerySatisfied } from "@mui/icons-material";

interface QuestionRadioGroupProps {
  question: string;
  current: number;
  field: string;
  updateField: (field: string, value: string | number) => void;
  learningObjective?: boolean;
  error: boolean | undefined;
  helperText: string | boolean | undefined;
}

const QuestionRadioGroup = ({ question, updateField, current, field, learningObjective, error, helperText }: QuestionRadioGroupProps) => {
  return (
    <>
      <Typography className={learningObjective ? "learning-objective-text" : "question-text"}>{question}</Typography>
      {error ? <Typography sx={{ color: "red" }}>{helperText}</Typography> : ""}
      <RadioGroup row name="relevant" sx={{ marginBottom: "40px", display: "flex", justifyContent: "space-between", maxWidth: "320px" }}>
        <FormControlLabel sx={{mx: 0}} value={1} control={<Radio value={1} checked={current === 1} onClick={(e) => updateField(field, 1)} name="radio-buttons" inputProps={{ "aria-label": "A" }} icon={<SentimentVeryDissatisfied />} />} label="1" labelPlacement="bottom" />
        <FormControlLabel sx={{mx: 0}} value={2} control={<Radio value={1} checked={current === 2} onClick={(e) => updateField(field, 2)} name="radio-buttons" inputProps={{ "aria-label": "A" }} icon={<SentimentDissatisfied />} />} label="2" labelPlacement="bottom" />
        <FormControlLabel sx={{mx: 0}} value={3} control={<Radio value={1} checked={current === 3} onClick={(e) => updateField(field, 3)} name="radio-buttons" inputProps={{ "aria-label": "A" }} icon={<SentimentNeutral />} />} label="3" labelPlacement="bottom" />
        <FormControlLabel sx={{mx: 0}} value={4} control={<Radio value={1} checked={current === 4} onClick={(e) => updateField(field, 4)} name="radio-buttons" inputProps={{ "aria-label": "A" }} icon={<SentimentSatisfied />} />} label="4" labelPlacement="bottom" />
        <FormControlLabel sx={{mx: 0}} value={5} control={<Radio value={1} checked={current === 5} onClick={(e) => updateField(field, 5)} name="radio-buttons" inputProps={{ "aria-label": "A" }} icon={<SentimentVerySatisfied />} />} label="5" labelPlacement="bottom" />
      </RadioGroup>
    </>
  );
};

export default QuestionRadioGroup;
