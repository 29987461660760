import styled from "@emotion/styled"; //emotion styled components
import { useEffect, useMemo } from "react";
import { IconButton, Tooltip } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import TodayIcon from "@mui/icons-material/Today";
import { ActivityType, Brand, Region, Users } from "../../typings/interfaces";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import ActivityContext from "../../context/ActivityContext";
import InstructionsPopover from "../styled/InstructionsPopover";
import MobileActivityFiltersDrawer from "./MobileActivityFiltersDrawer";
import { provinceList } from "../../utils/provinces"

const ToolBarWrapper = styled.div`
  color: #494949;
  display: flex;
  background-color: white;
  background: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(145, 145, 145, 0.5);
  align-items: center;
  padding: 3px 10px;
  z-index: 2;
  position: relative;
  min-height: 60px;
`;

type ToolBarColumnProps = {
  textAlign?: "center" | "left" | "right";
  flex?: boolean;
  justifyContent?: string;
  width?: string;
  minWidth?: string;
}
const ToolBarColumn = styled("div")<ToolBarColumnProps>(({ textAlign, flex, justifyContent, width, minWidth, theme }) => ({
  width: width ? width : "100%",
  minWidth: minWidth ? minWidth : "auto",
  textAlign: textAlign ? textAlign : "center",
  display: flex ? "flex" : "block",
  justifyContent: justifyContent ? justifyContent : "center",
  alignItems: "center"
}));

type Props = {
  calendarRef: any;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  title: string;
  brands?: Brand[];
  eventTypes?: ActivityType[];
  updateCurrentYear: (newYear: number) => void;
  currentYear: number;
};

const ActivityCalendarToolBar = ({ calendarRef, setTitle, title, updateCurrentYear, currentYear }: Props): JSX.Element => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { 
    brands, 
    activityTypes,
    segmentations
  } = useContext(ActivityContext);

  useEffect(() => {
    const getInitialDate = () => {
      setCalendarTitle();
    };

    getInitialDate();
  }, [calendarRef, currentLanguage]);



  //Sets the month title in the toolbar
  const setCalendarTitle = () => {
    if (calendarRef && calendarRef.current) {
      let calendarApi = calendarRef.current.getApi();
      const CalTitle = calendarApi.view.title.toUpperCase();
      setTitle(CalTitle);
    }
  };

  //Sets the current year in the activity context
  const setCurrentYear = () => {
    if (calendarRef && calendarRef.current) {
      let calendarApi = calendarRef.current.getApi();
      const calendarYear = Number(calendarApi.getDate().getFullYear());
      
      if(calendarYear !== currentYear){
        updateCurrentYear(calendarYear);
      }
    }
  }

  //Moves the calendar to the previous month and sets the month title in the toolbar
  const prevMonth = () => {
    if (calendarRef && calendarRef.current) {
      let calendarApi = calendarRef.current.getApi();
      calendarApi.prev();
      setCalendarTitle();
      setCurrentYear();
    }
  };

  //Moves the calendar to the next month and sets the month title in the toolbar
  const nextMonth = () => {
    if (calendarRef && calendarRef.current) {
      let calendarApi = calendarRef.current.getApi();
      calendarApi.next();
      setCalendarTitle();
      setCurrentYear();
    }
  };

  //Moves the calendar to the current date/month
  const goToToday = () => {
    if (calendarRef && calendarRef.current) {
      let calendarApi = calendarRef.current.getApi();
      calendarApi.today();
      setCalendarTitle();
      setCurrentYear();
    }
  };

  const toolBarDateSelector = useMemo( () => {
    return (
      <>
      <IconButton aria-label="Previous month" onClick={prevMonth}>
        <NavigateBeforeIcon />
      </IconButton>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Tooltip title="Today">
          <IconButton aria-label="Today" onClick={goToToday}>
            <TodayIcon />
          </IconButton>
        </Tooltip>
        <span style={{ fontWeight: "500" }}>{title}</span>
      </div>
      <IconButton aria-label="Next month" onClick={nextMonth}>
        <NavigateNextIcon />
      </IconButton>
      </>
    )}, [title] );

  return (
    <ToolBarWrapper>
      <ToolBarColumn textAlign="left" flex={true} justifyContent="flex-start">
        <MobileActivityFiltersDrawer brands={brands} provinces={provinceList} activityTypes={activityTypes} segmentations={segmentations} />
      </ToolBarColumn>

      <ToolBarColumn flex={true} width="30%" minWidth="300px">{toolBarDateSelector}</ToolBarColumn>


      <ToolBarColumn textAlign="right">
        <InstructionsPopover>
          <p>{t("instructions.activity_page_toolbar")}</p>
        </InstructionsPopover>
      </ToolBarColumn>
    </ToolBarWrapper>
  );
};

export default ActivityCalendarToolBar;
