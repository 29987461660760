import { MoreLinkArg, EventSegment, CalendarOptions } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
// import listPlugin from "@fullcalendar/list";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction"; // needed for dayClick
import { format, endOfDay, isToday, addMinutes } from "date-fns";

import frLocale from "@fullcalendar/core/locales/fr";

import emotionStyled from "@emotion/styled"; //emotion styled components

import { Event } from "../../typings/interfaces";
import { useContext, useEffect, useRef, useState } from "react";
import { Tooltip, IconButton, alpha } from "@mui/material";
import CalendarEventInfoCard from "../CalendarEventInfoCard";
import EventPageToolBar from "../EventPageToolBar";
import { EventApi } from "@fullcalendar/core";
import { useTranslation } from "react-i18next";
import DraggableDialog from "../styled/DraggableDialog";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useEventName from "../../hooks/useEventName";
import EventContext from "../../context/EventContext";
import React from "react";
import { useSpeakers } from "../../api/speakers";
import muiTheme from "../../muiTheme";

const initialEvent = {} as Event;

type EventRowProps = {
  ellipsis: boolean;
};

const EventRow = emotionStyled.div<EventRowProps>`
  cusor: pointer;
  width: 100%;
  display: block;
  white-space: ${(props) => (props.ellipsis ? "nowrap" : "wrap")};
  overflow: hidden;
  text-overflow: ${(props) => (props.ellipsis ? "ellipsis" : "unset")};

  & > i {
    font-weight: 500;
  }
`;

const EventRowList = emotionStyled.p`
  cursor: pointer;
  border-bottom: 1px solid #e1e1e1;
  padding: 14px 0px;
  margin: 0;
  display: flex; 
  align-items: center;

  &:last-of-type {
    border-bottom: 0px; 
  }

  & > i {
    font-weight: 500;
  }
`;

type Props = {
  view: string; //current view calendar/list
  updateView: (view: string) => void;
  toggleCreateModal: (date: Date) => void;
  toggleEditModal: (event: Event) => void;
  events: Event[];
};

//we provide events here to ensure we have them as the Event page handles waiting for the data to load
const CalendarView = ({ view, updateView, toggleCreateModal, toggleEditModal, events }: Props) => {
  const { t, i18n } = useTranslation();
  const { isSpeakersLoading } = useSpeakers();
  const currentLanguage = i18n.language;
  const [title, setTitle] = useState("");
  const [event, setEvent] = useState<Event>(initialEvent);
  const calendarRef = useRef<any>(null);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [moreLinkModal, setMoreLinkModal] = useState(false);
  const [moreEvents, setMoreEvents] = useState<EventSegment[]>([]);
  const [cameFromMoreList, setCameFromMoreList] = useState(false);
  const [formatEventName] = useEventName();
  const { reps, loading } = useContext(EventContext);

  useEffect(() => {
    const getInitialDate = () => {
      setCalendarTitle();
    };

    getInitialDate();
  }, [calendarRef, currentLanguage]);


  //Runs when an event is clicked on from the calendar
  const eventClickHandler = (id: string, view?: string) => {
    const findEvent = events.find((event) => id === event.id);

    if (findEvent) {
      setEvent(findEvent);
    }

    //if the user clicked on an event from the More Link - Event List Modal
    if (view === "moreList") {
      setCameFromMoreList(true);
    } else {
      setCameFromMoreList(false);
    }
    setInfoModalOpen(true);
    setMoreLinkModal(false);
  };

  //Formats events for full calendar
  const formatEventsForCalendar = (events: Event[]) => {
    const timeNow = new Date();
    timeNow.setHours(0, 0, 0, 0);
    const calendarEvents = events.map((event, index) => {
      const { id, title, start_time, end_time } = event;

      const date = new Date(start_time).getTime();
      const pastEvent = date < timeNow.getTime();

      return {
        id,
        title: formatEventName(event),
        start: start_time,
        end: end_time,
        // allDay: true,
        classNames: ["nnci_event", `${id}`],
        // customData: { data1: "data1", data2: "data2" },
        isHiddenDay: false,
        // backgroundColor:"red",
        color: pastEvent ? alpha(muiTheme.palette.primary.main, 0.4) : "var(--primary-blue)",
        // borderColor: "#000000",
        // textColor: "purple",
      };
    });

    return calendarEvents;
  };

  //Sets the month title in the toolbar
  const setCalendarTitle = () => {
    if (calendarRef && calendarRef.current) {
      let calendarApi = calendarRef.current.getApi();
      const title = calendarApi.view.title.toUpperCase();
      setTitle(title);
    }
  };

  //Moves the calendar to the previous month and sets the month title in the toolbar
  const prevMonth = () => {
    if (calendarRef && calendarRef.current) {
      let calendarApi = calendarRef.current.getApi();
      calendarApi.prev();
      setCalendarTitle();
    }
  };

  //Moves the calendar to the next month and sets the month title in the toolbar
  const nextMonth = () => {
    if (calendarRef && calendarRef.current) {
      let calendarApi = calendarRef.current.getApi();
      calendarApi.next();
      setCalendarTitle();
    }
  };

  //Moves the calendar to the current date/month
  const goToToday = () => {
    if (calendarRef && calendarRef.current) {
      let calendarApi = calendarRef.current.getApi();
      calendarApi.today();
      setCalendarTitle();
    }
  };

  //Runs when new events get pushed to the calendar
  const handleEvents = (events: EventApi[]) => {
    //console.log("new events loaded to calendar", events);
  };

  const dayClicked = (arg: DateClickArg) => {
    //limit previous days from being clicked
    if(endOfDay(arg.date) < new Date()) {
      return;
    }

    if(isToday(arg.date)) {
      let newDate = addMinutes(new Date(), 30);
      toggleCreateModal(newDate);
      return;
    }

    toggleCreateModal(arg.date);
  };

  const closeInfoModal = () => {
    setInfoModalOpen(false);
  };

  const showMoreLinkModal = (info: MoreLinkArg) => {
    setMoreLinkModal(true);
    setMoreEvents(info.allSegs);
  };

  const closeMoreLinkModal = () => {
    setMoreLinkModal(false);
  };


  //When the edit button is clicked from the EventInfoCard modal
  const switchToEdit = (event: Event) => {
    //close the info card
    closeInfoModal();

    //open the edit modal
    toggleEditModal(event);
  }

  // const handleWindowResize = (view: any): void => {
  //     const calendar = calendarRef.current.getApi();
  //     console.log("width", window.innerWidth)
  //     calendar.changeView(window.innerWidth < 765 ? 'listWeek' : 'dayGridMonth');
  // };


  const memoFullCalendar = React.useMemo( () => {
  return (
    <FullCalendar             
      dayMaxEvents={true} //true will add as many events into the day that can fit and then show a more link
      fixedWeekCount={false}
      showNonCurrentDates={true} //whether to show the past or future month dates
      locales={[frLocale]}
      firstDay={0}
      locale={currentLanguage}
      headerToolbar={false} //turns off the default calendar header
      // windowResize={handleWindowResize} // handles resizing the window to handle mobile
      ref={calendarRef}
      aspectRatio={2}
      contentHeight="90vh"
      eventDisplay="block"
      eventContent={renderEventContent}
      plugins={[dayGridPlugin, interactionPlugin]}
      initialView="dayGridMonth"
      events={formatEventsForCalendar(events)}
      dateClick={(arg: DateClickArg) => dayClicked(arg)}
      eventClick={(info) => eventClickHandler(info.event.id)}
      eventsSet={handleEvents}
      moreLinkClick={(info) => showMoreLinkModal(info)}
      dayHeaderFormat={{
        weekday: 'long'
      }}
      eventTimeFormat={{
        hour: 'numeric',
        minute: '2-digit',
      }} 
    />
  )}, [events, currentLanguage, isSpeakersLoading] );

  return (
    <div className="nnci_calendar" style={{scrollbarWidth: "none"}}>
      {events && (
        <>
          <EventPageToolBar view={view} updateView={updateView} prevMonth={prevMonth} nextMonth={nextMonth} goToToday={goToToday} title={title} reps={reps} />
          {memoFullCalendar}
        </>
      )}

      {/* Event info card modal */}
      <DraggableDialog size="600px" open={infoModalOpen} closeModal={closeInfoModal}>
        <>
          {cameFromMoreList && (
            <IconButton sx={{ padding: "0", margin: "0", marginLeft: "-8px", marginTop: "10px" }} onClick={() => ((setInfoModalOpen(false), setMoreLinkModal(true)))}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <CalendarEventInfoCard event={event} toggleEditModal={switchToEdit} closeModal={closeInfoModal} />
        </>
      </DraggableDialog>

      {/* More Events Modal - shows when clicking the more events link in the full calendar day */}
      <DraggableDialog open={moreLinkModal} closeModal={closeMoreLinkModal}>
        <>
          {moreEvents.map((calendarEvent) => {
            return (
              <EventRowList onClick={() => eventClickHandler(calendarEvent.event.id, "moreList")}>
                <AccessTimeIcon sx={{ paddingRight: "5px" }} /> <strong style={{ paddingRight: "8px" }}>{calendarEvent.event.start && format(calendarEvent.event.start, "h:mm a")}</strong>
                <span>{calendarEvent.event.title}</span>
              </EventRowList>
            );
          })}
        </>
      </DraggableDialog>
    </div>
  );

  function renderEventContent(eventInfo: { timeText: string; view: any; event: { title: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; extendedProps: any, start: any } }) {
    //console.log("eventInfo", eventInfo);

    // Get the event day of week to determine the position of the tooltip
    const dayOfweek = format(eventInfo.event.start, 'EEEE');

    const toolTipPosition = dayOfweek === "Saturday" ? "left-start" : "right-start"; //if saturday event then show tooltip on left

    const formatTime = (lang: string) => {
      let time = eventInfo.timeText;
      if(lang === "en") {
        return time;
      } else {
        return time.replace(":", " h ");
      }
    };

    const time = eventInfo.timeText && currentLanguage && formatTime(currentLanguage);

    const content = (ellipsis: boolean) => (
      <EventRow ellipsis={ellipsis}>
        <b style={{paddingRight:"5px"}}>{time}</b>
        <i>{eventInfo.event.title}</i>
      </EventRow>
    );

    return (
      <Tooltip
        key={title}
        sx={{ fontSize: "20px", color: "red" }}
        title={content(false)}
        enterDelay={1000}
        enterNextDelay={1000}
        placement={toolTipPosition}
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              fontSize: "14px",
            },
          },
        }}
      >
        {content(true)}
      </Tooltip>
    );
  }
};

export default CalendarView;
