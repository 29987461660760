import { Button, ButtonProps, Checkbox, Container, FormGroup, FormControlLabel, styled, TextField, Typography, Link, Box, FormControl, MenuItem } from "@mui/material";
import muiTheme from "../../muiTheme";
import { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import { useEvents } from "../../api/events";
import { updateAttendee } from "../../api/attendee";
import Cookies from "js-cookie";
import JobTitles from "../../utils/jobTitles";

const StyledTextField = styled(TextField)(({ theme }) => ({
  color: "muiTheme.palette.primary.main",
  width: "100%",
  marginBottom: "20px",
}));

const FormButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "#ffffff",
  padding: "8px",
  fontSize: "16px",
  boxShadow: "unset",
  borderRadius: "4px",
  marginBottom: "20px",
  textTransform: "uppercase",
  "&.Mui-disabled": {
    color: "#ffffff",
  },
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: "unset",
    color: "#fff",
  },
}));

interface CheckInFormProps {
  setCheckedIn: (state: boolean) => void;
  exit: () => void;
  eventId: string;
  attendeeId: string;
  setAttendeeId: (id: string) => void;
  setAttendeeName: (name: string) => void;
  completed: VoidFunction;
}

const CheckInForm = ({ setCheckedIn, exit, eventId, attendeeId, setAttendeeId, setAttendeeName, completed }: CheckInFormProps) => {
  const { t } = useTranslation();
  const [validEmail, setValidEmail] = useState(false);
  const { getAttendee, checkIn } = useEvents();
  const [successMessage, setSuccessMessage] = useState("evaluation.checkin_form.validation.success");
  const [isCompleted, setCompleted] = useState(false);

  const validationSchema = yup.object({
    email: yup.string().email(t("evaluation.checkin_form.validation.valid_email")).required(t("evaluation.checkin_form.email")),
    firstName: yup.string().required(t("evaluation.checkin_form.validation.first_name")),
    lastName: yup.string().required(t("evaluation.checkin_form.validation.last_name")),
    jobTitle: yup.string().required(t("evaluation.checkin_form.validation.job_title")),
    clinic: yup.string().required(t("evaluation.checkin_form.validation.clinic")),
    presentationFiles: yup.bool(),
    communications: yup.bool(),
  });

  const [state, setState] = useState({
    success: false,
    error: "",
  });

  const handleSubmit = async (values: { email: string; firstName: string; lastName: string; jobTitle: string; clinic: string; communications: boolean; presentationFiles: boolean }) => {
    try {
      if (attendeeId !== "") {
        // update the record
        const attendee = await updateAttendee(attendeeId, values.email, values.firstName, values.lastName, values.jobTitle, values.clinic, values.presentationFiles, values.communications);

        setSuccessMessage("You've updated your data.");
        setState({ ...state, success: true });
        setCheckedIn(true);
        setAttendeeName(attendee.data.firstName);
      } else {
        const attendee = await checkIn(eventId, values.email, values.lastName, values.firstName, values.jobTitle, values.clinic, values.presentationFiles, values.communications);

        setSuccessMessage("You've checked in.");
        setState({ ...state, success: true });
        setCheckedIn(true);
        setAttendeeId(attendee.data.attendeeId);
        setAttendeeName(attendee.data.firstName);
        localStorage.setItem("attendeeId", attendee.data.attendeeId);
        localStorage.setItem("attendeeName", attendee.data.firstName);
        localStorage.setItem("eventId", eventId);
        exit();
      }
    } catch (error: any) {
      if (error.response) {
        setState({ ...state, error: "Error" });
        return;
      }
      setState({ ...state, error: "error" });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      jobTitle: "",
      clinic: "",
      communications: false,
      presentationFiles: false,
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const validateEmail = (value: string) => {
    if (attendeeId === "") {
      formik.setFieldValue("email", value);
      if (value.toLowerCase().match(/^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/)) {
        setValidEmail(true);
        checkAttendee(value);
      } else {
        setValidEmail(false);
      }
    }
  };

  async function checkAttendee(email: string) {
    try {
      const attendee = await getAttendee(email, eventId);
      const data = attendee.data;
      formik.setFieldValue("firstName", data.firstName);
      formik.setFieldValue("lastName", data.lastName);
      formik.setFieldValue("jobTitle", data.jobTitle);
      formik.setFieldValue("clinic", data.clinic);
      formik.setFieldValue("presentationFiles", data.receiveFiles);
      formik.setFieldValue("communications", data.followUp);
      setAttendeeId(data.id);
      setAttendeeName(data.firstName);
      setState({ ...state, success: true });
      setCheckedIn(true);
      setCompleted(data.completedEvaluation);
      localStorage.setItem("attendeeId", data.id);
      localStorage.setItem("attendeeName", data.firstName);
      localStorage.setItem("eventId", eventId);
      if (data.completedEvaluation) {
        localStorage.setItem("completedEvaluation", "true");
        completed();
      } else {
        exit();
      }
    } catch (error) {}
  }

  function exitForm() {
    if (isCompleted) {
      completed();
    } else {
      exit();
    }
  }

  const handleCommunicationsChange = (e:React.ChangeEvent<any>) => {
    formik.setFieldValue("communications", e.target.checked);

    //if the communications box gets unchecked then uncheck the presenentation files box as they've opted out of receiving communications
    if(e.target.checked === false) {
      formik.setFieldValue("presentationFiles", e.target.checked);
    }
  }

  return (
    <Box style={{ display: "flex" }}>
      <Link onClick={exitForm} sx={{ cursor: "pointer" }} color="inherit">
        <CloseIcon sx={{ position: "absolute", right: "10px", top: "10px", height: "35px", width: "35px" }} />
      </Link>
      <Container maxWidth={"sm"} sx={{ alignContent: "center" }}>
        <div style={{ margin: "50px 10px 40px 10px", alignItems: "center", textAlign: "center" }}>
          <Typography sx={{ fontSize: "28px", fontWeight: "bold", color: muiTheme.palette.primary.dark }}>{t("evaluation.checkin_form.title")}</Typography>
          {state.success && (
            <>
              <CheckCircleOutlineIcon sx={{ color: "green", width: "60px", height: "60px", marginTop: "20px" }} />
              <Typography sx={{ fontSize: "18px" }}>{successMessage}</Typography>
            </>
          )}
        </div>
        <div style={{ alignItems: "center", textAlign: "center" }}>
          <StyledTextField disabled={attendeeId ? true : false} required focused label={t("evaluation.checkin_form.labels.email")} id="email" name="email" value={formik.values.email} error={formik.touched.email && Boolean(formik.errors.email)} helperText={formik.touched.email && formik.errors.email} onChange={(e) => validateEmail(e.target.value)} />
          <StyledTextField focused id="firstName" name="firstName" label={t("evaluation.checkin_form.labels.first_name")} value={formik.values.firstName} error={formik.touched.firstName && Boolean(formik.errors.firstName)} helperText={formik.touched.firstName && formik.errors.firstName} onChange={formik.handleChange} disabled={!validEmail} />
          <StyledTextField focused id="lastName" name="lastName" label={t("evaluation.checkin_form.labels.last_name")} value={formik.values.lastName} error={formik.touched.lastName && Boolean(formik.errors.lastName)} helperText={formik.touched.lastName && formik.errors.lastName} onChange={formik.handleChange} disabled={!validEmail} />
          <StyledTextField
            select focused
            id="jobTitle" name="jobTitle"
            label={t("evaluation.checkin_form.labels.job_title")}
            value={formik.values.jobTitle}
            error={formik.touched.jobTitle && Boolean(formik.errors.jobTitle)}
            helperText={formik.touched.jobTitle && formik.errors.jobTitle}
            onChange={formik.handleChange}
            disabled={!validEmail}
            sx={{
              textAlign: "left"
            }}
            SelectProps={{
              MenuProps: {
                sx: {
                  maxHeight: "200px"
                }  
              }
            }}>
            {JobTitles.Jobs.map(i => {
              return <MenuItem key={i.key} value={i.value}>{ t(i.value) }</MenuItem>
            })}
          </StyledTextField>
          <StyledTextField focused id="clinic" name="clinic" label={t("evaluation.checkin_form.labels.clinic")} value={formik.values.clinic} error={formik.touched.clinic && Boolean(formik.errors.clinic)} helperText={formik.touched.clinic && formik.errors.clinic} onChange={formik.handleChange} disabled={!validEmail} />
        </div>
        <div style={{ opacity: validEmail ? 1 : 0.3 }}>
          <Typography sx={{ textAlign: "center" }} color={muiTheme.palette.error.main}>
            <span style={{ fontWeight: 700 }}>{t("evaluation.checkin_form.important")} </span>
            {t("evaluation.checkin_form.note")}{" "}
          </Typography>
          <FormGroup sx={{ marginTop: "20px" }}>
            <FormControlLabel sx={{ alignItems: "flex-start", marginTop: "5px", fontStyle: "italic" }} control={<Checkbox checked={formik.values.communications} onChange={(e: React.ChangeEvent<any>) => handleCommunicationsChange(e)} id="communications" name="communications" />} label={t("evaluation.checkin_form.labels.communication_consent")} />
            <FormControlLabel disabled={!formik.values.communications} sx={{ alignItems: "flex-start", marginTop: "5px" }} control={<Checkbox checked={formik.values.presentationFiles} onChange={formik.handleChange} id="presentationFiles" name="presentationFiles" />} label={t("evaluation.checkin_form.labels.presentation_files_consent")} />
          </FormGroup>
          <div style={{ alignItems: "center", textAlign: "center", marginTop: "30px" }}>
            {state.success ? (
              <FormButton
                sx={{width: {xs: "80%", md: "40%"}}}
                onClick={() => {
                  formik.submitForm();
                }}
              >
                {t("evaluation.checkin_form.checkin_btn")}
              </FormButton>
            ) : (
              <FormButton
                sx={{width: {xs: "80%", md: "40%"}}}
                disabled={!validEmail}
                onClick={() => {
                  formik.submitForm();
                }}
              >
                {t("evaluation.checkin_form.checkin_btn")}
              </FormButton>
            )}
          </div>
          <div>
            <Typography sx={{fontSize: 14, color: "gray"}}>{ t("event_form.required_micetype") }</Typography>
          </div>
        </div>
      </Container>
    </Box>
  );
};

export default CheckInForm;
