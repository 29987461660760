import { axios } from "./axios";
import { ActivityType } from "../typings/interfaces";
import useSWR from "swr";

export const ENDPOINT = "/activityTypes";

export const getActivityTypes = async () => {
  const { data } = await axios.get(ENDPOINT);
  return data;
};

interface CreateEditProps {
  enName: string;
  frName: string;
}

export function useActivityTypes() {
  const {
    isLoading: isActivityTypesLoading,
    data,
    error: activityTypesError,
    mutate,
  } = useSWR(ENDPOINT, getActivityTypes, {
    onSuccess: (data) => data,
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });

  const create = async ({enName, frName}:CreateEditProps) => {
    const { data } = await axios.post(ENDPOINT, { enName, frName });
    mutate();

    return data;
  }

  const update = async (id: string, { enName, frName }: CreateEditProps) => {
    const { data } = await axios.put(`${ENDPOINT}/${id}`, { enName, frName });
    mutate();

    return data;
  }

  const deleteById = async (id: string) => {
    const { data } = await axios.delete(`${ENDPOINT}/${id}`);
    mutate();

    return data;
  }

  return {
    isActivityTypesLoading,
    activityTypes: data?.activityTypes as ActivityType[],
    activityTypesError,
    mutate,
    create,
    update,
    deleteById
  };
}
