import { GridColDef, GridToolbarContainer, GridToolbarExport, enUS, GridFooter } from "@mui/x-data-grid";
import { format } from "date-fns";
import { frFRCustom as frFR } from "../locale/frFR";
import { IconButton, Modal } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Holiday } from "../typings/interfaces";
import { useTranslation } from "react-i18next";
import { useState, useContext } from "react";
import ReportingDataGridTable from "./styled/ReportingGrid";
import ActivityContext from "../context/ActivityContext";
import DraggableDialog from "./styled/DraggableDialog";
import HolidayForm from "./activityViews/HolidayForm";
import { useHolidays } from "../api/holidays";

type Props = {
  updateTable: () => void;
  update: boolean;
  toggleOpen: () => void;
  open: boolean;
};

const HolidayList = ({ updateTable, update, toggleOpen, open }: Props) => {
  const { t, i18n } = useTranslation();
  const holiday_label = t("activity_management.holiday");
  const holiday_date_label = t("activity_management.holiday_date");
  const edit_label = t("edit");
  const delete_label = t("delete");
  const current_language = i18n.language;
  const [holiday, setHoliday] = useState<Holiday>({ _id: "", enName: "", frName: "", date: "", internalDate: new Date() });
  const { holidays } = useContext(ActivityContext);
  const { deleteById } = useHolidays();
  const [edit, setEdit] = useState(false);

  const closeModal = () => {
    setEdit(false);
    setHoliday({} as Holiday);
    toggleOpen();
  }

  // console.log("activityTypes", activityTypes);

  const editHoliday = (holiday: Holiday) => {
    const { _id, enName, frName, date, internalDate } = holiday;
    setEdit(true);
    setHoliday({ _id, enName, frName, date, internalDate });
    toggleOpen();
  };

  const deleteHoliday = async (_id: string) => {
    const confirm = window.confirm(t("delete_confirm_message"));

    if (!confirm) {
      return;
    }

    try {
      await deleteById(_id);
    } catch (error: any) {
      alert(error.response.data.message);
    }
  };

  const columns: GridColDef[] = [
    {      
      sortable: true,
      editable: false,
      filterable: true,
      hideable: false,
      field: "holidayName",
      headerName: holiday_label,
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        if (current_language === "en") return params.row.enName;
        return params.row.frName;
      },
      valueGetter: (params) => {
        if (current_language === "en") return params.row.enName;
        return params.row.frName;
      }
    },
    {      
      sortable: true,
      editable: false,
      filterable: true,
      hideable: false,
      field: "date",
      type: 'date',
      headerName: holiday_date_label,
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return format(new Date(params.row.date), "yyyy-MM-dd");
      },
      valueGetter: (params) => {
        return new Date(params.row.date);
      },
      valueFormatter: (params) => {
        return format(new Date(params.value), "yyyy-MM-dd");
      }
    },
    {
      sortable: false,
      editable: false,
      filterable: false,
      hideable: false,
      field: "edit",
      width: 50,
      headerName: "",
      renderHeader: () => (null),
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,

      renderCell: (params) => (
        <strong>
          <IconButton aria-label="edit" color="warning" style={{ marginLeft: 0 }} onClick={() => editHoliday(params.row)}>
            <EditOutlinedIcon />
          </IconButton>
        </strong>
      ),
    },
    {
      sortable: false,
      editable: false,
      filterable: false,
      hideable: false,
      field: "delete",
      headerName: "",
      renderHeader: () => (null),
      width: 50,
      headerAlign: "center",
      disableColumnMenu: true,
      align: "center",
      renderCell: (params) => (
        <strong>
          <IconButton
            aria-label="delete"
            color="error"
            style={{ marginLeft: 0 }}
            onClick={() => {
              deleteHoliday(params.row._id);
            }}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </strong>
      ),
    },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
        <div>
          <GridToolbarExport
            csvOptions={{
              fields: ["holidayName", "date"],
            }}
            printOptions={{
              fields: ["holidayName", "date"],
            }}
          />
        </div>
        <div style={{ display: "flex" }}>
          <GridFooter
            sx={{
              border: "none", // To delete double border.
            }}
          />
        </div>
      </GridToolbarContainer>
    );
  };

  if (!holidays) {
    return <></>;
  } else {
    return (
      <div style={{ height: "100%", width: "100%", marginTop: "20px" }}>
        <ReportingDataGridTable
          getRowId={(row: any) => row._id}
          className="activity_type_grid"
          rows={holidays}
          columns={columns}
          disableRowSelectionOnClick
          localeText={current_language === "en" ? { ...enUS.components.MuiDataGrid.defaultProps.localeText, toolbarExport: "" } : { ...frFR.components.MuiDataGrid.defaultProps.localeText, toolbarExport: "" }}
          slots={{
            footer: CustomToolbar,
          }}
          pageSizeOptions={[100]}
          initialState={{
            sorting: {
              sortModel: [{ field: 'date', sort: 'desc' }],
            },
          }}
          disableColumnSelector  // Disable the "Manage Columns" feature
        />
        <DraggableDialog open={open} closeModal={closeModal}>
          <HolidayForm closeModal={closeModal} edit={edit} holiday={holiday} />
        </DraggableDialog>
      </div>
    );
  }
};

export default HolidayList;
