/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useEffect, useState } from "react";
import {preload} from "swr";
import { ENDPOINT as speakersCacheKey, getSpeakers } from "../api/speakers";
import { ENDPOINT as repsCacheKey, getReps } from "../api/reps";
import { ENDPOINT as evaluationsCacheKey, getEvaluations } from "../api/evaluations";
import { ENDPOINT as learningObjectiveCacheKey, getLearningObjectives } from "../api/learningObjectives";
import { ENDPOINT as theraputicAreasCacheKey, getTherapeuticAreas } from "../api/therapeuticAreas";
import { ENDPOINT as regionsCacheKey, getRegions } from "../api/regions";
import { ENDPOINT as eventsCacheKey, getEventsWithEvals } from "../api/events";
import { UserTool, Users } from "../typings/interfaces";
import { whoAmI } from "../api/users";

type Role = "sudo" | "admin" | "msl" | "rep";

type Tool = "event-evaluations" | "activity-calendar";
export type CurrentTool = "" | "event-evaluations" | "activity-calendar";

interface IAppContext {
  roles: Role[];
  updateIsAuthenticated(isAuthenticated: boolean): void;
  isAuthenticated: boolean;
  hasRole(roles: string[]): boolean;
  userName: string;
  loading: boolean;
  updateRoles: (roles: Role[]) => void;
  logout: () => void;
  userId: string;
  updateUserId: (userId: string) => void;
  tools: Tool[];
  updateTools: (tools: Tool[]) => void;
  currentTool: CurrentTool,
  updateCurrentTool: (tool: CurrentTool) => void;
  hasTool(tools: string[]): boolean;
  user: Users | null;
  userLoading: boolean;
  readOnlyEngagementTracker: boolean,
  updateUser: (user: Users) => void;
  fetchUser: () => void;
}

//Initial App Context
export const AppContext = createContext<IAppContext>({
  roles: [],
  isAuthenticated: false,
  updateIsAuthenticated: function (): void {
    // do nothing
  },
  hasRole: function (): boolean {
    return false;
  },
  userName: "",
  loading: false,
  updateRoles: function (): void {},
  logout: function (): void {},
  userId: "",
  updateUserId: function (): void { },
  tools: [],
  updateTools: function (): void { },
  currentTool: "",
  updateCurrentTool: function (): void { },
  hasTool: function (): boolean {
    return false;
  },
  user: null,
  userLoading: true,
  readOnlyEngagementTracker: false,
  updateUser: function (): void { },
  fetchUser: function(): void { },
});

interface Props {
  children: React.ReactNode;
}
export const AppProvider: React.FC<Props> = ({ children }) => {
  const [roles, setRoles] = useState<Role[]>([]);
  const [tools, setTools] = useState<Tool[]>([]);
  const [currentTool, setCurrentTool] = useState<CurrentTool>("");
  const [userName, setUserName] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [authError, setAuthError] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<null | Users>(null);
  const [userLoading, setUserLoading] = useState(true);
  const [readOnlyEngagementTracker, setReadOnlyEngagementTracker] = useState(false);

  const updateIsAuthenticated = (isAuthenticated: boolean) => {
    setIsAuthenticated(isAuthenticated);
  };

  const hasRole = (checkRoles: string[]) => {
    return roles.some((role) => checkRoles.includes(role));
  };

  const updateRoles = (roles: Role[]) => {
    setRoles(roles);
  };

  const hasTool = (checkTools: string[]) => {
    return tools.some((tool) => checkTools.includes(tool));
  }

  const updateTools = (tools: Tool[]) => {
    setTools(tools);
  }

  const updateCurrentTool = (tool: CurrentTool) => {
    setCurrentTool(tool);
    localStorage.setItem("SavedTool", tool);
  }

  useEffect(() => {
    if (hasTool(["event-evaluations"])) {
      // preload the speakers right when authenticated. this fixes the undefined speakers issue
      preload(speakersCacheKey, getSpeakers);
      preload(repsCacheKey, getReps);
      preload(evaluationsCacheKey, getEvaluations);
      preload(learningObjectiveCacheKey, getLearningObjectives);
      preload(theraputicAreasCacheKey, getTherapeuticAreas);
      preload(regionsCacheKey, getRegions);
      preload(eventsCacheKey, getEventsWithEvals);
    }
  }, [tools]);

  const updateUserId = (authedUserId: string) => {
    setUserId(authedUserId);
  }

  const updateUser = (user: Users) => {
    setUser(user);
    updateReadOnlyEngagementTracker(user);
  }

  const updateReadOnlyEngagementTracker = (user: Users) => {
    const readOnlyActivityCalendar = user.tools?.find((tool: UserTool) => tool.tool === "activity-calendar")?.readonly ?? false;
    setReadOnlyEngagementTracker(readOnlyActivityCalendar);
  }

  const fetchUser = async () => {
    await getUser();
  }

  const logout = () => {
    updateIsAuthenticated(false);
    localStorage.removeItem("SavedToken");
    localStorage.removeItem("SavedTool");
    setReadOnlyEngagementTracker(false);
    updateCurrentTool("");
    updateTools([]);
    updateRoles([]);
    updateUserId("");
    setLoading(false);
    setUser(null);
  };

  useEffect(() => {
    //Pull the local storage
      setLoading(true);
      const token = localStorage.getItem("SavedToken") || null;

      // If token log user in
      if (token) {
        setIsAuthenticated(true);
      } else {
        setLoading(false);
      }
  }, []);

  async function getUser() {
    try {
      const userData = await whoAmI();
      const SavedTool = localStorage.getItem("SavedTool") || null;
      updateRoles([userData.role as Role]);

      const tools = userData.tools;
      if (tools) {
        const toolNames = tools.map((i: any) => i.tool);
        updateTools(toolNames);

        //check if user activity calendar is read only
        const readOnlyActivityCalendar = userData.tools?.find((tool: UserTool) => tool.tool === "activity-calendar")?.readonly ?? false;
        updateReadOnlyEngagementTracker(readOnlyActivityCalendar);
        //if the user has a tool saved in local storage - and that tool is still in their list of tool access
        //then set the users CurrentTool to the previous saved tool
        if(SavedTool && toolNames.includes(SavedTool)) {
          updateCurrentTool(SavedTool as CurrentTool);
        } else {
          updateCurrentTool(toolNames[0]);
        }
      }

      setIsAuthenticated(true);
      updateUserId(userData._id);
      setUser(userData);
      setUserLoading(false);
      setLoading(false);
    } catch (error) {
      logout();
    }
  }

  useEffect(() => {
    setUserLoading(true);
    if (isAuthenticated && !user) {
      getUser();
    } else if (isAuthenticated){
      setUserLoading(false);
      setLoading(false);
    }
  }, [isAuthenticated]);

  return (
    <AppContext.Provider
      value={{
        roles,
        updateIsAuthenticated,
        isAuthenticated,
        hasRole,
        userName,
        loading,
        updateRoles,
        logout,
        userId,
        updateUserId,
        tools,
        updateTools,
        currentTool,
        updateCurrentTool,
        hasTool,
        user,
        userLoading,
        readOnlyEngagementTracker,
        updateUser,
        fetchUser
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
