import { Button, Menu, MenuItem } from "@mui/material";
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AppContext, { CurrentTool } from "../../context/Auth";
import { styled, lighten } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const ToolBarListItemCurrent = styled(ListItemButton)(({ theme }) => ({
  color: "#FFFFFF",
  backgroundColor: theme.palette.primary.dark,

  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const ToolBarListItem = styled(ListItemButton)(({ theme }) => ({
  color: "#FFFFFF",
  backgroundColor: theme.palette.primary.main,

  "&:hover": {
    backgroundColor: lighten(theme.palette.primary.main, 0.1),
  },
}));

const ToolBarListItemText = styled(ListItemText)(({ theme }) => ({
  color: "#FFFFFF",
  span: {
    fontWeight: "700",
  },
}));

type Props = {
  mobile: boolean;
};

const NavToolSelector = ({ mobile }: Props) => {
  const { isAuthenticated, hasRole, hasTool, updateCurrentTool, currentTool } = useContext(AppContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const [openMobileManagement, setOpenMobileManagement] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const changeCurrentTool = (tool: CurrentTool) => {
    updateCurrentTool(tool);
    navigate("/");
  };
  const getToolName = (currentTool: CurrentTool) => {
    switch (currentTool) {
      case "event-evaluations":
        return t("nav_tool_selector.event_tool_name");
      case "activity-calendar":
        return t("nav_tool_selector.calendar_tool_name");
      default:
        return "";
    }
  };

  const mobileManagementClick = () => {
    setOpenMobileManagement(!openMobileManagement);
  };

  if (mobile) {
    return (
      <>
        {isAuthenticated && hasRole(["sudo", "admin", "rep", "msl"]) && (
          <>
            <ListItem disablePadding>
              <ToolBarListItemCurrent onClick={mobileManagementClick}>
                <ToolBarListItemText sx={{ fontWeight: "bold" }} primary={`${t("nav_tool_selector.tool")} ${getToolName(currentTool)}`} />
                {openMobileManagement ? <ExpandLess /> : <ExpandMore />}
              </ToolBarListItemCurrent>
            </ListItem>
            <Collapse in={openMobileManagement} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {hasTool(["event-evaluations"]) && (
                  <ToolBarListItem onClick={() => changeCurrentTool("event-evaluations")} sx={{ pl: 2 }}>
                    <ListItemText primary={t("nav_tool_selector.event_tool_name")} />
                  </ToolBarListItem>
                )}
                {hasTool(["activity-calendar"]) && (
                  <ToolBarListItem onClick={() => changeCurrentTool("activity-calendar")} sx={{ pl: 2 }}>
                    <ListItemText primary={t("nav_tool_selector.calendar_tool_name")} />
                  </ToolBarListItem>
                )}
              </List>
            </Collapse>
          </>
        )}
      </>
    );
  } else {
    return (
      <>
        {isAuthenticated && hasRole(["sudo", "admin", "rep", "msl"]) && (
          <>
            <Button sx={{ display: "flex", justifyContent: "space-between", width: mobile ? "100%" : "auto", textTransform: "none", "&.isOpen-true": { borderRadius: "4px 4px 0px 0px" } }} variant="contained" onClick={handleClick} className={`isOpen-${open}`}>
              {t("nav_tool_selector.tool")} {getToolName(currentTool)}
              <KeyboardArrowDownIcon />
            </Button>

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              className={`isOpen-${open}`}
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  borderRadius: "0px 4px 4px 4px",
                  minWidth: 200,
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 0,
                },
              }}
              transformOrigin={{ horizontal: "left", vertical: "top" }}
              anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            >
              {hasTool(["event-evaluations"]) && <MenuItem onClick={() => changeCurrentTool("event-evaluations")}>{t("nav_tool_selector.event_tool_name")}</MenuItem>}
              {hasTool(["activity-calendar"]) && <MenuItem onClick={() => changeCurrentTool("activity-calendar")}>{t("nav_tool_selector.calendar_tool_name")}</MenuItem>}
            </Menu>
          </>
        )}
      </>
    );
  }
};

export default NavToolSelector;
