import React, { useState, useEffect } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ActivityFormScheduleDaysOfWeek from "./ActivityFormScheduleDaysOfWeek";
import ActivityFormScheduleEndsOn from "./ActivityFormScheduleEndsOn";
import { ActivityFormScheduleLabel } from "./styled";
import ActivityFormScheduleMonthDropdown from "./ActivityFormScheduleMonthDropdown";
import { RecurringActivityInfo } from "../../../typings/interfaces";

type Props = {
  // recurringInfo?: RecurringActivityInfo;
  handleRecurringInfoChange: () => void;
  defaultFrequency?: string;
  defaultFrequencyAmount?: number;
  date: Date;
  formik: any;
  edit: boolean;
};

function ActivityFormSchedule({ handleRecurringInfoChange, defaultFrequency, date, formik, edit }: Props) {
  const { t, i18n } = useTranslation();
  const [frequency, setFrequency] = useState<string | undefined>(formik.values.repeat_frequency);
  const [frequencyAmount, setFrequencyAmount] = useState<number>(formik.values.repeat_every);

  const handleFrequencyChange = (e: SelectChangeEvent) => {
    if (e.target.value) {
      const value = e.target.value;
      setFrequency(value);
      formik.setFieldValue("repeat_frequency", value);
      handleRecurringInfoChange();
    }
  };
  const handleFrequencyAmountChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = parseInt(e.target.value, 10); // Parse string value to integer
    setFrequencyAmount(isNaN(value) ? 0 : value);
    formik.setFieldValue("repeat_every", value);
    handleRecurringInfoChange();
  };

  return (
    <>
      <Grid item xs={12}>
        <ActivityFormScheduleLabel>{t("activity_form.recurring_activity.repeat_every")}</ActivityFormScheduleLabel>
      </Grid>
      <Grid item xs={6} sx={{ color: "red", paddingTop: "5px!important" }}>
        <TextField
          InputProps={{
            inputProps: {
              min: 1,
              max: 100,
            },
          }}
          id="repeat_every"
          name="repeat_every"
          type="number"
          value={frequencyAmount}
          variant="outlined"
          focused
          fullWidth
          size="medium"
          label={t("activity_form.recurring_activity.frequency_interval")}
          onChange={(e) => handleFrequencyAmountChange(e)}
          error={formik.touched.repeat_every && Boolean(formik.errors.repeat_every)}
        />
      </Grid>
      <Grid item xs={6} sx={{ paddingTop: "5px!important" }}>
        <FormControl fullWidth focused>
          <InputLabel id="demo-simple-select-label">{t("activity_form.recurring_activity.frequency")}</InputLabel>
          <Select labelId="repeat_frequency" id="repeat_frequency" value={frequency} label="Frequency" variant="outlined" onChange={(e) => handleFrequencyChange(e)}>
            <MenuItem value="day">{t("activity_form.recurring_activity.repeat_frequency_options.day")}{frequencyAmount > 1 ? "s": ""}</MenuItem>
            <MenuItem value="week">{t("activity_form.recurring_activity.repeat_frequency_options.week")}{frequencyAmount > 1 ? "s": ""}</MenuItem>
            <MenuItem value="month">{t("activity_form.recurring_activity.repeat_frequency_options.month")}{frequencyAmount > 1 && i18n.language === "en" ? "s": ""}</MenuItem>
            <MenuItem value="year">{t("activity_form.recurring_activity.repeat_frequency_options.year")}{frequencyAmount > 1 ? "s": ""}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {(frequency === "week" ||frequency === "month") &&
        <Grid item xs={12}>
          {frequency === "week" && <ActivityFormScheduleDaysOfWeek handleRecurringInfoChange={handleRecurringInfoChange} date={date} formik={formik} />}
          {frequency === "month" && <ActivityFormScheduleMonthDropdown handleRecurringInfoChange={handleRecurringInfoChange} date={date} formik={formik} edit={edit} />}
        </Grid>
        }

      <Grid item xs={12} sx={{paddingTop: "8px"}}>
        <ActivityFormScheduleEndsOn handleRecurringInfoChange={handleRecurringInfoChange} formik={formik} />
      </Grid>
    </>
  );
}

export default ActivityFormSchedule;
