import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";


type ReportingDataGridTableProps = {
  size?: string, 
}

const ReportingDataGridTable = styled(DataGrid)<ReportingDataGridTableProps>(({ theme, size }) => ({
  backgroundColor: "#ffffff",
  height: "686px",

  "& .MuiDataGrid-menuIcon": {
    visibility: "visible",
    width: "auto",
    marginRight: "0px",
  },

  "& a": {
    color: theme.palette.primary.dark,
  },

  "& .MuiDataGrid-columnHeader": {
    height: size === "small" ? "39px !important" : "56px",
  },
  // "& .MuiDataGrid-virtualScroller": {
  //   marginTop: size === "small" ? "39px !important" : "56px",
  // },

  "& .eventDisplayName": {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  "& .comment_cell": {
    alignItems: "flex-start",
    borderRight: "1px solid #dadada",

    ":last-of-type": {
      borderRight: "none",
    }
  },

  "& .reporting_cell": {
    color: theme.palette.primary.main,
    backgroundColor: "#005ad20d",
    borderRight: `2px solid ${theme.palette.primary.main}`,
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    fontWeight: "bold",
    fontSize: "1rem",
    justifyContent: "center",
  },

  "& .MuiDataGrid-row--lastVisible": {
    "& .reporting_cell": {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
  },

  "& .MuiDataGrid-row:nth-of-type(odd)": {
    backgroundColor: "#ffffff",
  },
  "& .MuiDataGrid-row:nth-of-type(even)": {
    backgroundColor: "#cecece14",
  },

  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: theme.palette.primary.light,
    textTransform: "uppercase",
    fontSize: size === "small" ? "13px" : "15px",
    minHeight: size === "small" ? "39px !important" : "56px",
    maxHeight: size === "small" ? "39px !important" : "56px",
    lineHeight: size === "small" ? "39px !important" : "56px",
    [theme.breakpoints.down('lg')]: {
      fontSize: "14px",
    },

    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },

    "& .comment_header": {
      height:"auto",
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        whiteSpace: "normal",
        overflow: "hidden",
        "& .MuiDataGrid-columnHeaderTitleContainerContent": {
          whiteSpace: "normal",
          overflow: "hidden",

          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            overflow: "hidden",
            lineHeight: "1.2rem",
            textAlign:"center",
            textTransform: "initial",
          } 
        }
      }
    },

    "& .reporting_header": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiDataGrid-columnHeaderTitle": {
        color: "#ffffff",
      },
      "& .MuiButtonBase-root": {
        color: "#ffffff",
      }
    },

    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold",
      letterSpacing: "0.19px",
      color: theme.palette.primary.dark,
    },
  },
  "& .MuiDataGrid-toolbarContainer": {
    borderTop: "1px solid #e7e7e7",
    "& .MuiButtonBase-root": {

      "& .MuiButton-startIcon": {
        margin:0,

        "& .MuiSvgIcon-root": {
          fontSize: "24px",
          
        }
      }
    }
  } 
}));


export default ReportingDataGridTable;