import { Box } from "@mui/material";
import Layout from "../components/Layout";
import LearningObjectivesList from "../components/LearningObjectivesList";
import { useContext } from "react";
import NoAccess from "../components/NoAccess";
import AppContext from "../context/Auth";
import Loading from "../components/Loading";

const LearningObjectivesManagementPage = () => {
  const { hasTool, hasRole, userLoading, loading } = useContext(AppContext);

  if (userLoading || loading) {
    return (
      <Layout>
        <Loading />
      </Layout>
    );
  }

  return (
    <Layout>
      {hasTool(["event-evaluations"]) && hasRole(["sudo", "admin"]) ? (
        <Box my={5}>
          <LearningObjectivesList />
        </Box>
      ) : (
        <NoAccess />
      )}
    </Layout>
  );
};

export default LearningObjectivesManagementPage;
