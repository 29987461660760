import { addDays, addMonths, addSeconds, addWeeks, addYears, eachDayOfInterval, endOfDay, endOfMonth, endOfWeek, getDate, getDay, lastDayOfYear, parse, setDate, setHours, setMinutes, startOfMonth, startOfWeek } from "date-fns";
import { RecurringActivity, RecurringActivityOccurence } from "../typings/interfaces";
import { zonedTimeToUtc, format } from "date-fns-tz";

export const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
const validOccurrences = ['first', 'second', 'third', 'fourth', 'last'];


function shouldBreak(maxOccurences: number | undefined, currentOccurence: number, maxDate: Date | undefined, startDate: Date) {
    return (maxOccurences && maxOccurences < currentOccurence) || (maxDate && maxDate < startDate);
}

function getWeekdayOccurenceOfMonth(date: Date, weekDay: string, occurence: string) {
    let currentDate = startOfMonth(date);
    while (getDay(currentDate) !== daysOfWeek.indexOf(weekDay.toLowerCase())) {
        currentDate = addDays(currentDate, 1);
    }

    if (occurence === "first") {
        return currentDate;
    } else {
        const weeksToAdd = (occurence === 'last') ? 4 : validOccurrences.indexOf(occurence);
        let targetDate = addWeeks(currentDate, weeksToAdd);
    
        // Ensure the target date is still within the current month
        while (targetDate.getMonth() !== date.getMonth()) {
            targetDate = addWeeks(targetDate, -1);
        }
  
        return targetDate;
    }
}

function handleCustomOccurence(recurringActivity: RecurringActivity, currentOccurence: number, startDate: Date) {
     // use the data from custom occurance
     const customOccurence = recurringActivity.customOccurences.find((custom) => custom.occurence === currentOccurence);
     if (customOccurence) {
         const startingTime = customOccurence.startTime.split(' ')[1];
         const startingHour = Number(startingTime.split(':')[0]);
         const startingMinute = Number(startingTime.split(':')[1]);
         const startingDate = customOccurence.startTime.split(' ')[0];
         const startingDay = Number(startingDate.split('-')[2]);
         let startTime = setHours(startDate, startingHour);
         startTime = setMinutes(startTime, startingMinute);
         startTime = setDate(startTime, startingDay);

         const utcStart = zonedTimeToUtc(startTime, "UTC");
         const endDate = addSeconds(utcStart, customOccurence.timeBetween);
         const endDateWithoutTimezone = new Date(endDate.toISOString().slice(0, -1));

         const occurence = {
            id: `${recurringActivity.id}-${currentOccurence}`,
            recurring: true,
            ...customOccurence,
            startTime: format(startTime, "yyyy-MM-dd HH:mm"),
            endTime: format(endDateWithoutTimezone, "yyyy-MM-dd HH:mm"),
            userId: recurringActivity.userId,
            parentId: recurringActivity.id,
            recurringInfo: getActivityInfo(recurringActivity)
         } as RecurringActivityOccurence;
         return occurence
     }
    return undefined;
}

function isCustomOccurence(currentOccurence: number, customOccurences: number[]) {
    return customOccurences.includes(currentOccurence);
}

function getDayScheduleOccurences(currentOccurence: number, recurringActivity: RecurringActivity, endTimeOfSelectedYear: Date, maxDate: Date | undefined, interval: number, startDate: Date, excludedOccurences: number[], customOccurences: number[], maxOccurences: number | undefined) {
    const occurences: RecurringActivityOccurence[] = [];
    const activityInfo = recurringActivity.activityInfo;

    const updateStartDateAndCurrentOccurence = () => {
        startDate = addDays(startDate, interval);
        currentOccurence++;
    }

    while (startDate < endTimeOfSelectedYear) {
        if (shouldBreak(maxOccurences, currentOccurence, maxDate, startDate)) break;

        if (excludedOccurences.includes(currentOccurence)) {
            updateStartDateAndCurrentOccurence();
            continue;
        }

        if (isCustomOccurence(currentOccurence, customOccurences)) {
            const occurence = handleCustomOccurence(recurringActivity, currentOccurence, startDate);
            if (occurence) {
                occurences.push(occurence)
            }
            updateStartDateAndCurrentOccurence();
            continue;
        }

        const utcStart = zonedTimeToUtc(startDate, "UTC");
        const endDate = addSeconds(utcStart, activityInfo.timeBetween);
        const endDateWithoutTimezone = new Date(endDate.toISOString().slice(0, -1));

        const occurence = {
            id: `${recurringActivity.id}-${currentOccurence}`,
            recurring: true,
            ...activityInfo,
            occurence: currentOccurence,
            startTime: format(startDate, "yyyy-MM-dd HH:mm"),
            endTime: format(endDateWithoutTimezone, "yyyy-MM-dd HH:mm"),
            userId: recurringActivity.userId,
            parentId: recurringActivity.id,
            recurringInfo: getActivityInfo(recurringActivity)
        } as RecurringActivityOccurence;
        occurences.push(occurence);
        updateStartDateAndCurrentOccurence();
    }

    return occurences;
}

function getWeekScheduleOccurences(currentOccurence: number, recurringActivity: RecurringActivity, endTimeOfSelectedYear: Date, maxDate: Date | undefined, interval: number, startDate: Date, excludedOccurences: number[], customOccurences: number[], maxOccurences: number | undefined) {
    const occurences: RecurringActivityOccurence[] = [];
    const activityInfo = recurringActivity.activityInfo;
    const initialStart = startDate;

    const updateStartDate = () => {
        startDate = addWeeks(startDate, interval);
    }

    const addOneWeek = () => {
        startDate = addWeeks(startDate, 1);
    }

    const updateCurrentOccurence = () => {
        currentOccurence++;
    }

    while (startDate < endTimeOfSelectedYear) {
        const startOfCurrentWeek = startOfWeek(startDate);
        const endOfCurrentWeek = endOfWeek(startDate);
        const daysInCurrentWeek = eachDayOfInterval({
            start: startOfCurrentWeek,
            end: endOfCurrentWeek
        });

        let create = false;
        for (const day of daysInCurrentWeek) {
            const dayOfWeekIndex = getDay(day);
            const dayOfWeek = daysOfWeek[dayOfWeekIndex];

            const startingTime = recurringActivity.activityInfo.startTime.split(' ')[1];
            const startingHour = Number(startingTime.split(':')[0]);
            const startingMinute = Number(startingTime.split(':')[1]);
            let startTime = setHours(day, startingHour);
            startTime = setMinutes(startTime, startingMinute);

            create = false;

            switch (dayOfWeek) {
                case "sunday":
                    create = recurringActivity.weekly?.sunday || false;
                    break;
                
                case "monday":
                    create = recurringActivity.weekly?.monday || false;
                    break;
                
                case "tuesday":
                    create = recurringActivity.weekly?.tuesday || false;
                    break;
                
                case "wednesday":
                    create = recurringActivity.weekly?.wednesday || false;
                    break;
                
                case "thursday":
                    create = recurringActivity.weekly?.thursday || false;
                    break;
                
                case "friday":
                    create = recurringActivity.weekly?.friday || false;
                    break;
                
                case "saturday":
                    create = recurringActivity.weekly?.saturday || false;
                    break;
            }

            if (startTime < initialStart) {
                create = false;
            }

            if (create) {
                if (shouldBreak(maxOccurences, currentOccurence, maxDate, startTime)) break;
                if (excludedOccurences.includes(currentOccurence)) {
                    updateCurrentOccurence();
                    continue;
                }
    
                if (isCustomOccurence(currentOccurence, customOccurences)) {
                    const occurence = handleCustomOccurence(recurringActivity, currentOccurence, startTime);
                    if (occurence) {
                        occurences.push(occurence);
                    }
                    updateCurrentOccurence();
                    continue;
                }
    
                const utcStart = zonedTimeToUtc(startTime, "UTC");
                const endDate = addSeconds(utcStart, activityInfo.timeBetween);
                const endDateWithoutTimezone = new Date(endDate.toISOString().slice(0, -1));
  
                const occurence = {
                    id: `${recurringActivity.id}-${currentOccurence}`,
                    recurring: true,
                    ...activityInfo,
                    occurence: currentOccurence,
                    startTime: format(startTime, "yyyy-MM-dd HH:mm"),
                    endTime: format(endDateWithoutTimezone, "yyyy-MM-dd HH:mm"),
                    userId: recurringActivity.userId,
                    parentId: recurringActivity.id,
                    recurringInfo: getActivityInfo(recurringActivity)
                } as RecurringActivityOccurence;
                occurences.push(occurence);
                updateCurrentOccurence();
            }
        }

        updateStartDate();
    }

    return occurences;
}

function getYearScheduleOccurences(currentOccurence: number, recurringActivity: RecurringActivity, endTimeOfSelectedYear: Date, maxDate: Date | undefined, interval: number, startDate: Date, excludedOccurences: number[], customOccurences: number[], maxOccurences: number | undefined) {
    const occurences: RecurringActivityOccurence[] = [];
    const activityInfo = recurringActivity.activityInfo;

    const updateStartDateAndCurrentOccurence = () => {
        startDate = addYears(startDate, interval);
        currentOccurence++;
    }

    while (startDate < endTimeOfSelectedYear) {
        if (shouldBreak(maxOccurences, currentOccurence, maxDate, startDate)) break;

        if (excludedOccurences.includes(currentOccurence)) {
            updateStartDateAndCurrentOccurence();
            continue;
        }

        if (isCustomOccurence(currentOccurence, customOccurences)) {
            const occurence = handleCustomOccurence(recurringActivity, currentOccurence, startDate);
            if (occurence) {
                occurences.push(occurence)
            }
            updateStartDateAndCurrentOccurence();
            continue;
        }

        const utcStart = zonedTimeToUtc(startDate, "UTC");
        const endDate = addSeconds(utcStart, activityInfo.timeBetween);
        const endDateWithoutTimezone = new Date(endDate.toISOString().slice(0, -1));

        const occurence = {
            id: `${recurringActivity.id}-${currentOccurence}`,
            recurring: true,
            ...activityInfo,
            occurence: currentOccurence,
            startTime: format(startDate, "yyyy-MM-dd HH:mm"),
            endTime: format(endDateWithoutTimezone, "yyyy-MM-dd HH:mm"),
            userId: recurringActivity.userId,
            parentId: recurringActivity.id,
            recurringInfo: getActivityInfo(recurringActivity)
        } as RecurringActivityOccurence;
        occurences.push(occurence);
        updateStartDateAndCurrentOccurence();
    }

    return occurences;
}

function getMonthScheduleOccurences(currentOccurence: number, recurringActivity: RecurringActivity, endTimeOfSelectedYear: Date, maxDate: Date | undefined, interval: number, startDate: Date, excludedOccurences: number[], customOccurences: number[], maxOccurences: number | undefined) {
    const occurences: RecurringActivityOccurence[] = [];
    const activityInfo = recurringActivity.activityInfo;

    const updateStartDateAndCurrentOccurence = () => {
        startDate = addMonths(startDate, interval);
        currentOccurence++;
    }

    while (startDate < endTimeOfSelectedYear) {
        if (shouldBreak(maxOccurences, currentOccurence, maxDate, startDate)) break;
        if (excludedOccurences.includes(currentOccurence)) {
            updateStartDateAndCurrentOccurence();
            continue;
        }

        if (recurringActivity.monthly) {
            if (recurringActivity.monthly.dayOfTheMonth > 0) {
                startDate = setDate(startDate, recurringActivity.monthly.dayOfTheMonth);
                // the schedule is set to run on a specific day of the month
                if (isCustomOccurence(currentOccurence, customOccurences)) {
                    const occurence = handleCustomOccurence(recurringActivity, currentOccurence, startDate);
                    if (occurence) {
                        occurences.push(occurence);
                    }
                    updateStartDateAndCurrentOccurence();
                    continue;
                }
    
                const utcStart = zonedTimeToUtc(startDate, "UTC");
                const endDate = addSeconds(utcStart, activityInfo.timeBetween);
                const endDateWithoutTimezone = new Date(endDate.toISOString().slice(0, -1));
                const occurence = {
                    id: `${recurringActivity.id}-${currentOccurence}`,
                    recurring: true,
                    ...activityInfo,
                    occurence: currentOccurence,
                    startTime: format(startDate, "yyyy-MM-dd HH:mm"),
                    endTime: format(endDateWithoutTimezone, "yyyy-MM-dd HH:mm"),
                    userId: recurringActivity.userId,
                    parentId: recurringActivity.id,
                    recurringInfo: getActivityInfo(recurringActivity)
                } as RecurringActivityOccurence;
                occurences.push(occurence);
                updateStartDateAndCurrentOccurence();
            } else {
                if (isCustomOccurence(currentOccurence, customOccurences)) {
                    const occurence = handleCustomOccurence(recurringActivity, currentOccurence, startDate);
                    if (occurence) {
                        occurences.push(occurence);
                    }
                    updateStartDateAndCurrentOccurence();
                    continue;
                }

                // the schedule is set to run on a specific Weekday of the month (ex: 3rd Tuesday of every month)
                const monthlySchedule = recurringActivity.monthly;
                const dayOfTheWeek = monthlySchedule.weekDay;

                let date = startDate;
                if (monthlySchedule.first) {
                    date = getWeekdayOccurenceOfMonth(date, dayOfTheWeek, "first");
                }
                if (monthlySchedule.second) {
                    date = getWeekdayOccurenceOfMonth(date, dayOfTheWeek, "second");
                }
                if (monthlySchedule.third) {
                    date = getWeekdayOccurenceOfMonth(date, dayOfTheWeek, "third");
                }
                if (monthlySchedule.fourth) {
                    date = getWeekdayOccurenceOfMonth(date, dayOfTheWeek, "fourth");
                }
                if (monthlySchedule.last) {
                    date = getWeekdayOccurenceOfMonth(date, dayOfTheWeek, "last");
                }

                const utcStart = zonedTimeToUtc(date, "UTC");
                const endDate = addSeconds(utcStart, activityInfo.timeBetween);
                const endDateWithoutTimezone = new Date(endDate.toISOString().slice(0, -1));
                const occurence = {
                    id: `${recurringActivity.id}-${currentOccurence}`,
                    recurring: true,
                    ...activityInfo,
                    occurence: currentOccurence,
                    startTime: format(date, "yyyy-MM-dd HH:mm"),
                    endTime: format(endDateWithoutTimezone, "yyyy-MM-dd HH:mm"),
                    userId: recurringActivity.userId,
                    parentId: recurringActivity.id,
                    recurringInfo: getActivityInfo(recurringActivity)
                } as RecurringActivityOccurence;
                occurences.push(occurence);
                updateStartDateAndCurrentOccurence();
            }
        }
    }

    return occurences;
}

export const getOccurencesForYear = (recurringActivities: RecurringActivity[], currentYear: number) => {
    const dateForSelectedYear = new Date(`${currentYear}-02-01`);

    const lastDayOfSelectedYear = lastDayOfYear(dateForSelectedYear);
    const endTimeOfSelectedYear = endOfDay(lastDayOfSelectedYear);

    let occurences: RecurringActivityOccurence[] = [];

    for (const recurringActivity of recurringActivities) {
        let currentOccurence = 1;

        const maxOccurences = Boolean(recurringActivity.end.occurences) ? recurringActivity.end.occurences : undefined;
        const maxDate = recurringActivity.end.endDate ? new Date(recurringActivity.end.endDate) : undefined;
        const interval = recurringActivity.occurenceInterval;

        const excludedOccurences = recurringActivity.excludedOccurences;
        const customOccurences = recurringActivity.customOccurences.map((custom) => custom.occurence);

        let startDate = parse(recurringActivity.activityInfo.startTime, "yyyy-MM-dd HH:mm", new Date(recurringActivity.activityInfo.startTime));

        switch (recurringActivity.occurenceFrequency) {
            case "day":
                const dailyOccurences = getDayScheduleOccurences(currentOccurence, recurringActivity, endTimeOfSelectedYear, maxDate, interval, startDate, excludedOccurences, customOccurences, maxOccurences);        
                occurences = occurences.concat(dailyOccurences);
                break;
            case "week":
                const weeklyOccurences = getWeekScheduleOccurences(currentOccurence, recurringActivity, endTimeOfSelectedYear, maxDate, interval, startDate, excludedOccurences, customOccurences, maxOccurences);
                occurences = occurences.concat(weeklyOccurences);
                break;
            case "month":
                const monthlyOccurences = getMonthScheduleOccurences(currentOccurence, recurringActivity, endTimeOfSelectedYear, maxDate, interval, startDate, excludedOccurences, customOccurences, maxOccurences);
                occurences = occurences.concat(monthlyOccurences);
                break;
            case "year":
                const yearlyOccurences = getYearScheduleOccurences(currentOccurence, recurringActivity, endTimeOfSelectedYear, maxDate, interval, startDate, excludedOccurences, customOccurences, maxOccurences);
                occurences = occurences.concat(yearlyOccurences);
                break;
        }
    }

    return occurences;
}

export function getOccurrenceOfWeekdayInMonth(date: Date) {
    const startOfMonthDate = startOfMonth(date);
    const dayOfWeek = getDay(date);
    let currentDay = startOfMonthDate;
    let occurrence = 0;
  
    while (currentDay.getMonth() === date.getMonth()) {
      if (getDay(currentDay) === dayOfWeek) {
        occurrence++;
        if (currentDay.getDate() === getDate(date)) {
          return occurrence;
        }
      }
      currentDay = addDays(currentDay, 1);
    }
  
    return -1; // Return -1 if the date is not found in the month
}

export function getNumberOfWeekdayOccurrencesInMonth(date: Date) {
    const startOfMonthDate = startOfMonth(date);
    const endOfMonthDate = endOfMonth(date);
    const dayOfWeekIndex = getDay(date);
    
    let occurrences = 0;
    let currentDay = startOfMonthDate;
  
    while (currentDay <= endOfMonthDate) {
      if (getDay(currentDay) === dayOfWeekIndex) {
        occurrences++;
      }
      currentDay = addDays(currentDay, 1);
    }
  
    return occurrences;
}

function getActivityInfo (recurringActivity: RecurringActivity) {
    const recurringInfo = {
        occurenceInterval: recurringActivity.occurenceInterval,
        occurenceFrequency: recurringActivity.occurenceFrequency,
        monthly: recurringActivity.monthly,
        weekly: recurringActivity.weekly,
        end: recurringActivity.end,
    };
    return recurringInfo;
}