import React from "react";
import Routes from "./routes";

import { StyleProvider, legacyLogicalPropertiesTransformer } from '@ant-design/cssinjs';

const App: React.FC = () => {
  return (
    <StyleProvider hashPriority="high" transformers={[legacyLogicalPropertiesTransformer]}>
      <div>
        <Routes />
      </div>
    </StyleProvider>
  );
};

export default App;
