import { Segmentation } from "../typings/interfaces"

export const getSegmentationsById = (ids: string[], segmentations: Segmentation[], language: string) => {
    const segmentation = segmentations.filter((segmentations) => ids.includes(segmentations._id));
    if (segmentation.length > 0) {
    return language === "en" ? segmentation.map(i => i.enName).join(', ') : segmentation.map(i => i.frName).join(', ');
    }
    return "No Brand";
}

export const GetSegmentationsInOrder = (ids: string[], segmentations: Segmentation[]) => {
    var segmentationsFound: Segmentation[] = [];

    for (const id of ids) {
        const segmentation = segmentations.find(i => i._id === id);
        if (segmentation) {
            segmentationsFound.push(segmentation);
        }
    }

    return segmentationsFound;
}