import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { Paper } from "@mui/material";
import { enUS, GridColDef, GridToolbarContainer } from "@mui/x-data-grid";
import { frFRCustom as frFR } from "../../locale/frFR";
import { useTranslation } from "react-i18next";
import ReportingSpeakerGridTable from "../styled/reportingSpeakers/ReportingSpeakerGrid";
import SpeakerTableHeader from "../styled/reportingSpeakers/SpeakerTableHeader";
import { Evaluation, Event, ReportingSpeakerTherapeuticArea } from "../../typings/interfaces";
import { AverageNumbers } from "../../utils/average";
import { useNavigate } from "react-router-dom";
import useEventName from "../../hooks/useEventName";

const ReportStatsWrapper = styled(Grid)(({ theme }) => ({
  width: "100%",
  padding: "0px",
  borderRadius: "5px",
  paddingLeft: "0px",
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#FAFAFA",
  ...theme.typography.body2,
  paddingLeft: "0px",
  paddingTop: "0px",
  color: theme.palette.text.secondary,
  paddingBottom: "0px",
  boxShadow: "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)",
}));

type ReportSpeakerStatsProps = {
  category: ReportingSpeakerTherapeuticArea;
  events: Event[];
  speakerName: string;
  speakerId: string;
};

export const ReportSpeakerStats = ({
  category,
  events,
  speakerName,
  speakerId,
}: ReportSpeakerStatsProps) => {
  const { t, i18n } = useTranslation();
  const current_language = i18n.language;
  const navigate = useNavigate();
  const [formatEventName] = useEventName();
  const [evaluationCount, setEvaluationCount] = useState<number>(0);
  const [speakerAverage, setSpeakerAverage] = useState<number>(0);

  const goToEventReport = (eventId: string) => {
    navigate(`/reporting/event/${eventId}`);
  };

  const speakerColumns: GridColDef[] = [
    {
      field: "eventDate",
      headerName: `${t("create_event_form_name")}`,
      width: 350,
      minWidth: 350, 
      editable: false,
      renderCell: (params) => {
        return (
          <a style={{cursor:"pointer", fontWeight:400}} onClick={() => goToEventReport(params.row.id)}>
            {formatEventName(params.row as Event)}
          </a>
        );
      },
      valueGetter: (params) => {
        {formatEventName(params.row as Event)}
      },
    },
    {
      field: "evaluationCount",
      headerName: `${t("speaker_report.num_of_evals")} (${evaluationCount})`,
      minWidth: 200, 
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
    },
    {
      field: "programScore",
      headerName: t("reporting.program_score"),
      minWidth: 200, 
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      renderCell: (params) => (
        <span>
          {params.row.evaluations.length > 0
            ? AverageNumbers(
                params.row.evaluations.map((i: Evaluation) => i.programRating)
              )
            : 0}
        </span>
      ),
    },
    {
      field: "learningObjectiveScore",
      headerName: t("reporting.learning_obj_score"),
      minWidth: 200, 
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      renderCell: (params) => (
        <span>
          {params.row.evaluations.length > 0
            ? AverageNumbers(
                params.row.evaluations.map(
                  (i: Evaluation) => i.learningObjectiveRating
                )
              )
            : 0}
        </span>
      ),
    },
    {
      field: "presentationScore",
      headerName: t("reporting.presentation_score"),
      minWidth: 200, 
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      renderCell: (params) => (
        <span>
          {params.row.evaluations.length > 0
            ? AverageNumbers(
                params.row.evaluations.map(
                  (i: Evaluation) => i.presentationRating
                )
              )
            : 0}
        </span>
      ),
    },
    {
      field: "speakerRating",
      headerName: t("reporting.speaker_rating"),
      width: 220,
      minWidth: 220, 
      headerAlign: "center",
      align: "center",
      editable: false,
      renderCell: (params) => {
        const speakerRatings = params.row.evaluations
          .map((evaluation: Evaluation) =>
            evaluation.speakers
              .filter((i) => i.speakerId == speakerId)
              .map((i) => i.rating)
          )
          .flat(1);

        return (
          <span>
            {speakerRatings.length > 0 ? AverageNumbers(speakerRatings) : 0}
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    if (events.length > 0) {
      const count = events
        .map((i) => i.evaluationCount)
        .reduce((a, b) => a + b);
      setEvaluationCount(count);

      const eventEvaluations = events
        .map((event) => event.evaluations)
        .flat(1) as Evaluation[];
      const speakerRatings = eventEvaluations
        .map((evaluation) =>
          evaluation?.speakers
            .filter((i) => i.speakerId === speakerId)
            .map((i) => i.rating)
        )
        .flat(1);
      const average = AverageNumbers(speakerRatings);
      setSpeakerAverage(average);
    }
  }, [events]);

  return (
    <ReportStatsWrapper item xs={12}>
      <Item elevation={0}>
        <ReportingSpeakerGridTable
          rowHeight={40}
          size="small"
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          className="reporting_grid"
          getRowId={(event) => event.id}
          rows={events}
          columns={speakerColumns}
          disableRowSelectionOnClick
          localeText={
            current_language === "en"
              ? enUS.components.MuiDataGrid.defaultProps.localeText
              : frFR.components.MuiDataGrid.defaultProps.localeText
          }
          components={{
            Toolbar: () => CustomExportToolbar(category, speakerAverage, current_language),
          }}
        />
      </Item>
    </ReportStatsWrapper>
  );
};

const CustomExportToolbar = (category: ReportingSpeakerTherapeuticArea, rating: number, current_language: string) => {
  if (Number.isNaN(rating)) rating = 0;
  return (
    <GridToolbarContainer
      sx={{
        border: "none",
        width: "100%",
        padding: 0,
      }}
    >
      <SpeakerTableHeader>
        <span>{current_language === "en" ? category.enName : category.frName}</span>
        <span>{rating.toFixed(1) || 0}</span>
      </SpeakerTableHeader>
    </GridToolbarContainer>
  );
};

export default ReportSpeakerStats;
