import { axios } from "./axios";
import { Region } from "../typings/interfaces";
import useSWR from "swr";

export const ENDPOINT = "/regions";

export const getRegions = async () => {
  const { data } = await axios.get(ENDPOINT);
  return data;
};

export function useRegions() {
  const {
    isLoading: isRegionsLoading,
    data,
    error: regionsError,
    mutate,
  } = useSWR(ENDPOINT, getRegions, {
    onSuccess: (data) => data,
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });

  return {
    isRegionsLoading,
    regions: data?.regions.sort((a:any,b:any) => a.value - b.value) as Region[],
    regionsError,
    mutate,
  };
}
