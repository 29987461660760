import { Link, Grid, LinkProps, Menu, MenuItem } from "@mui/material";
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import AppContext from "../../context/Auth";
import { useTranslation } from "react-i18next";
import EventIcon from "@mui/icons-material/Event";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from '@mui/material/Collapse';
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const NavLink = styled(Link)<LinkProps>(({ theme }) => ({
  color: "#3D3D3D",
  margin: "0 10px",
  textDecoration: "none",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  "& .MuiSvgIcon-root": {
    marginRight: "5px",
  },
}));

type Props = {
  mobile: boolean;
}

const NavEventHub = ({mobile}: Props) => {
  const { isAuthenticated, hasRole, logout, hasTool } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openMobileManagement, setOpenMobileManagement] = useState(false);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const mobileManagementClick = () => {
    setOpenMobileManagement(!openMobileManagement);
  };


  if (mobile) {
    return (
      <React.Fragment>
        <Grid container>
        <List
            sx={{ width: '100%', maxWidth: "100%", bgcolor: 'background.paper', pb:2 }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
          {isAuthenticated && hasTool(["event-evaluations"]) && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate("/");
                }}
              >
                <ListItemIcon>
                  <EventIcon />
                </ListItemIcon>
                <ListItemText primary={t("events")} />
              </ListItemButton>
            </ListItem>
          )}

          {isAuthenticated && hasRole(["sudo", "admin", "msl", "rep"]) && hasTool(["event-evaluations"]) && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate("/reporting");
                }}
              >
                <ListItemIcon>
                  <AnalyticsOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={t("event_hub_nav.reporting")} />
              </ListItemButton>
            </ListItem>
          )}

          {isAuthenticated && hasRole(["sudo", "admin"]) && hasTool(["event-evaluations"]) && (
            <>
              <ListItem disablePadding>
                <ListItemButton onClick={mobileManagementClick}>
                  <ListItemIcon>
                    <SettingsOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("event_hub_nav.management")}/>
                  {openMobileManagement ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              </ListItem>
              <Collapse in={openMobileManagement} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton onClick={() => navigate("/therapeutic-areas")} sx={{ pl: 11 }}>
                    <ListItemText primary={t("event_hub_nav.therapeutic_areas")} />
                  </ListItemButton>
                  <ListItemButton onClick={() => navigate("/learning-objectives")} sx={{ pl: 11}}>
                    <ListItemText primary={t("event_hub_nav.learning_objectives")} />
                  </ListItemButton>
                </List>
              </Collapse>
            </>
          )}
          </List>
        </Grid>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Grid container>
          {isAuthenticated && hasTool(["event-evaluations"]) && (
              <NavLink
                onClick={() => {
                  navigate("/");
                }}
              >
                <EventIcon />
                {t("events")}
              </NavLink>
          )}

          {isAuthenticated && hasRole(["sudo", "admin", "msl", "rep"]) && hasTool(["event-evaluations"]) && (
            <NavLink
              sx={{ ml: "3%" }}
              onClick={() => {
                navigate("/reporting");
              }}
            >
              <AnalyticsOutlinedIcon />
              {t("event_hub_nav.reporting")} 
            </NavLink>
          )}

          {isAuthenticated && hasRole(["sudo", "admin"]) && hasTool(["event-evaluations"]) && (
            <>
              <NavLink sx={{ ml: "3%" }} onClick={handleClick}>
                <SettingsOutlinedIcon /> {t("event_hub_nav.management")}
              </NavLink>

              <Menu
                anchorEl={anchorEl}
                id="settings-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      left: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "left", vertical: "top" }}
                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
              >
                <MenuItem onClick={() => navigate("/therapeutic-areas")}> {t("event_hub_nav.therapeutic_areas")}</MenuItem>
                <MenuItem onClick={() => navigate("/learning-objectives")}>{t("event_hub_nav.learning_objectives")}</MenuItem>
              </Menu>
            </>
          )}
        </Grid>
      </React.Fragment>
    );
  }
};

export default NavEventHub;
