import { Alert, Box, Grid } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { formatFormErrors } from "../utils/forms";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { TherapeuticArea } from "../typings/interfaces";
import { FormButton } from "./styled/Buttons/StyledButtons";
import { useTherapeuticAreas } from "../api/therapeuticAreas";

const FormTitle = styled("h1")(({ theme }) => ({
  color: "#3D3D3D",
  textAlign: "center",
  marginTop: 26,
  fontSize: 26,
  fontWeight: "bold",
  textTransform: "uppercase",
}));

type Props = {
  edit: boolean;
  toggleOpen: () => void;
  therapeuticArea: TherapeuticArea;
};

const TherapeuticAreaForm = ({ edit, toggleOpen, therapeuticArea }: Props): JSX.Element => {
  const [displayError, setDisplayError] = useState("");
  const [displaySuccess, setDisplaySuccess] = useState("");
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { mutateTherapeuticAreas, update, create } = useTherapeuticAreas(); //used to refetch the activities after brand info is updated

  const validationSchema =
    !edit
      ? yup.object({
          enName: yup.string().required(t("therapeutic_areas_management.form.enName_error")),
          frName: yup.string().required(t("therapeutic_areas_management.form.frName_error")),
        })
      : yup.object({
        enName: yup.string().required(t("therapeutic_areas_management.form.enName_error")),
        frName: yup.string().required(t("therapeutic_areas_management.form.frName_error")),
        });

  const initialValues =
    !edit
      ? {
          enName: "",
          frName: "",
          color: "#ffffff",
        }
      : {
          enName: therapeuticArea.enName,
          frName: therapeuticArea.frName,
        };

  const createBrand = async (values: any, token: any) => {
    try {
      await create(values);
      setLoading(false);
      setDisplaySuccess(t("therapeutic_areas_management.form.create_success"));
      setDisplayError("");
      setLoading(true);
      setTimeout(() => {
        toggleOpen();
      }, 2000);
    } catch (error: any) {
      if (error.response) {
        const formattedErrors = formatFormErrors(error.response.message);
        formik.setErrors(formattedErrors);
        setDisplaySuccess("");
        setDisplayError(t("therapeutic_areas_management.form.create_error"));
        setLoading(false);
      }
    }
  };
  const editBrand = async (values: any, token: any) => {
    try {
        await update(therapeuticArea._id, values);
        await mutateTherapeuticAreas();
        setDisplaySuccess(t("therapeutic_areas_management.form.edit_success"));
        setDisplayError("");
        setLoading(true);
        setTimeout(() => {
          toggleOpen();
        }, 2000);
    } catch (error: any) {
      if (error.response) {
        console.log("error.response", error.response)
        setDisplaySuccess("");
        setDisplayError(t("therapeutic_areas_management.form.edit_error"));
        setLoading(false);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const token = localStorage.getItem("SavedToken");
      setLoading(true);
      !edit ? createBrand(values, token) : editBrand(values, token);
    },
  });

  return (
    <Box my={2} sx={{ width: "100%" }}>
      <FormTitle sx={{marginBottom:5}}>
        {!edit ? t("therapeutic_areas_management.form.create_title") : t("therapeutic_areas_management.form.edit_title")}
      </FormTitle>
      <div>
        {displayError ? <Alert severity="error">{displayError}</Alert> : ""}
        {displaySuccess ? (
          <Alert sx={{ marginBottom: "20px" }} severity="success">
            {displaySuccess}
          </Alert>
        ) : (
          ""
        )}
      </div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={6}>
            <TextField fullWidth size="medium" id="enName" label={t("therapeutic_areas_management.form.enName_label")} disabled={loading} type="text" variant="outlined" value={formik.values.enName} onChange={formik.handleChange} error={formik.touched.enName && Boolean(formik.errors.enName)} helperText={formik.touched.enName && formik.errors.enName} focused />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth size="medium" id="frName" label={t("therapeutic_areas_management.form.frName_label")} disabled={loading} type="text" variant="outlined" value={formik.values.frName} onChange={formik.handleChange} error={formik.touched.frName && Boolean(formik.errors.frName)} helperText={formik.touched.frName && formik.errors.frName} focused />
          </Grid>
          <Grid item xs={6} style={{ marginTop: 20 }}>
            <FormButton sx={{fontSize:20}} fullWidth variant={"contained"} type={"submit"} disabled={loading}>
              {!edit ? t("activity_management.brand_form.add_btn") : t("activity_management.brand_form.save_btn")}
            </FormButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default TherapeuticAreaForm;
