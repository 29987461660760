import { axios } from "./axios";
import useSWR from "swr";
import { LearningObjective } from "../typings/interfaces";

export const ENDPOINT = "/learningObjectives";

interface CreateEditProps {
  enName: string;
  frName: string;
  therapeuticArea: string;
}

export async function getLearningObjectives() {
  const { data } = await axios.get(ENDPOINT);
  return data.learningObjectives;
}

export const useLearningObjectives = () => {
  const {
    isLoading: isLearningObjectivesLoading,
    error: learningObjectivesError,
    data: learningObjectives,
    mutate: mutateLearningObjectives,
  } = useSWR(ENDPOINT, getLearningObjectives, {
    onSuccess: (data) => data.sort((a:LearningObjective,b:LearningObjective) => Number(b.showInEval) - Number(a.showInEval)),
    revalidateOnFocus: false,
  });

  const create = async ({ enName, frName, therapeuticArea }: CreateEditProps) => {
    const { data } = await axios.post(ENDPOINT, { enName, frName, therapeuticArea });
    mutateLearningObjectives();

    return data;
  };

  const update = async (id: string, { enName, frName, therapeuticArea }: CreateEditProps) => {
    const { data } = await axios.put(`${ENDPOINT}`, { _id: id, enName, frName, therapeuticArea });
    mutateLearningObjectives();

    return data;
  };

  const deleteById = async (id: string) => {
    const { data } = await axios.delete(`${ENDPOINT}/${id}`);
    mutateLearningObjectives();

    return data;
  };

  const data = learningObjectives as LearningObjective[];

  return {
    isLearningObjectivesLoading,
    learningObjectivesError,
    learningObjectives: data,
    mutateLearningObjectives,
    create,
    update,
    deleteById,
  };
};
