import { Box, Toolbar, BoxProps } from "@mui/material";
import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import AppContext from "../context/Auth";
import NavUnauthed from "./navigation/NavUnauthed";
import NavEventHub from "./navigation/NavEventHub";
import NavActivityHub from "./navigation/NavActivityHub";
import NavMobileMenu from "./navigation/NavMobileMenu";

const Header = styled(Box)<BoxProps>(({ theme }) => ({
  color: "#FFFFFF",
}));

type Props = {
  genericLogo?: boolean;
};

const Nav = ({ genericLogo }: Props) => {
  const { isAuthenticated, currentTool } = useContext(AppContext);

  const getNavigation = () => {
    if (!isAuthenticated) {
      return <NavUnauthed genericLogo={genericLogo} />;
    } else if (isAuthenticated && currentTool === "event-evaluations") {
      return (
        <div style={{ width: "100%" }}>
          <div>
            <NavMobileMenu ToolNavigation={NavEventHub} currentTool={currentTool} />
          </div>
        </div>
      );
    } else if (isAuthenticated && currentTool === "activity-calendar") {
      return (
        <div style={{ width: "100%" }}>
          <div>
            <NavMobileMenu ToolNavigation={NavActivityHub} currentTool={currentTool} />
          </div>
        </div>
      );
    } else {
      <></>;
    }
  };

  return (
    <React.Fragment>
      <Header
        sx={{
          backgroundColor: "#fff",
          zIndex: 5,
          position: "relative",
          py: 2,
          boxShadow: 1,
        }}
      >
        <Toolbar>{getNavigation()}</Toolbar>
      </Header>
    </React.Fragment>
  );
};

export default Nav;
