import { styled } from "@mui/material/styles";

const ActivityFormScheduleLabel = styled("span")<{}>(({}) => ({
  marginBottom: "10px",
  fontSize: "16px",
  fontWeight: 400,
  display: "block"
}));

export {
  ActivityFormScheduleLabel
}