import { GridColDef, GridToolbarContainer, GridToolbarExport, enUS, GridFooter } from "@mui/x-data-grid";
import { frFRCustom as frFR } from "../locale/frFR";
import { IconButton } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useTranslation } from "react-i18next";
import { useState, useContext } from "react";
import ReportingDataGridTable from "./styled/ReportingGrid";
import ActivityContext from "../context/ActivityContext";
import BrandForm from "./activityViews/BrandForm";
import DraggableDialog from "./styled/DraggableDialog";
import { useBrands } from "../api/brands";
import { Brand } from "../typings/interfaces";
import Loading from "./Loading";
import muiTheme from "../muiTheme";
import { AddCircle } from "@mui/icons-material";

type Props = {
  updateTable: () => void;
  update: boolean;
  toggleOpen: () => void;
  open: boolean;
};

const initialBrand = { _id: "", enName: "", frName: "", color: "", value: "", status: "" };
const ActivityBrandList = ({ updateTable, update, open, toggleOpen }: Props) => {
  const { t, i18n } = useTranslation();
  const [edit, setEdit] = useState(false);
  const product_brand_label = t("activity_management.products_brands");
  const color_label = t("activity_management.color");
  const edit_label = t("edit");
  const delete_label = t("delete");
  const current_language = i18n.language;
  const [brand, setBrand] = useState<Brand>(initialBrand);
  const { brands, isBrandsLoading } = useContext(ActivityContext);
  const { deleteById } = useBrands();

  const editBrand = (brand: Brand) => {
    const { _id, enName, frName, color, value, status } = brand;
    setEdit(true);
    setBrand({ _id, enName, frName, color, value, status });
    toggleOpen();
  };

  const createBrand = () => {
    setEdit(false);
    setBrand(initialBrand);
    toggleOpen();
  };

  const closeBrandModal = () => {
    createBrand(); 
  }

  const deleteBrand = async (_id: string) => {
    const confirm = window.confirm(t("delete_confirm_message"));

    if (!confirm) {
      return;
    }
    try {
      await deleteById(_id);
    } catch (error: any) {
      if (error.response) {
        console.log("error", error.response);
      }
    }
  };

  const columns: GridColDef[] = [
    {
      field: "brandName",
      headerName: product_brand_label,
      flex: 1,
      minWidth: 200,
      editable: false,
      hideable: false,
      renderCell: (params) => {
        if (current_language === "en") return params.row.enName;
        return params.row.frName;
      },
      valueGetter: (params) => {
        if (current_language === "en") return params.row.enName;
        return params.row.frName;
      },
    },
    {
      field: "color",
      headerName: color_label,
      width: 250,
      editable: false,
      hideable: false,
      renderCell: (params) => {
        const color = params.row.color;
        const styles = {
          backgroundColor: color,
          padding: "5px",
          marginRight: "5px",
          width: "10px",
          height: "10px",
          border: "2px solid #FFFFFF",
          boxShadow: "0 1px 5px 0 rgba(0,0,0,0.25)",
          borderRadius: "4px",
          display: "inline-flex",
        };
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={styles}></span> {params.row.color}
          </div>
        );
      },
    },
    {
      sortable: false,
      editable: false,
      filterable: false,
      hideable: false,
      field: "edit",
      width: 50,
      headerName: "",
      renderHeader: () => (null),
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,

      renderCell: (params) => (
        <strong>
          <IconButton aria-label="edit" color="warning" style={{ marginLeft: 0 }} onClick={() => editBrand(params.row)}>
            <EditOutlinedIcon />
          </IconButton>
        </strong>
      ),
    },
    {
      sortable: false,
      editable: false,
      filterable: false,
      hideable: false,
      field: "delete",
      headerName: "",
      renderHeader: () => (null),
      width: 50,
      headerAlign: "center",
      disableColumnMenu: true,
      align: "center",
      renderCell: (params) => (
        <strong>
          <IconButton
            aria-label="delete"
            color="error"
            style={{ marginLeft: 0 }}
            onClick={() => {
              deleteBrand(params.row._id);
            }}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </strong>
      ),
    },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
        <div>
          <GridToolbarExport
            csvOptions={{
              fields: ["brandName", "color"],
            }}
            printOptions={{
              fields: ["brandName", "color"],
            }}
          />
        </div>
        <div style={{ display: "flex" }}>
          <GridFooter
            sx={{
              border: "none", // To delete double border.
            }}
          />
        </div>
      </GridToolbarContainer>
    );
  };

  if (isBrandsLoading) {
    return <></>;
  } else {
    return (
      <div style={{ height: "100%", width: "100%", marginTop: "20px" }}>
        <ReportingDataGridTable
          getRowId={(row: any) => row._id}
          className="activity_brand_grid"
          pageSizeOptions={[200]}
          disableColumnSelector
          // hideFooterPagination
          rows={brands}
          columns={columns}
          disableRowSelectionOnClick
          localeText={current_language === "en" ? { ...enUS.components.MuiDataGrid.defaultProps.localeText, toolbarExport: "" } : { ...frFR.components.MuiDataGrid.defaultProps.localeText, toolbarExport: "" }}
          components={{
            Footer: CustomToolbar,
          }}
        />
        <DraggableDialog open={open} closeModal={closeBrandModal}>
          <BrandForm edit={edit} toggleOpen={closeBrandModal} brand={brand} updateTable={updateTable} />
        </DraggableDialog>
      </div>
    );
  }
};

export default ActivityBrandList;
