import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { ActivityFormScheduleLabel } from "./styled";

interface Day {
  label: string;
  value: number;
  selected: boolean;
  fullName: string;
}

const DaysOfWeekContainer = styled("div")<{ disabled?: boolean }>(({ disabled, theme }) => ({
  display: "flex",
  opacity: disabled ? 0.6 : 1,
  padding: "5px 0px"
}));

const DaysOfWeekButtons = styled("div")<{ defaultActive?: boolean; active?: boolean }>(({ active, theme }) => ({
  backgroundColor: active ? theme.palette.primary.main : "#efefef",
  textTransform: "uppercase",
  padding: "10px",
  color: active ? "white" : "#000000",
  fontSize: "14px",
  borderRadius: "50%",
  // opacity: active ? 0.6 : 1,
  width: "18px",
  height: "18px",
  textAlign: "center",
  margin: "0px 3px",
  cursor: "pointer",
}));

type Props = {
  handleRecurringInfoChange: () => void;
  date: Date;
  formik: any;
};
const ActivityFormScheduleDaysOfWeek = ({handleRecurringInfoChange, date, formik} : Props) => {
  const { t, i18n } = useTranslation();
  // Initialize state to hold the selected days and their values
  const dayOFWeek = dayjs(date).day();
  
  const [selectedDays, setSelectedDays] = useState<Day[]>(formik.values.weekDay);

  useEffect(() => {
    formik.setFieldValue("weekDay", selectedDays)
    // If no day is selected, set Monday as selected
    const areAnyDaysSelected = selectedDays.some((day) => day.selected);
    if (!areAnyDaysSelected) {
      setSelectedDays((prevSelectedDays) => {
        const updatedDays = prevSelectedDays.map((day) => (day.value === dayOFWeek ? { ...day, selected: true } : day));
        return updatedDays;
      });
    }
  }, [selectedDays]);

  // Function to handle click on a day
  const handleDayClick = (value: number) => {
    handleRecurringInfoChange();
    setSelectedDays((prevSelectedDays) => {
      return prevSelectedDays.map((day) => (day.value === value ? { ...day, selected: !day.selected } : day));
    });
  };

  return (
    <div>
      <ActivityFormScheduleLabel>{t("activity_form.recurring_activity.repeat_on")}</ActivityFormScheduleLabel>
      <DaysOfWeekContainer>
        {selectedDays.map((day, index) => {
          return (
            <Tooltip title={day.fullName} arrow>
              <DaysOfWeekButtons key={day.value} onClick={() => handleDayClick(day.value)} active={day.selected}>
                {day.label}
              </DaysOfWeekButtons>
            </Tooltip>
          );
        })}
      </DaysOfWeekContainer>
    </div>
  );
};
export default ActivityFormScheduleDaysOfWeek;
