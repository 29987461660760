import { styled } from "@mui/material/styles";
import { lighten } from "@mui/material";

const ColumnHeader = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  padding: "8px 12px",
  backgroundColor: lighten(theme.palette.primary.light, 0.525),
  borderBottom: `3px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.dark,
  fontWeight: 900,
  fontSize: "20px",
  borderRadius: "5px 5px 0px 0px",
  textTransform: "uppercase",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  "& .MuiButtonBase-root": {
    backgroundColor:"#ffffff", 
    padding: "4px 10px",
    boxShadow: "0px 1px 1px #9e9e9e",
    marginLeft: "10px",
    borderRadius: "3px",
  } 
}));

export default ColumnHeader;