import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";
import { Button, ButtonProps, ButtonGroup, styled} from "@mui/material";

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: "#e4e4e4",
  color: "#5e5e5e",
  boxShadow: "unset",
  borderRadius: "4px",
  textTransform: "unset",
  "&:hover": {
    boxShadow: "unset",
    color: "#fff",
  },
  '&.Mui-disabled': {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "#fff",
  }
}));

const languages = [
  {
    code: "en",
    name: "EN",
  },
  {
    code: "fr",
    name: "FR",
  },
];

const NavLanguagePicker = () => {
  const currentLanguage = cookies.get("i18next") || "en";
  return (
    <ButtonGroup disableElevation variant="contained" sx={{ border: "1px solid #fff", mt:0 }}>
      {languages.map(({ code, name }) => (
        <CustomButton key={code} onClick={() => i18next.changeLanguage(code)} disabled={code === currentLanguage}>
          {name}
        </CustomButton>
      ))}
    </ButtonGroup>
  );
}

export default NavLanguagePicker;
