import { axios } from "./axios";
import useSWR from "swr";
import { Evaluation } from "../typings/interfaces";

export const ENDPOINT = "/evaluations";

export async function getEvaluations() {
  const token = localStorage.getItem("SavedToken");
  if (token !== null) {
    const { data } = await axios.get(ENDPOINT);
    return data.evaluations;
  }
  return [];
}

export const useEvaluations = () => {
  async function create(eventId: string, attendeeId: string, relevant: number, credible: number, organized: number, timeAllotted: number, interaction: number, learningObjectives: any[], speakers: any[], moderators: any[], conceptLearnedComment: string, otherTopicsComment: string, additionalComment: string, therapeuticAreaID: string, language: string) {
    const { data } = await axios.post(`${ENDPOINT}/event/${eventId}`, {
      attendeeId,
      relevant,
      credible,
      organized,
      timeAllotted,
      interaction,
      learningObjectives,
      speakers,
      moderators,
      conceptLearnedComment,
      otherTopicsComment,
      additionalComment,
      therapeuticAreaID,
      language
    });

    return data;
  }

  const {
    isLoading: isEvaluationsLoading,
    error: evaluationsError,
    data: evaluations,
    mutate,
  } = useSWR(ENDPOINT, getEvaluations, {
    onSuccess: (data) => data,
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnMount: true,
  });

  const data = evaluations as Evaluation[];

  return {
    isEvaluationsLoading,
    evaluationsError,
    evaluations: data,
    create,
  };
};
