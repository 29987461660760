import React, { useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { Paper, IconButton, Menu, MenuItem } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ColumnHeader from "../styled/reportingEvents/ColumnHeader";
import InsertCommentOutlinedIcon from "@mui/icons-material/InsertCommentOutlined";
import ReportEventStatsSection from "./ReportEventStatsSection";
import { EventEvaluation, Event, Attendee, LearningObjective } from "../../typings/interfaces";
import ReportEventStatsSectionDynamic from "./ReportEventStatsSectionDynamic";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";
import { AverageNumbers } from "../../utils/average";
import { eventResultCsvDataWithDetails, eventEvaluationsResultCsvData } from "../../utils/csvBuilder";
import CSVDownloadLink from "../styled/CSVDownloadLink";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import useEventName from "../../hooks/useEventName";

const ReportStatsWrapper = styled(Grid)(({ theme }) => ({
  width: "100%",
  padding: "0px",

  borderRadius: "5px",
  paddingRight: "0px",
}));

const MotionReportStatsWrapper = motion(ReportStatsWrapper);

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fafafa",
  ...theme.typography.body2,
  marginLeft: "0px",
  paddingRight: "0px",
  paddingTop: "0px",
  color: theme.palette.text.secondary,
  height: "100%",
  boxShadow: "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)",
  borderRadius: "5px",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.up('lg')]: {
    marginLeft: theme.spacing(1),
  }
}));

const ReportStatsContent = styled(Grid)(({ theme }) => ({
  backgroundColor: "#ffffff",
  width: "100%",
  padding: "0px",
  height: "100%",
  paddingRight: "0px",
  border: "1px solid #D5D5D5",
  paddingBottom: "0px",
}));


type ReportEventStatsProps = {
  evaluations:EventEvaluation[];
  eventId: string;
  loading: boolean;
  event: Event;
  attendees: Attendee[];
  learningObjectives: LearningObjective[];
}

export const ReportEventStats = ({evaluations, eventId, loading, event, attendees, learningObjectives}:ReportEventStatsProps) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const componentRef = useRef<HTMLDivElement>(null);
  const [wrapperSize, setWrapperSize] = useState<number>(6);
  const [exportAnchor, setExportAnchor] = useState<null | HTMLElement>(null);
  const openExport = Boolean(exportAnchor);
  const [formatEventName] = useEventName();
  const eventName = formatEventName(event);
  const { data } = eventResultCsvDataWithDetails(evaluations, event, eventName, learningObjectives);
  const { dataEval } = eventEvaluationsResultCsvData(evaluations, event, eventName, attendees, learningObjectives);

  const programRatingAvg = AverageNumbers(evaluations.map(i => i.programRating));
  const learningObjectiveAvg = AverageNumbers(evaluations.map(i => i.learningObjectiveRating));
  const presentationAvg = AverageNumbers(evaluations.map(i => i.presentationRating));

  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => Promise.all([setWrapperSize(12)]),
    onBeforePrint: () => setWrapperSize(6),
    content: () => componentRef.current,
    documentTitle: `results_${eventId}_${new Date().getTime()}`
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setExportAnchor(null);
  };

  const goToComments = () => {
    navigate(`/reporting/comments/${event.id}`);
  }

  return (
    <MotionReportStatsWrapper item xs={12} lg={6} ref={componentRef}
      initial={{opacity: 0}}
      animate={{ opacity: loading ? 0 : 1 }}
      transition={{duration: 0.8}}>
      <Item elevation={0} >
        <ColumnHeader>
          <span>{t("reporting.results")} ({evaluations.length})</span>
          <div className="columnHeader_buttons">
            <IconButton disableRipple onClick={goToComments}>
              <InsertCommentOutlinedIcon sx={{ color: (theme) => theme.palette.primary.dark }} />
            </IconButton>
            <IconButton disableRipple onClick={handleClick}>
              <FileDownloadOutlinedIcon sx={{ color: (theme) => theme.palette.primary.dark }} />
            </IconButton>
            <Menu
              id="export-results"
              anchorEl={exportAnchor}
              open={openExport}
              onClose={handleClose}
            >
              <MenuItem disabled={evaluations.length === 0} onClick={() => {handleClose()} }>
                <CSVDownloadLink data={data} filename={`results_${eventId}_${new Date().getTime()}`}>
                {t("download_csv_summary")}
                </CSVDownloadLink>
              </MenuItem>
              <MenuItem disabled={evaluations.length === 0} onClick={() => {handleClose()} }>
                <CSVDownloadLink data={dataEval} filename={`results_${eventId}_${new Date().getTime()}`}>
                {t("download_csv_all_results")}
                </CSVDownloadLink>
              </MenuItem>
              <MenuItem onClick={() => {handleClose();handlePrint();}}>{t("print")}</MenuItem>
            </Menu>
          </div>
        </ColumnHeader>

        <ReportStatsContent>
          <ReportEventStatsSection sectionTitle={t("evaluation.evaluation_form.program_title")} overallRating={programRatingAvg} programEvaluations={evaluations.map(i => i.program)}/>
          <ReportEventStatsSectionDynamic
            sectionTitle={t("create_event_form_learning_objectives")}
            overallRating={learningObjectiveAvg}
            evaluations={evaluations}
            type={{ learningObjective: true, presentation: false }}
            event={event ? event : {} as Event}
            learningObjectives={learningObjectives} />
          <ReportEventStatsSectionDynamic
            sectionTitle={t("evaluation.evaluation_form.presentation_title")}
            overallRating={presentationAvg}
            evaluations={evaluations}
            type={{learningObjective: false, presentation: true}}
            event={event}
            learningObjectives={learningObjectives} />
        </ReportStatsContent>
      </Item>
    </MotionReportStatsWrapper>
  );
};


export default ReportEventStats;
