/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useEffect, useState } from "react";
import { Event, LearningObjective, TherapeuticArea, Users, Region, Speaker, Moderator, Evaluation, EventEvaluation, LanguageOption } from "../typings/interfaces";
import { RangeKeyDict } from "react-date-range";
import { isWithinInterval } from 'date-fns';
import { parseISO } from 'date-fns';
import { useReps } from "../api/reps";
import { useRegions } from "../api/regions";
import { useSpeakers } from "../api/speakers";

import { useEvaluations } from "../api/evaluations";
import { useEvents } from "../api/events";
import { useLearningObjectives } from "../api/learningObjectives";
import { useTherapeuticAreas} from "../api/therapeuticAreas";
import { parseEventStartTime } from "../utils/parseDate";
import { sortDataAlpha } from "../utils/sort";
import i18n, { useTranslation } from 'react-i18next';

interface IReportingContext {
  events: Event[];
  filteredEvents: Event[];
  speakers: Speaker[];
  filteredSpeakers: Speaker[];
  moderators: Moderator[];
  filteredModerators: Moderator[];
  regions: Region[];
  therapeuticAreas: TherapeuticArea[];
  learningObjectives: LearningObjective[];
  filteredLearningObjectives: LearningObjective[];
  languages: LanguageOption[];
  section: string;
  reps: Users[];
  evaluations: Evaluation[];
  filteredEvaluations: Evaluation[];
  selectedEventId: string;
  selectedSpeakerId: string;
  selectedModeratorId: string;
  selectedLearningObjective: string;
  dateRange: any,
  updateSection: (section: string) => void;

  updateSelectedSpeakerId(speakerId: string): void;
  updateSelectedModeratorId(moderatorId: string): void;
  updateSelectedTherapeuticArea(therapeuticArea: string): void;
  updateSelectedLearningObjective(learningObjective: string): void;
  updateSelectedRep(rep: string): void;
  updateSelectedLanguage(language: string): void;
  updateSelectedRegion(region: string): void;
  updateSelectedEventType(eventType: string): void;
  updateSelectedEventId(eventId: string): void;
  updateDateRange(range: RangeKeyDict): void;
  loading: boolean;

  usingFilters: boolean;
}

//Initial App Context
export const ReportingContext = createContext<IReportingContext>({
  events: [],
  filteredEvents: [],
  speakers: [],
  filteredSpeakers:[],
  moderators: [],
  filteredModerators: [],
  regions: [],
  therapeuticAreas: [],
  learningObjectives: [],
  filteredLearningObjectives: [],
  languages: [],
  section: "speakers",
  reps: [],
  evaluations: [],
  filteredEvaluations: [],
  selectedEventId: "",
  selectedSpeakerId: "",
  selectedModeratorId: "",
  selectedLearningObjective: "",
  dateRange: [],
  updateSection: function (): void {},
  updateSelectedSpeakerId: function (): void {},
  updateSelectedModeratorId: function (): void {},
  updateSelectedTherapeuticArea: function (): void {},
  updateSelectedLearningObjective: function (): void {},
  updateSelectedRep: function (): void {},
  updateSelectedLanguage: function (): void {},
  updateSelectedRegion: function (): void {},
  updateSelectedEventType: function (): void {},
  updateSelectedEventId: function (): void {},
  updateDateRange: function (): void {},
  loading: true,
  usingFilters: false
  // updateRoles: function (): void {},
});

interface Props {
  children: React.ReactNode;
}

//Provider for Reporting pages
export const ReportingProvider: React.FC<Props> = ({ children }) => {
  const { t, i18n } = useTranslation();
  const { events, isEventsLoading } = useEvents();
  const [filteredEvents, setFilteredEvents] = useState<Event[]>([]);

  const {regions, isRegionsLoading} = useRegions();
  const {therapeuticAreas, isTherapeuticAreasLoading} = useTherapeuticAreas();

  /* filter data for dropdowns */
  const [moderators, setModerators] = useState<Speaker[]>([]);
  const [filteredModerators, setFilteredModerators] = useState<Moderator[]>([]);

  const { speakers, isSpeakersLoading } = useSpeakers();
  const [filteredSpeakers, setFilteredSpeakers] = useState<Speaker[]>([]);

  // const [therapeuticAreas, setTherapeuticAreas] = useState<TherapeuticArea[]>([]);
  const { learningObjectives, isLearningObjectivesLoading } = useLearningObjectives();
  const [filteredLearningObjectives, setFilteredLearningObjectives] = useState<LearningObjective[]>([]);

  const languages = [
    {
      value: "English",
      name: "English",
      frName: "Anglais"
    },
    {
      value: "French",
      name: "French",
      frName: "Français"
    }
  ];
  const { reps } = useReps();
  const { evaluations, isEvaluationsLoading } = useEvaluations();
  const [filteredEvaluations, setFilteredEvaluations] = useState<Evaluation[]>([]);

  /* Filter dropdown values */
  const [selectedSpeakerId, setSelectedSpeakerId] = useState("");
  const [selectedModeratorId, setSelectedModeratorId] = useState("");
  const [selectedRep, setSelectedRep] = useState("");
  const [selectedTherapeuticArea, setSelectedTherapeuticArea] = useState("");
  const [selectedLearningObjective, setSelectedLearningObjective] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedRegionId, setSelectedRegionId] = useState("");
  const [selectedEventType, setSelectedEventType] = useState("");
  const [selectedEventId, setSelectedEventId] = useState("");

  const [section, setSection] = useState("speakers");

  const [dateRange, setDateRange] = useState<any>({});

  const [loading, setLoading] = useState<boolean>(true);

  const [usingFilters, setUsingFilters] = useState<boolean>(false);

  // set if filters are being used or not 
  useEffect(() => {
    setUsingFilters(selectedSpeakerId !== "" || selectedModeratorId !== "" || selectedRep !== ""
      || selectedTherapeuticArea !== "" || selectedLearningObjective !== "" || selectedLanguage !== ""
      || selectedRegionId !== "" || selectedEventType !== "" || selectedEventId !== "" || dateRange.startDate);
  }, [selectedSpeakerId, selectedModeratorId, selectedRep, selectedTherapeuticArea, selectedLearningObjective,
    selectedLanguage, selectedRegionId, selectedEventType, selectedEventId, dateRange]);


  useEffect(() => {
    if (!isEventsLoading && !isLearningObjectivesLoading && !isSpeakersLoading && !isTherapeuticAreasLoading && !isRegionsLoading && !isEvaluationsLoading) {
      setLoading(true);
      setFilteredEvents(events);
      setLoading(false);
    }
  }, [events, isEventsLoading, isLearningObjectivesLoading, isSpeakersLoading, isTherapeuticAreasLoading, isRegionsLoading, isEvaluationsLoading])

  // Fetch all the regions

  // reset all filters on reporting tab change
  useEffect(() => {
    if (section !== "comments") {
      setSelectedEventId("");
    }
    setDateRange({});
    setSelectedSpeakerId("");
    setSelectedModeratorId("");
    setSelectedRep("");
    setSelectedTherapeuticArea("");
    setSelectedLanguage("");
    setSelectedRegionId("");
    setSelectedEventType("");
    setSelectedLearningObjective("");
  }, [section])

  useEffect(() => {
    if (!isSpeakersLoading) {
      setFilteredSpeakers(speakers);

      const moderators: Moderator[] = speakers.filter((speaker) => speaker.hasBeenModerator === true);
      setModerators(moderators);
      setFilteredModerators(moderators);
    }
  }, [speakers, isSpeakersLoading])

  //depending on the section we are goign to provide a different set of data and columns to the mui-x date grid
  const updateSection = (section: string) => {
    setSection(section);
  };

  const updateSelectedSpeakerId = (speakerId: string) => {
    setSelectedSpeakerId(speakerId);
  };

  const updateSelectedModeratorId = (moderatorId: string) => {
    setSelectedModeratorId(moderatorId);
  };

  const updateSelectedTherapeuticArea = (therapeuticArea: string) => {
    setSelectedTherapeuticArea(therapeuticArea);
  };

  const updateSelectedLearningObjective = (learningObjective: string) => {
    setSelectedLearningObjective(learningObjective);
  };

  const updateSelectedRep = (rep: string) => {
    setSelectedRep(rep);
  };

  const updateSelectedLanguage = (language: string) => {
    setSelectedLanguage(language);
  };

  const updateSelectedRegion = (region: string) => {
    setSelectedRegionId(region);
  };

  const updateSelectedEventType = (eventType: string) => {
    setSelectedEventType(eventType);
  };

  const updateSelectedEventId = (eventId: string) => {
    setSelectedEventId(eventId);
  };

  const updateDateRange = (ranges: RangeKeyDict) => {
    const { selection } = ranges;
    if (selection) {
      setDateRange({ ...selection });
    } else {
      setDateRange({}); //set to empty if cleared
    }
  };


  const filterDatesEvents = function(event: Event) {
    const eventStartTime = parseEventStartTime(event.start_time);
    const startRange = dateRange.startDate;
    const endRange = dateRange.endDate;
    let isBetween = true; //default to true to show all events

    if(startRange && endRange){
      isBetween = isWithinInterval(
        eventStartTime,
        { start: startRange, end: endRange }
      )
    }
    // console.log("event:", event, "isBetween:", isBetween, "start", startRange, "end", endRange)
    return isBetween;
  }

  const filterDatesEvaluations = function(evaluation: Evaluation) {
    const eventStartTime = new Date(evaluation.eventStartTime);
    const startRange = dateRange.startDate;
    const endRange = dateRange.endDate;
    let isBetween = true; //default to true to show all events

    if(startRange && endRange){
      isBetween = isWithinInterval(
        eventStartTime,
        { start: startRange, end: endRange }
      )
    }
    // console.log("evaluation:", evaluation, "isBetween:", isBetween, "start", startRange, "end", endRange)
    return isBetween;
  }


  /**
   * Applys all filtering for event related data
   *
   * @param {events} An array of events: ReportingEvent[].
   * @return {events} An array of filtered events: ReportingEvent[].
  */
  const applyAllEventFilters = (events: Event[]) => {

    const allFilteredEvents = events
    .filter((event) => (dateRange ? filterDatesEvents(event) : event))
    .filter((event) => (selectedEventId ? event.id === selectedEventId : event))
    .filter((event) => (selectedRegionId ? event.regionId === selectedRegionId : event))
    .filter((event) => (selectedTherapeuticArea ? event.therapeuticArea === selectedTherapeuticArea : event))
    .filter((event) => (selectedLanguage ? event.language.toLowerCase() === selectedLanguage.toLowerCase() : event))
    .filter((event) => (selectedEventType ? event.eventType === selectedEventType : event))
    .filter((event) => (selectedRep ? event.repId === selectedRep : event)
    )
    setFilteredEvents(allFilteredEvents)
  }

    /**
   * Applys all filtering for evaluations data
   *
   * @param {evaluations} An array of evaluations: Evaluation[].
   * @return {evaluations} An array of filtered evaluations: Evaluation[].
  */
  const applyAllEvaluationFilters = (evaluations: Evaluation[]) => {
      const allFilteredEvaluations = evaluations
      .filter((evaluation) => (dateRange ? filterDatesEvaluations(evaluation) : evaluation))
      .filter((evaluation) => (selectedEventId ? evaluation.eventId === selectedEventId : evaluation))
      .filter((evaluation) => (selectedRegionId ? evaluation.regionId === selectedRegionId : evaluation))
      .filter((evaluation) => (selectedTherapeuticArea ? evaluation.therapeuticArea === selectedTherapeuticArea : evaluation))
      .filter((evaluation) => (selectedLanguage ? evaluation.language.toLowerCase() === selectedLanguage.toLowerCase() : evaluation)
      )
      setFilteredEvaluations(allFilteredEvaluations)
  }


  //use Effect for Filters!
  useEffect(() => {
    if(section === "comments" && !isEvaluationsLoading){
      applyAllEvaluationFilters(evaluations);
    } else {
      if(!isEventsLoading)
        applyAllEventFilters(events);
    }
  }, [selectedModeratorId, selectedRep, selectedLearningObjective, selectedTherapeuticArea, selectedLanguage, selectedRegionId, selectedEventType]);


  useEffect(() => {
    if (!isEvaluationsLoading) {
      applyAllEvaluationFilters(evaluations);
    }
    if(!isEvaluationsLoading && !isEventsLoading)
      applyAllEventFilters(events);
  }, [selectedEventId, dateRange]);

  useEffect(() => {
    if (!isEvaluationsLoading) {
      applyAllEvaluationFilters(evaluations);
    }
  }, [evaluations, isEvaluationsLoading])

  //specific speaker filter
  useEffect(() => {

   /**
   * Filters all speakers by the state selectedSpeakerId
   * @return {speakers} An array of filtered speakers: Speaker[].
   */
  const filterSpeakersBySpeakerId = () => {
    if(selectedSpeakerId && !isSpeakersLoading) {
      const speakersFiltered = speakers.filter((speaker) => {
        if (speaker.speakerId === selectedSpeakerId) {
          return true;
        }
        return false
      });
      setFilteredSpeakers(speakersFiltered);
      return speakersFiltered;
    }
    setFilteredSpeakers(speakers);
    return speakers;
  };

    if(!isSpeakersLoading && speakers) filterSpeakersBySpeakerId();
  }, [selectedSpeakerId]);


  useEffect(() => {
  /**
   * Filters all speakers by the state selectedSpeakerId
   * @return {speakers} An array of filtered speakers: Speaker[].
   */
  const filterModeratorsById = () => {
    if(selectedModeratorId && !isSpeakersLoading) {
      const moderatorsFiltered = moderators.filter((moderator) => {
        if (moderator.speakerId === selectedModeratorId) {
          return true;
        }
        return false
      });
      setFilteredModerators(moderatorsFiltered);
      return moderatorsFiltered;
    }
    setFilteredModerators(moderators);
    return moderators;
  };
  filterModeratorsById();

  }, [selectedModeratorId, moderators]); //ensure speakers are loaded and moderators are set


  const getLearningObjectiveTherapeuticAreaName = (learningObjectives: LearningObjective[]) => {
    if(therapeuticAreas && therapeuticAreas.length > 0 && learningObjectives && learningObjectives.length > 0) {
      learningObjectives.forEach((lo) => {
        const ta = therapeuticAreas.find((ta) => ta.value === lo.therapeuticArea)
        if(ta) {
          lo.therapeuticAreaEnName = ta.enName;
          lo.therapeuticAreaFrName = ta.frName;
        }
      })
    }
    return learningObjectives;
  }

  //specific Learning Objective filter
  useEffect(() => {

   /**
   * Filters learningObjectives based on a learningObjective._id
   * @return {learningObjective} An array of filtered learningObjectives: ReportingLearningObjectives[].
   */
  const filterLearningObjectivesByLearningObjectivesId = () => {
    let learningObjectivesWithTaName: LearningObjective[] = learningObjectives;
    if(selectedLearningObjective && !isLearningObjectivesLoading && !isTherapeuticAreasLoading) {
      const learningObjectivesFiltered = learningObjectives.filter((learningObjective) => {
        if (learningObjective._id === selectedLearningObjective) {
          return true;
        }
        return false
      });
      learningObjectivesWithTaName = getLearningObjectiveTherapeuticAreaName(learningObjectivesFiltered);
      setFilteredLearningObjectives(learningObjectivesWithTaName);
      return learningObjectivesWithTaName;
    }
    if(therapeuticAreas && therapeuticAreas.length > 0 && learningObjectives && learningObjectives.length > 0) {
      learningObjectivesWithTaName = getLearningObjectiveTherapeuticAreaName(learningObjectives);
    }

    setFilteredLearningObjectives(isLearningObjectivesLoading? [] : learningObjectivesWithTaName); //reset to all if learning objective is not selected
    return learningObjectivesWithTaName;
  };

    filterLearningObjectivesByLearningObjectivesId();
  }, [selectedLearningObjective, isLearningObjectivesLoading, isTherapeuticAreasLoading]);


  // useEffect(() => {
  //   setError("");
  // }, []);


  return (
    <ReportingContext.Provider
      value={{
        events,
        filteredEvents,
        speakers,
        filteredSpeakers,
        moderators,
        filteredModerators,
        regions,
        therapeuticAreas: i18n.language === "en" ? sortDataAlpha(therapeuticAreas, "enName") as TherapeuticArea[] : sortDataAlpha(therapeuticAreas, "frName") as TherapeuticArea[],
        learningObjectives: i18n.language === "en" ? sortDataAlpha(learningObjectives, "enName") as LearningObjective[] : sortDataAlpha(learningObjectives, "frName") as LearningObjective[],
        filteredLearningObjectives: i18n.language === "en" ? sortDataAlpha(filteredLearningObjectives, "enName") as LearningObjective[] : sortDataAlpha(filteredLearningObjectives, "frName") as LearningObjective[],
        languages,
        reps,
        evaluations,
        filteredEvaluations,
        section,
        selectedEventId,
        selectedSpeakerId,
        selectedModeratorId,
        selectedLearningObjective,
        dateRange,
        updateSection,
        updateSelectedSpeakerId,
        updateSelectedModeratorId,
        updateSelectedTherapeuticArea,
        updateSelectedLearningObjective,
        updateSelectedRep,
        updateSelectedLanguage,
        updateSelectedRegion,
        updateSelectedEventType,
        updateSelectedEventId,
        updateDateRange,
        loading,
        usingFilters
      }}
    >
      {children}
    </ReportingContext.Provider>
  );
};

export default ReportingContext;
