import { Speaker } from "../typings/interfaces";

const getSpeakersByIds = (speakerIds: string[], speakers: Speaker[]) => {
  const speakerNames = speakerIds
    .map((speakerId: string) => (speakers.find((speaker) => speaker.speakerId === speakerId) || {}).displayName)
    .filter(Boolean)
    .join(", ");

  return speakerNames ? speakerNames : "N/A";
};

const getEventSpeakerNames = (speakers: any) => {
  const speakerNames = speakers.map((speaker: any) => speaker.name).join(", ");
  return speakerNames ? speakerNames : "N/A";
};

export { getSpeakersByIds, getEventSpeakerNames };
