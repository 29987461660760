import { useState, useContext, useEffect } from "react";
import * as yup from "yup";
import { useFormik, setNestedObjectValues, FormikTouched, FormikValues } from "formik";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Alert, Autocomplete, AutocompleteChangeReason, Box, ButtonBase, FormControl, FormLabel, Grid, Paper, Popper, InputLabel, FormHelperText, Stack, Chip, RadioGroup, Radio } from "@mui/material";
import { format, addMinutes, addHours, getDate } from "date-fns";
import { formatFormErrors } from "../../../utils/forms";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Activity, RecurringActivity, Brand, Province, Segmentation, ActivityFormCreate, RecurringActivityFormCreate, Monthly } from "../../../typings/interfaces";
import { styled } from "@mui/material/styles";
import InstructionsPopover from "../../styled/InstructionsPopover";
import ActivityContext from "../../../context/ActivityContext";
import { useActivities } from "../../../api/activities";
import { useRecurringActivities } from "../../../api/activitiesRecurring";
import { StyledDatePicker } from "../../styled/Inputs/StyledDatePicker";
import type { DatePickerProps, RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import { Event as EventIcon } from "@mui/icons-material";
import { GetTimeDisplayFormat, GetTimeInputFieldFormat } from "../../../utils/timeFormat";
import JobsTitles from "../../../utils/jobTitles";
import { GetSegmentationsInOrder } from "../../../utils/segmentations";
import { provinceList } from "../../../utils/provinces";
import ActivityFormSchedule from "./ActivityFormSchedule";
import { getDayOfWeekName, updateWeeklyFromArray } from "../../../utils/activities";

const NextButton = styled(ButtonBase)<{ disabled?: boolean }>(({ disabled, theme }) => ({
  backgroundColor: theme.palette.primary.main,
  textTransform: "uppercase",
  padding: "14px 60px 14px 60px",
  color: "white",
  fontSize: 18,
  borderRadius: "5px",
  opacity: disabled ? 0.6 : 1,
}));

const ModalTitle = styled("h1")(({ theme }) => ({
  color: "#3D3D3D",
  textAlign: "center",
  marginTop: 24,
  fontSize: 24,
  fontWeight: "bold",
  textTransform: "uppercase",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  color: "muiTheme.palette.primary.main",
  width: "100%",
}));

type Props = {
  date?: Date;
  edit?: boolean;
  duplicate?: boolean;
  activity?: Activity;
  closeModal: () => void;
};

type InitialValuesType = {
  activityTypeId: string;
  activityTypeName: string;
  provinces: string[];
  brandIds: string[];
  segmentationIds: string[];
  specialties: string[];
  startTime: Date;
  endTime: Date;
  notes: string;
  repeat_every?: number; // Add repeat_every as an optional property
  repeat_frequency?: string;
  recurringEndDateType?: string;
  occurrences?: number;
  endScheduleDate?: Date;
  weekDay?: {};
  monthly?: Monthly;
  submitType: string;
};

const ActivityForm = ({ date, edit = false, duplicate, activity, closeModal }: Props): JSX.Element => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [displayError, setDisplayError] = useState("");
  const [displaySuccess, setDisplaySuccess] = useState("");

  const [created, setCreated] = useState(false);
  const { brands, activityTypes, segmentations, setSelectedBrandIDs, setSelectedProvinces, setSelectedActivityTypeIDs } = useContext(ActivityContext);
  const { createActivity, editActivity, mutateActivities } = useActivities();
  const { createRecurringActivity, editRecurringActivity, editRecurringActivityOccurrence, editRecurringActivityFromDate } = useRecurringActivities();
  const provinceListWithAll = [{ name: "All", frName: "Tout", value: "all", abbreviation: "All" }, ...provinceList];
  const [isRecurring, setIsRecurring] = useState(activity?.recurring ? true : false);
  const [showRecurringSaveOptions, setShowRecurringSaveOptions] = useState(false);
  const [saveOption, setSaveOption] = useState("");
  const [isEditedRecurringInfo, setIsEditedRecurringInfo] = useState<boolean>(false);
  const [isEditedStartTime, setIsEditedStartTime] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleRecurringInfoChange = () => {
    if(!isEditedRecurringInfo) {
      setIsEditedRecurringInfo(true);
    }
  }

  const handleValidationAndShowRecurringOptions = async () => {
    const validation = await formik.validateForm();
    const errors = await formik.validateForm();
      if (Object.keys(errors).length === 0) {
        setShowRecurringSaveOptions(true)
      } else {
        formik.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(errors, true));
      }
  }

  const getNextButton = () => {
        
    const handleClickSave = () => {
      formikSubmitRecurring(saveOption);
    };

    if (edit) {
      if (isRecurring && activity?.recurring) {
        return (
          <NextButton onClick={handleValidationAndShowRecurringOptions} sx={{ marginTop: "30px" }}>
            {t("activity_form.save_btn")}
          </NextButton>
        );
      }
      return (
        <NextButton type="submit" sx={{ marginTop: "30px" }}>
          {t("activity_form.save_btn")}
        </NextButton>
      );
    } else {
      return (
        <NextButton type="submit" sx={{ marginTop: "30px" }}>
          {edit ? t("activity_form.save_btn") : t("activity_form.add_btn")}
        </NextButton>
      );
    }
  };

  const geRecurringEventSubmitButtons = () => {

    const handleChangeSaveOption = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSaveOption((event.target as HTMLInputElement).value);
    };
    
    const handleClickSave = () => {
      formikSubmitRecurring(saveOption);
    };
  
    return (
      <>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label" style={{color: "#000000"}}>{t("activity_form.recurring_activity.edit_is_recurring")}</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="this"
            name="radio-buttons-group"
            onChange={handleChangeSaveOption}
            value={saveOption}
          >
            {!isEditedRecurringInfo && <FormControlLabel disabled={loading} value="this" control={<Radio />} label={t("activity_form.recurring_activity.save_options.this")} /> }
            <FormControlLabel disabled={loading} value="future" control={<Radio />} label={t("activity_form.recurring_activity.save_options.future")} />
            {!isEditedStartTime && <FormControlLabel disabled={loading} value="all" control={<Radio />} label={t("activity_form.recurring_activity.save_options.all")} /> }
          </RadioGroup>
        </FormControl>
        <div style={{margin: "auto"}}>
          <NextButton disabled={loading || !saveOption} onClick={handleClickSave} sx={{ marginTop: "30px" }}>
            {t("activity_form.save_btn")}
          </NextButton>
        </div>
      </>
    );
  };

  const formikSubmitRecurring = (submitType: string) => {
    formik.setFieldValue("submitType", submitType);
    formik.handleSubmit();
  }

  let initialValidationSchema = yup.object({
    activityTypeId: yup.string().required(t("activity_form.activityType_error")),
    activityTypeName: yup.string(),
    provinces: yup.array().min(1, t("activity_form.province_error")),
    brandIds: yup.array().min(1, t("activity_form.brand_error")),
    startTime: yup.date().required(t("activity_form.startTime_error")),
    endTime: yup
      .date()
      .when("activityTypeName", {
        is: (activityTypeName: string) => {
          return activityTypeName !== "email" ? true : false;
        },
        then: yup.date().min(yup.ref("startTime"), t("instructions.activity_form.end_date_error")).required("End Date/Time is required"),
      })
      .nullable(),
    notes: yup.string().max(500, t("activity_form.notes_max_char_error")),
    specialties: yup.array().min(1, t("activity_form.specialty_required")).required(t("activity_form.specialty_required")),
  });

  const defaultWeekdays = [
    { label: `${t("activity_form.recurring_activity.repeat_frequency_weekly_options.sunday")}`, selected: false, value: 0, fullName: t("recurring_activity_info_card.weekdays.sunday") },
    { label: `${t("activity_form.recurring_activity.repeat_frequency_weekly_options.monday")}`, selected: false, value: 1, fullName: t("recurring_activity_info_card.weekdays.monday") },
    { label: `${t("activity_form.recurring_activity.repeat_frequency_weekly_options.tuesday")}`, selected: false, value: 2, fullName: t("recurring_activity_info_card.weekdays.tuesday") },
    { label: `${t("activity_form.recurring_activity.repeat_frequency_weekly_options.wednesday")}`, selected: false, value: 3, fullName: t("recurring_activity_info_card.weekdays.wednesday") },
    { label: `${t("activity_form.recurring_activity.repeat_frequency_weekly_options.thursday")}`, selected: false, value: 4, fullName: t("recurring_activity_info_card.weekdays.thursday") },
    { label: `${t("activity_form.recurring_activity.repeat_frequency_weekly_options.friday")}`, selected: false, value: 5, fullName: t("recurring_activity_info_card.weekdays.friday") },
    { label: `${t("activity_form.recurring_activity.repeat_frequency_weekly_options.saturday")}`, selected: false, value: 6, fullName: t("recurring_activity_info_card.weekdays.saturday") },
  ]

  let initialValuesObject: InitialValuesType =
    (edit || duplicate) && activity
      ? {
          activityTypeId: activity.activityTypeId,
          activityTypeName: activity.activityType[0].value,
          provinces: activity.provinces,
          brandIds: activity.brandIds,
          segmentationIds: activity.segmentationIds,
          specialties: activity.specialties,
          startTime: activity.startTime ? new Date(activity.startTime) : new Date(new Date().getTime() + 30 * 60000),
          endTime: activity.endTime ? new Date(activity.endTime) : new Date(new Date().getTime() + 30 * 60000),
          notes: activity.notes,
          recurringEndDateType: "never",
          repeat_every: 1, // Add default value for recurrenceRule
          repeat_frequency: "day",
          endScheduleDate: date ? date : new Date(),
          occurrences: 0,
          submitType: "all",
          weekDay: defaultWeekdays,
          monthly: {
            dayOfTheMonth: getDate(new Date(activity.startTime)),
            weekDay: "monday",
            first: false,
            second: false,
            third: false,
            fourth: false,
            last: false,
          },
        }
      : {
          activityTypeId: "",
          activityTypeName: "",
          provinces: [],
          brandIds: [] as string[],
          segmentationIds: [],
          specialties: [],
          startTime: date ? date : new Date(new Date().getTime() + 30 * 60000),
          endTime: date ? addHours(date, 1) : new Date(new Date().getTime() + 30 * 60000),
          notes: "",
          recurringEndDateType: "never",
          repeat_every: 1, // Add default value for recurrenceRule
          repeat_frequency: "day",
          endScheduleDate: date ? date : new Date(),
          occurrences: 0,
          weekDay: defaultWeekdays,
          monthly: {
            dayOfTheMonth: date ? getDate(date) : 0,
            weekDay: "monday",
            first: false,
            second: false,
            third: false,
            fourth: false,
            last: false,
          },
          submitType: "all"
        };

  if (activity?.recurring && activity?.recurringInfo) {
    initialValuesObject.repeat_every = activity.recurringInfo.occurenceInterval;
    initialValuesObject.repeat_frequency = activity.recurringInfo.occurenceFrequency;
    initialValuesObject.recurringEndDateType = activity.recurringInfo.end.never ? "never" : activity.recurringInfo.end.occurences !== 0 ? "after" : "on";
    initialValuesObject.occurrences = activity.recurringInfo.end.occurences;
    initialValuesObject.endScheduleDate = activity.recurringInfo.end.endDate ? new Date(activity.recurringInfo.end.endDate) : activity.endTime ? new Date(activity.endTime) : new Date(activity.startTime);

    if (activity?.recurringInfo.monthly) {
      initialValuesObject.monthly = activity.recurringInfo.monthly;
    }
    if (activity?.recurringInfo.weekly) {
      const { sunday, monday, tuesday, wednesday, thursday, friday, saturday } = activity?.recurringInfo.weekly;
      initialValuesObject.weekDay = [
        { label: `${t("activity_form.recurring_activity.repeat_frequency_weekly_options.sunday")}`, selected: sunday, value: 0, fullName: t("recurring_activity_info_card.weekdays.sunday") },
        { label: `${t("activity_form.recurring_activity.repeat_frequency_weekly_options.monday")}`, selected: monday, value: 1, fullName: t("recurring_activity_info_card.weekdays.monday") },
        { label: `${t("activity_form.recurring_activity.repeat_frequency_weekly_options.tuesday")}`, selected: tuesday, value: 2, fullName: t("recurring_activity_info_card.weekdays.tuesday") },
        { label: `${t("activity_form.recurring_activity.repeat_frequency_weekly_options.wednesday")}`, selected: wednesday, value: 3, fullName: t("recurring_activity_info_card.weekdays.wednesday") },
        { label: `${t("activity_form.recurring_activity.repeat_frequency_weekly_options.thursday")}`, selected: thursday, value: 4, fullName: t("recurring_activity_info_card.weekdays.thursday") },
        { label: `${t("activity_form.recurring_activity.repeat_frequency_weekly_options.friday")}`, selected: friday, value: 5, fullName: t("recurring_activity_info_card.weekdays.friday") },
        { label: `${t("activity_form.recurring_activity.repeat_frequency_weekly_options.saturday")}`, selected: saturday, value: 6, fullName: t("recurring_activity_info_card.weekdays.saturday") },
      ];
    }
  }

  const [validationSchema, setValidationSchema] = useState<any>(initialValidationSchema);

  const formik = useFormik({
    initialValues: initialValuesObject,
    validationSchema: validationSchema,
    // enableReinitialize: true,
    onSubmit: async (values) => {
      edit ? editSubmit(values) : createSubmit(values);
    },
  });

  const validationRecurring = yup.object({
    ...initialValidationSchema.fields,
    recurringEndDateType: yup.string().required("Required field"),
    repeat_every: yup.number().min(1).required("Required field"),
    endScheduleDate: yup
      .date()
      .when("recurringEndDateType", {
        is: (recurringEndDateType: string) => {
          return recurringEndDateType === "on" ? true : false;
        },
        then: yup.date().required("End Date/Time is required"),
      })
      .nullable(),
    occurrences: yup
      .number()
      .when("recurringEndDateType", {
        is: (recurringEndDateType: string) => {
          return recurringEndDateType === "after" ? true : false;
        },
        then: yup.number().min(1).required("Required field"),
      })
      .nullable(),
  });

  useEffect(() => {
    if (isRecurring) {
      // Recreate the validation schema with additional fields
      const newValidationSchema = validationRecurring;
      setValidationSchema(newValidationSchema);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecurring]);

  const editSubmit = async (values: any) => {
    try {
      setDisplayError("");
      setLoading(true);

      const data = formatDataForSubmit(values, true, activity);

      //if this was a standard activity that is now set to recurring
      if(!activity?.recurring && isRecurring ){
        data.activityId = activity?.id;
      }

      if (isRecurring) {
        if(values.submitType === "all") {
          if(!activity?.recurring){
            await createRecurringActivity(data);
            mutateActivities();
          } else {
            await editRecurringActivity(data);
          }
        } else if(values.submitType === "this" && activity?.occurence) {
          await editRecurringActivityOccurrence(data, activity.occurence);
        } else {
          await editRecurringActivityFromDate(data);
        }

      } else {
        await editActivity(data);
      }

      setDisplaySuccess(t("activity_form.update_success_msg"));

      setTimeout(() => {
        closeModal();
        setLoading(false);
        setCreated(true);
      }, 3000);
    } catch (error: any) {
      if (error.response) {
        console.log(error.response);
        if (error.response.data.errorsValidation) {
          const formattedErrors = formatFormErrors(error.response.data.errorsValidation);
          formik.setErrors(formattedErrors);
          return;
        }
        setLoading(false);
      }
      setDisplayError(error.response.data.message);
    }
  };

  const createSubmit = async (values: any) => {
    try {
      setDisplayError("");
      setLoading(true);
      const data = formatDataForSubmit(values, false);

      if (isRecurring) {
        await createRecurringActivity(data);
      } else {
        await createActivity(data);
      }

      setDisplaySuccess(t("activity_form.create_success_msg"));
      setLoading(false);
      setCreated(true);
      closeModal();
      setSelectedActivityTypeIDs([]);
      setSelectedBrandIDs([]);
      setSelectedProvinces([]);
    } catch (error: any) {
      if (error.response) {
        if (error.response.data.errorsValidation) {
          const formattedErrors = formatFormErrors(error.response.data.errorsValidation);
          formik.setErrors(formattedErrors);
          return;
        }
        setLoading(false);
      }
      setDisplayError(t("create_forms_request_fail"));
    }
  };

  const formatDataForSubmit = (values: any, isEdit: boolean, activity?: any) => {
    // If this is for an email activity type, adjust endTime for consistency
    const endTime = values.activityTypeName === "email" ? addMinutes(values.startTime, 1) : values.endTime;

    // Create the activityInfo object
    const activityInfo: any = {
      activityTypeId: values.activityTypeId,
      provinces: values.provinces,
      brandIds: values.brandIds,
      segmentationIds: values.segmentationIds,
      specialties: values.specialties,
      startTime: format(values.startTime, "yyyy-MM-dd HH:mm"),
      endTime: format(endTime, "yyyy-MM-dd HH:mm"),
      notes: values.notes,
    };

    // Initialize data object
    let data = {} as RecurringActivityFormCreate | ActivityFormCreate;

    // If the activity is recurring
    if (isRecurring) {
      // Populate data with recurring activity details
      data = {
        activityInfo: { ...activityInfo },
        occurenceInterval: values.repeat_every,
        occurenceFrequency: values.repeat_frequency,
        end: {
          endDate: undefined,
          never: false,
          occurences: 0,
        },
      };

      if (values.recurringEndDateType === "never") {
        data.end.never = true;
      } else if (values.recurringEndDateType === "on") {
        data.end.endDate = format(values.endScheduleDate, "yyyy-MM-dd HH:mm");
      } else {
        data.end.occurences = values.occurrences;
      }

      data.date = data.activityInfo.startTime;

      // Add monthly or weekly details based on repeat_frequency
      if (values.repeat_frequency === "month") {
        data.monthly = values.monthly;
      } else if (values.repeat_frequency === "week") {
        const dayOfWeek = updateWeeklyFromArray(values.weekDay);
        data.weekly = { ...dayOfWeek };
      }
    } else {
      // If not recurring, populate data with activityInfo only
      data = { ...activityInfo };
    }

    if (isEdit && activity) {
      data.id = isRecurring ? activity.parentId : activity.id;
    }

    return data;
  };

  function handleActivityTypeChange(event: any) {
    if (event.target.value) {
      formik.setFieldValue("activityTypeId", event.target.value);
      const foundActivity = activityTypes.find((activity) => activity._id === event.target.value);
      if (foundActivity) {
        formik.setFieldValue("activityTypeName", foundActivity.value);
      } else {
        formik.setFieldValue("activityTypeName", "");
      }
    }
  }

  const onChangeDate = async (field: "startTime" | "endTime", value: Date | null) => {
    if(field === "startTime" && isRecurring && edit) {
      setIsEditedStartTime(true);
    }
    if (value) {
      formik.setFieldValue(field, value);
    } else {
      formik.setFieldValue(field, "");
    }
  };

  const onOk = (field: "startTime" | "endTime", value: DatePickerProps["value"] | RangePickerProps["value"]) => {
    if (value) {
      formik.setFieldValue(field, new Date(value.toString()));
    }
  };

  function DatePickerHasError(field: string) {
    switch (field) {
      case "startTime":
        return Boolean(formik.errors.startTime) && formik.touched.startTime;
      case "endTime":
        return Boolean(formik.errors.endTime) && formik.touched.endTime;
    }
  }

  const handleBrandsSelect = (reason: AutocompleteChangeReason, value: any, field: string) => {
    if (reason === "selectOption" || reason === "removeOption") {
      formik.setFieldValue(
        field,
        value.map((i: Brand) => i._id)
      );
    } else {
      formik.setFieldValue(field, []);
    }
  };

  const handleProvinceSelect = (reason: AutocompleteChangeReason, value: Province[], field: string, details: any) => {
    if (reason === "selectOption" || reason === "removeOption") {
      const allSelected = details.option.value === "all";

      if (!allSelected) {
        value = value.filter((province) => province.value !== "all");
      }

      if (allSelected && reason === "selectOption") {
        formik.setFieldValue(
          field,
          provinceListWithAll.map((province) => province.value)
        );
      } else if (allSelected && reason === "removeOption") {
        formik.setFieldValue(field, []);
      } else {
        formik.setFieldValue(
          field,
          value.map((i: Province) => i.value)
        );
      }
    } else {
      formik.setFieldValue(field, []);
    }
  };

  const handleSpecialtySelect = (reason: AutocompleteChangeReason, value: any, field: string) => {
    if (reason === "selectOption" || reason === "removeOption") {
      formik.setFieldValue(
        field,
        value.map((i: any) => i.value)
      );
    } else {
      formik.setFieldValue(field, []);
    }
  };

  const handleSegmentationSelect = (reason: AutocompleteChangeReason, value: any, field: string) => {
    if (reason === "selectOption" || reason === "removeOption") {
      formik.setFieldValue(
        field,
        value.map((i: Segmentation) => i._id)
      );
    } else {
      formik.setFieldValue(field, []);
    }
  };

  const handleProvinceChipDelete = (province: Province) => {
    //remove delete chip and all option since all is no longer selected
    const newValues = formik.values.provinces.filter((provinceText: string) => provinceText !== province.value && provinceText !== "all");
    formik.setFieldValue("provinces", newValues);
  };

  const toggleIsRecurring = () => {
    setIsRecurring(!isRecurring);
  };

  return (
    <>
      <InstructionsPopover title={t("instructions.activity_form.title")} isModal={true} width="320px">
        <>
          <p>{t("instructions.activity_form.content_1")}</p>
        </>
      </InstructionsPopover>

      <Box my={0}>
        <ModalTitle>{!edit ? t("activity_form.create_title") : t("activity_form.edit_title")}</ModalTitle>
        <div>
          {displayError ? <Alert severity="error">{displayError}</Alert> : ""}
          {displaySuccess ? <Alert severity="success">{displaySuccess}</Alert> : ""}
        </div>
        {!showRecurringSaveOptions ? (
          <form onSubmit={formik.handleSubmit}>
            <Box hidden={created}>
              <Grid container spacing={3} style={{ marginTop: "10px" }} justify-content="center">
                <Grid item xs={12} sx={{ paddingTop: "10px!important" }}>
                  <FormControl fullWidth focused error={formik.touched.activityTypeId && Boolean(formik.errors.activityTypeId)}>
                    <InputLabel> {t("activity_form.activityType_label")} </InputLabel>
                    <Select id="activityTypeId" name="activityTypeId" variant="outlined" label={`${t("activity_form.activityType_label")}`} disabled={false} type="text" value={formik.values.activityTypeId} onChange={(event) => handleActivityTypeChange(event)} error={formik.touched.activityTypeId && Boolean(formik.errors.activityTypeId)}>
                      {activityTypes.map((activityType) => (
                        <MenuItem key={activityType._id} value={activityType._id}>
                          {currentLanguage === "en" ? activityType.enName : activityType.frName}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.activityTypeId && <FormHelperText>{formik.errors.activityTypeId}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: "10px", paddingTop: "10px!important" }}>
                  <FormControl fullWidth focused error={formik.touched.brandIds && Boolean(formik.errors.brandIds)}>
                    <Autocomplete
                      PaperComponent={(props: any) => <Paper {...props} sx={{ maxHeight: 250, width: "100%" }}></Paper>}
                      PopperComponent={(props: any) => <Popper {...props} placement="bottom"></Popper>}
                      id="brands"
                      options={brands}
                      multiple
                      disableCloseOnSelect
                      value={brands.filter((i) => formik.values.brandIds.includes(i._id))}
                      isOptionEqualToValue={(option, value) => {
                        if (value) return option._id === value._id;
                        return false;
                      }}
                      getOptionLabel={(option) => {
                        return currentLanguage === "en" ? option.enName : option.frName;
                      }}
                      onChange={(event, value, reason, details) => {
                        handleBrandsSelect(reason, value || [], "brandIds");
                      }}
                      renderOption={(props, option, { selected }) => (
                        <Box component="li" {...props}>
                          <Checkbox style={{ marginRight: 8 }} checked={selected} />
                          {currentLanguage === "en" ? option.enName : option.frName}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={formik.touched.brandIds && Boolean(formik.errors.brandIds)}
                          focused
                          label={t("activity_form.brand_label")}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "off", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                    {formik.touched.brandIds && <FormHelperText>{formik.errors.brandIds}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={formik.values.activityTypeName !== "email" ? 6 : 12} justifyContent="center">
                  <FormControl id="startTime" fullWidth focused variant="filled" error={formik.touched.startTime && Boolean(formik.errors.startTime)}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <FormLabel sx={{ position: "absolute", top: -10, left: 10, zIndex: 10, fontSize: "13px", background: "white", px: "6px" }}>{t("activity_form.startTime_label")}</FormLabel>
                      <StyledDatePicker value={dayjs(formik.values.startTime)} size="large" showTime={{ format: GetTimeDisplayFormat(i18next.language) }} suffixIcon={<EventIcon />} allowClear={false} use12Hours={i18next.language === "en" ? true : undefined} format={GetTimeInputFieldFormat(i18next.language)} onChange={(value) => onChangeDate("startTime", value ? new Date(value.toString()) : null)} onOk={(value) => onOk("startTime", value)} status={DatePickerHasError("startTime") ? "error" : undefined} />
                    </LocalizationProvider>
                    {formik.touched.startTime && formik.errors.startTime && (
                      <FormHelperText>
                        <>{formik.errors.startTime}</>
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                {formik.values.activityTypeName !== "email" && (
                  <Grid item xs={6} justifyContent="center">
                    <FormControl id="endTime" fullWidth focused variant="filled" error={formik.touched.endTime && Boolean(formik.errors.endTime)}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <FormLabel sx={{ position: "absolute", top: -10, left: 10, zIndex: 10, fontSize: "13px", background: "white", px: "6px" }}>{t("activity_form.endTime_label")}</FormLabel>
                        <StyledDatePicker value={dayjs(formik.values.endTime)} size="large" showTime={{ format: GetTimeDisplayFormat(i18next.language) }} suffixIcon={<EventIcon />} allowClear={false} use12Hours={i18next.language === "en" ? true : undefined} format={GetTimeInputFieldFormat(i18next.language)} onChange={(value) => onChangeDate("endTime", value ? new Date(value.toString()) : null)} onOk={(value) => onOk("endTime", value)} status={DatePickerHasError("endTime") ? "error" : undefined} />
                      </LocalizationProvider>
                      {formik.touched.endTime && formik.errors.endTime && (
                        <FormHelperText>
                          <>{formik.errors.endTime}</>
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12} justifyContent="center">
                  <FormControlLabel control={<Checkbox disabled={isRecurring && edit && activity?.recurring} checked={isRecurring} onChange={toggleIsRecurring} />} label={t("activity_form.recurring_activity.is_recurring")} />
                </Grid>
                {isRecurring && <ActivityFormSchedule handleRecurringInfoChange={handleRecurringInfoChange} defaultFrequency="day" date={formik.values.startTime} formik={formik} edit={edit} />}

                <Grid item xs={12} sx={{ paddingTop: "20px!important" }}>
                  <FormControl fullWidth focused error={formik.touched.specialties && Boolean(formik.errors.specialties)}>
                    <Autocomplete
                      PaperComponent={(props: any) => <Paper {...props} sx={{ width: "100%" }}></Paper>}
                      PopperComponent={(props: any) => <Popper {...props} placement="bottom"></Popper>}
                      id="specialties"
                      multiple
                      disablePortal={true}
                      disableCloseOnSelect
                      options={JobsTitles.Jobs}
                      value={JobsTitles.Jobs.filter((i) => formik.values.specialties.includes(i.value))}
                      getOptionLabel={(option) => t(option.value)}
                      isOptionEqualToValue={(option, value) => {
                        return option.value === value.value;
                      }}
                      renderOption={(props, option, { selected }) => (
                        <Box key={option.value} component="li" {...props}>
                          <Checkbox style={{ marginRight: 8 }} checked={selected} />
                          {t(option.value)}
                        </Box>
                      )}
                      onChange={(event, value, reason, details) => {
                        handleSpecialtySelect(reason, value || [], "specialties");
                      }}
                      renderInput={(params) => (
                        <TextField
                          error={formik.touched.specialties && Boolean(formik.errors.specialties)}
                          focused
                          {...params}
                          label={t("activity_form.specialties")}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "off", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                    {formik.touched.specialties && <FormHelperText>{formik.errors.specialties}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth focused error={formik.touched.provinces && Boolean(formik.errors.provinces)}>
                    <Autocomplete
                      PaperComponent={(props: any) => <Paper {...props} sx={{ maxHeight: 250, width: "100%" }}></Paper>}
                      PopperComponent={(props: any) => <Popper {...props} placement="bottom"></Popper>}
                      id="provinces"
                      multiple
                      disablePortal={true}
                      disableCloseOnSelect
                      renderTags={(params) => {
                        const provinceChips = params.filter((province) => province.value !== "all");
                        return provinceChips.map((province) => {
                          return (
                            <Stack direction="row" spacing={1} sx={{ "& .MuiButtonBase-root": { margin: "3px" } }}>
                              <Chip label={currentLanguage === "en" ? province.name : province.frName} onDelete={() => handleProvinceChipDelete(province)} />
                            </Stack>
                          );
                        });
                      }}
                      options={provinceListWithAll}
                      value={provinceListWithAll.filter((i) => formik.values.provinces.includes(i.value))}
                      getOptionLabel={(option) => (currentLanguage === "en" ? option.name : option.frName)}
                      isOptionEqualToValue={(option, value) => {
                        return option.value === value.value;
                      }}
                      renderOption={(props, option, { selected }) => (
                        <Box component="li" {...props}>
                          <Checkbox style={{ marginRight: 8 }} checked={selected} />
                          {currentLanguage === "en" ? option.name : option.frName}
                        </Box>
                      )}
                      onChange={(event, value, reason, details) => {
                        handleProvinceSelect(reason, value || [], "provinces", details);
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            error={formik.touched.provinces && Boolean(formik.errors.provinces)}
                            focused
                            {...params}
                            label={t("activity_form.province_label")}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "off", // disable autocomplete and autofill
                            }}
                          />
                        );
                      }}
                    />
                    {formik.touched.provinces && <FormHelperText>{formik.errors.provinces}</FormHelperText>}
                  </FormControl>
                </Grid>

                <Grid item xs={12} pt={"20px!important"}>
                  <FormControl fullWidth focused error={formik.touched.segmentationIds && Boolean(formik.errors.segmentationIds)}>
                    <Autocomplete
                      PaperComponent={(props: any) => <Paper {...props} sx={{ maxHeight: 250, width: "100%" }}></Paper>}
                      PopperComponent={(props: any) => <Popper {...props} placement="bottom"></Popper>}
                      id="segmentationIds"
                      multiple
                      disablePortal={true}
                      disableCloseOnSelect
                      options={segmentations}
                      value={GetSegmentationsInOrder(formik.values.segmentationIds, segmentations)}
                      getOptionLabel={(option) => (currentLanguage === "en" ? option.enName : option.frName)}
                      isOptionEqualToValue={(option, value) => {
                        return option._id === value._id;
                      }}
                      renderOption={(props, option, { selected }) => (
                        <Box component="li" {...props}>
                          <Checkbox style={{ marginRight: 8 }} checked={selected} />
                          {currentLanguage === "en" ? option.enName : option.frName}
                        </Box>
                      )}
                      onChange={(event, value, reason, details) => {
                        handleSegmentationSelect(reason, value || [], "segmentationIds");
                      }}
                      renderInput={(params) => (
                        <TextField
                          error={formik.touched.segmentationIds && Boolean(formik.errors.segmentationIds)}
                          focused
                          {...params}
                          label={t("activity_form.segmentations")}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "off", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                    {formik.touched.segmentationIds && <FormHelperText>{formik.errors.segmentationIds}</FormHelperText>}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sx={{ paddingTop: "20px!important" }}>
                  <TextField id="notes" name="notes" multiline rows={4} value={formik.values.notes} variant="outlined" focused fullWidth size="medium" label={t("activity_form.notes_label")} onChange={formik.handleChange} inputProps={{ maxLength: 580 }} error={formik.touched.notes && Boolean(formik.errors.notes)} />
                  <FormHelperText sx={{ fontStyle: "italic" }} error={formik.values.notes.length >= 500 ? true : false}>
                    {t("activity_form.notes_helper_text")} {formik.values.notes.length}/500
                  </FormHelperText>
                  {formik.touched.notes && <FormHelperText error={true}>{formik.errors.notes}</FormHelperText>}
                </Grid>

                <Grid item xs={12} sx={{ paddingTop: "10px!important", display: "flex", justifyContent: "center" }}>
                  {getNextButton()}
                </Grid>
              </Grid>
            </Box>
          </form>
        ) : (
          <Grid container spacing={3} style={{ marginTop: "10px" }} justify-content="center">
            <Grid item xs={12} sx={{ paddingTop: "10px!important" }}>
              {geRecurringEventSubmitButtons()}
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default ActivityForm;
