import React from "react";
import { styled } from "@mui/material/styles";
import Grid, { GridProps } from "@mui/material/Grid";
import StatsSectionHeader from "../styled/reportingEvents/StatsSectionHeader";
import { EvaluationProgram } from "../../typings/interfaces";
import { motion } from "framer-motion";
import { useAnimatedCounter } from "../../hooks/useAnimatedCounter";
import { AverageNumbers } from "../../utils/average";
import { useTranslation } from "react-i18next";

const ratingNumber = 5;

const ratingBlocksArray = createArray(ratingNumber);

function createArray(n: number) {
  var array = [];
  for (var i = 0; i < n; i++) {
    array.push(i);
  }

  return array;
}

const SectionWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "20px",
}));

const SectionRow = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  padding: "0px",
  alignItems: "center",
}));

const SectionQuestion = styled("div")(({ theme }) => ({
  width: "50%",
  marginTop: "10px",
  paddingRight: "20px",
}));

const SectionQuestionText = styled("span")(({ theme }) => ({
  color: "#3D3D3D",
  display: "block",
  paddingRight: "40px",
  lineHeight: "1rem",
}));

const SectionRatings = styled("div")(({ theme }) => ({
  width: "50%",
  display: "flex",
  borderLeft: "3px solid #979797",
  paddingTop: "10px",
}));

const SectionRatingsBlock = styled("span")(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: "40px",
}));

const MotionSectionRatingsBlock = motion(SectionRatingsBlock);

const SectionRatingsNumbers = styled("div")(({ theme }) => ({
  width: "50%",
  display: "flex",
  borderLeft: "3px solid #979797",
  paddingTop: "10px",
  alignItems: "baseline",
}));

const SectionRatingsNumbersBlocks = styled("span")(({ theme }) => ({
  backgroundColor: "white",
  width: "20%",
  padding: "5px 0px",
  // borderRight: "1px solid white",
  textAlign: "end",
}));

//send in props for the question ratings

type Props = {
  sectionTitle: string; 
  overallRating: number;
  programEvaluations: EvaluationProgram[];
};

const ReportEventStatsSection = ({sectionTitle, overallRating, programEvaluations}: Props) => {

  const { t, i18n } = useTranslation();
  const relevantAvg = AverageNumbers(programEvaluations.map(i => i.relevant));
  const credibleAvg = AverageNumbers(programEvaluations.map((i) => i.credible));
  const organizedAvg = AverageNumbers(programEvaluations.map(i => i.organized));
  const timeAllottedAvg = AverageNumbers(programEvaluations.map(i => i.timeAllotted));
  const interactionAvg = AverageNumbers(programEvaluations.map(i => i.interaction));

  return (
    <>
      <StatsSectionHeader>
        <span>{sectionTitle}</span>
        <div>
        {t("reporting.program_score_2")} <span className="rating"> {useAnimatedCounter(overallRating, 0, 1).toFixed(1)}</span>
        </div>
      </StatsSectionHeader>
      <SectionWrapper>
        <SectionRow>
          <SectionQuestion>
            <SectionQuestionText> {t("evaluation.evaluation_form.program_questions.question_one")}</SectionQuestionText>
          </SectionQuestion>
          <SectionRatings>
            <MotionSectionRatingsBlock
              initial={{width: 0}}
              animate={{width: `${Math.floor((relevantAvg / 5) * 100)}%`}}
              transition={{duration: 0.8, delay: 0.1}}>
            </MotionSectionRatingsBlock>
          </SectionRatings>
        </SectionRow>
        <SectionRow>
          <SectionQuestion>
            <SectionQuestionText> {t("evaluation.evaluation_form.program_questions.question_two")} </SectionQuestionText>
          </SectionQuestion>
          <SectionRatings>
            <MotionSectionRatingsBlock
              initial={{width: 0}}
              animate={{width: `${Math.floor((credibleAvg / 5) * 100)}%`}}
              transition={{duration: 0.8, delay: 0.2}}>
            </MotionSectionRatingsBlock>
          </SectionRatings>
        </SectionRow>
        <SectionRow>
          <SectionQuestion>
            <SectionQuestionText> {t("evaluation.evaluation_form.program_questions.question_three")} </SectionQuestionText>
          </SectionQuestion>
          <SectionRatings>
            <MotionSectionRatingsBlock
              initial={{width: 0}}
              animate={{width: `${Math.floor((organizedAvg / 5) * 100)}%`}}
              transition={{duration: 0.8, delay: 0.3}}>
            </MotionSectionRatingsBlock>
          </SectionRatings>
        </SectionRow>
        <SectionRow>
          <SectionQuestion>
            <SectionQuestionText> {t("evaluation.evaluation_form.program_questions.question_four")}</SectionQuestionText>
          </SectionQuestion>
          <SectionRatings>
           <MotionSectionRatingsBlock
              initial={{width: 0}}
              animate={{width: `${Math.floor((timeAllottedAvg / 5) * 100)}%`}}
              transition={{duration: 0.8, delay: 0.4}}>
            </MotionSectionRatingsBlock>
          </SectionRatings>
        </SectionRow>
        <SectionRow>
          <SectionQuestion>
            <SectionQuestionText> {t("evaluation.evaluation_form.program_questions.question_five")}</SectionQuestionText>
          </SectionQuestion>
          <SectionRatings>
            <MotionSectionRatingsBlock
              initial={{width: 0}}
              animate={{width: `${Math.floor((interactionAvg / 5) * 100)}%`}}
              transition={{duration: 0.8, delay: 0.5}}>
            </MotionSectionRatingsBlock>
          </SectionRatings>
        </SectionRow>

        <SectionRow>
          <SectionQuestion></SectionQuestion>

          <SectionRatingsNumbers>
            <SectionRatingsNumbersBlocks>1</SectionRatingsNumbersBlocks>
            <SectionRatingsNumbersBlocks>2</SectionRatingsNumbersBlocks>
            <SectionRatingsNumbersBlocks>3</SectionRatingsNumbersBlocks>
            <SectionRatingsNumbersBlocks>4</SectionRatingsNumbersBlocks>
            <SectionRatingsNumbersBlocks>5</SectionRatingsNumbersBlocks>
          </SectionRatingsNumbers>
        </SectionRow>
      </SectionWrapper>
    </>
  );
};

export default ReportEventStatsSection;
