import React, {useState, useEffect} from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Radio from "@mui/material/Radio";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { StyledDatePicker } from "../../styled/Inputs/StyledDatePicker";
import { GetTimeDisplayFormat, GetDateInputFieldFormat } from "../../../utils/timeFormat";
import { Event as EventIcon } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { ActivityFormScheduleLabel } from "./styled";
import InputAdornment from "@mui/material/InputAdornment";
import { End } from "../../../typings/interfaces";

const EndsOnRadioContainer = styled("div")<{ defaultActive?: boolean; active?: boolean }>(({ active, theme }) => ({
  display: "flex",
  alignItems: "center",
}));
const EndsOnRadioLeftContainer = styled("div")<{}>(({}) => ({
  display: "flex",
  alignItems: "center",
  width: "30%",
}));

type Props = {
  handleRecurringInfoChange: () => void;
  formik: any;
}

export default function ActivityFormScheduleEndsOn({ handleRecurringInfoChange, formik } : Props) {
  const { t, i18n } = useTranslation();
  const [selectedEndDateType, setSelectedEndDateType] = useState(formik.values.recurringEndDateType);


  const handleChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedEndDateType(value);
    formik.setFieldValue("recurringEndDateType", value);
    handleRecurringInfoChange();
  };

  const handleChangeOccurrence = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    formik.setFieldValue("occurrences", value);
    handleRecurringInfoChange();
  }

  const onChangeDate = async (value: Date | null) => {
    if (value) {
      formik.setFieldValue("endScheduleDate", value);
    } else {
      formik.setFieldValue("endScheduleDate",  "");
    }
    handleRecurringInfoChange();
  };


  return (
    <div id="scheduleEndOn" style={{ marginBottom: "20px" }}>
      <ActivityFormScheduleLabel>{t("activity_form.recurring_activity.ends")}</ActivityFormScheduleLabel>
      <EndsOnRadioContainer>
        <EndsOnRadioLeftContainer>
          <Radio checked={selectedEndDateType === "never"} onChange={handleChangeType} value="never" name="radio-buttons" inputProps={{ "aria-label": "Never" }} />
          <div>{t("activity_form.recurring_activity.end_options.never")}</div>
        </EndsOnRadioLeftContainer>
      </EndsOnRadioContainer>
      <EndsOnRadioContainer>
        <EndsOnRadioLeftContainer>
          <Radio checked={selectedEndDateType === "on"} onChange={handleChangeType} value="on" name="radio-buttons" inputProps={{ "aria-label": "On" }} />
          <div>{t("activity_form.recurring_activity.end_options.on")}</div>
        </EndsOnRadioLeftContainer>
        <div className="endsOn-right">
          <FormControl
            id="endScheduleDate"
            fullWidth
            focused
            variant="filled"
            style={{ marginLeft: "25px" }}
            error={Boolean(formik.errors.endScheduleDate)}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StyledDatePicker
                status={Boolean(formik.errors.endScheduleDate && formik.touched.endScheduleDate) ? 'error' : undefined}
                value={dayjs(formik.values.endScheduleDate)}
                disabled={selectedEndDateType !== "on"}
                style={{ marginLeft: "0px", width: "180px", padding: "4px", paddingLeft: "10px", fontSize: "14px" }}
                size="large"
                suffixIcon={<EventIcon />}
                allowClear={false}
                use12Hours={i18next.language === "en" ? true : undefined}
                format={GetDateInputFieldFormat(i18next.language)}
                onChange={(value) => onChangeDate(value ? new Date(value.toString()) : null)} 
                // onOk={(value) => onOk("endTime", value)}
              />
            </LocalizationProvider>
            {formik.errors.endScheduleDate && (
            <FormHelperText>
              <>{formik.errors.endScheduleDate}</>
            </FormHelperText>
          )}
          </FormControl>
        </div>
      </EndsOnRadioContainer>
      <EndsOnRadioContainer>
        <EndsOnRadioLeftContainer>
          <Radio checked={selectedEndDateType === "after"} onChange={handleChangeType} value="after" name="radio-buttons" inputProps={{ "aria-label": "After" }} />
          <div>{t("activity_form.recurring_activity.end_options.after")}</div>
        </EndsOnRadioLeftContainer>
        <div className="endsOn-right">
          <TextField
            style={{ marginLeft: "25px", width: "180px" }}
            id="occurrences"
            name="occurrences"
            type="number"
            value={formik.values.occurrences}
            InputProps={{
              sx: { "&.Mui-disabled": { background: "rgba(0, 0, 0, 0.04)", "& .MuiTypography-root": { color: "rgba(0, 0, 0, 0.25)" } }, "& .MuiInputAdornment-root .MuiTypography-root":{fontSize: "14px"} },
              endAdornment: <InputAdornment position="end">{t("activity_form.recurring_activity.occurrences")}</InputAdornment>,
              inputProps: { 
                min: 1, 
                max: 100 
              }
            }}
            variant="outlined"
            focused
            fullWidth
            size="small"
            disabled={selectedEndDateType !== "after"}
            onChange={handleChangeOccurrence}
            inputProps={{ maxLength: 580 }}
            error={formik.touched.occurrences && Boolean(formik.errors.occurrences)}
          />
        </div>
      </EndsOnRadioContainer>
    </div>
  );
}
