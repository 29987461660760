import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Grid, { GridProps } from "@mui/material/Grid";
import StatsSectionHeader from "../styled/reportingEvents/StatsSectionHeader";
import { EventEvaluation, EvaluationLearningObjective, EvaluationModerator, EvaluationSpeaker, EventModerator, EventSpeaker, Event, EventLearningObjective, LearningObjective } from "../../typings/interfaces";
import { motion } from "framer-motion";
import { useAnimatedCounter } from "../../hooks/useAnimatedCounter";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { useTranslation } from "react-i18next";


const SectionWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "20px",
}));

const SectionRow = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  padding: "0px",
  alignItems: "center",
}));

const SectionQuestion = styled("div")(({ theme }) => ({
  width: "50%",
  marginTop: "10px",
  paddingRight: "20px",
}));

const SectionQuestionText = styled("span")(({ theme }) => ({
  color: "#3D3D3D",
  display: "block",
  paddingRight: "20px",
  lineHeight: "1rem",
}));

const SectionRatings = styled("div")(({ theme }) => ({
  width: "50%",
  display: "flex",
  borderLeft: "3px solid #979797",
  paddingTop: "10px",
}));

const SectionRatingsBlock = styled("span")(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: "40px",
}));

const MotionSectionRatingsBlock = motion(SectionRatingsBlock);

const SectionRatingsNumbers = styled("div")(({ theme }) => ({
  width: "50%",
  display: "flex",
  borderLeft: "3px solid #979797",
  paddingTop: "10px",
  alignItems: "baseline",
}));

const SectionRatingsNumbersBlocks = styled("span")(({ theme }) => ({
  backgroundColor: "white",
  width: "20%",
  padding: "5px 0px",
  // borderRight: "1px solid white",
  marginLeft:"2px",
  textAlign: "end",
}));

//send in props for the question ratings

type SectionType = {
  presentation: boolean;
  learningObjective: boolean;
};

type Props = {
  sectionTitle: string;
  overallRating: number;
  evaluations: EventEvaluation[];
  type: SectionType;
  event: Event;
  learningObjectives: LearningObjective[];
};

const getLearningObjectiveExcerpt = (text: string, length = 100) => {
  let excerpt = text;
  if (text.length > length) {
    excerpt = `${text.substring(0, length)}...`;
  }
  return excerpt;
};

const getLearningObjectivesAndRatings = (learningObjectives: EvaluationLearningObjective[], dbLearningObjectives: LearningObjective[], language: string) => {
  var learningObjectivesWithAvg: EvaluationLearningObjective[] = [];

  var objectiveNames = Array.from(new Set(learningObjectives.map((i) => i.name)));
  for (const name of objectiveNames) {
    const objectives = learningObjectives.filter((i) => i.name === name);
    let loName = name;
    const foundLearningObjective = dbLearningObjectives.find(i => i._id === objectives[0].learningObjectiveId);
    if (foundLearningObjective) {
      let translatedName = language === "fr" ? foundLearningObjective.frName : foundLearningObjective.enName;

      if (foundLearningObjective.value !== name) {
        loName = translatedName + ": " + name;
      } else {
        loName = translatedName;
      }
    }
    if (objectives.length > 0) {
      const rating = objectives.map((i) => i.rating).reduce((a, b) => a + b) / objectives.length;
      learningObjectivesWithAvg.push({
        learningObjectiveId: objectives[0].learningObjectiveId,
        therapeuticAreaId: objectives[0].therapeuticAreaId,
        name: loName,
        excerpt: getLearningObjectiveExcerpt(loName, 85),
        rating: rating,
      });
    }
  }
  return learningObjectivesWithAvg;
};

const getSpeakerRatings = (speakerEvaluations: EvaluationSpeaker[]) => {
  var speakersWithAvg: any[] = [];

  var speakerIds = Array.from(new Set(speakerEvaluations.map((i) => i.speakerId)));
  for (const id of speakerIds) {
    const evalSpeakers = speakerEvaluations.filter((i) => i.speakerId === id);
    if (evalSpeakers.length > 0) {
      const rating = evalSpeakers.map((i) => i.rating).reduce((a, b) => a + b) / evalSpeakers.length;
      const name = evalSpeakers[0].name;
      speakersWithAvg.push({
        speakerId: id,
        rating: rating,
        name,
      });
    }
  }

  return speakersWithAvg;
};

const getModeratorRatings = (moderatorEvaluations: EvaluationModerator[]) => {
  var moderatorsWithAvg: any[] = [];

  var moderatorIds = Array.from(new Set(moderatorEvaluations.map((i) => i.moderatorId)));
  for (const id of moderatorIds) {
    const evalModerators = moderatorEvaluations.filter((i) => i.moderatorId === id);
    if (evalModerators.length > 0) {
      const rating = evalModerators.map((i) => i.rating).reduce((a, b) => a + b) / evalModerators.length;
      const name = evalModerators[0].name;
      moderatorsWithAvg.push({
        moderatorId: id,
        rating: rating,
        name,
      });
    }
  }

  return moderatorsWithAvg;
};

const ReportEventStatsSectionDynamic = ({ sectionTitle, overallRating, evaluations, type, event, learningObjectives }: Props) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const [learningObjectiveWithRatings, setLearningObjectiveWithRatings] = useState<EvaluationLearningObjective[]>([]);
  const [speakersWithRatings, setSpeakersWithRatings] = useState<EvaluationSpeaker[]>([]);
  const [moderatorsWithRatings, setModeratorsWithRatings] = useState<EvaluationModerator[]>([]);

  useEffect(() => {
    const learningObjectiveEvaluations = [...evaluations.map((i) => i.learningObjectives.map((j) => j))].flat(1);
    setLearningObjectiveWithRatings(getLearningObjectivesAndRatings(learningObjectiveEvaluations, learningObjectives, language));

    const speakerEvaluations = [...evaluations.map((i) => [...i.speakers])].flat(1);
    setSpeakersWithRatings(getSpeakerRatings(speakerEvaluations));

    const moderatorEvaluations = [...evaluations.map((i) => [...i.moderators])].flat(1);
    setModeratorsWithRatings(getModeratorRatings(moderatorEvaluations));
  }, [evaluations, event, language]);

  return (
    <>
      <StatsSectionHeader>
        <span>{sectionTitle}</span>
        <div>
          {type.learningObjective ? t("reporting.learning_obj_score_2") : t("reporting.presentation_score_2")} <span className="rating">{useAnimatedCounter(overallRating, 0, 1).toFixed(1)}</span>
        </div>
      </StatsSectionHeader>
      <SectionWrapper>
        {type.learningObjective === true
          ? learningObjectiveWithRatings.map((i, index) => {
              return (
                <SectionRow>
                  <SectionQuestion>
                    <SectionQuestionText>
                      <Tooltip
                        title={i.name}
                        TransitionComponent={Zoom}
                        enterNextDelay={500}
                        enterDelay={500}
                        leaveDelay={200}
                        placement="top"
                        arrow={true}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              fontSize: "0.8rem!important",
                              maxWidth:"500px",
                              textAlign:"center",
                              marginBottom: "5px!important",
                              bgcolor: "#ffffff",
                              color: "primary.dark",
                              boxShadow: " 0 2px 4px 0 rgba(0,0,0,0.15)",
                              border: "1px solid #D8D8D8",
                              "& .MuiTooltip-arrow": {
                                color: "#ffffff",
                                "&:before": {
                                  border: "1px solid #D8D8D8",
                                },
                              },
                            },
                          },
                        }}
                      >
                        <div style={{ display: "inline" }}>{i.excerpt ? i.excerpt : i.name} </div>
                      </Tooltip>
                    </SectionQuestionText>
                  </SectionQuestion>
                  <SectionRatings>
                    <MotionSectionRatingsBlock initial={{ width: 0 }} animate={{ width: `${Math.floor((i.rating / 5) * 100)}%` }} transition={{ duration: 0.8, delay: 0.1 * (index + 1) }}></MotionSectionRatingsBlock>
                  </SectionRatings>
                </SectionRow>
              );
            })
          : ""}

        {type.presentation === true
          ? speakersWithRatings.map((i, index) => {
              return (
                <SectionRow>
                  <SectionQuestion>
                    <SectionQuestionText> {i.name} {t("evaluation.evaluation_form.presentation_questions.speaker_2")}</SectionQuestionText>
                  </SectionQuestion>
                  <SectionRatings>
                    <MotionSectionRatingsBlock initial={{ width: 0 }} animate={{ width: `${Math.floor((i.rating / 5) * 100)}%` }} transition={{ duration: 0.8, delay: 0.1 * (index + 1) }}></MotionSectionRatingsBlock>
                  </SectionRatings>
                </SectionRow>
              );
            })
          : ""}

        {type.presentation === true
          ? moderatorsWithRatings.map((i, index) => {
              return (
                <SectionRow>
                  <SectionQuestion>
                    <SectionQuestionText> {i.name} {t("evaluation.evaluation_form.presentation_questions.moderator_2")}</SectionQuestionText>
                  </SectionQuestion>
                  <SectionRatings>
                    <MotionSectionRatingsBlock initial={{ width: 0 }} animate={{ width: `${Math.floor((i.rating / 5) * 100)}%` }} transition={{ duration: 0.8, delay: 0.1 * (index + 1) }}></MotionSectionRatingsBlock>
                  </SectionRatings>
                </SectionRow>
              );
            })
          : ""}

        <SectionRow>
          <SectionQuestion></SectionQuestion>

          <SectionRatingsNumbers>
            <SectionRatingsNumbersBlocks>1</SectionRatingsNumbersBlocks>
            <SectionRatingsNumbersBlocks>2</SectionRatingsNumbersBlocks>
            <SectionRatingsNumbersBlocks>3</SectionRatingsNumbersBlocks>
            <SectionRatingsNumbersBlocks>4</SectionRatingsNumbersBlocks>
            <SectionRatingsNumbersBlocks>5</SectionRatingsNumbersBlocks>
          </SectionRatingsNumbers>
        </SectionRow>
      </SectionWrapper>
    </>
  );
};

export default ReportEventStatsSectionDynamic;
