import { Alert, Autocomplete, AutocompleteChangeReason, Box, Button, ButtonBase, ButtonGroup, Checkbox, createFilterOptions, FormControl, FormHelperText, FormLabel, Grid, IconButton, InputLabel, Paper, Popper, Tab, Tabs, Typography } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useState, useContext, useEffect, Dispatch, SetStateAction } from "react";
import { format } from "date-fns";
import { formatFormErrors } from "../utils/forms";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import { LearningObjective, Speaker, Users, Event } from "../typings/interfaces";
import { BackButton } from "./styled/Buttons/StyledButtons";
import ReportingContext from "../context/ReportingContext";
import { styled } from "@mui/material/styles";
import AppContext from "../context/Auth";
import { useSpeakers } from "../api/speakers";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { Add, Event as EventIcon, Remove } from "@mui/icons-material";
import { useEvents } from "../api/events";
import useEventName from "../hooks/useEventName";
import { provinceList } from "../utils/provinces";
import InstructionsPopover from "./styled/InstructionsPopover";
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import dayjs from "dayjs";
import { StyledDatePicker } from "./styled/Inputs/StyledDatePicker";
import i18next from "i18next";
import { GetTimeDisplayFormat, GetTimeInputFieldFormat } from "../utils/timeFormat";
import { parseEventStartTime } from "../utils/parseDate";

const TabContainer = styled(Box)({
  marginTop: "20px",
  marginLeft: "20px",
  marginRight: "20px",
  "& .MuiTab-root": {
    flexGrow: 1,
    fontSize: 16,
  },
});

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "bold",
  paddingLeft: "20px",
}));

const RequiredMicetype = styled(Typography)(({ theme }) => ({
  color: "gray",
  fontSize: "14px"
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const StyledEventTypeButton = styled(Button)<{ index: number; selected: number }>(({ theme, selected, index }) => ({
  backgroundColor: selected === index ? theme.palette.primary.main : "white",
  color: selected === index ? "white" : theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: "45px",
  padding: "0px 15px 0px 15px",
  width: "115px",
  marginRight: "10px",
  textTransform: "none",
  fontSize: 16,
  ":hover": {
    backgroundColor: selected === index ? theme.palette.primary.main : "white",
    color: selected === index ? "white" : theme.palette.primary.main,
  }
}));

const NextButton = styled(ButtonBase)<{ disabled?: boolean }>(({ disabled, theme }) => ({
  backgroundColor: theme.palette.primary.main,
  textTransform: "uppercase",
  padding: "14px 60px 14px 60px",
  color: "white",
  fontSize: 18,
  borderRadius: "5px",
  opacity: disabled ? 0.6 : 1,
}));

const ModalTitle = styled("h1")(({ theme }) => ({
  color: "#3D3D3D",
  textAlign: "center",
  marginTop: 24,
  fontSize: 24,
  fontWeight: "bold",
  textTransform: "uppercase",
}));

const ModalTab = styled(Tab)(({ theme }) => ({
  fontWeight: "normal",
  textTransform: "uppercase",
  fontSize: "18px!important",
}));

type Props = {
  date?: Date;
  edit?: boolean;
  event?: Event;
  closeModal: () => void;
  setShowExitButton: Dispatch<SetStateAction<boolean>>;
  showSuccess: (text: string) => void;
};

const speakerFilter = createFilterOptions<Speaker>();

const GeneralEventForm = ({ date, edit, event, closeModal, setShowExitButton, showSuccess }: Props): JSX.Element => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const [displayError, setDisplayError] = useState("");

  const [displaySpeakerSuccess, setDisplaySpeakerSuccess] = useState("");
  const [displayModeratorSuccess, setDisplayModeratorSuccess] = useState("");

  const [currentTab, setCurrentTab] = useState<number>(0);
  const [selectedType, setSelectedType] = useState(-1);

  const [showAddSpeaker, setShowAddSpeaker] = useState(false);
  const [showAddModerator, setShowAddModerator] = useState(false);
  const [formatEventName] = useEventName();
  const emptyLearningObjectives = { _id: "", customName: "" };

  const [learningObjectiveCount, setLearningObjectiveCount] = useState<number>(3);
  const AddLearningObjective = () => {
    if (learningObjectiveCount < 6) {
      setLearningObjectiveCount(learningObjectiveCount + 1);
    }
  }
  const RemoveLearningObjective = () => {
    switch (learningObjectiveCount) {
      case 2:
        formik.setFieldValue("learningObjectiveTwo", emptyLearningObjectives);
        break;
      case 3:
        formik.setFieldValue("learningObjectiveThree", emptyLearningObjectives);
        break;
      case 4:
        formik.setFieldValue("learningObjectiveFour", emptyLearningObjectives);
        break;
      case 5:
        formik.setFieldValue("learningObjectiveFive", emptyLearningObjectives);
        break;
      case 6:
        formik.setFieldValue("learningObjectiveSix", emptyLearningObjectives);
        break;
    }

    if (learningObjectiveCount > 1) {
      setLearningObjectiveCount(learningObjectiveCount - 1);
    }
  }

  type EVENT_TYPES_OPTIONS = {
    [key: string]: number;
  };

  const EVENT_TYPES: EVENT_TYPES_OPTIONS = {
    VIRTUAL: 0,
    LIVE: 1,
    HYBRID: 2,
  };

  const { createSpeaker } = useSpeakers();
  const { createEvent, editEvent } = useEvents();

  function handleNext(event: any) {
    setCurrentTab(Number(event.target.value));
  }

  const { speakers, learningObjectives, regions, therapeuticAreas, languages, reps } = useContext(ReportingContext);

  const { hasRole, userId } = useContext(AppContext);

  const [loading, setLoading] = useState(false);

  const [selectedLearningObjectives, setSelectedLearningObjectives] = useState<LearningObjective[]>([]);
  const [learningObjectivesArray, setLearningObjectivesArray] = useState<LearningObjective[]>(learningObjectives);
  const minDateTime = new Date(); 

  useEffect(() => {
    if (event) {
      const type = event.eventType.toUpperCase();
      setSelectedType(EVENT_TYPES[type]);
    }
  }, [event]);

  const validationSchema = yup.object({
    title: yup.string(),
    therapeuticArea: yup.string().required(t("create_event_form_therapeuticArea_error")),
    learningObjectiveOne: yup.object({ _id: yup.string().required(t("create_event_form_learning_obj_error")) }),
    regionId: yup.string().required(t("create_event_form_region_error")),
    timeZone: yup.string().required(t("create_event_form_timzone_error")),
    language: yup.string().required(t("create_event_form_language_error")),
    startTime: yup.date().min(new Date(), t("create_event_form_date_past_error")).required(t("create_event_form_date_error")),
    durationMinutes: yup.number().required(t("create_event_form_duration_error")),
    eventType: yup.string().required(t("create_type_error")),
    street: yup.string().when("eventType", {
      is: (eventType: string) => eventType === "Live" || eventType === "Hybrid",
      then: yup.string().required(t("create_event_form_address_error")),
    }),
    city: yup.string().when("eventType", {
      is: (eventType: string) => eventType === "Live" || eventType === "Hybrid",
      then: yup.string().required(t("create_event_form_city_error")),
    }),
    province: yup.string().when("eventType", {
      is: (eventType: string) => eventType === "Live" || eventType === "Hybrid",
      then: yup.string().required(t("create_event_form_province_error")),
    }),
    postal: yup.string().when("eventType", {
      is: (eventType: string) => eventType === "Live" || eventType === "Hybrid",
      then: yup.string().required(t("create_event_form_postal_error")),
    }),
  });

  function GetLearningObjective(index:number) {
    if (event?.learningObjectives[index]) {
      if (therapeuticAreas.find(i => i.value === event.therapeuticArea && i.showInEval)) {
        if (learningObjectives.find(i => i._id === event.learningObjectives[index]._id && i.showInEval)) {
          return {_id: event.learningObjectives[index]._id, customName: event.learningObjectives[index].customName}
        }
      }
    }
    return emptyLearningObjectives
  }

  const initialValues = event
    ? {
        channelId: "operatic",
        title: event && event.title ? event.title : "",
        therapeuticArea: therapeuticAreas.find(i => i.value === event.therapeuticArea && i.showInEval)?.value || "",
        learningObjectiveOne: GetLearningObjective(0),
        learningObjectiveTwo: GetLearningObjective(1),
        learningObjectiveThree: GetLearningObjective(2),
        learningObjectiveFour: GetLearningObjective(3),
        learningObjectiveFive: GetLearningObjective(4),
        learningObjectiveSix: GetLearningObjective(5),
        regionId: event.regionId,
        timeZone: event.timeZone,
        startTime: parseEventStartTime(event.start_time),
        durationMinutes: event.duration,
        street: event.street,
        city: event.city,
        province: event.province,
        postal: event.postal,
        eventType: event.eventType,
        language: event.language,
        isGeneral: true,
        speakerOne: event.speakerIds[0],
        speakerTwo: event.speakerIds.length > 1 ? event.speakerIds[1] : "",
        speakerThree: event.speakerIds.length > 2 ? event.speakerIds[2] : "",
        speakerFour: event.speakerIds.length > 3 ? event.speakerIds[3] : "",
        speakerFive: event.speakerIds.length > 4 ? event.speakerIds[4] : "",
        moderatorOne: event.moderatorIds.length > 0 ? event.moderatorIds[0] : "",
        moderatorTwo: event.moderatorIds.length > 1 ? event.moderatorIds[1] : "",
        moderatorThree: event.moderatorIds.length > 2 ? event.moderatorIds[2] : "",
        moderatorFour: event.moderatorIds.length > 3 ? event.moderatorIds[3] : "",
        moderatorFive: event.moderatorIds.length > 4 ? event.moderatorIds[4] : "",
        repId: event.repId,
      }
    : {
        channelId: "operatic",
        title: "",
        therapeuticArea: "",
        learningObjectiveOne: { _id: "", customName: "" },
        learningObjectiveTwo: { _id: "", customName: "" },
        learningObjectiveThree: { _id: "", customName: "" },
        learningObjectiveFour: { _id: "", customName: "" },
        learningObjectiveFive: { _id: "", customName: "" },
        learningObjectiveSix: { _id: "", customName: "" },
        regionId: "",
        timeZone: "Canada/Eastern", //default value
        startTime: date ? date : new Date(new Date().getTime() + 30 * 60000),
        durationMinutes: 60,
        street: "",
        city: "",
        province: "",
        postal: "",
        eventType: "",
        language: t("language"),
        isGeneral: true,
        speakerOne: "",
        speakerTwo: "",
        speakerThree: "",
        speakerFour: "",
        speakerFive: "",
        moderatorOne: "",
        moderatorTwo: "",
        moderatorThree: "",
        moderatorFour: "",
        moderatorFive: "",
        repId: "",
      };

  useEffect(() => {
    if (event && edit) {
      const learningObjectivesArrayFilter: LearningObjective[] = [];
      learningObjectives.forEach((objective: LearningObjective) => {
        event.therapeuticArea?.toLowerCase() === objective.therapeuticArea && objective.showInEval ? learningObjectivesArrayFilter.push(objective) : console.log("no match");
      });

      setLearningObjectivesArray(learningObjectivesArrayFilter);
      setLearningObjectiveCount(event.learningObjectives.length);

      if (formik.values.learningObjectiveTwo._id === "" && formik.values.learningObjectiveOne._id === "") {
          formik.setFieldValue("learningObjectiveOne", { ...formik.values.learningObjectiveThree });
          formik.setFieldValue("learningObjectiveTwo", { ...emptyLearningObjectives });
          formik.setFieldValue("learningObjectiveThree", { ...emptyLearningObjectives }); 
      } else {
        if (formik.values.learningObjectiveTwo._id === "") {
          formik.setFieldValue("learningObjectiveTwo", { ...formik.values.learningObjectiveThree });
          formik.setFieldValue("learningObjectiveThree", { ...emptyLearningObjectives }); 
        }
        if (formik.values.learningObjectiveOne._id === "") {
          formik.setFieldValue("learningObjectiveOne", { ...formik.values.learningObjectiveTwo });
          formik.setFieldValue("learningObjectiveTwo", { ...formik.values.learningObjectiveThree });
          formik.setFieldValue("learningObjectiveThree", { ...emptyLearningObjectives }); 
        }
      }
    }
  }, [event, edit])

  const editSubmit = async (values: any) => {
    try {
      setDisplayError("");
      setLoading(true);
      var endTime = new Date(values.startTime);
      endTime.setHours(endTime.getHours() + 1);

      const eventObject = {
        id: event?.id,
        title: values.title,
        therapeuticArea: values.therapeuticArea,
        learningObjectives: [
          {
            _id: values.learningObjectiveOne._id,
            customName: values.learningObjectiveOne.customName,
          },
          {
            _id: values.learningObjectiveTwo._id,
            customName: values.learningObjectiveTwo.customName,
          },
          {
            _id: values.learningObjectiveThree._id,
            customName: values.learningObjectiveThree.customName,
          },
          {
            _id: values.learningObjectiveFour._id,
            customName: values.learningObjectiveFour.customName,
          },
          {
            _id: values.learningObjectiveFive._id,
            customName: values.learningObjectiveFive.customName,
          },
          {
            _id: values.learningObjectiveSix._id,
            customName: values.learningObjectiveSix.customName,
          },
        ],
        regionId: values.regionId,
        timeZone: values.timeZone,
        startTime: format(values.startTime, "yyyy-MM-dd HH:mm"),
        endTime: format(endTime, "yyyy-MM-dd HH:mm"),
        durationMinutes: values.durationMinutes,
        eventType: values.eventType,
        language: values.language,
        speakerIds: [values.speakerOne, values.speakerTwo, values.speakerThree, values.speakerFour, values.speakerFive].filter(Boolean),
        moderatorIds: [values.moderatorOne, values.moderatorTwo, values.moderatorThree, values.moderatorFour, values.moderatorFive].filter(Boolean),
        repId: values.repId,
        street: values.street !== "" ? values.street : undefined,
        city: values.city !== "" ? values.city : undefined,
        postal: values.postal !== "" ? values.postal : undefined,
        province: values.province !== "" ? values.province : undefined,
      };
      await editEvent(eventObject);
      setLoading(false);
      closeModal();
      showSuccess(`: ${formatEventName({...event, therapeuticArea: eventObject.therapeuticArea, speakerIds: eventObject.speakerIds, start_time: eventObject.startTime} as Event)}`);
    } catch (error: any) {
      if (error.response) {
        console.log(error.response);
        if (error.response.data.errorsValidation) {
          const formattedErrors = formatFormErrors(error.response.data.errorsValidation);
          formik.setErrors(formattedErrors);
          return;
        }
        setLoading(false);
      }
      setDisplayError(error.response.data.message);
    }
  };

  const createSubmit = async (values: any) => {
    try {
      setDisplayError("");
      setLoading(true);
      var endTime = new Date(values.startTime);
      endTime.setHours(endTime.getHours() + 1);

      const newEvent = await createEvent({
        title: values.title,
        therapeuticArea: values.therapeuticArea,
        learningObjectives: [
          {
            _id: values.learningObjectiveOne._id,
            customName: values.learningObjectiveOne.customName,
          },
          {
            _id: values.learningObjectiveTwo._id,
            customName: values.learningObjectiveTwo.customName,
          },
          {
            _id: values.learningObjectiveThree._id,
            customName: values.learningObjectiveThree.customName,
          },
          {
            _id: values.learningObjectiveFour._id,
            customName: values.learningObjectiveFour.customName,
          },
          {
            _id: values.learningObjectiveFive._id,
            customName: values.learningObjectiveFive.customName,
          },
          {
            _id: values.learningObjectiveSix._id,
            customName: values.learningObjectiveSix.customName,
          },
        ],
        regionId: values.regionId,
        timeZone: values.timeZone,
        startTime: format(values.startTime, "yyyy-MM-dd HH:mm"),
        endTime: format(endTime, "yyyy-MM-dd HH:mm"),
        durationMinutes: values.durationMinutes,
        eventType: values.eventType,
        language: values.language,
        isGeneral: true,
        speakerIds: [values.speakerOne, values.speakerTwo, values.speakerThree, values.speakerFour, values.speakerFive].filter(Boolean),
        moderatorIds: [values.moderatorOne, values.moderatorTwo, values.moderatorThree, values.moderatorFour, values.moderatorFive].filter(Boolean),
        repId: values.repId,
        street: values.street !== "" ? values.street : undefined,
        city: values.city !== "" ? values.city : undefined,
        postal: values.postal !== "" ? values.postal : undefined,
        province: values.province !== "" ? values.province : undefined,
      });
      const diplayDate = language === "English" ? format(values.startTime, "MMM d, yyyy h:mm aa") : format(values.startTime, "dd MMMM, YYY H 'h 'mm");
      setLoading(false);
      closeModal();
      showSuccess(` ${formatEventName(newEvent)}`);
    } catch (error: any) {
      if (error.response) {
        if (error.response.data.errorsValidation) {
          const formattedErrors = formatFormErrors(error.response.data.errorsValidation);
          formik.setErrors(formattedErrors);
          return;
        }
        setLoading(false);
      }
      setDisplayError(t("create_forms_request_fail"));
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      edit ? editSubmit(values) : createSubmit(values);
    },
  });

  const speakerValidationSchema = yup.object({
    firstName: yup.string().required(t("event_form.speaker_form.errors.first_name_required")),
    lastName: yup.string().required(t("event_form.speaker_form.errors.last_name_required")),
    email: yup.string().email(t("event_form.speaker_form.errors.invalid_email")).required(t("event_form.speaker_form.errors.email_required"))
  });

  const speakerFormik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      speakerField: "",
    },
    validationSchema: speakerValidationSchema,
    validateOnBlur: false,
    onSubmit: async (values) => {
      try {
        const newSpeaker = await createSpeaker(values.firstName + " " + values.lastName, values.email, false);
        formik.setFieldValue(values.speakerField, newSpeaker.speakerId);
        setDisplaySpeakerSuccess(t("event_form.speaker_form.added"));
        setTimeout(() => {
          handleSpeakerFormBackButton();
        }, 2000);
      } catch (err: any) {
        setDisplaySpeakerSuccess("");
        speakerFormik.setErrors({ email: t("event_form.speaker_form.errors.email_in_use") });
      }
    },
  });

  const moderatorValidationSchema = yup.object({
    firstName: yup.string().required(t("event_form.moderator_form.errors.first_name_required")),
    lastName: yup.string().required(t("event_form.moderator_form.errors.last_name_required")),
    email: yup.string().email(t("event_form.moderator_form.errors.invalid_email")).required(t("event_form.moderator_form.errors.email_required"))
  });

  const moderatorFormik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      moderatorField: "",
    },
    validationSchema: moderatorValidationSchema,
    validateOnBlur: false,
    onSubmit: async (values) => {
      try {
        const newModerator = await createSpeaker(values.firstName + " " + values.lastName, values.email, true);
        formik.setFieldValue(values.moderatorField, newModerator.speakerId);
        setDisplayModeratorSuccess(t("event_form.moderator_form.added"));
        setTimeout(() => {
          handleModeratorFormBackButton();
        }, 2000);
      } catch (err: any) {
        setDisplayModeratorSuccess("");
        moderatorFormik.setErrors({ email: t("event_form.moderator_form.errors.email_in_use") });
      }
    },
  });

  useEffect(() => {
    if (hasRole(["rep"])) {
      // console.log(userId);
      formik.setFieldValue("repId", userId);
    }
  }, [userId]);

  // True/False value if the user has fully filled out the 'EVENT' tab on the modal
  const CompletedEventTab = () => {
    if (selectedType < 1) {
      return formik.values.eventType != "" && formik.values.repId != "" && formik.values.regionId != "" && formik.values.language != "" && !formik.errors.startTime;
    } else {
      return formik.values.eventType != "" && formik.values.repId != "" && formik.values.regionId != "" && formik.values.language != "" && formik.values.city != "" && formik.values.street != "" && formik.values.postal != "" && formik.values.province != "";
    }
  };

  function selectTab(event: any, value: number) {
    var hasAccess = false;
    if (value === 1 && CompletedEventTab()) {
      hasAccess = true;
    } else if (value === 0) {
      hasAccess = true;
    } else if (value === 2 && formik.values.speakerOne !== "") {
      hasAccess = true;
    }
    if (hasAccess) setCurrentTab(value);
  }

  const handleChangeTherapeuticArea = (event: SelectChangeEvent | null, therapeuticArea?: string) => {
    let value = "";

    if (event) {
      value = event.target.value;
    } else {
      value = therapeuticArea ? therapeuticArea : "";
    }

    if (value) {
      formik.setFieldValue("therapeuticArea", value);
    } else {
      formik.setFieldValue("therapeuticArea", "");
    }

    formik.setFieldValue("learningObjectiveIds", []);

    formik.setFieldValue("learningObjectiveOne", { ...emptyLearningObjectives });
    formik.setFieldValue("learningObjectiveTwo", { ...emptyLearningObjectives });
    formik.setFieldValue("learningObjectiveThree", { ...emptyLearningObjectives });
    formik.setFieldValue("learningObjectiveFour", { ...emptyLearningObjectives });
    formik.setFieldValue("learningObjectiveFive", { ...emptyLearningObjectives });
    formik.setFieldValue("learningObjectiveSix", { ...emptyLearningObjectives });
    setLearningObjectiveCount(3);

    setSelectedLearningObjectives([]);
    setLearningObjectivesArray([]);

    const learningObjectivesArrayFilter: LearningObjective[] = [];
    learningObjectives.forEach((objective: LearningObjective) => {
      value.toLowerCase() === objective.therapeuticArea && objective.showInEval ? learningObjectivesArrayFilter.push(objective) : console.log("no match");
    });

    setLearningObjectivesArray(learningObjectivesArrayFilter);
  };

  function handleEventType(event: any) {
    const eventTypeIndex = Number(event.target.getAttribute("index"));

    setSelectedType(eventTypeIndex);

    var type = "";

    switch (eventTypeIndex) {
      case EVENT_TYPES.VIRTUAL:
        type = "Virtual";
        break;
      case EVENT_TYPES.LIVE:
        type = "Live";
        break;
      case EVENT_TYPES.HYBRID:
        type = "Hybrid";
        break;
    }

    formik.setFieldValue("eventType", type);
  }

  const handleAutoCompleteSelect = (reason: AutocompleteChangeReason, value: any, field: string) => {
    if (reason === "selectOption") {
      formik.setFieldValue(field, value);
    } else if (reason === "clear") {
      //MOVE THE LEARNING OBJECTIVES UP THE CHAIN IF ONE ABOVE IS DELETED
      switch (field) {
        case "learningObjectiveOne":
          formik.setFieldValue(field, { ...formik.values.learningObjectiveTwo });
          formik.setFieldValue("learningObjectiveTwo", { ...formik.values.learningObjectiveThree });
          formik.setFieldValue("learningObjectiveThree", { ...formik.values.learningObjectiveFour });
          formik.setFieldValue("learningObjectiveFour", { ...formik.values.learningObjectiveFive });
          formik.setFieldValue("learningObjectiveFive", { ...formik.values.learningObjectiveSix });
          formik.setFieldValue("learningObjectiveSix", { ...emptyLearningObjectives });
          break;
        case "learningObjectiveTwo":
          formik.setFieldValue(field, { ...formik.values.learningObjectiveThree });
          formik.setFieldValue("learningObjectiveThree", { ...formik.values.learningObjectiveFour });
          formik.setFieldValue("learningObjectiveFour", { ...formik.values.learningObjectiveFive });
          formik.setFieldValue("learningObjectiveFive", { ...formik.values.learningObjectiveSix });
          formik.setFieldValue("learningObjectiveSix", { ...emptyLearningObjectives });
          break;
        case "learningObjectiveThree":
          formik.setFieldValue(field, { ...formik.values.learningObjectiveFour });
          formik.setFieldValue("learningObjectiveFour", { ...formik.values.learningObjectiveFive });
          formik.setFieldValue("learningObjectiveFive", { ...formik.values.learningObjectiveSix });
          formik.setFieldValue("learningObjectiveSix", { ...emptyLearningObjectives });
          break;
        case "learningObjectiveFour":
          formik.setFieldValue(field, { ...formik.values.learningObjectiveFive });
          formik.setFieldValue("learningObjectiveFive", { ...formik.values.learningObjectiveSix });
          formik.setFieldValue("learningObjectiveSix", { ...emptyLearningObjectives });
          break;
        case "learningObjectiveFive":
          formik.setFieldValue(field, { ...formik.values.learningObjectiveThree });
          formik.setFieldValue("learningObjectiveSix", { ...emptyLearningObjectives });
          break;
        case "speakerOne":
          formik.setFieldValue(field, formik.values.speakerTwo);
          formik.setFieldValue("speakerTwo", formik.values.speakerThree);
          formik.setFieldValue("speakerThree", formik.values.speakerFour);
          formik.setFieldValue("speakerFour", formik.values.speakerFive);
          formik.setFieldValue("speakerFive", "");
          break;
        case "speakerTwo":
          formik.setFieldValue(field, formik.values.speakerThree);
          formik.setFieldValue("speakerThree", formik.values.speakerFour);
          formik.setFieldValue("speakerFour", formik.values.speakerFive);
          formik.setFieldValue("speakerFive", "");
          break;
        case "speakerThree":
          formik.setFieldValue(field, formik.values.speakerFour);
          formik.setFieldValue("speakerFour", formik.values.speakerFive);
          formik.setFieldValue("speakerFive", "");
          break;
        case "speakerFour":
          formik.setFieldValue(field, formik.values.speakerFive);
          formik.setFieldValue("speakerFive", "");
          break;
        case "moderatorOne":
          formik.setFieldValue(field, formik.values.moderatorTwo);
          formik.setFieldValue("moderatorTwo", formik.values.moderatorThree);
          formik.setFieldValue("moderatorThree", formik.values.moderatorFour);
          formik.setFieldValue("moderatorFour", formik.values.moderatorFive);
          formik.setFieldValue("moderatorFive", "");
          break;
        case "moderatorTwo":
          formik.setFieldValue(field, formik.values.moderatorThree);
          formik.setFieldValue("moderatorThree", formik.values.moderatorFour);
          formik.setFieldValue("moderatorFour", formik.values.moderatorFive);
          formik.setFieldValue("moderatorFive", "");
          break;
        case "moderatorThree":
          formik.setFieldValue(field, formik.values.moderatorFour);
          formik.setFieldValue("moderatorFour", formik.values.moderatorFive);
          formik.setFieldValue("moderatorFive", "");
        break;
        case "moderatorFour":
          formik.setFieldValue(field, formik.values.moderatorFive);
          formik.setFieldValue("moderatorFive", "");
          break;
        default:
          formik.setFieldValue(field, "");
          break;
      }
    }
  };

  // clear out the new speaker form when clicking the back button so it can be used again
  const handleSpeakerFormBackButton = () => {
    speakerFormik.setFieldValue("firstName", "");
    speakerFormik.setFieldValue("lastName", "");
    speakerFormik.setFieldValue("email", "");

    speakerFormik.setFieldValue("speakerField", "");
    speakerFormik.resetForm();
    setDisplaySpeakerSuccess("");

    setShowAddSpeaker(false);
    setShowExitButton(true);
  };

  // clear out the new moderator form when clicking the back button so it can be used again
  const handleModeratorFormBackButton = () => {
    moderatorFormik.setFieldValue("firstName", "");
    moderatorFormik.setFieldValue("lastName", "");
    moderatorFormik.setFieldValue("email", "");

    moderatorFormik.setFieldValue("moderatorField", "");
    moderatorFormik.resetForm();
    setDisplayModeratorSuccess("");

    setShowAddModerator(false);
    setShowExitButton(true);
  };

  const onChangeDate = async (value: Date | null) => {
    if (value) {
      await formik.setFieldError("startTime", "test")
      formik.setFieldValue("startTime", value);
    } else {
      formik.setFieldValue("startTime", "");
    }
  }

  const onChange = (
    value: DatePickerProps['value'] | RangePickerProps['value'],
    dateString: [string, string] | string,
  ) => {
    if (value) {
      formik.setFieldValue("startTime", new Date(value.toString()));  
    } else {
      formik.setFieldValue("startTime", new Date());
    }
  };
  
  const onOk = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
    if (value) {
      formik.setFieldValue("startTime", new Date(value.toString()));
    }
  };

  return (
    <>
      {currentTab === 0 && !showAddModerator && !showAddSpeaker ? (
        <InstructionsPopover title={t("instructions.general_event_form.event.title")} isModal={true} width="300px">
          <>
            <p>{t("instructions.general_event_form.event.content_1")}</p>
            <p>{t("instructions.general_event_form.event.content_2")}</p>
          </>
        </InstructionsPopover>
      ) : currentTab === 1 && !showAddModerator && !showAddSpeaker ? (
        <InstructionsPopover title={t("instructions.general_event_form.presenters.title")} isModal={true} width="300px">
          <>
            <p>{t("instructions.general_event_form.presenters.content_1")}</p>
            <p>{t("instructions.general_event_form.presenters.content_2")}</p>
            <p>{t("instructions.general_event_form.presenters.content_3")}</p>
          </>
        </InstructionsPopover>
      ) : currentTab === 2 && !showAddModerator && !showAddSpeaker ? (
        <InstructionsPopover title={t("instructions.general_event_form.program.title")} isModal={true} width="300px">
          <>
            <p>{t("instructions.general_event_form.program.content_1")}</p>
            <p>{t("instructions.general_event_form.program.content_2")}</p>
            <p>{t("instructions.general_event_form.program.content_3")}</p>
          </>
        </InstructionsPopover>
      ) : <></>}

      <Box hidden={!showAddSpeaker} sx={{ position: "relative" }}>
        {!displaySpeakerSuccess &&
        <BackButton sx={{ left: 10 }} onClick={() => handleSpeakerFormBackButton()}>
          <ArrowBack />
        </BackButton>
        }
        <ModalTitle>{t("event_form.speaker_form.title")}</ModalTitle>
        <div>
          {displaySpeakerSuccess ? <Alert severity="success">{displaySpeakerSuccess}</Alert> : ""}
        </div>
        {!displaySpeakerSuccess &&
        <form onSubmit={speakerFormik.handleSubmit}>
          <Grid container spacing={3} style={{ marginTop: "10px", textAlign: "center" }} justify-content="center">
            <Grid item xs={6} sx={{ paddingTop: "20px!important" }}>
              <TextField value={speakerFormik.values.firstName} fullWidth size="medium" id="speakerFirstName" label={t("event_form.speaker_form.labels.first_name")} type="text" variant="outlined" error={speakerFormik.touched.firstName && Boolean(speakerFormik.errors.firstName)} helperText={speakerFormik.touched.firstName && speakerFormik.errors.firstName} onChange={(e) => speakerFormik.setFieldValue("firstName", e.target.value)} focused />
            </Grid>
            <Grid item xs={6} sx={{ paddingTop: "20px!important" }}>
              <TextField value={speakerFormik.values.lastName} fullWidth size="medium" id="speakerLastName" label={t("event_form.speaker_form.labels.last_name")} type="text" variant="outlined" error={speakerFormik.touched.lastName && Boolean(speakerFormik.errors.lastName)} helperText={speakerFormik.touched.lastName && speakerFormik.errors.lastName} onChange={(e) => speakerFormik.setFieldValue("lastName", e.target.value)} focused />
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "20px!important" }}>
              <TextField value={speakerFormik.values.email} fullWidth size="medium" id="speakerEmail" label={t("event_form.speaker_form.labels.email")} type="text" variant="outlined" error={speakerFormik.touched.email && Boolean(speakerFormik.errors.email)} helperText={speakerFormik.touched.email && speakerFormik.errors.email} onChange={(e) => speakerFormik.setFieldValue("email", e.target.value)} focused />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <NextButton type="submit" sx={{ marginTop: "30px", textAlign: "center" }}>
              {t("activity_form.add_btn")}
              </NextButton>
            </Grid>
          </Grid>
        </form>
        }
      </Box>
      <Box hidden={!showAddModerator} sx={{ position: "relative" }}>
        {!displayModeratorSuccess &&
        <BackButton sx={{ left: 10 }} onClick={() => handleModeratorFormBackButton()}>
          <ArrowBack />
        </BackButton>
        }
        <ModalTitle>{ t("event_form.moderator_form.title") }</ModalTitle>
        <div>
          {displayModeratorSuccess ? <Alert severity="success">{displayModeratorSuccess}</Alert> : ""}
        </div>
        {!displayModeratorSuccess &&
        <form onSubmit={moderatorFormik.handleSubmit}>
          <Grid container spacing={3} style={{ marginTop: "10px", textAlign: "center" }} justify-content="center">
            <Grid item xs={6} sx={{ paddingTop: "20px!important" }}>
              <TextField value={moderatorFormik.values.firstName} fullWidth size="medium" id="moderatorFirstName" label={t("event_form.moderator_form.labels.first_name")} type="text" variant="outlined" error={moderatorFormik.touched.firstName && Boolean(moderatorFormik.errors.firstName)} helperText={moderatorFormik.touched.firstName && moderatorFormik.errors.firstName} onChange={(e) => moderatorFormik.setFieldValue("firstName", e.target.value)} focused />
            </Grid>
            <Grid item xs={6} sx={{ paddingTop: "20px!important" }}>
              <TextField value={moderatorFormik.values.lastName} fullWidth size="medium" id="moderatorLastName" label={t("event_form.moderator_form.labels.last_name")} type="text" variant="outlined" error={moderatorFormik.touched.lastName && Boolean(moderatorFormik.errors.lastName)} helperText={moderatorFormik.touched.lastName && moderatorFormik.errors.lastName} onChange={(e) => moderatorFormik.setFieldValue("lastName", e.target.value)} focused />
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "20px!important" }}>
              <TextField value={moderatorFormik.values.email} fullWidth size="medium" id="moderatorEmail" label={t("event_form.moderator_form.labels.email")} type="text" variant="outlined" error={moderatorFormik.touched.email && Boolean(moderatorFormik.errors.email)} helperText={moderatorFormik.touched.email && moderatorFormik.errors.email} onChange={(e) => moderatorFormik.setFieldValue("email", e.target.value)} focused />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <NextButton type="submit" sx={{ marginTop: "30px", textAlign: "center" }}>
              {t("activity_form.add_btn")}
              </NextButton>
            </Grid>
          </Grid>
        </form>
        }
      </Box>

      <Box my={0} hidden={showAddSpeaker || showAddModerator}>
        <ModalTitle>{!edit ? t("create_event_page_title") : t("edit_event_page_title")}</ModalTitle>
        <div>
          {displayError ? <Alert severity="error">{displayError}</Alert> : ""}
        </div>
        <form onSubmit={formik.handleSubmit}>
          <Box>
            <TabContainer>
              <Tabs value={currentTab} onChange={selectTab} aria-label="Create event tabs">
                <ModalTab label={t("event")} />
                <ModalTab label={t("presenters")} />
                <ModalTab label={t("evaluation.evaluation_form.program_title")} />
              </Tabs>
            </TabContainer>
            <TabPanel value={currentTab} index={0}>
              <Grid container spacing={3} style={{ marginTop: "10px" }} justify-content="center">
                {edit && event && (
                  <>
                    <Grid item xs={12} sx={{ paddingTop: "20px!important" }}>
                      <TextField value={formatEventName(event)} fullWidth size="medium" id="eventName" label={t("create_event_form_name")} type="text" variant="outlined" disabled={true} focused />
                    </Grid>
                    <Grid item xs={12} sx={{ paddingTop: "20px!important" }}>
                      <TextField value={formik.values.title} placeholder={t("create_event_form_custom_name")} fullWidth size="medium" id="title" name="title" label="" type="text" variant="outlined" focused onChange={formik.handleChange} />
                    </Grid>
                  </>
                )}
                
                <Grid item xs={6} justifyContent="center">
                  <FormControl id="startTime" fullWidth focused variant="filled">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <FormLabel sx={{position: "absolute", top: -10, left: 10, zIndex: 10, fontSize: "13px", background: "white", px: "6px"}}>{t("create_event_form_date_label")}</FormLabel>
                      <StyledDatePicker value={dayjs(formik.values.startTime)} size="large" showTime={{ format: GetTimeDisplayFormat(i18next.language) }} suffixIcon={<EventIcon />} allowClear={false} use12Hours={i18next.language === "en" ? true : undefined} format={GetTimeInputFieldFormat(i18next.language)} onChange={onChange} onOk={onOk} />
                    </LocalizationProvider>
                    {formik.touched.startTime && formik.errors.startTime && (
                      <FormHelperText>
                        <>{formik.errors.startTime}</>
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    // disablePortal
                    PaperComponent={(props: any) => <Paper {...props} sx={{ maxHeight: 250 }}></Paper>}
                    PopperComponent={(props: any) => <Popper {...props} placement="bottom"></Popper>}
                    id="rep"
                    options={reps.filter((rep) => rep.status !== "deleted")}
                    disabled={hasRole(["rep"])}
                    defaultValue={hasRole(["rep"]) ? reps.find((i) => i.id == userId) || null : null}
                    value={reps.find((i: Users) => i.id == formik.values.repId)}
                    isOptionEqualToValue={(option, value) => {
                      if (value) return option.id === value.id;
                      return false;
                    }}
                    autoHighlight
                    onChange={(event, value, reason, details) => handleAutoCompleteSelect(reason, value?.id || "", "repId")}
                    getOptionLabel={(option) => `${option.displayName} (${option.email})`}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.displayName} ({option.email})
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        focused
                        {...params}
                        label={t("create_event_form_rep_label")}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    PaperComponent={(props: any) => <Paper {...props} sx={{ maxHeight: 250, width: "250px" }}></Paper>}
                    PopperComponent={(props: any) => <Popper {...props} placement="bottom"></Popper>}
                    id="region"
                    options={regions ? regions.sort((a,b) => a.value - b.value) : []}
                    value={regions.find((i) => i._id === formik.values.regionId)}
                    isOptionEqualToValue={(option, value) => {
                      if (value) return option._id === value._id;
                      return false;
                    }}
                    autoHighlight
                    getOptionLabel={(option) => language === "en" ? option.enName : option.frName}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {language === "en" ? option.enName : option.frName}
                      </Box>
                    )}
                    onChange={(event, value, reason, details) => {
                      handleAutoCompleteSelect(reason, value?._id || "", "regionId");
                    }}
                    renderInput={(params) => (
                      <TextField
                        focused
                        {...params}
                        label={`${t("create_event_form_region_label")}*`}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth focused error={formik.touched.timeZone && Boolean(formik.errors.timeZone)}>
                    <InputLabel> {t("create_event_form_timezone")}* </InputLabel>
                    <Select defaultValue="Canada/Eastern" id="timeZone" name="timeZone" variant="outlined" label={`${t("create_event_form_timezone")}*`} disabled={false} type="text" value={formik.values.timeZone} onChange={formik.handleChange} error={formik.touched.timeZone && Boolean(formik.errors.timeZone)}>
                      <MenuItem value="Canada/Eastern">{t("time_zones.Eastern")}</MenuItem>
                      <MenuItem value="Canada/Pacific">{t("time_zones.Pacific")}</MenuItem>
                      <MenuItem value="Canada/Central">{t("time_zones.Central")}</MenuItem>
                      <MenuItem value="Canada/Atlantic">{t("time_zones.Atlantic")}</MenuItem>
                      <MenuItem value="Canada/Newfoundland">{t("time_zones.Newfoundland")}</MenuItem>
                      <MenuItem value="Canada/Mountain">{t("time_zones.Mountain")}</MenuItem>
                    </Select>
                    {formik.touched.timeZone && <FormHelperText>{formik.errors.timeZone}</FormHelperText>}
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <Autocomplete
                    id="language"
                    options={languages}
                    value={languages.find((i) => i.value === formik.values.language) || null}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    autoHighlight
                    getOptionLabel={(option) => language === "en" ? option.name: option.frName}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {language === "en" ? option.name : option.frName}
                      </Box>
                    )}
                    onChange={(event, value, reason, details) => handleAutoCompleteSelect(reason, value?.value, "language")}
                    renderInput={(params) => (
                      <TextField
                        focused
                        {...params}
                        label={`${t("create_event_form_language_label")}*`}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: "10px!important" }}>
                  <StyledFormLabel id="event-type-label">{t("create_event_form_event_type_label")}*</StyledFormLabel>
                  <div className="d-flex event-type-list">
                    <StyledEventTypeButton index={0} selected={selectedType} onClick={handleEventType}>
                    {t("virtual")}
                    </StyledEventTypeButton>
                    <StyledEventTypeButton index={1} selected={selectedType} onClick={handleEventType}>
                    {t("live")}
                    </StyledEventTypeButton>
                    <StyledEventTypeButton index={2} selected={selectedType} onClick={handleEventType}>
                    {t("hybrid")}
                    </StyledEventTypeButton>
                  </div>
                </Grid>
                <Grid hidden={selectedType < 1} item xs={6}>
                  <TextField focused fullWidth size="medium" id="street" label={`${t("create_event_form_street_label")}*`} type="text" variant="outlined" onChange={(e) => formik.setFieldValue("street", e.target.value)} value={formik.values.street} />
                </Grid>
                <Grid hidden={selectedType < 1} item xs={6}>
                  <TextField focused fullWidth size="medium" id="city" label={`${t("create_event_form_city_label")}*`} type="text" variant="outlined" onChange={(e) => formik.setFieldValue("city", e.target.value)} value={formik.values.city} />
                </Grid>
                <Grid hidden={selectedType < 1} item xs={6}>
                  <FormControl fullWidth focused error={formik.touched.province && Boolean(formik.errors.province)}>
                    <InputLabel> {t("create_event_form_province_label")}* </InputLabel>
                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        PaperProps: { sx: { maxHeight: 205 } },
                      }}
                      defaultValue="Alberta"
                      id="province"
                      name="province"
                      variant="outlined"
                      label={`${t("create_event_form_province_label")}*`}
                      disabled={false}
                      type="text"
                      value={formik.values.province}
                      onChange={formik.handleChange}
                      error={formik.touched.province && Boolean(formik.errors.province)}
                    >
                      {provinceList.map((province) => {
                        return <MenuItem value={province.value}>{language === "en" ? province.name : province.frName}</MenuItem>;
                      })}
                    </Select>
                    {formik.touched.province && <FormHelperText>{formik.errors.province}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid hidden={selectedType < 1} item xs={6}>
                  <TextField focused fullWidth size="medium" id="postal" label={`${t("create_event_form_postal_label")}*`} type="text" variant="outlined" onChange={(e) => formik.setFieldValue("postal", e.target.value)} value={formik.values.postal} />
                </Grid>
                <Grid item xs={12} sx={{ textAlign: "center", marginTop: "20px" }}>
                  <NextButton className="next-button" disabled={!CompletedEventTab()} value={1} onClick={handleNext}>
                    {t("create_event_form_next_button")}
                  </NextButton>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              <Grid container spacing={3} style={{ marginTop: "10px" }} justify-content="center">
                <Grid item xs={6}>
                  <Autocomplete
                    // disablePortal
                    id="speaker"
                    options={speakers}
                    autoHighlight
                    value={speakers.find((i) => i.speakerId === formik.values.speakerOne) || null}
                    onChange={(e, value, reason, details) => {
                      if (value && !value.speakerId) {
                        formik.setFieldValue("speakerOne", "");
                        speakerFormik.setFieldValue("speakerField", "speakerOne");
                        setShowExitButton(false);
                        setShowAddSpeaker(true);
                      } else {
                        handleAutoCompleteSelect(reason, value?.speakerId, "speakerOne");
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = speakerFilter(options, params);
                      if (params.inputValue !== "") {
                        filtered.push({
                          displayName: t("create_event_form_add_speaker"),
                          email: "+",
                        } as Speaker);
                      }
                      return filtered;
                    }}
                    getOptionLabel={(option) => `${option.displayName} (${option.email})`}
                    renderOption={(props, option) => (
                      <Box sx={{backgroundColor: (theme) => option.email === "+" ? `${theme.palette.primary.light}!important`: "white", fontWeight:  option.email === "+" ? "500" : "normal"}} component="li" {...props}>
                        { option.email === "+" ? `${option.displayName} +`  : `${option.displayName} (${option.email})` }
                      </Box>
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        focused
                        {...params}
                        label={t("create_event_form_speaker")}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    // disablePortal
                    id="moderator"
                    options={speakers}
                    value={speakers.find((i) => i.speakerId === formik.values.moderatorOne) || null}
                    onChange={(e, value, reason, details) => {
                      if (value && !value.speakerId) {
                        formik.setFieldValue("moderatorOne", "");
                        moderatorFormik.setFieldValue("moderatorField", "moderatorOne");
                        setShowExitButton(false);
                        setShowAddModerator(true);
                      } else {
                        handleAutoCompleteSelect(reason, value?.speakerId, "moderatorOne");
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = speakerFilter(options, params);
                      if (params.inputValue !== "") {
                        filtered.push({
                          displayName: t("create_event_form_add_moderator"),
                          email: "+",
                        } as Speaker);
                      }
                      return filtered;
                    }}
                    autoHighlight
                    getOptionLabel={(option) => `${option.displayName} (${option.email})`}
                    renderOption={(props, option) => (
                      <Box sx={{backgroundColor: (theme) => option.email === "+" ? `${theme.palette.primary.light}!important`: "white", fontWeight:  option.email === "+" ? "500" : "normal"}} component="li" {...props}>
                        { option.email === "+" ? `${option.displayName} +`  : `${option.displayName} (${option.email})` }
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        focused
                        {...params}
                        label={t("create_event_form_moderator")}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    // disablePortal
                    id="speaker2"
                    options={speakers.filter((i) => i.speakerId !== formik.values.speakerOne)}
                    disabled={formik.values.speakerOne == ""}
                    isOptionEqualToValue={(option, value) => {
                      return option.speakerId === value.speakerId;
                    }}
                    value={speakers.find((i) => i.speakerId === formik.values.speakerTwo) || null}
                    onChange={(e, value, reason, details) => {
                      if (value && !value.speakerId) {
                        formik.setFieldValue("speakerTwo", "");
                        speakerFormik.setFieldValue("speakerField", "speakerTwo");
                        setShowExitButton(false);
                        setShowAddSpeaker(true);
                      } else {
                        handleAutoCompleteSelect(reason, value?.speakerId, "speakerTwo");
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = speakerFilter(options, params);
                      if (params.inputValue !== "") {
                        filtered.push({
                          displayName: t("create_event_form_add_speaker"),
                          email: "+",
                        } as Speaker);
                      }
                      return filtered;
                    }}
                    autoHighlight
                    getOptionLabel={(option) => `${option.displayName} (${option.email})`}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.displayName} ({option.email})
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        focused
                        {...params}
                        label={t("create_event_form_speaker_2")}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    // disablePortal
                    id="moderator2"
                    options={speakers.filter((i) => i.speakerId !== formik.values.moderatorOne)}
                    disabled={formik.values.moderatorOne == ""}
                    value={speakers.find((i) => i.speakerId === formik.values.moderatorTwo) || null}
                    onChange={(e, value, reason, details) => {
                      if (value && !value.speakerId) {
                        formik.setFieldValue("moderatorTwo", "");
                        moderatorFormik.setFieldValue("moderatorField", "moderatorTwo");
                        setShowExitButton(false);
                        setShowAddModerator(true);
                      } else {
                        handleAutoCompleteSelect(reason, value?.speakerId, "moderatorTwo");
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = speakerFilter(options, params);
                      if (params.inputValue !== "") {
                        filtered.push({
                          displayName: t("create_event_form_add_moderator"),
                          email: "+",
                        } as Speaker);
                      }
                      return filtered;
                    }}
                    autoHighlight
                    getOptionLabel={(option) => `${option.displayName} (${option.email})`}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.displayName} ({option.email})
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        focused
                        {...params}
                        label={t("create_event_form_moderator_2")}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    // disablePortal
                    id="speaker3"
                    options={speakers.filter((i) => ![formik.values.speakerOne, formik.values.speakerTwo].includes(i.speakerId))}
                    disabled={formik.values.speakerTwo == ""}
                    isOptionEqualToValue={(option, value) => {
                      return option.speakerId === value.speakerId;
                    }}
                    autoHighlight
                    getOptionLabel={(option) => `${option.displayName} (${option.email})`}
                    value={speakers.find((i) => i.speakerId === formik.values.speakerThree) || null}
                    onChange={(e, value, reason, details) => {
                      if (value && !value.speakerId) {
                        formik.setFieldValue("speakerThree", "");
                        speakerFormik.setFieldValue("speakerField", "speakerThree");
                        setShowExitButton(false);
                        setShowAddSpeaker(true);
                      } else {
                        handleAutoCompleteSelect(reason, value?.speakerId, "speakerThree");
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = speakerFilter(options, params);
                      if (params.inputValue !== "") {
                        filtered.push({
                          displayName: t("create_event_form_add_speaker"),
                          email: "+",
                        } as Speaker);
                      }
                      return filtered;
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.displayName} ({option.email})
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        focused
                        {...params}
                        label={t("create_event_form_speaker_3")}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    // disablePortal
                    id="moderator3"
                    options={speakers.filter((i) => ![formik.values.moderatorTwo, formik.values.moderatorOne].includes(i.speakerId))}
                    disabled={formik.values.moderatorTwo == ""}
                    value={speakers.find((i) => i.speakerId === formik.values.moderatorThree) || null}
                    onChange={(e, value, reason, details) => {
                      if (value && !value.speakerId) {
                        formik.setFieldValue("moderatorThree", "");
                        moderatorFormik.setFieldValue("moderatorField", "moderatorThree");
                        setShowExitButton(false);
                        setShowAddModerator(true);
                      } else {
                        handleAutoCompleteSelect(reason, value?.speakerId, "moderatorThree");
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = speakerFilter(options, params);
                      if (params.inputValue !== "") {
                        filtered.push({
                          displayName: t("create_event_form_add_moderator"),
                          email: "+",
                        } as Speaker);
                      }
                      return filtered;
                    }}
                    autoHighlight
                    getOptionLabel={(option) => `${option.displayName} (${option.email})`}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.displayName} ({option.email})
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        focused
                        {...params}
                        label={t("create_event_form_moderator_3")}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    // disablePortal
                    id="speaker4"
                    options={speakers.filter((i) => ![formik.values.speakerOne, formik.values.speakerTwo, formik.values.speakerThree].includes(i.speakerId))}
                    disabled={formik.values.speakerThree == ""}
                    isOptionEqualToValue={(option, value) => {
                      return option.speakerId === value.speakerId;
                    }}
                    autoHighlight
                    getOptionLabel={(option) => `${option.displayName} (${option.email})`}
                    value={speakers.find((i) => i.speakerId === formik.values.speakerFour) || null}
                    onChange={(e, value, reason, details) => {
                      if (value && !value.speakerId) {
                        formik.setFieldValue("speakerFour", "");
                        speakerFormik.setFieldValue("speakerField", "speakerFour");
                        setShowExitButton(false);
                        setShowAddSpeaker(true);
                      } else {
                        handleAutoCompleteSelect(reason, value?.speakerId, "speakerFour");
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = speakerFilter(options, params);
                      if (params.inputValue !== "") {
                        filtered.push({
                          displayName: t("create_event_form_add_speaker"),
                          email: "+",
                        } as Speaker);
                      }
                      return filtered;
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.displayName} ({option.email})
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        focused
                        {...params}
                        label={t("create_event_form_speaker_4")}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    // disablePortal
                    id="moderator4"
                    options={speakers.filter((i) => ![formik.values.moderatorTwo, formik.values.moderatorOne, formik.values.moderatorThree].includes(i.speakerId))}
                    disabled={formik.values.moderatorThree == ""}
                    value={speakers.find((i) => i.speakerId === formik.values.moderatorFour) || null}
                    onChange={(e, value, reason, details) => {
                      if (value && !value.speakerId) {
                        formik.setFieldValue("moderatorFour", "");
                        moderatorFormik.setFieldValue("moderatorField", "moderatorFour");
                        setShowExitButton(false);
                        setShowAddModerator(true);
                      } else {
                        handleAutoCompleteSelect(reason, value?.speakerId, "moderatorFour");
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = speakerFilter(options, params);
                      if (params.inputValue !== "") {
                        filtered.push({
                          displayName: t("create_event_form_add_moderator"),
                          email: "+",
                        } as Speaker);
                      }
                      return filtered;
                    }}
                    autoHighlight
                    getOptionLabel={(option) => `${option.displayName} (${option.email})`}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.displayName} ({option.email})
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        focused
                        {...params}
                        label={t("create_event_form_moderator_4")}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    // disablePortal
                    id="speaker5"
                    options={speakers.filter((i) => ![formik.values.speakerOne, formik.values.speakerTwo, formik.values.speakerThree, formik.values.speakerFour].includes(i.speakerId))}
                    disabled={formik.values.speakerFour == ""}
                    isOptionEqualToValue={(option, value) => {
                      return option.speakerId === value.speakerId;
                    }}
                    autoHighlight
                    getOptionLabel={(option) => `${option.displayName} (${option.email})`}
                    value={speakers.find((i) => i.speakerId === formik.values.speakerFive) || null}
                    onChange={(e, value, reason, details) => {
                      if (value && !value.speakerId) {
                        formik.setFieldValue("speakerFive", "");
                        speakerFormik.setFieldValue("speakerField", "speakerFive");
                        setShowExitButton(false);
                        setShowAddSpeaker(true);
                      } else {
                        handleAutoCompleteSelect(reason, value?.speakerId, "speakerFive");
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = speakerFilter(options, params);
                      if (params.inputValue !== "") {
                        filtered.push({
                          displayName: t("create_event_form_add_speaker"),
                          email: "+",
                        } as Speaker);
                      }
                      return filtered;
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.displayName} ({option.email})
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        focused
                        {...params}
                        label={t("create_event_form_speaker_5")}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    // disablePortal
                    id="moderator5"
                    options={speakers.filter((i) => ![formik.values.moderatorTwo, formik.values.moderatorOne, formik.values.moderatorThree, formik.values.moderatorFour].includes(i.speakerId))}
                    disabled={formik.values.moderatorFour == ""}
                    value={speakers.find((i) => i.speakerId === formik.values.moderatorFive) || null}
                    onChange={(e, value, reason, details) => {
                      if (value && !value.speakerId) {
                        formik.setFieldValue("moderatorFive", "");
                        moderatorFormik.setFieldValue("moderatorField", "moderatorFive");
                        setShowExitButton(false);
                        setShowAddModerator(true);
                      } else {
                        handleAutoCompleteSelect(reason, value?.speakerId, "moderatorFive");
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = speakerFilter(options, params);
                      if (params.inputValue !== "") {
                        filtered.push({
                          displayName: t("create_event_form_add_moderator"),
                          email: "+",
                        } as Speaker);
                      }
                      return filtered;
                    }}
                    autoHighlight
                    getOptionLabel={(option) => `${option.displayName} (${option.email})`}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.displayName} ({option.email})
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        focused
                        {...params}
                        label={t("create_event_form_moderator_5")}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <NextButton className="next-button-2" disabled={formik.values.speakerOne == ""} value={2} onClick={handleNext}>
                  {t("create_event_form_next_button")}
                  </NextButton>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
              <Grid container spacing={3} style={{ marginTop: "10px" }} justifyContent="center">
                <Grid item xs={12}>
                  <FormControl fullWidth focused variant="outlined" error={formik.touched.therapeuticArea && Boolean(formik.errors.therapeuticArea)}>
                    <InputLabel sx={{ backgroundColor: "#ffffff" }}> {t("create_event_form_therapeutic_area")} </InputLabel>
                    <Select id="therapeuticArea" value={formik.values.therapeuticArea} onChange={(e) => handleChangeTherapeuticArea(e)} error={formik.touched.therapeuticArea && Boolean(formik.errors.therapeuticArea)}>
                      {therapeuticAreas.filter(i => i.showInEval).map((area) => (
                        //<MenuItem value={area.value}>{language === "en" ? area.enName : area.frName}</MenuItem>
                        <MenuItem className="therapeuticArea-item" key={area.value} value={area.value}>
                          {/* <Checkbox checked={therapeuticArea.indexOf(area) > -1} /> */}
                          {language === "en" ? area.enName : area.frName}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.therapeuticArea && <FormHelperText>{formik.errors.therapeuticArea}</FormHelperText>}
                  </FormControl>
                </Grid>

                {learningObjectiveCount >= 1 && 
                <>
                <Grid item xs={12}>
                  <Autocomplete
                    id="learningObjectiveOne"
                    limitTags={3}
                    options={learningObjectivesArray}
                    value={learningObjectivesArray.find((i) => i._id === formik.values.learningObjectiveOne._id) || null}
                    disablePortal={true}
                    noOptionsText={t("event_form.no_options")}
                    disabled={formik.values.therapeuticArea === ""}
                    isOptionEqualToValue={(option, value) => {
                      return option._id === value._id;
                    }}
                    getOptionLabel={(option) => (language === "en" ? option.enName : option.frName)}
                    onChange={(event, newValue, reason) => handleAutoCompleteSelect(reason, { _id: newValue?._id || "", customName: "" }, "learningObjectiveOne")}
                    renderOption={(props, option, { selected }) => (
                      <li className="learningObjectiveOne-list-item" {...props}>
                        <Checkbox style={{ marginRight: 8 }} checked={selected} />
                        {language === "en" ? option.enName : option.frName}
                      </li>
                    )}
                    style={{ width: "100%", margin: "0px 0px 10px 0px", backgroundColor: "#ffffff" }}
                    renderInput={(params) => <TextField focused {...params} variant="outlined" label={t("create_event_form_learning_obj_1")} placeholder={selectedLearningObjectives.length === 0 ? t("create_event_form_learning_objectives") : ""} error={Boolean(formik.touched.learningObjectiveOne) && Boolean(formik.errors.learningObjectiveOne)} fullWidth />}
                  />
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: "0px!important" }}>
                  <TextField value={formik.values.learningObjectiveOne.customName} disabled={formik.values.learningObjectiveOne._id === ""} fullWidth size="medium" id="customize-objective-one" name="learningObjectiveOne.customName" placeholder={t("create_event_form_custom_learning_objective_label")} type="text" variant="outlined" onChange={formik.handleChange} focused />
                </Grid> 
                </>}

                {learningObjectiveCount >= 2 && 
                <>
                <Grid item xs={12}>
                  <Autocomplete
                    id="learningObjectiveTwo"
                    limitTags={3}
                    options={learningObjectivesArray}
                    value={learningObjectivesArray.find((i) => i._id === formik.values.learningObjectiveTwo._id) || null}
                    disablePortal={true}
                    noOptionsText={t("event_form.no_options")}
                    disabled={formik.values.learningObjectiveOne._id == ""}
                    isOptionEqualToValue={(option, value) => {
                      return option._id === value._id;
                    }}
                    getOptionLabel={(option) => (language === "en" ? option.enName : option.frName)}
                    onChange={(event, newValue, reason) => handleAutoCompleteSelect(reason, { _id: newValue?._id || "", customName: "" }, "learningObjectiveTwo")}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox style={{ marginRight: 8 }} checked={selected} />
                        {language === "en" ? option.enName : option.frName}
                      </li>
                    )}
                    style={{ width: "100%", margin: "0px 0px 10px 0px", backgroundColor: "#ffffff" }}
                    renderInput={(params) => <TextField focused {...params} variant="outlined" label={t("create_event_form_learning_obj_2")} placeholder={selectedLearningObjectives.length === 0 ? t("create_event_form_learning_objectives") : ""} error={Boolean(formik.touched.learningObjectiveTwo) && Boolean(formik.errors.learningObjectiveTwo)} fullWidth />}
                  />
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: "0px!important" }}>
                  <TextField value={formik.values.learningObjectiveTwo.customName} disabled={formik.values.learningObjectiveTwo._id === ""} fullWidth size="medium" id="customize-objective-two" name="learningObjectiveTwo.customName" placeholder={t("create_event_form_custom_learning_objective_label")} type="text" variant="outlined" onChange={formik.handleChange} focused />
                </Grid>
                </>}

                {learningObjectiveCount >= 3 && 
                <>
                <Grid item xs={12}>
                  <Autocomplete
                    id="learningObjectiveThree"
                    limitTags={3}
                    options={learningObjectivesArray}
                    value={learningObjectivesArray.find((i) => i._id === formik.values.learningObjectiveThree._id) || null}
                    disablePortal={true}
                    noOptionsText={t("event_form.no_options")}
                    disabled={formik.values.learningObjectiveTwo._id == ""}
                    isOptionEqualToValue={(option, value) => {
                      return option._id === value._id;
                    }}
                    getOptionLabel={(option) => (language === "en" ? option.enName : option.frName)}
                    onChange={(event, newValue, reason) => handleAutoCompleteSelect(reason, { _id: newValue?._id || "", customName: "" }, "learningObjectiveThree")}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox style={{ marginRight: 8 }} checked={selected} />
                        {language === "en" ? option.enName : option.frName}
                      </li>
                    )}
                    style={{ width: "100%", margin: "0px 0px 10px 0px", backgroundColor: "#ffffff" }}
                    renderInput={(params) => <TextField focused {...params} variant="outlined" label={t("create_event_form_learning_obj_3")} placeholder={selectedLearningObjectives.length === 0 ? t("create_event_form_learning_objectives") : ""} error={Boolean(formik.touched.learningObjectiveThree) && Boolean(formik.errors.learningObjectiveThree)} fullWidth />}
                  />
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: "0px!important" }}>
                  <TextField value={formik.values.learningObjectiveThree.customName} disabled={formik.values.learningObjectiveThree._id === ""} fullWidth size="medium" id="customize-objective-three" name="learningObjectiveThree.customName" placeholder={t("create_event_form_custom_learning_objective_label")} type="text" variant="outlined" onChange={formik.handleChange} focused />
                </Grid>
                </>
                }

                {learningObjectiveCount >= 4 && 
                <>
                <Grid item xs={12}>
                  <Autocomplete
                    id="learningObjectiveFour"
                    limitTags={3}
                    options={learningObjectivesArray}
                    value={learningObjectivesArray.find((i) => i._id === formik.values.learningObjectiveFour._id) || null}
                    disablePortal={true}
                    noOptionsText={t("event_form.no_options")}
                    disabled={formik.values.learningObjectiveThree._id == ""}
                    isOptionEqualToValue={(option, value) => {
                      return option._id === value._id;
                    }}
                    getOptionLabel={(option) => (language === "en" ? option.enName : option.frName)}
                    onChange={(event, newValue, reason) => handleAutoCompleteSelect(reason, { _id: newValue?._id || "", customName: "" }, "learningObjectiveFour")}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox style={{ marginRight: 8 }} checked={selected} />
                        {language === "en" ? option.enName : option.frName}
                      </li>
                    )}
                    style={{ width: "100%", margin: "0px 0px 10px 0px", backgroundColor: "#ffffff" }}
                    renderInput={(params) => <TextField focused {...params} variant="outlined" label={t("create_event_form_learning_obj_4")} placeholder={selectedLearningObjectives.length === 0 ? t("create_event_form_learning_objectives") : ""} error={Boolean(formik.touched.learningObjectiveFour) && Boolean(formik.errors.learningObjectiveFour)} fullWidth />}
                  />
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: "0px!important" }}>
                  <TextField value={formik.values.learningObjectiveFour.customName} disabled={formik.values.learningObjectiveFour._id === ""} fullWidth size="medium" id="customize-objective-three" name="learningObjectiveFour.customName" placeholder={t("create_event_form_custom_learning_objective_label")} type="text" variant="outlined" onChange={formik.handleChange} focused />
                </Grid>
                </>
                }

                {learningObjectiveCount >= 5 && 
                <>
                <Grid item xs={12}>
                  <Autocomplete
                    id="learningObjectiveFive"
                    limitTags={3}
                    options={learningObjectivesArray}
                    value={learningObjectivesArray.find((i) => i._id === formik.values.learningObjectiveFive._id) || null}
                    disablePortal={true}
                    noOptionsText={t("event_form.no_options")}
                    disabled={formik.values.learningObjectiveFour._id == ""}
                    isOptionEqualToValue={(option, value) => {
                      return option._id === value._id;
                    }}
                    getOptionLabel={(option) => (language === "en" ? option.enName : option.frName)}
                    onChange={(event, newValue, reason) => handleAutoCompleteSelect(reason, { _id: newValue?._id || "", customName: "" }, "learningObjectiveFive")}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox style={{ marginRight: 8 }} checked={selected} />
                        {language === "en" ? option.enName : option.frName}
                      </li>
                    )}
                    style={{ width: "100%", margin: "0px 0px 10px 0px", backgroundColor: "#ffffff" }}
                    renderInput={(params) => <TextField focused {...params} variant="outlined" label={t("create_event_form_learning_obj_5")} placeholder={selectedLearningObjectives.length === 0 ? t("create_event_form_learning_objectives") : ""} error={Boolean(formik.touched.learningObjectiveFive) && Boolean(formik.errors.learningObjectiveFive)} fullWidth />}
                  />
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: "0px!important" }}>
                  <TextField value={formik.values.learningObjectiveFive.customName} disabled={formik.values.learningObjectiveFive._id === ""} fullWidth size="medium" id="customize-objective-three" name="learningObjectiveFive.customName" placeholder={t("create_event_form_custom_learning_objective_label")} type="text" variant="outlined" onChange={formik.handleChange} focused />
                </Grid>
                </>
                }

                {learningObjectiveCount >= 6 && 
                <>
                <Grid item xs={12}>
                  <Autocomplete
                    id="learningObjectiveSix"
                    limitTags={3}
                    options={learningObjectivesArray}
                    value={learningObjectivesArray.find((i) => i._id === formik.values.learningObjectiveSix._id) || null}
                    disablePortal={true}
                    noOptionsText={t("event_form.no_options")}
                    disabled={formik.values.learningObjectiveFive._id == ""}
                    isOptionEqualToValue={(option, value) => {
                      return option._id === value._id;
                    }}
                    getOptionLabel={(option) => (language === "en" ? option.enName : option.frName)}
                    onChange={(event, newValue, reason) => handleAutoCompleteSelect(reason, { _id: newValue?._id || "", customName: "" }, "learningObjectiveSix")}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox style={{ marginRight: 8 }} checked={selected} />
                        {language === "en" ? option.enName : option.frName}
                      </li>
                    )}
                    style={{ width: "100%", margin: "0px 0px 10px 0px", backgroundColor: "#ffffff" }}
                    renderInput={(params) => <TextField focused {...params} variant="outlined" label={t("create_event_form_learning_obj_6")} placeholder={selectedLearningObjectives.length === 0 ? t("create_event_form_learning_objectives") : ""} error={Boolean(formik.touched.learningObjectiveSix) && Boolean(formik.errors.learningObjectiveSix)} fullWidth />}
                  />
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: "0px!important" }}>
                  <TextField value={formik.values.learningObjectiveSix.customName} disabled={formik.values.learningObjectiveSix._id === ""} fullWidth size="medium" id="customize-objective-three" name="learningObjectiveSix.customName" placeholder={t("create_event_form_custom_learning_objective_label")} type="text" variant="outlined" onChange={formik.handleChange} focused />
                </Grid>
                </>
                }

                <Grid item xs={12} display="flex" justifyContent="flex-end" sx={{paddingTop: "5px!important"}}>
                  <ButtonGroup>
                    <IconButton onClick={() => AddLearningObjective()} disabled={learningObjectiveCount >= 6}>
                      <Add />
                    </IconButton>
                    <IconButton onClick={() => RemoveLearningObjective()} disabled={learningObjectiveCount <= 1}>
                      <Remove />
                    </IconButton>
                  </ButtonGroup>
                </Grid>

                <NextButton id="create-event" type="submit" disabled={formik.values.learningObjectiveOne._id == ""} sx={{ marginTop: "30px" }}>
                  {edit ? t("save_event_button") : t("create_event_form_button")}
                </NextButton>
              </Grid>
            </TabPanel>

            <Grid item xs={12}>
              <RequiredMicetype>
                {t("event_form.required_micetype")}
              </RequiredMicetype>
            </Grid>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default GeneralEventForm;
