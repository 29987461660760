import { Alert, Box, FormControl, FormHelperText, Grid, InputLabel, Checkbox, ListItemText, FormControlLabel, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as yup from "yup";
import { useFormik } from "formik";
import { useContext, useState } from "react";
import AppContext from "../context/Auth";
import { formatFormErrors } from "../utils/forms";
import axios from "axios";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { FormButton } from "./styled/Buttons/StyledButtons";
import { UserTool, Users } from "../typings/interfaces";
import { useReps } from "../api/reps";
import { L } from "@fullcalendar/list/internal-common";

type Props = {
  action: string;
  toggleOpen: () => void;
  user: Users;
  updateTable: () => void;
};

const FormTitle = styled("h1")(({ theme }) => ({
  color: "#3D3D3D",
  textAlign: "center",
  marginTop: 26,
  fontSize: 26,
  fontWeight: "bold",
  textTransform: "uppercase",
}));

const TOOL_OPTIONS = ["event-evaluations", "activity-calendar"];

const UserForm = ({ action, toggleOpen, user, updateTable }: Props): JSX.Element => {
  const [displayError, setDisplayError] = useState("");
  const [displaySuccess, setDisplaySuccess] = useState("");
  const { t } = useTranslation();
  const { mutateReps } = useReps(); 
  const [loading, setLoading] = useState(false);
  const language = i18next.language;
  const activityCalendarCapabilities = action === "edit" ? user?.tools.find(obj => obj.tool === 'activity-calendar') : undefined;
  const { updateIsAuthenticated, hasRole, hasTool } = useContext(AppContext);

  const validationSchema =
    action === "create"
      ? yup.object({
          firstName: yup.string().required(t("create_user_form_name_error")),
          lastName: yup.string().required(t("create_user_form_last_name_error")),
          email: yup.string().email(t("create_user_form_email_error_2")).required(t("create_user_form_email_error")),
          password: yup
            .string()
            .min(8, t("create_user_form_password_error_min_8"))
            .max(13, t("create_user_form_password_error_max_13"))
            .required(t("create_user_form_password_error"))
            .matches(
              //eslint-disable-next-line
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,13})/,
              t("create_user_form_password_error_full")
            ),
          role: yup.string().required(t("create_user_form_role_error")),
          tools: yup.array().min(1, t("create_user_form_tools_error")),
          activityCalendarReadonly: yup.boolean(),
        })
      : yup.object({
          firstName: yup.string().required(t("create_user_form_name_error")),
          lastName: yup.string().required(t("create_user_form_last_name_error")),
          email: yup.string().required(t("create_user_form_email_error")),
          role: yup.string().required(t("create_user_form_role_error")),
          tools: yup.array().min(1, t("create_user_form_tools_error")),
          activityCalendarReadonly: yup.boolean(),
        });

  const initialValues =
    action === "create"
      ? {
          email: "",
          firstName: "",
          lastName: "",
          password: "",
          role: "",
          tools: [] as string[],
          activityCalendarReadonly: false,
        }
      : {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          role: user.role,
          tools: user.tools.map((tool) => tool.tool) || [],
          activityCalendarReadonly: activityCalendarCapabilities ? activityCalendarCapabilities.readonly : false
        };

  const createUser = (values: any, token: any) => {
    try {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/register`,
          {
            email: values.email.toLowerCase(),
            firstName: values.firstName,
            lastName: values.lastName,
            name: `${values.firstName} ${values.lastName}`,
            password: values.password,
            role: values.role,
            language,
            tools: values.tools,
            activityCalendarReadonly: values.role ==="rep" ? values.activityCalendarReadonly : false, //only get readonly value if this is for a rep 
          },
          {
            headers: {
              authorization: `${token}`,
            },
          }
        )
        .then(function (response) {
          setLoading(false);
          if (response.data.message === t("create_user_form_user_exists")) {
            setDisplayError(response.data.message);
            setDisplaySuccess("");
          } else if (response.data.user) {
            if(values.role === "rep") {
              mutateReps(); 
            }
            setDisplaySuccess(t("create_user_form_success") + `${values.firstName} ${values.lastName}`);
            setDisplayError("");
            updateTable();
            formik.resetForm();
          }
        })
        .catch(function (error:any) {
          console.log("error", error)
          setLoading(false);
          if (error.response && error.response.status === 409) {
            setDisplayError(t("create_user_form_user_exists"))
          }
          else {
            setDisplayError(error.message)
          }
          console.log(error.code);
        });
      updateIsAuthenticated(true);
    } catch (error: any) {
      if (error.response.data.errorsValidation) {
        const formattedErrors = formatFormErrors(error.response.data.errorsValidation);
        formik.setErrors(formattedErrors);
      }
      setLoading(false);
    }
  };
  const editUser = (values: any, token: any) => {
    setLoading(true);
    try {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/users/update/${user._id}`,
          {
            firstName: values.firstName,
            lastName: values.lastName,
            name: `${values.firstName} ${values.lastName}`,
            tools: values.tools,
            activityCalendarReadonly: values.activityCalendarReadonly
          },
          {
            headers: {
              authorization: `${token}`,
            },
          }
        )
        .then(function (response) {
          setLoading(true);
          if (response.data.message) {
            setDisplayError(response.data.message);
            setDisplaySuccess("");
          } else if (response.data.user) {
            if(values.role === "rep") {
              mutateReps(); 
            }
            setDisplaySuccess(t("edit_user_form_success") + `${values.firstName} ${values.lastName}`);
            setDisplayError("");
            //refresh table
            updateTable();
            setTimeout(() => {
              toggleOpen();
            }, 1500);
          }
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
      updateIsAuthenticated(true);
    } catch (error: any) {
      if (error.response.data.errorsValidation) {
        const formattedErrors = formatFormErrors(error.response.data.errorsValidation);
        formik.setErrors(formattedErrors);
      }
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const token = localStorage.getItem("SavedToken");
      setLoading(true);
      action === "create" ? createUser(values, token) : editUser(values, token);
    },
  });

  return (
    <Box sx={{ position: "relative"}} my={2}>
        <FormTitle>
          {action === "create" ? t("create_user_form_title") : t("edit_user_page_title")}
        </FormTitle>
        <div>
          {displayError ? <Alert severity="error">{displayError}</Alert> : ""}
          {displaySuccess ? (
            <Alert sx={{ marginBottom: "20px" }} severity="success">
              {displaySuccess}
            </Alert>
          ) : (
            ""
          )}
        </div>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3} justifyContent="center" sx={{marginTop: "10px"}}>
            <Grid item xs={6}>
              <TextField fullWidth size="medium" id="firstName" label={t("create_user_form_first_name_label")} disabled={loading} type="input" variant="outlined" value={formik.values.firstName} onChange={formik.handleChange} error={formik.touched.firstName && Boolean(formik.errors.firstName)} helperText={formik.touched.firstName && formik.errors.firstName} focused />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth size="medium" id="lastName" label={t("create_user_form_last_name_label")} disabled={loading} type="input" variant="outlined" value={formik.values.lastName} onChange={formik.handleChange} error={formik.touched.lastName && Boolean(formik.errors.lastName)} helperText={formik.touched.lastName && formik.errors.lastName} focused />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth size="medium" id="email" label={t("create_user_form_email_label")} type="email" variant="outlined" value={formik.values.email} onChange={formik.handleChange} error={formik.touched.email && Boolean(formik.errors.email)} helperText={formik.touched.email && formik.errors.email} focused disabled={action === "edit" || loading ? true : false} />
            </Grid>
            {action === "create" && (
              <Grid item xs={12}>
                <TextField fullWidth size="medium" id="password" label={t("create_user_form_password_label")} disabled={loading} type="password" variant="outlined" value={formik.values.password} onChange={formik.handleChange} error={formik.touched.password && Boolean(formik.errors.password)} helperText={formik.touched.password && formik.errors.password} focused />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl fullWidth focused variant="filled" error={formik.touched.role && Boolean(formik.errors.role)} disabled={action === "edit" || loading ? true : false}>
                <InputLabel> {t("create_user_form_role_label")} </InputLabel>
                <Select id="role" name="role" placeholder="Select One" value={formik.values.role} onChange={formik.handleChange} error={formik.touched.role && Boolean(formik.errors.role)}>
                  <MenuItem value="msl">{t("create_user_form_role_msl")}</MenuItem>
                  <MenuItem value="rep">{t("create_user_form_role_rep")}</MenuItem>
                  {hasRole(["sudo"]) && <MenuItem value="admin">{t("create_user_form_role_admin")}</MenuItem>}
                </Select>
                {formik.touched.role && <FormHelperText>{formik.errors.role}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth focused variant="filled" error={formik.touched.tools && Boolean(formik.errors.tools)}>
                <InputLabel> {t("create_user_form_tools_label")} </InputLabel>
                <Select id="tools" name="tools" placeholder="Grant Tool Access" disabled={loading} value={formik.values.tools} onChange={formik.handleChange} error={formik.touched.tools && Boolean(formik.errors.tools)} multiple renderValue={(selected) => selected.map((i) => t("create_user_form_tools." + i)).join(", ")}>
                  {TOOL_OPTIONS.map((i) => {
                    //disable any tools that this currentUser doesnt have access too 
                    const userHasTool = hasTool([i]);
                    const toolDisabled = !userHasTool;
                    return (
                      <MenuItem value={i} disabled={toolDisabled}>
                        <Checkbox checked={formik.values.tools.indexOf(i) > -1}/>
                        <ListItemText>{t("create_user_form_tools." + i)}</ListItemText>
                      </MenuItem>
                    );
                  })}
                </Select>
                {formik.touched.tools && <FormHelperText>{formik.errors.tools}</FormHelperText>}
              </FormControl>
            </Grid>
            
            {/* readonly checkbox for activity calendar */}
            { formik.values.tools.includes("activity-calendar") && formik.values.role === "rep" &&
              <Grid item xs={12}>
                <FormControlLabel name="activityCalendarReadonly" onChange={formik.handleChange} control={<Checkbox checked={formik.values.activityCalendarReadonly} disabled={!hasTool(["activity-calendar"])} />} label={`${t("create_user_form_tools.activity-calendar")} ${t("create_user_form_tools_permissions.readonly")}`} />
              </Grid>
            } 

            <Grid item xs={language === "en" ? 6 : 8} style={{ marginTop: 20 }}>
              <FormButton sx={{ fontSize: 20 }} fullWidth variant={"contained"} type={"submit"} disabled={loading}>
                {action === "create" ? t("create_user_form_button") : t("edit_user_form_button")}
              </FormButton>
            </Grid>
          
            <Grid item xs={12}>
              <Typography sx={{color: "gray", fontSize: "14px"}}>{t("event_form.required_micetype")}</Typography>
            </Grid>
          </Grid>
        </form>
    </Box>
  );
};

export default UserForm;
