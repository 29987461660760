import { Alert, Box, Grid } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useState, useContext } from "react";
import { formatFormErrors } from "../../utils/forms";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Brand } from "../../typings/interfaces";
import { ChromePicker, ColorResult } from '@hello-pangea/color-picker';
import { FormButton } from "../styled/Buttons/StyledButtons";
import { useBrands } from "../../api/brands";
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { useActivities } from "../../api/activities";

const FormTitle = styled("h1")(({ theme }) => ({
  color: "#3D3D3D",
  textAlign: "center",
  marginTop: 26,
  fontSize: 26,
  fontWeight: "bold",
  textTransform: "uppercase",
}));

type ColorPickerButtonProps = {
  color: string;
}

const ColorPickerButton = styled("span")<ColorPickerButtonProps>(({ theme, color }) => ({
  width: "50px",
  height: "50px",
  display: "inline-flex",
  backgroundColor: color,
  border: "3px solid #FFFFFF",
  boxShadow: "0 1px 5px 0 rgba(0,0,0,0.25)",
  borderRadius: "4px",
  cursor: "pointer",
}));

const ColorPickerWrapper = styled("div")(({ theme }) => ({
  zIndex: 20,
  marginLeft: "60px",
  marginTop: "-70px",
  top: 0,
}));

type Props = {
  edit: boolean;
  toggleOpen: () => void;
  brand: Brand;
  updateTable: () => void;
};

const BrandForm = ({ edit, toggleOpen, brand, updateTable }: Props): JSX.Element => {
  const [displayError, setDisplayError] = useState("");
  const [displaySuccess, setDisplaySuccess] = useState("");
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const language = i18next.language;
  const { create, update } = useBrands();
  const { mutateActivities } = useActivities(); //used to refetch the activities after brand info is updated

  const invalidColorCode = t("activity_management.brand_form.invalid_color");

  const toggleColorPicker = (e:any) => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const closeColorPicker = () => {
    setDisplayColorPicker(false);
  };

  const handleColorPickerChange = (color: ColorResult) => {
    // setDisplayColorPicker(!displayColorPicker);
    formik.setFieldValue("color", color.hex)
  };


  const validationSchema =
    !edit
      ? yup.object({
          enName: yup.string().required(t("activity_management.brand_form.brand_error")),
          frName: yup.string().required(t("activity_management.brand_form.brand_error")),
          color: yup.string()
          .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3}|[A-Fa-f0-9]{8})$/, invalidColorCode)
          .required(t("activity_management.brand_form.color_error")),
        })
      : yup.object({
          enName: yup.string().required(t("activity_management.brand_form.brand_error")),
          frName: yup.string().required(t("activity_management.brand_form.brand_error")),
          color: yup.string()
          .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3}|[A-Fa-f0-9]{8})$/, invalidColorCode)
          .required(t("activity_management.brand_form.color_error")),
        });

  const initialValues =
    !edit
      ? {
          enName: "",
          frName: "",
          color: "#ffffff",
        }
      : {
          enName: brand.enName,
          frName: brand.frName,
          color: brand.color,
        };

  const createBrand = async (values: any, token: any) => {
    try {
      await create(values);
      setLoading(false);
      setDisplaySuccess("Successfully created the product/brand");
      setDisplayError("");
      setLoading(false);
      toggleOpen();

    } catch (error: any) {
      if (error.response) {
        const formattedErrors = formatFormErrors(error.response.message);
        formik.setErrors(formattedErrors);
        setDisplaySuccess("");
        setDisplayError("Error creating the product/brand");
        setLoading(false);
      }
    }
  };
  const editBrand = async (values: any, token: any) => {
    try {
        await update(brand._id, values);
        await mutateActivities();
        setDisplaySuccess("Successfully updated the product/brand");
        setDisplayError("");
        setLoading(false);
        toggleOpen();
    } catch (error: any) {
      if (error.response) {
        console.log("error.response", error.response)
        setDisplaySuccess("");
        setDisplayError("Error updating the product/brand");
        setLoading(false);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const token = localStorage.getItem("SavedToken");
      setLoading(true);
      !edit ? createBrand(values, token) : editBrand(values, token);
    },
  });

  return (
    <Box my={2} sx={{ width: "100%" }}>
      <FormTitle sx={{marginBottom:5}}>
        {!edit ? t("activity_management.brand_form.create_title") : t("activity_management.brand_form.edit_title")}
      </FormTitle>
      <div>
        {displayError ? <Alert severity="error">{displayError}</Alert> : ""}
        {displaySuccess ? (
          <Alert sx={{ marginBottom: "20px" }} severity="success">
            {displaySuccess}
          </Alert>
        ) : (
          ""
        )}
      </div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={6}>
            <TextField fullWidth size="medium" id="enName" label={t("activity_management.brand_form.brand_english_label")} type="text" variant="outlined" value={formik.values.enName} onChange={formik.handleChange} error={formik.touched.enName && Boolean(formik.errors.enName)} helperText={formik.touched.enName && formik.errors.enName} focused />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth size="medium" id="frName" label={t("activity_management.brand_form.brand_french_label")} type="text" variant="outlined" value={formik.values.frName} onChange={formik.handleChange} error={formik.touched.frName && Boolean(formik.errors.frName)} helperText={formik.touched.frName && formik.errors.frName} focused />
          </Grid>
          <Grid item xs={5}>
            <TextField
              fullWidth
              size="medium"
              id="color"
              label={t("activity_management.brand_form.color_label")}
              type="text"
              variant="outlined"
              value={formik.values.color}
              onChange={formik.handleChange}
              error={formik.touched.color && Boolean(formik.errors.color)}
              helperText={formik.touched.color && formik.errors.color}
              focused
            />
          </Grid>
          <Grid item xs={7} sx={{ position: "relative"}}>
            <div style={{position:"absolute"}}>
            <ClickAwayListener onClickAway={closeColorPicker}>
            <div style={{position:"absolute"}}>
              <ColorPickerButton color={formik.values.color} onClick={(e) => toggleColorPicker(e)}/>
              
              {displayColorPicker && 
              <div>
                <ColorPickerWrapper style={{position:"absolute"}}>
                    <ChromePicker color={formik.values.color} defaultColor={formik.values.color ?  formik.values.color : "#ffffff"} disableAlpha={true} onChange={(color: ColorResult) => handleColorPickerChange(color)} onChangeComplete={() => console.log("Change completed")} />
                </ColorPickerWrapper>
                </div>
              }
              </div>
              </ClickAwayListener>
            </div>
          </Grid>
          <Grid item xs={6} style={{ marginTop: 20 }}>
            <FormButton sx={{fontSize:20}} fullWidth variant={"contained"} type={"submit"} disabled={loading}>
              {!edit ? t("activity_management.brand_form.add_btn") : t("activity_management.brand_form.save_btn")}
            </FormButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default BrandForm;
