import { useState, useEffect, useContext, useCallback, useRef, Dispatch } from "react";
import { useGridApiRef, GridColDef, GridToolbarContainer, GridToolbarExport, enUS, GridFooter, gridFilterModelSelector, useGridApiEventHandler, GridEventListener, useGridApiContext, GridFilterItem, gridExpandedSortedRowIdsSelector, GridCsvGetRowsToExportParams, gridFilteredSortedRowEntriesSelector } from "@mui/x-data-grid";
import { frFRCustom as frFR } from "../../locale/frFR";
import { useTranslation } from "react-i18next";
import ReportingContext from "../../context/ReportingContext";
import ReportingGridColumnsFC from "./columnDefinitions";
import ReportingDataGridTable from "../styled/ReportingGrid";
import { Speaker, Event, Moderator, Users, Region, LearningObjective, ReportingLearningObjective } from "../../typings/interfaces";
import { format } from "date-fns";
import { AverageNumbers, Round } from "../../utils/average";
import useEventName from "../../hooks/useEventName";
import { IconButton, Menu, MenuItem, styled } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useReactToPrint } from "react-to-print";
import { getReportingDetailsForCsv } from "../../utils/csvBuilder";
import CSVDownloadLink from "../styled/CSVDownloadLink";
import { parseEventStartTime } from "../../utils/parseDate";

/**
 * Returns speakers with numberOfEvents/numberOfEvaluations/speakerRating
 *
 * @param {array} speakers An array of speakers: Speaker[]
 * @param {array} events An array of events: ReportingEvent[] to be used to determine the speaker stats.
 * @return {speakers} An array of speakers: Speaker[] with numberOfEvents/numberOfEvaluations/speakerRating for each speaker.
 */
const getSpeakerStats = (speakers: Speaker[], events: Event[], usingFilters:boolean) => {
  speakers.forEach((speaker) => {
    let numberOfEvents = 0;
    let numberOfEvaluations = 0;
    let speakerRatingArray: number[] = [];
    speaker.events.forEach((speakerEvent) => {
      // console.log("filteredEvents data grid", events)
      events.some((event) => {
        if (event.id === speakerEvent.eventId) {
          // console.log("for speaker:", speaker.displayName, "event", event)

          const isSpeaker = event.speakerIds.includes(speaker.speakerId);
          if (isSpeaker) {
            numberOfEvents = numberOfEvents + 1;
          }

          event.evaluations?.forEach((evaluation) => {
            //find in the evaluations this speakers evaluation
            const speakerEvaluation = evaluation.speakers.find((speakerEval) => speakerEval.speakerId === speaker.speakerId);
            //if speaker evaluation was found for this whole evaluation record - increment number of evals and add speaker rating to speakerRating array
            if (speakerEvaluation && speakerEvaluation.rating) {
              numberOfEvaluations = numberOfEvaluations + 1;
              speakerRatingArray.push(speakerEvaluation.rating);
            }
          });

          return true;
        }
      });
    });

    speaker["numberOfEvents"] = numberOfEvents;
    speaker.numberOfEvaluations = numberOfEvaluations;
    speaker.rating = AverageNumbers(speakerRatingArray);

    // console.log("Speaker:", speaker.displayName, "numberOfEvents", numberOfEvents, "Number of Evals:", numberOfEvaluations, "Evaluation Rating Array", speakerRatingArray);
  });

  if (speakers && usingFilters) {
    return speakers.filter(i => i.numberOfEvents && i.numberOfEvents > 0)
  } else {
    return speakers
  }
};

/**
 * Returns moderators with numberOfEvents/numberOfEvaluations/speakerRating
 *
 * @param {array} moderators An array of moderators: Moderator[]
 * @param {array} events An array of events: ReportingEvent[] to be used to determine the moderator stats.
 * @return {events} An array of speakers: Moderator[] with numberOfEvents/numberOfEvaluations/rating for each moderator.
 */
const getModeratorStats = (moderators: Moderator[], events: Event[], usingFilters: boolean) => {
  moderators.forEach((moderator) => {
    let numberOfEvents = 0;
    let numberOfEvaluations = 0;
    let moderatorRatingArray: number[] = [];
    moderator.events.forEach((moderatorEvent) => {
      // console.log("filteredEvents data grid", events)
      events.some((event) => {
        if (event.id === moderatorEvent.eventId) {
          // console.log("for speaker:", moderator.displayName, "event", event);

          //check if they were a moderator
          const isModerator = event.moderatorIds.includes(moderator.speakerId);
          if (isModerator) {
            numberOfEvents = numberOfEvents + 1; // TODO: should we be checking if they have been evaluated
          }

          event.evaluations?.forEach((evaluation) => {
            //find in the evaluations this speakers evaluation
            const moderatorEvaluation = evaluation.moderators.find((moderatorEval) => moderatorEval.moderatorId === moderator.speakerId);
            //if moderator evaluation was found for this whole evaluation record - increment number of evals and add speaker rating to speakerRating array
            if (moderatorEvaluation && moderatorEvaluation.rating) {
              numberOfEvaluations = numberOfEvaluations + 1;
              moderatorRatingArray.push(moderatorEvaluation.rating);
            }
          });

          return true;
        }
      });
    });

    moderator["numberOfEvents"] = numberOfEvents;
    moderator.numberOfEvaluations = numberOfEvaluations;
    moderator.rating = AverageNumbers(moderatorRatingArray);

    // console.log("Moderator:", moderator.displayName, "numberOfEvents", numberOfEvents, "Number of Evals:", numberOfEvaluations, "Evaluation Rating Array", moderatorRatingArray);
  });

  if (moderators && usingFilters) {
    return moderators.filter(i => i.numberOfEvents && i.numberOfEvents > 0)
  } else {
    return moderators;  
  }
};

/**
 * Returns events with speakerNames/repName/regionName/eventRating(rounded)
 *
 * @param {array} events An array of events: ReportingEvent[] to be used to determine the moderator stats.
 * @param {array} learningObjectives An array of learningObjectives: LearningObjective[]
 * @return {learningObjectives} An array of learningObjectives: ReportingLearningObjective[] with speakerNames/repName/regionName/eventRating(rounded)
 */

const getLearningObjectiveStats = (events: Event[], learningObjectives: LearningObjective[], usingFilters: boolean) => {
  learningObjectives.forEach((learningObjective) => {
    let popularity = 0; 

    events.forEach((event) => {
      const eventHasLearningObjective = event.learningObjectives.find((objective) => objective._id === learningObjective._id);
      if(eventHasLearningObjective){
        popularity = popularity + 1; 
      }
    });

    //cast to ReportingLearningObjective
    const reportingLearningObjective = learningObjective as ReportingLearningObjective;
    reportingLearningObjective.popularity = popularity;
  });

  if (learningObjectives && usingFilters) {
    var reportingLearningObjectives = learningObjectives as ReportingLearningObjective[]
    return reportingLearningObjectives.filter(i => i.popularity > 0);
  } else {
    return learningObjectives as ReportingLearningObjective[];
  }  
};

const ReportingDataGrid = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [rows, setRows] = useState<any[]>([]);
  const [exportRowData, setExportRowData] = useState<any[]>([]);
  const [dataGridFilters, setDataGridFilters] = useState<GridFilterItem>({} as GridFilterItem);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rowHeight, setRowHeight] = useState<"auto" | null>(null);
  const [exportOptionFields, setExportOptionFields] = useState<string[]>([]);
  const { section, filteredSpeakers, filteredModerators, filteredEvents, reps, regions, filteredLearningObjectives, filteredEvaluations, updateSelectedEventId, updateSection, usingFilters} = useContext(ReportingContext);
  const [formatEventName] = useEventName();

  const [wrapperSize, setWrapperSize] = useState<number>(6);
  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => Promise.all([setWrapperSize(12)]),
    onBeforePrint: () => setWrapperSize(6),
    content: () => componentRef.current,
    documentTitle: `results_${section}_${new Date().getTime()}`
  });

  

/**
 * Returns events with speakerNames/repName/regionName/eventRating(rounded)
 *
 * @param {array} events An array of events: ReportingEvent[] to be used to determine the moderator stats.
 * @param {array} speakers An array of speakers: Speaker[]
 * @param {array} reps An array of reps: Users[]
 * @param {array} regions An array of regions: Region[]
 * @return {events} An array of events: ReportingEvent[] with speakerNames/repName/regionName/eventRating(rounded)
 */
const getEventStats = (events: Event[], speakers: Speaker[], reps: Users[], regions: Region[], usingFilters: boolean) => {
  events.forEach((event) => {
    const eventDate = format(parseEventStartTime(event.start_time), "MMM d, yyyy");
    const therapeuticArea = event.therapeuticArea ? event.therapeuticArea : event.title;
    let speakerNames = "";

    const speakerRecords = event.speakerIds.map((speakerId) => {
      return speakers.find((speaker) => speaker.speakerId === speakerId);
    });

    let repName = "";
    if (event.repId) {
      const repRecord = reps.find((rep) => rep.id === event.repId);
      repName = repRecord ? repRecord.name : "";
    }

    let regionName = "";
    if (event.regionId) {
      const regionRecord = regions.find((region) => region._id === event.regionId);
      regionName = regionRecord ? currentLanguage === "en" ? regionRecord.enName : regionRecord.frName : "";
    }

    speakerRecords &&
      speakerRecords.length > 0 &&
      speakerRecords.forEach((speakerInfo, index) => {
        if (speakerInfo) {
          if (index === speakerRecords.length) {
            speakerNames += `${speakerInfo.displayName}`;
          } else {
            speakerNames += `${speakerInfo.displayName}, `;
          }
        }
      });


    const formattedName = formatEventName(event, true);
    event.displayName = formattedName;
    event.repName = repName;
    event.regionName = regionName;
    event.eventRating = Round(event.eventRating, 1);
  });

  if (events && usingFilters) {
    return events.filter(i => i.evaluationCount > 0);
  } else {
    return events;
  }
};


  useEffect(() => {
    if (section === "speakers") {
      const speakerColumns = ReportingGridColumnsFC("speakerColumns", currentLanguage, updateSelectedEventId, updateSection, t);
      setColumns(speakerColumns);
      const speakersWithStats = getSpeakerStats(filteredSpeakers, filteredEvents, usingFilters);
      setRows([...speakersWithStats]);
      const exportOptions = ["displayName", "email", "numberOfEvents", "numberOfEvaluations", "rating"];
      setExportOptionFields([...exportOptions]);

    } else if (section === "moderators") {
      const moderatorColumns = ReportingGridColumnsFC("moderatorColumns", currentLanguage, updateSelectedEventId, updateSection, t);
      setColumns(moderatorColumns);
      const moderatorsWithStats = getModeratorStats(filteredModerators, filteredEvents, usingFilters);
      setRows([...moderatorsWithStats]);
      const exportOptions = ["displayName", "email", "numberOfEvents", "numberOfEvaluations", "rating"];
      setExportOptionFields([...exportOptions]);

    } else if (section === "events") {
      const eventColumns = ReportingGridColumnsFC("eventColumns", currentLanguage, updateSelectedEventId, updateSection, t);
      setColumns(eventColumns);
      const eventsWithStats = getEventStats(filteredEvents, filteredSpeakers, reps, regions, usingFilters);
      setRows([...eventsWithStats].reverse());
      const exportOptions = ["displayName", "language", "repName", "regionName", "evaluationCount", "eventRating"];
      setExportOptionFields([...exportOptions]);

    } else if (section === "learning-objectives") {
      const learningObjectiveColumns = ReportingGridColumnsFC("learningObjectiveColumns", currentLanguage, updateSelectedEventId, updateSection, t);
      setColumns(learningObjectiveColumns);
      const learningObjectivesWithStats = getLearningObjectiveStats(filteredEvents, filteredLearningObjectives, usingFilters);
      setRows([...learningObjectivesWithStats]);
      const exportOptions = ["enName", "therapeuticArea", "popularity"];
      setExportOptionFields([...exportOptions]);

    } else if (section === "comments") {
      const commentsColumns = ReportingGridColumnsFC("commentsColumns", currentLanguage, updateSelectedEventId, updateSection, t);
      setColumns(commentsColumns);

      const evalsWithComments = filteredEvaluations.filter((evaluation) => {
        if(evaluation.additionalComment || evaluation.otherTopicsComment || evaluation.conceptLearnedComment ) {
          return evaluation;
        }
      });

      setRows([...evalsWithComments]);
      const exportOptions = ["conceptLearnedComment", "otherTopicsComment", "additionalComment"];
      setExportOptionFields([...exportOptions]);
      
    }
  }, [section, filteredSpeakers, filteredEvents, filteredEvaluations, currentLanguage]);


  // This sets the row height of the data grid depending on the section selected
  // if the section is the comments section then we need a dynamic row height/ all other sections get a fixed row height (null)
  useEffect(()=> {
    if(section === "comments") {
      setRowHeight("auto");
    } else {
      setRowHeight(null);
    }
  },[section])

  useEffect(() => {
    //get the mui data grid visible rows after filtering and sorting
    const visibleRows = gridFilteredSortedRowEntriesSelector(apiRef);
    //returns data for csv including mui data grid filters and custom reporting filters
    const exportRowsArray = getReportingDetailsForCsv(visibleRows, columns, dataGridFilters, section);
    setExportRowData(exportRowsArray);

  },[dataGridFilters, rows])

  const CustomToolbar = (setDataGridFilters: Dispatch<any>) => {
    const [exportAnchor, setExportAnchor] = useState<null | HTMLElement>(null);
    const openExport = Boolean(exportAnchor);
    const apiRef = useGridApiContext();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setExportAnchor(event.currentTarget);
    };
    
    const handleClose = () => {
      setExportAnchor(null);
    };

    const handleFilterChange: GridEventListener<'filterModelChange'> = (params) => {
      params.items.forEach((filter) => {
        setDataGridFilters(filter);
      })
    };

    useGridApiEventHandler(apiRef, 'filterModelChange', handleFilterChange);

    return (
      <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
          <div className="columnHeader_buttons">
            <IconButton disableRipple onClick={handleClick}>
              <FileDownloadOutlinedIcon sx={{ color: (theme) => theme.palette.primary.main }} />
            </IconButton>
            <Menu
              id="export-results"
              anchorEl={exportAnchor}
              open={openExport}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <MenuItem onClick={() => {handleClose()} }>
                <CSVDownloadLink data={exportRowData} filename={`results_${section}_${new Date().getTime()}`}>
                  {t("download_as_csv")}
                </CSVDownloadLink>
              </MenuItem>
              <MenuItem onClick={() => {handleClose();handlePrint();}}>{t("print")}</MenuItem>
            </Menu>
          </div>
        <div>
          <GridToolbarExport
            disableToolbarButton={true}
            csvOptions={{ disableToolbarButton: true }}
            printOptions={{ disableToolbarButton: true }}
          />
        </div>
        <GridFooter
          sx={{
            border: "none", // To delete double border.
          }}
        />
      </GridToolbarContainer>
    );
  };

  const getRowHeight = useCallback(() => { 
    return rowHeight
   }, [rowHeight]);

  const apiRef = useGridApiRef();

  return (
    <div style={{ marginTop: "20px", marginBottom:"400px" }}>
      <ReportingDataGridTable
        key={section}
        apiRef={apiRef}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 11,
            },
          },
        }}
        pageSizeOptions={[11]}
        className="reporting_grid"
        getRowId={(row: any) => (row._id ? row._id + section : row.id + section)}
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        localeText={currentLanguage === "en" ? {...enUS.components.MuiDataGrid.defaultProps.localeText, toolbarExport:""} : {...frFR.components.MuiDataGrid.defaultProps.localeText, toolbarExport:""} }
        slots={{
          footer: () => CustomToolbar(setDataGridFilters),
        }}
        getRowHeight={getRowHeight}
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
        }}
        columnHeaderHeight={section === "comments" ? 100 : 50}
      />
    </div>
  );
}

export default ReportingDataGrid; 