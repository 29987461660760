/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useContext, useEffect, useState } from "react";
import { Event, LearningObjective, TherapeuticArea, Users } from "../typings/interfaces";
import { useEvents } from "../api/events";
import { FilterEvent } from "../typings/types";
import { useTherapeuticAreas } from "../api/therapeuticAreas";
import { useLearningObjectives } from "../api/learningObjectives";
import { useReps } from "../api/reps";
import AppContext from "../context/Auth";
import { sortDataAlpha } from "../utils/sort";
import i18n, { useTranslation } from 'react-i18next';

interface IEventContext {
  allEvents: Event[];
  cardEvents: Event[];
  events: Event[];
  reps: Users[];
  therapeuticAreas: TherapeuticArea[];
  learningObjectives: LearningObjective[];

  filter: FilterEvent;
  loading: boolean;
  totalPages: number;
  error: string;
  repID: string;

  setFilter: (filterEvent: FilterEvent) => void;
  setError: (error: string) => void;
  setRepID: (id: string) => void;
  onEventDelete: (id: string) => void;
  onCardEventDelete: (id: string) => void;
  getAllEvents: (page: number, pageCount?: number) => void;
  // updateRoles: (roles: Role[]) => void;
}

//Initial App Context
export const EventContext = createContext<IEventContext>({
  allEvents: [],
  cardEvents: [],
  events: [],
  reps: [],
  therapeuticAreas: [],
  learningObjectives: [],

  loading: false,
  filter: "all",
  totalPages: 0,
  error: "",
  repID: "",

  setFilter: function (): void { },
  setError: function (): void { },
  setRepID: function (): void{ },
  onEventDelete: function (): void{ },
  onCardEventDelete: function(): void{},
  getAllEvents: function(page = 1, pageCount = 12): void{},
  // updateRoles: function (): void {},
});

interface Props {
  children: React.ReactNode;
}
export const EventProvider: React.FC<Props> = ({ children }) => {
  const {hasRole, userId} = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const { events:allEvents, isEventsLoading, getAllCardEvents } = useEvents();
  const [events, setEvents] = useState<Event[]>([]); //gets modified based on filters
  const [cardEvents, setCardEvents] = useState<Event[]>([]); //events strictly for the card view 
  const [filter, setFilter] = useState<FilterEvent>("all"); //events filters for the card view only
  const [totalPages, setTotalPages] = useState(1); //total pages used for card view
  const { therapeuticAreas } = useTherapeuticAreas();
  const { learningObjectives } = useLearningObjectives();
  const { reps } = useReps();
  const [repID, setRepID] = useState(""); //the event.id that is currently being edited
  const [error, setError] = useState("");

  useEffect(() => {
    if (!isEventsLoading && allEvents) {
      setEvents(allEvents);
    }
  }, [allEvents, isEventsLoading])

  useEffect(() => {
    getAllEvents(1);
  }, [filter])

  const getAllEvents = async (page: number = 1, pageCount: number = 12) => {
    try {
      const { cardEvents, totalPages } = await getAllCardEvents(page, pageCount, filter);
      
      setCardEvents(cardEvents);
      setTotalPages(totalPages);
    } catch (error) {
      // TODO: better language for error
      setError("Failed retrieving events");
    }
  };

  const onCardEventDelete = (id: string) => {
    const filteredCardEvents = cardEvents.filter(i => i.id !== id);
    setCardEvents(filteredCardEvents);
  }

  useEffect(() => {
    const hasRepRole = hasRole(["rep"]);
    if (hasRepRole) {
      setRepID(userId);
    } else {
      setRepID("");
    }
  }, [userId]);

  // This is done because we still need to filter events if the year hasn't finished loading yet but the user still selected a Rep from the Filter Dropdown in the toolbar
  useEffect(() => {
    if(repID) {
      filterEventsByRepID(repID);
    } else {
      setEvents(allEvents);
    }
  }, [allEvents, repID])
  
  //Filter events by a repId
  const filterEventsByRepID = (id: string) => {
    //if a repID is sent - filter by the repID
    if(id && !isEventsLoading) {
      const filteredEvents = allEvents.filter((event, index, array) => {
        if(event.repId === id) {
          return true;
        }
      })
      setEvents(filteredEvents); //events
      return;
    }
  }

  const onEventDelete = (eventId: string) => {
    const filteredEvents = events.filter((x) => x.id !== eventId);
    setEvents(filteredEvents);
  }

  return (
    <EventContext.Provider
      value={{
        allEvents,
        cardEvents,
        events,
        reps,
        therapeuticAreas: i18n.language === "en" ? sortDataAlpha(therapeuticAreas, "enName") as TherapeuticArea[] : sortDataAlpha(therapeuticAreas, "frName") as TherapeuticArea[],
        learningObjectives: i18n.language === "en" ? sortDataAlpha(learningObjectives, "enName") as LearningObjective[] : sortDataAlpha(learningObjectives, "frName") as LearningObjective[],

        loading: isEventsLoading,
        filter,
        totalPages,
        error,
        repID,
        
        setFilter,
        setRepID,
        onEventDelete,
        onCardEventDelete,
        setError,
        getAllEvents
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export default EventContext;
