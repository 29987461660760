import React, { useEffect, useState } from "react";
import { Alert, Container, Grid, Link } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import * as yup from "yup";
import { useFormik } from "formik";
import { useContext } from "react";
import AppContext from "../context/Auth";
import { formatFormErrors } from "../utils/forms";
import { useNavigate } from "react-router";
import axios from "axios";
import Layout from "../components/Layout";
import { useTranslation } from "react-i18next";

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  boxShadow: "unset",
  borderRadius: "4px",
  width: "300px",
  textTransform: "unset",
  padding:"10px",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: "unset",
    color: "#fff",
  },
}));

type Props = {
  genericLogo?: boolean;
}

const LoginPage = ({genericLogo}: Props): JSX.Element => {
  const history = useNavigate();
  const { t } = useTranslation();


  // useEffect(() => {
  //   document.title = 'NNCI | Event Hub';
  // },[])

  const { updateIsAuthenticated, updateRoles, updateUserId, updateTools, updateUser, updateCurrentTool } = useContext(AppContext);
  const [state, setState] = useState({
    error: "",
    loading: false,
  });

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(t("login_form.error.email_format"))
      .required(t("login_form.error.email_required")),
    password: yup.string().required(t("login_form.error.password")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setState({ loading: true, error: "" });
        axios
          .post(`${process.env.REACT_APP_API_URL}/login`, {
            email: values.email,
            password: values.password,
          })
          .then(function (response) {
            if (response.data.message === "Auth Successful") {
              localStorage.setItem(
                "SavedToken",
                "Bearer " + response.data.token
              );
              updateRoles([response.data.user.role]);
              updateUserId(response.data.user._id);
              updateUser(response.data.user);

              updateCurrentTool(response.data.user.tools[0].tool);

              setState({ loading: false, error: "" });

              var tools = response.data.user.tools;
              if (tools) {
                // redirect the user to the correct page on login
                const toolNames = response.data.user.tools.map((i: any) => i.tool);
                updateTools(toolNames);
              }
              updateIsAuthenticated(true);
              history("/");
            }
          })
          .catch(function (error) {
            const { message } = error.response.data.error;
            setState({ loading: false, error: "login_form.error.email_or_pass_incorrect" });
            console.log(error.response);
          });
      } catch (error: any) {
        if (error.response.data.errorsValidation) {
          const formattedErrors = formatFormErrors(
            error.response.data.errorsValidation
          );
          formik.setErrors(formattedErrors);
        }
      }
    },
  });

  return (
    <Layout genericLogo={true}>
      <Container maxWidth={"sm"}>
        {state.error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {t(state.error)}
          </Alert>
        )}
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            spacing={3}
            style={{ marginTop: "25px" }}
            justifyContent="center"
          >
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="medium"
                id="email"
                label={t("login_form.email")}
                type="text"
                autoComplete="email"
                variant="outlined"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                onFocus={() => setState({ ...state, error: "" })}
                focused
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="medium"
                id="password"
                label={t("login_form.password")}
                type="password"
                autoComplete="password"
                variant="outlined"
                value={formik.values.password}
                onChange={formik.handleChange("password")}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                onFocus={() => setState({ ...state, error: "" })}
                focused
              />
            </Grid>
            <Grid item xs={12} alignItems="center" display="flex" flexDirection="column">
              <CustomButton
                fullWidth
                variant={"contained"}
                type={"submit"}
                disabled={state.loading}
              >
                {t("signin")}
              </CustomButton>
              <Link sx={{my:2}} href="/forgot-password">{t("forgot_password_link")}</Link>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Layout>
  );
};

export default LoginPage;
