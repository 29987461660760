import { axios } from "./axios";
import { Segmentation } from "../typings/interfaces";
import useSWR from "swr";

export const ENDPOINT = "/segmentations";

export const getSegmentations = async () => {
  const { data } = await axios.get(ENDPOINT);
  return data;
};

interface CreateEditProps {
  enName: string;
  frName: string;
}

export function useSegmentations() {
  const {
    isLoading: isSegmentationsLoading,
    data,
    error: segmentationsError,
    mutate,
  } = useSWR(ENDPOINT, getSegmentations, {
    onSuccess: (data) => data,
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });

  const create = async ({enName, frName}:CreateEditProps) => {
    const { data } = await axios.post(ENDPOINT, { enName, frName });
    mutate();

    return data;
  }

  const update = async (id: string, { enName, frName }: CreateEditProps) => {
    const { data } = await axios.put(`${ENDPOINT}/${id}`, { enName, frName });
    mutate();

    return data;
  }

  const deleteById = async (id: string) => {
    const { data } = await axios.delete(`${ENDPOINT}/${id}`);
    mutate();

    return data;
  }

  return {
    isSegmentationsLoading,
    segmentations: data?.segmentations as Segmentation[],
    segmentationsError,
    mutate,
    create,
    update,
    deleteById
  };
}
