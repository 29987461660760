import { useEffect, useContext } from "react";
import { Box, styled } from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";
import Layout from "../components/Layout";
import ReportingFilters from "../components/reporting/ReportingFilters";
import ReportingTabs from "../components/reporting/ReportingTabs";
import ReportingDataGrid from "../components/reporting/ReportingDataGrid";
import ReportingContext from "../context/ReportingContext";
import InstructionsPopover from "../components/styled/InstructionsPopover";
import { useNavigate, useParams } from 'react-router-dom';
import AppContext from "../context/Auth";
import NoAccess from "../components/NoAccess";
import Loading from "../components/Loading";
import { ReportingBackButton } from "../components/styled/Buttons/StyledButtons";

const InstructionsWrapper = styled("div")(({ theme }) => ({
  paddingTop: 20,
}));

const InstructionsTextWithImage = styled("h3")(({ theme }) => ({
  fontWeight: 600,
  marginLeft: "10px",
}));


const Reporting = () => {
  const { t } = useTranslation();
  const { section, updateSection, updateSelectedEventId, loading } = useContext(ReportingContext);
  const { hasTool, hasRole, userLoading } = useContext(AppContext);
  let { eventId:paramEventId, section:paramSection } = useParams();
  const navigate = useNavigate();

  //if param comments and eventId exist then update section to comments and apply eventId filter
  useEffect(() => {
    if(paramSection === "comments" && paramEventId && loading === false) {
      updateSection("comments");
      updateSelectedEventId(paramEventId);
    } else if (paramSection === "moderators" && loading === false) {
      updateSection("moderators");
    }
  },[paramSection, paramEventId, loading])

  const goBack = () => {
    navigate(-1);
  };


  const getInstructions = (section: string) => {
    let instructions = (
      <InstructionsPopover isPage={true} popoverPosition="right" width="350px" variant="secondary">
        <InstructionsWrapper>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
            <div>
              <img src="/assets/en/sort-columns.png" alt="Sort columns" />
            </div>
            <InstructionsTextWithImage>{t("instructions.reporting.general.sort")}</InstructionsTextWithImage>
          </div>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
            <div>
              <img src="/assets/en/export-data.png" alt="Export Data" />
            </div>
            <InstructionsTextWithImage>{t("instructions.reporting.general.export")}</InstructionsTextWithImage>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <img src="/assets/en/view-comments.png" alt="View Comments" />
            </div>
            <InstructionsTextWithImage>{t("instructions.reporting.general.view_comments")}</InstructionsTextWithImage>
          </div>
        </InstructionsWrapper>
      </InstructionsPopover>
    );

    return instructions;
  };

  if (userLoading || loading) {
    return (
      <Layout>
        <Loading />
      </Layout>
    )
  }

  return (
    <Layout>
      {hasTool(['event-evaluations']) || hasRole(['sudo', 'admin']) ?
      <Box sx={{ 
        display: {xs: 'block', lg: 'flex'},
        mt: {xs: 2, lg: 5},
        mb: {xs: 5, lg: 5}
      }}>
        <Box sx={{
          position: "relative", 
          minWidth: "50px",
          width: {xs: '100%', lg: '5%'},
          textAlign: {xs: 'left', lg: 'center'},
          marginTop: {xs: '0px', lg: '70px'},
          display: {xs: 'flex', lg: 'flex'},
          flexDirection: {xs: 'row', lg: 'column'},
        }}>
          { paramSection === "comments" && paramEventId &&
            <div>
              <ReportingBackButton onClick={goBack}>
                <ArrowBack />
              </ReportingBackButton>
            </div>
          }
          {getInstructions(section)}
        </Box>
        <Box sx={{ 
          width: {xs: '100%', lg: '95%'}
        }}>
          <ReportingTabs />
          <ReportingFilters />
          <ReportingDataGrid />
        </Box>
      </Box>
      : <NoAccess />}
    </Layout>
  );
};

export default Reporting;
