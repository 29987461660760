import i18n, { TFunction } from "i18next";
import { Attendee, Event, EventEvaluation, LearningObjective } from "../typings/interfaces";
import { AverageNumbers } from "./average";
import { GridColDef, GridFilterItem, GridRowId, GridValidRowModel } from "@mui/x-data-grid";
import { convertFromTimezoneDisplay } from "./timezones";

export const EventResultCsvDataAndHeaders = (evaluations: EventEvaluation[]) => {
  const headers = [
    { label: "Question", key: "question" },
    { label: "Mean Average Rating", key: "rating" },
  ];

  // get all the averages for the program questions
  const relevantAvg = AverageNumbers(evaluations.map((i) => i.program.relevant));
  const credibleAvg = AverageNumbers(evaluations.map((i) => i.program.credible));
  const organizedAvg = AverageNumbers(evaluations.map((i) => i.program.organized));
  const timeAllottedAvg = AverageNumbers(evaluations.map((i) => i.program.timeAllotted));
  const interactionAvg = AverageNumbers(evaluations.map((i) => i.program.interaction));

  // get all the averages for the learning objective questions
  const learningObjectiveIds = Array.from(new Set(evaluations.map((i) => i.learningObjectives.map((i) => i.learningObjectiveId)).flat(1)));
  const learningObjectiveRatings = evaluations.map((i) => i.learningObjectives).flat(1);

  // array of all learning objectives averaged with their name
  var objectiveRatings = [];
  for (const id of learningObjectiveIds) {
    const objectives = learningObjectiveRatings.filter((i) => i.learningObjectiveId === id);
    if (objectives.length > 0) {
      const avg = AverageNumbers(objectives.map((i) => i.rating));
      objectiveRatings.push({
        id: id,
        name: objectives[0].name,
        average: avg,
      });
    }
  }

  // get all the ratings for the speakers
  const speakerIds = Array.from(new Set(evaluations.map((i) => i.speakers.map((i) => i.speakerId)).flat(1)));
  const evaluationSpeakerRatings = evaluations.map((i) => i.speakers).flat(1);

  // array of all speakers with their average rating
  var speakerRatings = [];
  for (const id of speakerIds) {
    const ratings = evaluationSpeakerRatings.filter((i) => i.speakerId === id);
    if (ratings.length > 0) {
      const avg = AverageNumbers(ratings.map((i) => i.rating));
      speakerRatings.push({
        speakerId: id,
        name: ratings[0].name || "N/A",
        average: avg,
      });
    }
  }

  // get all the ratings for the moderators
  const moderatorIds = Array.from(new Set(evaluations.map((i) => i.moderators.map((i) => i.moderatorId)).flat(1)));
  const evaluationModeratorRatings = evaluations.map((i) => i.moderators).flat(1);

  // array of all moderators with their average rating
  var moderatorRatings = [];
  for (const id of moderatorIds) {
    const ratings = evaluationModeratorRatings.filter((i) => i.moderatorId === id);
    if (ratings.length > 0) {
      const avg = AverageNumbers(ratings.map((i) => i.rating));
      moderatorRatings.push({
        moderatorId: id,
        name: ratings[0].name || "N/A",
        average: avg,
      });
    }
  }

  // build out the data object
  var data = [
    { question: "The program was relevant to my practice", rating: relevantAvg },
    { question: "The program was credible and non-biased", rating: credibleAvg },
    { question: "The program was well organized", rating: organizedAvg },
    { question: "Adequate time was allotted for the program", rating: timeAllottedAvg },
    { question: "Participants were allowed adequate opportunity for interaction", rating: interactionAvg },
  ];

  for (const rating of objectiveRatings) {
    data.push({ question: rating.name, rating: rating.average });
  }

  for (const rating of speakerRatings) {
    data.push({ question: `${rating.name} presented information clearly`, rating: rating.average });
  }

  for (const rating of moderatorRatings) {
    data.push({ question: `${rating.name} facilitated discussion/question and answer effectively`, rating: rating.average });
  }

  return { headers, data };
};

export const eventResultCsvDataWithDetails = (evaluations: EventEvaluation[], event: Event, eventName: string, learningObjectives: LearningObjective[]) => {
  const t = i18n.t;
  const headers = ["Question", i18n.language === "en" ? "Mean Average Rating" : "Note Moyenne"];
  const emptyRow = [""];
  const { preHeader, eventDetails } = getEventDetailsForCsv(event, eventName);
  preHeader.push(i18n.language === "en" ? "Evaluation Count" : "Nombre d'évaluations");
  eventDetails.push(evaluations.length.toString());

  // get all the averages for the program questions
  const relevantAvg = AverageNumbers(evaluations.map((i) => i.program.relevant));
  const credibleAvg = AverageNumbers(evaluations.map((i) => i.program.credible));
  const organizedAvg = AverageNumbers(evaluations.map((i) => i.program.organized));
  const timeAllottedAvg = AverageNumbers(evaluations.map((i) => i.program.timeAllotted));
  const interactionAvg = AverageNumbers(evaluations.map((i) => i.program.interaction));

  // get all the averages for the learning objective questions
  const learningObjectiveNames = Array.from(new Set(evaluations.map((i) => i.learningObjectives.map((i) => i.name)).flat(1)));
  const learningObjectiveRatings = evaluations.map((i) => i.learningObjectives).flat(1);

  // array of all learning objectives averaged with their name
  var objectiveRatings = [];
  for (const name of learningObjectiveNames) {
    const objectives = learningObjectiveRatings.filter((i) => i.name === name);
    if (objectives.length > 0) {
      const foundObjective = learningObjectives.find(i => i._id === objectives[0].learningObjectiveId)
      const avg = AverageNumbers(objectives.map((i) => i.rating));

      let translatedName = i18n.language === "en" ? foundObjective?.enName || "" : foundObjective?.frName || "";

      let objName = translatedName;

      if (foundObjective?.value !== name) {
        objName = translatedName + ": " + name;
      }

      objectiveRatings.push({
        id: objectives[0].learningObjectiveId,
        name: objName,
        average: avg,
      });
    }
  }

  // get all the ratings for the speakers
  const speakerIds = Array.from(new Set(evaluations.map((i) => i.speakers.map((i) => i.speakerId)).flat(1)));
  const evaluationSpeakerRatings = evaluations.map((i) => i.speakers).flat(1);

  // array of all speakers with their average rating
  var speakerRatings = [];
  for (const id of speakerIds) {
    const ratings = evaluationSpeakerRatings.filter((i) => i.speakerId === id);
    if (ratings.length > 0) {
      const avg = AverageNumbers(ratings.map((i) => i.rating));
      speakerRatings.push({
        speakerId: id,
        name: ratings[0].name || "N/A",
        average: avg,
      });
    }
  }

  // get all the ratings for the moderators
  const moderatorIds = Array.from(new Set(evaluations.map((i) => i.moderators.map((i) => i.moderatorId)).flat(1)));
  const evaluationModeratorRatings = evaluations.map((i) => i.moderators).flat(1);

  // array of all moderators with their average rating
  var moderatorRatings = [];
  for (const id of moderatorIds) {
    const ratings = evaluationModeratorRatings.filter((i) => i.moderatorId === id);
    if (ratings.length > 0) {
      const avg = AverageNumbers(ratings.map((i) => i.rating));
      moderatorRatings.push({
        moderatorId: id,
        name: ratings[0].name || "N/A",
        average: avg,
      });
    }
  }
  
  let data = [headers, [t("evaluation.evaluation_form.program_questions.question_one").replace("*", ""), relevantAvg], [t("evaluation.evaluation_form.program_questions.question_two").replace("*", ""), credibleAvg], [t("evaluation.evaluation_form.program_questions.question_three").replace("*", ""), organizedAvg], [t("evaluation.evaluation_form.program_questions.question_four").replace("*", ""), timeAllottedAvg], [t("evaluation.evaluation_form.program_questions.question_five").replace("*", ""), interactionAvg]];

  for (const rating of objectiveRatings) {
    data.push([rating.name, rating.average]);
  }

  for (const rating of speakerRatings) {
    data.push([rating.name, rating.average]);
  }

  for (const rating of moderatorRatings) {
    data.push([rating.name, rating.average]);
  }

  //add event details header/data to the start of the csv
  data.unshift(preHeader, eventDetails, emptyRow);
  return { data };
};

//CSV of all evaluations for the event - includes event details
export const eventEvaluationsResultCsvData = (evaluations: EventEvaluation[], event: Event, eventName: string, attendees: Attendee[], learningObjectives: LearningObjective[]) => {
  const t = i18n.t;
  const emptyRow = [""];
  const { preHeader, eventDetails } = getEventDetailsForCsv(event, eventName);
  const programQOne = t("evaluation.evaluation_form.program_questions.question_one").replace("*", "");
  const programQTwo = t("evaluation.evaluation_form.program_questions.question_two").replace("*", "");
  const programQThree = t("evaluation.evaluation_form.program_questions.question_three").replace("*", "");
  const programQFour = t("evaluation.evaluation_form.program_questions.question_four").replace("*", "");
  const programQFive = t("evaluation.evaluation_form.program_questions.question_five").replace("*", "");

  //translate
  const dataEval: any[] = [[t("reporting.specialty"), programQOne, programQTwo, programQThree, programQFour, programQFive]];

  //only need to check 1 evaluation to see how many learning objectives there are to add the header
  evaluations[0]?.learningObjectives.forEach((learningObjective) => {
    let foundLearningObjective = learningObjectives.find(i => i._id === learningObjective.learningObjectiveId);
    if (foundLearningObjective) {
      let translatedName = i18n.language === "en" ? foundLearningObjective.enName  : foundLearningObjective.frName;

      let objName = translatedName;

      if (foundLearningObjective.value !== learningObjective.name) {
        objName = translatedName + ": " + learningObjective.name;
      }
      dataEval[0].push(objName);
    }
  });

  evaluations[0]?.speakers.forEach((speaker) => {
    dataEval[0].push(`${speaker.name}${t("evaluation.evaluation_form.presentation_questions.speaker_2")}`); //translate
  });

  evaluations[0]?.moderators.forEach((moderator) => {
    dataEval[0].push(`${moderator.name}${t("evaluation.evaluation_form.presentation_questions.moderator_2")}`); //translate
  });

  //push comments headers in last
  dataEval[0].push(t("evaluation.evaluation_form.comment_questions.question_one"), t("evaluation.evaluation_form.comment_questions.question_two"), t("evaluation.evaluation_form.comment_questions.question_three")); //translate

  evaluations?.forEach((evaluation: EventEvaluation) => {
    let attendee = attendees.find(i => i._id === evaluation.attendeeId);
    let evaluationArray: any[] = [attendee ? t(attendee?.jobTitle) : "N/A" ,evaluation.program.relevant, evaluation.program.credible, evaluation.program.organized, evaluation.program.timeAllotted, evaluation.program.interaction];
    evaluation.learningObjectives.map((learningObjective) => {
      evaluationArray.push(learningObjective.rating);
    });

    evaluation.speakers.forEach((speaker) => {
      evaluationArray.push(speaker.rating);
    });

    evaluation.moderators.forEach((moderator) => {
      evaluationArray.push(moderator.rating);
    });

    evaluationArray.push(evaluation.conceptLearnedComment, evaluation.otherTopicsComment, evaluation.additionalComment);

    dataEval.push(evaluationArray);
  });

  //add event details header/data to the start of the csv
  dataEval.unshift(preHeader, eventDetails, emptyRow);
  return { dataEval };
};

export const getEventDetailsForCsv = (event: Event, eventName: string) => {
  const t = i18n.t;
  const timeZoneArray = event.timeZone.split("/");
  const translatedTimeZone = t(convertFromTimezoneDisplay(event.timeZone));

  const region = i18n.language === "en" ? event.region.enName : event.region.frName;
  const rep = event.rep ? event.rep.name : "";

  const preHeader = [t("list_view.event_name"), t("attendee_invite_form_date_time_label").replace(": ", ""), t("list_view.event_type"), t("reporting.event_region"), t("list_view.rep")]; //translate
  const eventDetails = [eventName, `${event.start_time} ${translatedTimeZone}`, t(event.eventType.toLowerCase()), region, rep];

  return { preHeader, eventDetails };
};

type FilterRows = {
  id: GridRowId;
  model: GridValidRowModel;
};

//Reporting exports
export const getReportingDetailsForCsv = (visibleRows: FilterRows[], columns: GridColDef[], dataGridFilters: GridFilterItem, section?: string, event?: Event, eventName?: string) => {
  const columnHeaders: string[] = [];
  const exportRowsArray: any[] = [];
  const emptyRow = [""];

  //if we have row and column data
  if (visibleRows.length > 0 && columns.length > 0) {
    visibleRows.forEach((row: any) => {
      const rowArray: string[] = [];
      //for each column that should be exported
      columns.forEach((column, index) => {
        //get the row data for each column that needs to be exported
        if (column.field in row.model) {
          if (column.headerName) {
            //push unique header values
            if (columnHeaders.indexOf(column.headerName) === -1) {
              columnHeaders.push(column.headerName);
            }
            rowArray.push(row.model[column.field]);
          }
        }
      });
      exportRowsArray.push(rowArray);
    });
  }

  exportRowsArray.unshift(columnHeaders);

  const { dataGridHeader, muiFilterData } = getReportMuiFilters(dataGridFilters, columns);

  //if there is a section (ie. this is the reporting tables)
  if (section) {
    //if custom filter input values
    const { customInputFiltersHeader, customInputFiltersData } = getReportingFilterInputValues(section);
    if (customInputFiltersData.some(Boolean)) {
      exportRowsArray.unshift(customInputFiltersHeader, customInputFiltersData, emptyRow);
    }
  }

  //if mui data grid filter values
  if (dataGridFilters && dataGridFilters.value) {
    exportRowsArray.unshift(dataGridHeader, muiFilterData, emptyRow);
  }

  if (event && eventName) {
    const { preHeader, eventDetails } = getEventDetailsForCsv(event, eventName);
    exportRowsArray.unshift(preHeader, eventDetails, emptyRow);
  }

  return exportRowsArray;
};

export const getReportMuiFilters = (dataGridFilters: GridFilterItem, columns: GridColDef[]) => {
  const dataGridHeader = ["Field", "Operator", "Value"]; //translate

  let obj = columns.find((column, i) => {
    if (column.field === dataGridFilters.field) {
      return true; // stop searching
    }
  });

  let headerLabel: any = obj ? obj.headerName : dataGridFilters.field;

  const muiFilterData = [headerLabel, dataGridFilters.operator, dataGridFilters.value];
  return { dataGridHeader, muiFilterData };
};

export const getReportingFilterInputValues = (section: string) => {
  const customInputFiltersData: string[] = [];
  let customInputFiltersHeader: string[] = [];

  switch (section) {
    case "speakers":
      customInputFiltersHeader = ["Speaker Filter", "Region Filter", "Therapeutic Area Filter", "Event Type Filter", "Language Filter", "Rep Filter", "Date Range Filter"];
      const speakerNameInput = ((document.getElementById("speakers") || {}) as HTMLInputElement).value || "";
      const speakerRegionInput = ((document.getElementById("region") || {}) as HTMLInputElement).value;
      const speakerTherapeuticAreaInput = ((document.getElementById("therapeuticArea") || {}) as HTMLInputElement).value || "";
      const speakerEventTypeInput = ((document.getElementById("eventType") || {}) as HTMLInputElement).value || "";
      const speakerLanguageInput = ((document.getElementById("language") || {}) as HTMLInputElement).value || "";
      const speakerRepInput = ((document.getElementById("rep") || {}) as HTMLInputElement).value || "";
      const speakerDateRangeInput = ((document.getElementById("component-outlined-date-range") || {}) as HTMLInputElement).value || "";
      customInputFiltersData.push(speakerNameInput, speakerRegionInput, speakerTherapeuticAreaInput, speakerEventTypeInput, speakerLanguageInput, speakerRepInput, speakerDateRangeInput);
      break;
    case "moderators":
      customInputFiltersHeader = ["Moderator Filter", "Region Filter", "Therapeutic Area Filter", "Event Type Filter", "Language Filter", "Rep Filter", "Date Range Filter"];
      const moderatorNameInput = ((document.getElementById("moderators") || {}) as HTMLInputElement).value || "";
      const moderatorRegionInput = ((document.getElementById("region") || {}) as HTMLInputElement).value;
      const moderatorTherapeuticAreaInput = ((document.getElementById("therapeuticArea") || {}) as HTMLInputElement).value || "";
      const moderatorEventTypeInput = ((document.getElementById("eventType") || {}) as HTMLInputElement).value || "";
      const moderatorLanguageInput = ((document.getElementById("language") || {}) as HTMLInputElement).value || "";
      const moderatorRepInput = ((document.getElementById("rep") || {}) as HTMLInputElement).value || "";
      const moderatorDateRangeInput = ((document.getElementById("component-outlined-date-range") || {}) as HTMLInputElement).value || "";
      customInputFiltersData.push(moderatorNameInput, moderatorRegionInput, moderatorTherapeuticAreaInput, moderatorEventTypeInput, moderatorLanguageInput, moderatorRepInput, moderatorDateRangeInput);
      break;
    case "events":
      customInputFiltersHeader = ["Event Name Filter", "Region Filter", "Therapeutic Area Filter", "Event Type Filter", "Language Filter", "Rep Filter", "Date Range Filter"];
      const eventNameInput = ((document.getElementById("eventName") || {}) as HTMLInputElement).value || "";
      const eventRegionInput = ((document.getElementById("region") || {}) as HTMLInputElement).value;
      const eventTherapeuticAreaInput = ((document.getElementById("therapeuticArea") || {}) as HTMLInputElement).value || "";
      const eventEventTypeInput = ((document.getElementById("eventType") || {}) as HTMLInputElement).value || "";
      const eventLanguageInput = ((document.getElementById("language") || {}) as HTMLInputElement).value || "";
      const eventRepInput = ((document.getElementById("rep") || {}) as HTMLInputElement).value || "";
      const eventDateRangeInput = ((document.getElementById("component-outlined-date-range") || {}) as HTMLInputElement).value || "";
      customInputFiltersData.push(eventNameInput, eventRegionInput, eventTherapeuticAreaInput, eventEventTypeInput, eventLanguageInput, eventRepInput, eventDateRangeInput);
      break;
    case "learning-objectives":
      customInputFiltersHeader = ["Learning Objective Filter", "Region Filter", "Therapeutic Area Filter", "Event Type Filter", "Language Filter", "Date Range Filter"];
      const learningObjectivesInput = ((document.getElementById("learningObjectives") || {}) as HTMLInputElement).value || "";
      const learningObjectivesRegionInput = ((document.getElementById("region") || {}) as HTMLInputElement).value;
      const learningObjectivesTherapeuticAreaInput = ((document.getElementById("therapeuticArea") || {}) as HTMLInputElement).value || "";
      const learningObjectivesEventTypeInput = ((document.getElementById("eventType") || {}) as HTMLInputElement).value || "";
      const learningObjectivesLanguageInput = ((document.getElementById("language") || {}) as HTMLInputElement).value || "";
      const learningObjectivesDateRangeInput = ((document.getElementById("component-outlined-date-range") || {}) as HTMLInputElement).value || "";
      customInputFiltersData.push(learningObjectivesInput, learningObjectivesRegionInput, learningObjectivesTherapeuticAreaInput, learningObjectivesEventTypeInput, learningObjectivesLanguageInput, learningObjectivesDateRangeInput);
      break;
    case "comments":
      customInputFiltersHeader = ["Event Name Filter", "Region Filter", "Therapeutic Area Filter", "Event Type Filter", "Date Range Filter"];
      const commentsEventNameInput = ((document.getElementById("eventName") || {}) as HTMLInputElement).value || "";
      const commentsRegionInput = ((document.getElementById("region") || {}) as HTMLInputElement).value;
      const commentsTherapeuticAreaInput = ((document.getElementById("therapeuticArea") || {}) as HTMLInputElement).value || "";
      const commentsLanguageInput = ((document.getElementById("language") || {}) as HTMLInputElement).value || "";
      const commentsDateRangeInput = ((document.getElementById("component-outlined-date-range") || {}) as HTMLInputElement).value || "";
      customInputFiltersData.push(commentsEventNameInput, commentsRegionInput, commentsTherapeuticAreaInput, commentsLanguageInput, commentsDateRangeInput);
      break;
    default:
      break;
  }

  return { customInputFiltersHeader, customInputFiltersData };
};
