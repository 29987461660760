import { Alert, Box, ButtonBase, FormControl, FormLabel, Grid, styled, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ActivityType } from "../../typings/interfaces";
import InstructionsPopover from "../styled/InstructionsPopover";
import * as yup from "yup";
import { useActivityTypes } from "../../api/activityTypes";
import { formatFormErrors } from "../../utils/forms";
import { useActivities } from "../../api/activities";

const ModalTitle = styled("h1")(({ theme }) => ({
  color: "#3D3D3D",
  textAlign: "center",
  marginTop: 24,
  fontSize: 24,
  fontWeight: "bold",
  textTransform: "uppercase",
}));

const NextButton = styled(ButtonBase)<{ disabled?: boolean }>(({ disabled, theme }) => ({
  backgroundColor: theme.palette.primary.main,
  textTransform: "uppercase",
  padding: "14px 60px 14px 60px",
  color: "white",
  fontSize: 18,
  borderRadius: "5px",
  opacity: disabled ? 0.6 : 1,
}));

interface ActivityTypeFormProps {
  edit?: boolean;
  activityType?: ActivityType;
  closeModal: VoidFunction;
}

const ActivityTypeForm = ({ edit, activityType, closeModal }: ActivityTypeFormProps) => {
  const { t } = useTranslation();
  const [displayError, setDisplayError] = useState("");
  const [displaySuccess, setDisplaySuccess] = useState("");
  const [created, setCreated] = useState(false);
  const [loading, setLoading] = useState(false);

  const { create, update } = useActivityTypes();
  const { mutateActivities } = useActivities();

  const validationSchema = yup.object({
    enName: yup.string().required(t("activity_management.activity_type_form.english_name_error")),
    frName: yup.string().required(t("activity_management.activity_type_form.french_name_error")),
  });

  const createSubmit = async (values: any) => {
    setLoading(true);
    try {
      await create(values);
      setDisplayError("");
      setDisplaySuccess(t("activity_management.activity_type_form.create_success_msg"));
      setCreated(true);
      setLoading(false);
      closeModal();
    } catch (error: any) {
      if (error.response) {
        console.log(error.response);
        if (error.response.data.errorsValidation) {
          const formattedErrors = formatFormErrors(error.response.data.errorsValidation);
          activityTypeForm.setErrors(formattedErrors);
          return;
        }
      }
      setDisplaySuccess("");
      setDisplayError(error.response.data.message);
      setLoading(false);
    }
  };

  const editSubmit = async (values: any) => {
    setLoading(true);
    try {
      if (activityType) {
        await update(activityType._id, values);
        await mutateActivities();
        setDisplayError("");
        setDisplaySuccess(t("activity_management.activity_type_form.update_success_msg"));
        setCreated(true);
        setLoading(false);
        closeModal();
      } else {
        setDisplaySuccess("");
        setDisplayError(t("activity_management.activity_type_form.no_activity_type_error"));
      }
    } catch (error: any) {
      if (error.response) {
        console.log(error.response);
        if (error.response.data.errorsValidation) {
          const formattedErrors = formatFormErrors(error.response.data.errorsValidation);
          activityTypeForm.setErrors(formattedErrors);
          return;
        }
      }
      setDisplayError(error.response.data.message);
      setLoading(false);
    }
  };

  const initialValues = edit
    ? {
        enName: activityType?.enName,
        frName: activityType?.frName,
      }
    : {
        enName: "",
        frName: "",
      };

  const activityTypeForm = useFormik({
    initialValues: initialValues,
    onSubmit: edit ? editSubmit : createSubmit,
    validationSchema: validationSchema,
    validateOnChange: false,
  });

  return (
    <>
      <Box my={0}>
        <ModalTitle>{edit ? t("activity_management.activity_type_form.edit_title") : t("activity_management.activity_type_form.create_title")}</ModalTitle>
        <div>
          {displayError ? <Alert severity="error">{displayError}</Alert> : ""}
          {displaySuccess ? <Alert severity="success">{displaySuccess}</Alert> : ""}
        </div>
        <form onSubmit={activityTypeForm.handleSubmit}>
          <Box hidden={created}>
            <Grid container spacing={3} style={{ marginTop: "10px" }} justifyContent="center">
              <Grid item xs={10}>
                <TextField id="enName" name="enName" value={activityTypeForm.values.enName} variant="outlined" focused fullWidth size="medium" label={t("activity_management.activity_type_form.english_name_label")} onChange={activityTypeForm.handleChange} error={activityTypeForm.touched.enName && Boolean(activityTypeForm.errors.enName)} helperText={activityTypeForm.errors.enName} />
              </Grid>
              <Grid item xs={10}>
                <TextField id="frName" name="frName" value={activityTypeForm.values.frName} variant="outlined" focused fullWidth size="medium" label={t("activity_management.activity_type_form.french_name_label")} onChange={activityTypeForm.handleChange} error={activityTypeForm.touched.frName && Boolean(activityTypeForm.errors.frName)} helperText={activityTypeForm.errors.frName} />
              </Grid>

              <Grid item xs={12} sx={{ paddingTop: "10px!important", display: "flex", justifyContent: "center" }}>
                <NextButton type="submit" sx={{ marginTop: "30px" }} disabled={loading}>
                  {edit ? t("activity_management.activity_type_form.save_btn") : t("activity_management.activity_type_form.add_btn")}
                </NextButton>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default ActivityTypeForm;
