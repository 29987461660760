import { GridColDef, GridToolbarContainer, GridToolbarExport, enUS, GridFooter } from "@mui/x-data-grid";
import { frFRCustom as frFR } from "../locale/frFR";
import { IconButton, Modal } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Segmentation } from "../typings/interfaces";
import { useTranslation } from "react-i18next";
import { useState, useContext } from "react";
import ReportingDataGridTable from "./styled/ReportingGrid";
import ActivityContext from "../context/ActivityContext";
import DraggableDialog from "./styled/DraggableDialog";
import SegmentationForm from "./activityViews/SegmentationForm";
import { useSegmentations } from "../api/segmentations";

type Props = {
  updateTable: () => void;
  update: boolean;
  toggleOpen: () => void;
  open: boolean;
};

const SegmentationList = ({ updateTable, update, toggleOpen, open }: Props) => {
  const { t, i18n } = useTranslation();
  const segmentation_label = t("activity_management.segmentation");
  const edit_label = t("edit");
  const delete_label = t("delete");
  const current_language = i18n.language;
  const [segmentation, setSegmentation] = useState<Segmentation>({ _id: "", enName: "", frName: "", status: "" });
  const { segmentations } = useContext(ActivityContext);

  const { deleteById } = useSegmentations();

  const [edit, setEdit] = useState(false);

  const closeModal = () => {
    setEdit(false);
    setSegmentation({} as Segmentation);
    toggleOpen();
  }

  // console.log("activityTypes", activityTypes);

  const editSegmentation = (segmentation: Segmentation) => {
    const { _id, enName, frName, status } = segmentation;
    setEdit(true);
    setSegmentation({ _id, enName, frName, status });
    toggleOpen();
  };

  const deleteSegmentation = async (_id: string) => {
    const confirm = window.confirm(t("delete_confirm_message"));

    if (!confirm) {
      return;
    }

    try {
      await deleteById(_id);
    } catch (error: any) {
      alert(error.response.data.message);
    }
  };

  const columns: GridColDef[] = [
    {      
      sortable: false,
      editable: false,
      filterable: false,
      hideable: false,
      field: "segmentationName",
      headerName: segmentation_label,
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        if (current_language === "en") return params.row.enName;
        return params.row.frName;
      },
      valueGetter: (params) => {
        if (current_language === "en") return params.row.enName;
        return params.row.frName;
      }
    },
    {
      sortable: false,
      editable: false,
      filterable: false,
      hideable: false,
      field: "edit",
      width: 50,
      headerName: "",
      renderHeader: () => (null),
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,

      renderCell: (params) => (
        <strong>
          <IconButton aria-label="edit" color="warning" style={{ marginLeft: 0 }} onClick={() => editSegmentation(params.row)}>
            <EditOutlinedIcon />
          </IconButton>
        </strong>
      ),
    },
    {
      sortable: false,
      editable: false,
      filterable: false,
      hideable: false,
      field: "delete",
      headerName: "",
      renderHeader: () => (null),
      width: 50,
      headerAlign: "center",
      disableColumnMenu: true,
      align: "center",
      renderCell: (params) => (
        <strong>
          <IconButton
            aria-label="delete"
            color="error"
            style={{ marginLeft: 0 }}
            onClick={() => {
              deleteSegmentation(params.row._id);
            }}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </strong>
      ),
    },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
        <div>
          <GridToolbarExport
            csvOptions={{
              fields: ["segmentationName"],
            }}
            printOptions={{
              fields: ["segmentationName"],
            }}
          />
        </div>
        <div style={{ display: "flex" }}>
          <GridFooter
            sx={{
              border: "none", // To delete double border.
            }}
          />
        </div>
      </GridToolbarContainer>
    );
  };

  if (!segmentations) {
    return <></>;
  } else {
    return (
      <div style={{ height: "100%", width: "100%", marginTop: "20px" }}>
        <ReportingDataGridTable
          getRowId={(row: any) => row._id}
          className="activity_type_grid"
          rows={segmentations}
          columns={columns}
          disableRowSelectionOnClick
          localeText={current_language === "en" ? { ...enUS.components.MuiDataGrid.defaultProps.localeText, toolbarExport: "" } : { ...frFR.components.MuiDataGrid.defaultProps.localeText, toolbarExport: "" }}
          components={{
            Footer: CustomToolbar,
          }}
          pageSizeOptions={[100]}
        />
        <DraggableDialog open={open} closeModal={closeModal}>
          <SegmentationForm closeModal={closeModal} edit={edit} segmentation={segmentation} />
        </DraggableDialog>
      </div>
    );
  }
};

export default SegmentationList;
