import { Outlet } from 'react-router-dom';
import { ReportingProvider } from '../ReportingContext';

const ReportingContextLayout = () => {
  return (
    <ReportingProvider>
      <Outlet />
    </ReportingProvider>
  );
};

export default ReportingContextLayout;