import { Link, Grid, LinkProps } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import NavLanguagePicker from "./NavLanguagePicker";

const NavLink = styled(Link)<LinkProps>(({ theme }) => ({
  color: "#3D3D3D",
  margin: "0 15px",
  textDecoration: "none",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  "& .MuiSvgIcon-root": {
    marginRight: "5px",
  },
}));

type Props = {
  genericLogo?: boolean;
};

const NavUnauthed = ({ genericLogo }: Props) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const getLogo = () => {
    if (genericLogo) {
      return (
        <img
          src={currentLanguage === "en" ? "/assets/nn_logo_rgb_blue_large.png" : "/assets/nn_logo_rgb_blue_large.png"}
          alt={currentLanguage === "en" ? "Novo Nordisk" : "Novo Nordisk"}
          style={{
            width: "auto",
            marginBottom: 0,
            color: "#3D3D3D",
            height: "60px",
          }}
        />
      );
    }
    return (
      <img
        src={currentLanguage === "en" ? "/assets/en/eventhub_logo_en.png" : "/assets/en/eventhub_logo_en.png"}
        alt={currentLanguage === "en" ? "NNCI Event Hub" : "NNCI Event Hub"}
        style={{
          width: "auto",
          marginBottom: 0,
          color: "#3D3D3D",
          height: "52px",
        }}
      />
    );
  };

  return (
    <React.Fragment>
      <Grid container justifyContent="space-between">
        <Grid item display={"flex"} alignItems="center">
          <NavLink sx={{ height: { xs: "52px", sm: "52px" } }} onClick={() => navigate("/")}>
            {getLogo()}
          </NavLink>
        </Grid>
        <Grid item display="flex" alignItems="center">
          <NavLanguagePicker />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default NavUnauthed;
