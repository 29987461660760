import Layout from '../components/Layout';
import { Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import ReportEventHeader from '../components/reportingEvents/ReportEventHeader';
import ReportEventAttendees from '../components/reportingEvents/ReportEventAttendees';
import ReportEventStats from '../components/reportingEvents/ReportEventStats';
import { useNavigate, useParams } from 'react-router-dom';
import { useEventReport } from '../api/eventReport';
import Loading from '../components/Loading';
import InstructionsPopover from '../components/styled/InstructionsPopover';
import ArrowBack from "@mui/icons-material/ArrowBack";
import { useTranslation } from 'react-i18next';
import { useLearningObjectives } from '../api/learningObjectives';
import { ReportingBackButton } from '../components/styled/Buttons/StyledButtons';

const ReportContentWrapper = styled(Grid)(({ theme }) => ({
  width: '100%',
  padding: "0px",
  backgroundColor: "#FAFAFA",
  margin: 0,
  marginTop: "20px",
}));

const InstructionsWrapper = styled("div")(({ theme }) => ({
  paddingTop: 20
}));

const InstructionsTextWithImage = styled("h3")(({ theme }) => ({
  fontWeight: 600,
  marginLeft:"10px",
}));


export default function ReportingEvent() {
  let { eventId } = useParams();
  const { t } = useTranslation();
  const { eventReport, isEventReportLoading, eventReportError } = useEventReport(eventId ? eventId : "");
  const { learningObjectives, isLearningObjectivesLoading } = useLearningObjectives();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  if(isEventReportLoading || !eventId || isLearningObjectivesLoading) {
    return <Layout><Loading /></Layout>
  }

  if(eventReportError) {
    return (
    <Layout> 
      No Event Found
    </Layout>
    )
  }

  return (
    <Layout>
      <Box my={{xs: 2, lg: 5}} sx={{
        display: {xs: 'block', lg: 'flex'}
      }}>          
        <Box sx={{
          position:"relative", 
          textAlign:"center", 
          marginTop: {xs: '0px', lg: '30px'},
          width: {xs: '100%', lg: '5%'},
          display: {xs: 'flex', lg: 'block'},
          justifyContent: {xs: 'inherit', lg: 'inherit'}

        }}>
          <div>
            <ReportingBackButton onClick={goBack}>
              <ArrowBack />
            </ReportingBackButton>
          </div>
          <InstructionsPopover isPage={true} popoverPosition="right" width="350px" variant="secondary" >
            <InstructionsWrapper>
              <div style={{display:"flex", alignItems: "center", marginBottom: "10px"}}>
                <div><img src="/assets/en/sort-columns-event-report.png" alt="Sort Columns"/></div>
                <InstructionsTextWithImage>{t("instructions.reporting.general.sort")}</InstructionsTextWithImage>
              </div>
              <div style={{display:"flex", alignItems: "center", marginBottom: "10px"}}>
                <div><img src="/assets/en/export-data-event-report.png"  alt="Export Data"/></div>
                <InstructionsTextWithImage>{t("instructions.reporting.general.export")}</InstructionsTextWithImage>
              </div>
              <div style={{display:"flex", alignItems: "center"}}>
                <div><img src="/assets/en/view-comments-event-report.png"  alt="View Comments"/></div>
                <InstructionsTextWithImage>{t("instructions.reporting.general.view_comments")}</InstructionsTextWithImage>
              </div>
            </InstructionsWrapper>
          </InstructionsPopover>
        </Box>
        <Box sx={{
          width: {xs: '100%', lg: '95%'}
        }}>
          <ReportEventHeader event={eventReport.event} loading={isEventReportLoading} />
          <Box sx={{ flexGrow: 1 }}>
            <ReportContentWrapper container> 
              <ReportEventAttendees attendees={eventReport.attendees} event={eventReport.event} loading={isEventReportLoading} />
              <ReportEventStats evaluations={eventReport.evaluations} eventId={eventId} loading={isEventReportLoading} event={eventReport.event} attendees={eventReport.attendees} learningObjectives={learningObjectives} />
            </ReportContentWrapper>
          </Box>
          </Box>
      </Box>
    </Layout>
  );
  
}
