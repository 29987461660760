export interface IJob {
  key: string;
  value: string;
}

const Jobs: IJob[] = [
  { key: "na", value: "evaluation.checkin_form.job_titles.na" },
  { key: "administration", value: "evaluation.checkin_form.job_titles.administration" },
  { key: "cardiology", value: "evaluation.checkin_form.job_titles.cardiology" },
  { key: "diabetes_nurse", value: "evaluation.checkin_form.job_titles.diabetes_nurse" },
  { key: "dietetics", value: "evaluation.checkin_form.job_titles.dietetics" },
  { key: "metabolism", value: "evaluation.checkin_form.job_titles.metabolism" },
  { key: "family_medicine", value: "evaluation.checkin_form.job_titles.family_medicine" },
  { key: "general_practice", value: "evaluation.checkin_form.job_titles.general_practice" },
  { key: "internal_medicine", value: "evaluation.checkin_form.job_titles.internal_medicine" },
  { key: "kinesiology", value: "evaluation.checkin_form.job_titles.kinesiology" },
  { key: "nephrology", value: "evaluation.checkin_form.job_titles.nephrology" },
  { key: "staff", value: "evaluation.checkin_form.job_titles.staff" },
  { key: "nurse", value: "evaluation.checkin_form.job_titles.nurse" },
  { key: "practitioner", value: "evaluation.checkin_form.job_titles.practitioner" },
  { key: "pharmacy", value: "evaluation.checkin_form.job_titles.pharmacy" },
  { key: "registered_nurse", value: "evaluation.checkin_form.job_titles.registered_nurse" },
];

function GetValue(key: string) {
  let found = Jobs.find((i) => i.key === key);
  if (found) return found.value;
  return false;
}

const JobsTitles = {
  Jobs,
  GetValue,
};

export default JobsTitles;
