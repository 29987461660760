import { Event } from "../typings/interfaces";
import { GetProvinceName } from "./provinces";

export const GetLocationString = (event: Event, language: string) => {
  if (event.street) {
    return [event.street, event.city, GetProvinceName(event.province, language), event.postal].join(", ");
  } else {
    return "";
  }
};
