import { styled } from "@mui/material/styles";
import { lighten } from "@mui/material";

const SpeakerTableHeader = styled('div')(({ theme }) => ({
    ...theme.typography.body2,
  backgroundColor: lighten(theme.palette.primary.light, 0.525),
  borderBottom: `3px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.dark,
  fontWeight: 900,
  fontSize: "20px",
  borderRadius: "5px 5px 0px 0px",
  textTransform: "uppercase",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  paddingLeft: "20px",

  "& .MuiButtonBase-root": {
    backgroundColor:"#ffffff", 
    padding: "4px 10px",
    boxShadow: "0px 1px 1px #9e9e9e",
    marginLeft: "10px",
    borderRadius: "3px",
  },

  "& span": {
    padding: "8px 12px",
  },
  "& span:last-child": {
    width: "194px",
    textAlign: "right",
    border: "none",
    borderBottom: "none",
    fontSize: "26px",
    padding: "4px 12px",
    color: theme.palette.primary.main,
    [theme.breakpoints.up('lg')]: {
      border: `1px solid ${theme.palette.primary.main}`,
      textAlign: "center",
    },
  }
}));

export default SpeakerTableHeader;