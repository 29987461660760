import { Alert, Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { formatFormErrors } from "../utils/forms";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { LearningObjective, TherapeuticArea } from "../typings/interfaces";
import { FormButton } from "./styled/Buttons/StyledButtons";
import { useBrands } from "../api/brands";
import { useLearningObjectives } from "../api/learningObjectives";

const FormTitle = styled("h1")(({ theme }) => ({
  color: "#3D3D3D",
  textAlign: "center",
  marginTop: 26,
  fontSize: 26,
  fontWeight: "bold",
  textTransform: "uppercase",
}));

type Props = {
  edit: boolean;
  toggleOpen: () => void;
  learningObjective: LearningObjective;
  therapeuticAreas: TherapeuticArea[];
};

const LearningObjectiveForm = ({ edit, toggleOpen, learningObjective, therapeuticAreas }: Props): JSX.Element => {
  const [displayError, setDisplayError] = useState("");
  const [displaySuccess, setDisplaySuccess] = useState("");
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const language = i18next.language;
  const { mutateLearningObjectives, update, create } = useLearningObjectives(); //used to refetch the activities after brand info is updated

  const validationSchema = !edit
    ? yup.object({
        enName: yup.string().required(t("learning_objectives_management.form.enName_error")),
        frName: yup.string().required(t("learning_objectives_management.form.frName_error")),
        therapeuticArea: yup.string().required(t("learning_objectives_management.form.therapeutic_area_error")),
      })
    : yup.object({
        enName: yup.string().required(t("learning_objectives_management.form.enName_error")),
        frName: yup.string().required(t("learning_objectives_management.form.frName_error")),
        therapeuticArea: yup.string().required(t("learning_objectives_management.form.therapeutic_area_error")),
      });

  const initialValues = !edit
    ? {
        enName: "",
        frName: "",
        therapeuticArea: "",
      }
    : {
        enName: learningObjective.enName,
        frName: learningObjective.frName,
        therapeuticArea: learningObjective.therapeuticArea,
      };

  const createBrand = async (values: any, token: any) => {
    try {
      await create(values);
      setLoading(false);
      setDisplaySuccess(t("learning_objectives_management.form.create_success"));
      setDisplayError("");
      setLoading(true);
      setTimeout(() => {
        toggleOpen();
      }, 2000);
    } catch (error: any) {
      if (error.response) {
        const formattedErrors = formatFormErrors(error.response.message);
        formik.setErrors(formattedErrors);
        setDisplaySuccess("");
        setDisplayError(t("learning_objectives_management.form.create_error"));
        setLoading(false);
      }
    }
  };
  const editBrand = async (values: any, token: any) => {
    try {
      await update(learningObjective._id, values);
      await mutateLearningObjectives();
      setDisplaySuccess(t("learning_objectives_management.form.edit_success"));
      setDisplayError("");
      setLoading(true);
      setTimeout(() => {
        toggleOpen();
      }, 2000);
    } catch (error: any) {
      if (error.response) {
        console.log("error.response", error.response);
        setDisplaySuccess("");
        setDisplayError(t("learning_objectives_management.form.edit_error"));
        setLoading(false);
      }
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    if (value) {
      formik.setFieldValue("therapeuticArea", event.target.value as string);
    } else {
      formik.setFieldValue("therapeuticArea", "");
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const token = localStorage.getItem("SavedToken");
      setLoading(true);
      !edit ? createBrand(values, token) : editBrand(values, token);
    },
  });

  return (
    <Box my={2} sx={{ width: "100%" }}>
      <FormTitle sx={{ marginBottom: 5 }}>{!edit ? t("learning_objectives_management.form.create_title") : t("learning_objectives_management.form.edit_title")}</FormTitle>
      <div>
        {displayError ? <Alert severity="error">{displayError}</Alert> : ""}
        {displaySuccess ? (
          <Alert sx={{ marginBottom: "20px" }} severity="success">
            {displaySuccess}
          </Alert>
        ) : (
          ""
        )}
      </div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={6}>
            <TextField fullWidth size="medium" id="enName" label={t("learning_objectives_management.form.enName_label")} disabled={loading} type="text" variant="outlined" value={formik.values.enName} onChange={formik.handleChange} error={formik.touched.enName && Boolean(formik.errors.enName)} helperText={formik.touched.enName && formik.errors.enName} focused />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth size="medium" id="frName" label={t("learning_objectives_management.form.frName_label")} disabled={loading} type="text" variant="outlined" value={formik.values.frName} onChange={formik.handleChange} error={formik.touched.frName && Boolean(formik.errors.frName)} helperText={formik.touched.frName && formik.errors.frName} focused />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth focused variant="filled" error={formik.touched.therapeuticArea && Boolean(formik.errors.therapeuticArea)} disabled={edit || loading ? true : false}>
              <InputLabel> {t("learning_objectives_management.form.therapeutic_area_label")} </InputLabel>
              <Select variant="filled" id="therapeuticArea" value={formik.values.therapeuticArea} onChange={handleChange} error={formik.touched.therapeuticArea && Boolean(formik.errors.therapeuticArea)}>
                {therapeuticAreas?.map((area) => (
                  <MenuItem value={area.value}>{language === "en" ? area.enName : area.frName}</MenuItem>
                ))}
              </Select>
              {formik.touched.therapeuticArea && <FormHelperText>{formik.errors.therapeuticArea}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={6} style={{ marginTop: 20 }}>
            <FormButton sx={{ fontSize: 20 }} fullWidth variant={"contained"} type={"submit"} disabled={loading}>
              {!edit ? t("activity_management.brand_form.add_btn") : t("activity_management.brand_form.save_btn")}
            </FormButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default LearningObjectiveForm;
