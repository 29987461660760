import { styled } from "@mui/material/styles";

const StatsSectionHeader = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  padding: "7px 12px",
  backgroundColor: theme.palette.primary.light,
  borderBottom: `1px solid #fefefe`,
  color: theme.palette.primary.dark,
  fontWeight: 700,
  fontSize: "13px",
  textTransform: "uppercase",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  "& .rating": {
    color: theme.palette.primary.main,
    fontSize: "18px",
    lineHeight: "27px",
    fontWeight: 900,
  }

}));

export default StatsSectionHeader;