import { useEffect, useState, forwardRef, SyntheticEvent } from 'react'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps, AlertColor } from '@mui/material/Alert';
import { useTranslation } from "react-i18next";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type Props = {
  setOpenAlert: any;
  openAlert: boolean;
  textTranslationKey: string; 
  severity: AlertColor; 
  autoHideDuration: number;
  additionalText?: string;
}

const CustomAlertToast = ({setOpenAlert, openAlert, textTranslationKey, severity, autoHideDuration, additionalText = ""} : Props) => {
  const [open, setOpen] = useState(openAlert);
  const { t } = useTranslation();

  useEffect(() => {
    setOpen(openAlert);
  },[openAlert]);

  const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={handleClose}>
      <Alert severity={severity} sx={{ width: '100%' }}>
        {additionalText !== "" ? `${t(textTranslationKey)}${additionalText}` : t(textTranslationKey)}
      </Alert>
    </Snackbar>
  )
}
export default CustomAlertToast;
