import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { Paper } from "@mui/material";
import { enUS, GridColDef, GridToolbarContainer } from "@mui/x-data-grid";
import { frFRCustom as frFR } from "../../locale/frFR";
import { useTranslation } from "react-i18next";
import ReportingSpeakerGridTable from "../styled/reportingSpeakers/ReportingSpeakerGrid";
import SpeakerTableHeader from "../styled/reportingSpeakers/SpeakerTableHeader";
import {
    Evaluation,
    Event,
    ReportingSpeakerTherapeuticArea,
} from "../../typings/interfaces";
import { AverageNumbers } from "../../utils/average";
import { useNavigate } from "react-router-dom";
import useEventName from "../../hooks/useEventName";

const ReportStatsWrapper = styled(Grid)(({ theme }) => ({
    width: "100%",
    padding: "0px",
    borderRadius: "5px",
    paddingLeft: "0px",
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#FAFAFA",
    ...theme.typography.body2,
    paddingLeft: "0px",
    paddingTop: "0px",
    color: theme.palette.text.secondary,
    paddingBottom: "0px",
    boxShadow:
        "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)",
}));

type ReportModeratorStatsProps = {
    category: ReportingSpeakerTherapeuticArea;
    events: Event[];
    moderatorName: string;
    speakerId: string;
};

export const ReportModeratorStats = ({
    category,
    events,
    moderatorName,
    speakerId,
}: ReportModeratorStatsProps) => {
    const { t, i18n } = useTranslation();
    const current_language = i18n.language;
    const navigate = useNavigate();
    const [formatEventName] = useEventName();
    const [evaluationCount, setEvaluationCount] = useState<number>(0);
    const [moderatorAverage, setModeratorAverage] = useState<number>(0);

    const goToEventReport = (eventId: string) => {
        navigate(`/reporting/event/${eventId}`);
    };

    const moderatorColumns: GridColDef[] = [
        {
            field: "eventDate",
            headerName: `${t("create_event_form_name")}`,
            minWidth: 350,
            flex: 1,
            editable: false,
            renderCell: (params) => {
                return (
                    <a
                        style={{ cursor: "pointer", fontWeight: 400 }}
                        onClick={() => goToEventReport(params.row.id)}
                    >
                        {formatEventName(params.row as Event)}
                    </a>
                );
            },
            valueGetter: (params) => {
                {
                    formatEventName(params.row as Event);
                }
            },
        },
        {
            field: "evaluationCount",
            headerName: `${t("moderator_report.num_of_evals")} (${evaluationCount})`,
            width: 400,
            minWidth: 400,
            headerAlign: "center",
            align: "center",
            editable: false,
        },
        {
            field: "moderatorRating",
            headerName: `${t("moderator_report.moderator_score")}`,
            width: 220,
            minWidth: 220,
            headerAlign: "center",
            align: "center",
            editable: false,
            renderCell: (params) => {
                const moderatorRatings = params.row.evaluations
                    .map((evaluation: Evaluation) =>
                        evaluation.moderators
                            .filter((i) => i.moderatorId == speakerId)
                            .map((i) => i.rating)
                    )
                    .flat(1);

                return (
                    <span>
                        {moderatorRatings.length > 0
                            ? AverageNumbers(moderatorRatings)
                            : 0}
                    </span>
                );
            },
        },
    ];

    useEffect(() => {
        if (events.length > 0) {
            const count = events
                .map((i) => i.evaluationCount)
                .reduce((a, b) => a + b);
            setEvaluationCount(count);

            const eventEvaluations = events
                .map((event) => event.evaluations)
                .flat(1) as Evaluation[];
            const moderatorRatings = eventEvaluations
                .map((evaluation) =>
                    evaluation?.moderators
                        .filter((i) => i.moderatorId === speakerId)
                        .map((i) => i.rating)
                )
                .flat(1);
            const average = AverageNumbers(moderatorRatings);
            setModeratorAverage(average);
        }
    }, [events]);

    return (
        <ReportStatsWrapper item xs={12}>
            <Item elevation={0}>
                <ReportingSpeakerGridTable
                    rowHeight={40}
                    size="small"
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                            },
                        },
                    }}
                    pageSizeOptions={[5]}
                    className="reporting_grid"
                    getRowId={(event) => event.id}
                    rows={events}
                    columns={moderatorColumns}
                    disableRowSelectionOnClick
                    localeText={
                        current_language === "en"
                            ? enUS.components.MuiDataGrid.defaultProps
                                  .localeText
                            : frFR.components.MuiDataGrid.defaultProps
                                  .localeText
                    }
                    components={{
                        Toolbar: () =>
                            CustomExportToolbar(
                                category,
                                moderatorAverage,
                                current_language
                            ),
                    }}
                />
            </Item>
        </ReportStatsWrapper>
    );
};

const CustomExportToolbar = (
    category: ReportingSpeakerTherapeuticArea,
    rating: number,
    current_language: string
) => {
    if (Number.isNaN(rating)) rating = 0;
    return (
        <GridToolbarContainer
            sx={{
                border: "none",
                width: "100%",
                padding: 0,
            }}
        >
            <SpeakerTableHeader>
                <span>
                    {current_language === "en"
                        ? category.enName
                        : category.frName}
                </span>
                <span>{rating.toFixed(1) || 0}</span>
            </SpeakerTableHeader>
        </GridToolbarContainer>
    );
};

export default ReportModeratorStats;
