import { GridColDef, GridToolbarContainer, GridToolbarExport, enUS, GridFooter } from "@mui/x-data-grid";
import { frFRCustom as frFR } from "../locale/frFR";
import { IconButton, Modal } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { ActivityType } from "../typings/interfaces";
import { useTranslation } from "react-i18next";
import { useState, useContext } from "react";
import ReportingDataGridTable from "./styled/ReportingGrid";
import ActivityContext from "../context/ActivityContext";
import ActivityTypeForm from "./activityViews/ActivityTypeForm";
import DraggableDialog from "./styled/DraggableDialog";
import { useActivityTypes } from "../api/activityTypes";

type Props = {
  updateTable: () => void;
  update: boolean;
  toggleOpen: () => void;
  open: boolean;
};

const ActivityTypeList = ({ updateTable, update, toggleOpen, open }: Props) => {
  const { t, i18n } = useTranslation();
  const activity_type_label = t("activity_management.activity_type");
  const edit_label = t("edit");
  const delete_label = t("delete");
  const current_language = i18n.language;
  const [activityType, setActivityType] = useState<ActivityType>({ _id: "", enName: "", frName: "", value: "", status: "" });
  const { activityTypes } = useContext(ActivityContext);

  const { deleteById } = useActivityTypes();

  const [edit, setEdit] = useState(false);

  const closeModal = () => {
    setEdit(false);
    setActivityType({} as ActivityType);
    toggleOpen();
  }

  // console.log("activityTypes", activityTypes);

  const editActivityType = (activityType: ActivityType) => {
    const { _id, enName, frName, value, status } = activityType;
    setEdit(true);
    setActivityType({ _id, enName, frName, value, status });
    toggleOpen();
  };

  const deleteActivityType = async (_id: string) => {
    const confirm = window.confirm(t("delete_confirm_message"));

    if (!confirm) {
      return;
    }

    try {
      await deleteById(_id);
    } catch (error: any) {
      alert(error.response.data.message);
    }
  };

  const columns: GridColDef[] = [
    {      
      sortable: false,
      editable: false,
      filterable: false,
      hideable: false,
      field: "activityTypeName",
      headerName: activity_type_label,
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        if (current_language === "en") return params.row.enName;
        return params.row.frName;
      },
      valueGetter: (params) => {
        if (current_language === "en") return params.row.enName;
        return params.row.frName;
      }
    },
    {
      sortable: false,
      editable: false,
      filterable: false,
      hideable: false,
      field: "edit",
      width: 50,
      headerName: "",
      renderHeader: () => (null),
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,

      renderCell: (params) => (
        <strong>
          <IconButton aria-label="edit" color="warning" style={{ marginLeft: 0 }} onClick={() => editActivityType(params.row)}>
            <EditOutlinedIcon />
          </IconButton>
        </strong>
      ),
    },
    {
      sortable: false,
      editable: false,
      filterable: false,
      hideable: false,
      field: "delete",
      headerName: "",
      renderHeader: () => (null),
      width: 50,
      headerAlign: "center",
      disableColumnMenu: true,
      align: "center",
      renderCell: (params) => (
        <strong>
          <IconButton
            aria-label="delete"
            color="error"
            style={{ marginLeft: 0 }}
            onClick={() => {
              deleteActivityType(params.row._id);
            }}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </strong>
      ),
    },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
        <div>
          <GridToolbarExport
            csvOptions={{
              fields: ["activityTypeName"],
            }}
            printOptions={{
              fields: ["activityTypeName"],
            }}
          />
        </div>
        <div style={{ display: "flex" }}>
          <GridFooter
            sx={{
              border: "none", // To delete double border.
            }}
          />
        </div>
      </GridToolbarContainer>
    );
  };

  if (!activityTypes) {
    return <></>;
  } else {
    return (
      <div style={{ height: "100%", width: "100%", marginTop: "20px" }}>
        <ReportingDataGridTable
          getRowId={(row: any) => row._id}
          className="activity_type_grid"
          rows={activityTypes}
          columns={columns}
          disableRowSelectionOnClick
          localeText={current_language === "en" ? { ...enUS.components.MuiDataGrid.defaultProps.localeText, toolbarExport: "" } : { ...frFR.components.MuiDataGrid.defaultProps.localeText, toolbarExport: "" }}
          components={{
            Footer: CustomToolbar,
          }}
          pageSizeOptions={[100]}
        />
        <DraggableDialog open={open} closeModal={closeModal}>
          <ActivityTypeForm closeModal={closeModal} edit={edit} activityType={activityType} />
        </DraggableDialog>
      </div>
    );
  }
};

export default ActivityTypeList;
