import { axios } from "./axios";
import { Holiday } from "../typings/interfaces";
import useSWR from "swr";

export const ENDPOINT = "/holidays";

export const getHolidays = async () => {
  const { data } = await axios.get(ENDPOINT);
  return data.holidays as Holiday[];
};

interface CreateEditProps {
  enName: string;
  frName: string;
  date: string;
  forceHoliday: boolean;
}

export function useHolidays() {
  const {
    isLoading: isHolidaysLoading,
    data: holidays,
    error: holidaysError,
    mutate,
  } = useSWR(ENDPOINT, getHolidays, {
    onSuccess: (data) => data,
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });

  const create = async ({ enName, frName, date, forceHoliday }: CreateEditProps) => {
    const { data } = await axios.post(ENDPOINT, { enName, frName, date, forceHoliday });
    mutate();

    return data;
  };

  const update = async (id: string, { enName, frName, date, forceHoliday }: CreateEditProps) => {
    const { data } = await axios.put(`${ENDPOINT}/${id}`, { enName, frName, date, forceHoliday });
    mutate();

    return data;
  };

  const deleteById = async (id: string) => {
    const { data } = await axios.delete(`${ENDPOINT}/${id}`);
    mutate();

    return data;
  };

  const data = holidays as Holiday[];

  return {
    isHolidaysLoading,
    holidays: data,
    holidaysError,
    mutate,
    create,
    update,
    deleteById,
  };
}
