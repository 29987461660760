import Axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";

function authRequestInterceptor(config: InternalAxiosRequestConfig) {
  const token = localStorage.getItem("SavedToken");

  config.headers.setContentType("application/json");
  config.headers.setAuthorization(token);

  // assertion that env variable exists was already done outside the function
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  // config.headers.authorization = process.env.NEXT_PUBLIC_API_TOKEN!;
  return config;
}

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: `${localStorage.getItem("SavedToken")}`,
  },
});

axios.interceptors.request.use(authRequestInterceptor);
