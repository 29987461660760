import React, { useEffect, useState } from 'react'
import { FormControl, Grid, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useTranslation } from "react-i18next";
import { getDate, getDay } from 'date-fns';
import { daysOfWeek, getNumberOfWeekdayOccurrencesInMonth, getOccurrenceOfWeekdayInMonth } from '../../../utils/activitiesRecurringOccurences';
import { CapitalizeFirstChar } from '../../../utils/capitalize';

interface Props {
    handleRecurringInfoChange: () => void;
    date: Date
    formik: any;
    edit: boolean;
}

function ActivityFormScheduleMonthDropdown({handleRecurringInfoChange, date, formik, edit}: Props) {
    const { t, i18n } = useTranslation();
    const dayOfTheMonth = getDate(date);
    const weekdayOccurence = getOccurrenceOfWeekdayInMonth(date);
    const numberOfWeekdays = getNumberOfWeekdayOccurrencesInMonth(date);
    const weekday = daysOfWeek[getDay(date)];
    const weekdayTranslated = t(`recurring_activity_info_card.weekdays.${weekday}`)
    let initialMonthObject: any = {...formik.values.monthly, weekDay: weekday}
    const [selectedOption, setSelectedOption] = useState("dayOfTheMonth");

    useEffect(() => {
        if (!edit) {
            initialMonthObject.dayOfTheMonth = dayOfTheMonth;
            formik.setFieldValue("monthly", initialMonthObject);
        } else {
            initialMonthObject.dayOfTheMonth === 0 && setSelectedOption("weekDay");
            weekdayOccurence === numberOfWeekdays && numberOfWeekdays === 4 && initialMonthObject.last && setSelectedOption("lastWeekDay");
        }
    },[edit])

    function handleOptionChange(e:SelectChangeEvent) {
        resetOptions(); 
        const value = e.target.value
        if (value) {
            handleRecurringInfoChange();
            setSelectedOption(value);
            if(value === "dayOfTheMonth") {
                initialMonthObject.dayOfTheMonth = dayOfTheMonth;
            }

            else if(value == "weekDay" ) {
                initialMonthObject[occurrence] = true;
            } else {
                initialMonthObject["last"] = true;
            }

            formik.setFieldValue("monthly", initialMonthObject)
        }
    }

    const resetOptions = () => {
        initialMonthObject.dayOfTheMonth = 0;
        initialMonthObject.first = false;
        initialMonthObject.second = false;
        initialMonthObject.third = false;
        initialMonthObject.fourth = false;
        initialMonthObject.last = false;
    }
    
    let occurrenceTranslated = t("activity_form.recurring_activity.repeat_frequency_monthly_options.first");
    let occurrence = "first";
    if (weekdayOccurence === 2) {
        occurrence = "second";
        occurrenceTranslated = t("activity_form.recurring_activity.repeat_frequency_monthly_options.second");
    } else if (weekdayOccurence === 3) {
        occurrence = "third";
        occurrenceTranslated = t("activity_form.recurring_activity.repeat_frequency_monthly_options.third");
    } else if (weekdayOccurence === 4) {
        occurrence = "fourth";
        occurrenceTranslated = t("activity_form.recurring_activity.repeat_frequency_monthly_options.fourth");
    } else if (weekdayOccurence === 5) {
        occurrence = "last";
        occurrenceTranslated = t("activity_form.recurring_activity.repeat_frequency_monthly_options.last");
    }

    return (
        <Grid item xs={6} pr={"12px"}>
            <FormControl fullWidth focused>
                <Select
                    labelId="repeat_frequency"
                    id="repeat_frequency"
                    value={selectedOption}
                    variant="outlined"
                    onChange={handleOptionChange}
                >
                    <MenuItem value="dayOfTheMonth">{t("activity_form.recurring_activity.repeat_frequency_monthly_options.monthly_on_day")} {dayOfTheMonth}</MenuItem>
                    <MenuItem value="weekDay">{t("activity_form.recurring_activity.repeat_frequency_monthly_options.monthly_on_the")} {occurrenceTranslated} {weekdayTranslated}</MenuItem>
                    {weekdayOccurence === numberOfWeekdays && numberOfWeekdays === 4 &&
                        <MenuItem value="lastWeekDay">{t("activity_form.recurring_activity.repeat_frequency_monthly_options.monthly_on_the")} {t("activity_form.recurring_activity.repeat_frequency_monthly_options.last")} {CapitalizeFirstChar(weekday)}</MenuItem>
                    }
                </Select>
            </FormControl>
        </Grid>
    )
}

export default ActivityFormScheduleMonthDropdown