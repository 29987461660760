import { format, addMonths, subMonths, getMonth, getYear, isAfter } from "date-fns";
import { enCA, fr } from 'date-fns/locale';
import { GridColDef, GridToolbarContainer, GridToolbarExport, enUS, GridFooter } from "@mui/x-data-grid";
import { frFRCustom as frFR } from "../../locale/frFR";
import { Container, IconButton, styled, Tooltip } from "@mui/material";
import muiTheme from "../../muiTheme";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined";
import { Event } from "../../typings/interfaces";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import EventPageToolBar from "../EventPageToolBar";
import EventBusyIcon from '@mui/icons-material/EventBusy';
import ReportingDataGridTable from "../styled/ReportingGrid";
import { useRegions } from "../../api/regions";
import useEventName from "../../hooks/useEventName";
import { useSpeakers } from "../../api/speakers";
import EventContext from "../../context/EventContext";
import { useEvents } from "../../api/events";
import { convertFromTimezoneDisplay } from "../../utils/timezones";
import DraggableDialog from "../styled/DraggableDialog";
import CalendarEventInfoCard from "../CalendarEventInfoCard";
import { parseEventStartTime } from "../../utils/parseDate";
import { provinceList } from "../../utils/provinces";


const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}));


const CustomNoRowsOverlay = () => {
  const { t, i18n } = useTranslation();
  return (
    <StyledGridOverlay>
      <EventBusyIcon fontSize="large" color="primary"/>
        {t("list_view.no_event")}
      </StyledGridOverlay>
  )
}



type Props = {
  view: string; //current view calendar/list
  updateView: (view: string) => void;
  toggleCreateModal: (date?: Date) => void;
  toggleEditModal: (event: Event) => void;
  events: Event[];
};

//we provide events here to ensure we have them as the Event page handles waiting for the data to load
const ListView = ({ view, updateView, toggleCreateModal, toggleEditModal, events }: Props) => {
  const navigate = useNavigate();
  const todaysDate = new Date();
  const [formatEventName] = useEventName({includeTime: true});
  const { reps, therapeuticAreas } = useContext(EventContext);
  const { t, i18n } = useTranslation();
  const current_language = i18n.language;
  const dateFnsLocale = i18n.language === 'en' ? enCA : fr;
  const currentDateFormatted = format(todaysDate, 'LLLL yyyy', {locale: dateFnsLocale});
  const [date, setDate] = useState(todaysDate); //todays date
  const [title, setTitle] = useState(currentDateFormatted);

  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<Event>({} as Event);
  
  const closeInfoModal = () => {
    setInfoModalOpen(false);
  };
  const openInfoModal = (event: Event) => {
    setSelectedEvent(event);
    setInfoModalOpen(true);
  }
  //When the edit button is clicked from the EventInfoCard modal
  const switchToEdit = (event: Event) => {
    //close the info card
    closeInfoModal();

    //open the edit modal
    toggleEditModal(event);
  }

  const [monthEvents, setMonthEvents] = useState([] as Event[]);
  const {regions} = useRegions();
  const { speakers } = useSpeakers();
  const { deleteEvent } = useEvents();

  const event_label = t("list_view.event_name");
  const region_label = t("list_view.region");
  const date_time_label = t("list_view.date_time");
  const therapeutic_label = t("list_view.therapeutic_area");
  const speaker_label = t("list_view.speaker");
  const assigned_rep_label = t("list_view.rep");
  const event_type_label = t("list_view.event_type");
  const edit_label = t("list_view.edit");
  const delete_label = t("list_view.delete");
  const export_label = "";

  const currentDateTime = new Date().getTime();

  useEffect(() => {
    filterEventsByMonth();
  }, [date, events]);

  useEffect(() => {
    const formattedTitle = format(date, 'LLLL yyyy', {locale: dateFnsLocale}).toUpperCase();
    setTitle(formattedTitle);

  },[current_language])

  const mailTo = (event: Event) => {
    const evaluationURL = `${window.location.origin}/evaluations/${event.id}`;
    
    const to = "";
    const eventName = formatEventName(event, false, true);
    const eventDateTime = format(parseEventStartTime(event.start_time), i18next.language === "en" ? "h:mm aa" : "H 'h 'mm");
    const eventTimeZone = t(convertFromTimezoneDisplay(event.timeZone));
    console.log("event.eventType.toLowerCase()", event.eventType.toLowerCase())
    const title = `${t("evaluation.share_email.title")} ${t(event.eventType.toLowerCase())} ${i18next.language === "en" ? "event" : ""}: ${eventName} ${t("evaluation.share_email.at")} ${eventDateTime} (${eventTimeZone})`;
    const province = provinceList.find((i) => i.value === event.province);
    const location = event.eventType.toLowerCase() !== "virtual" && province ? `${t("evaluation.page.location")} ${event.street}, ${event.city}, ${i18next.language === "en" ? province.name : province.frName}, ${event.postal}` : undefined;

    const subject = encodeURIComponent(`${t("evaluation.share_email.subject")}: ${eventName}`);
    const evaluationText = `${t("evaluation.share_email.body")}: ${evaluationURL}`;
    const outro = t("evaluation.share_email.outro");
    const copy = t("evaluation.share_email.copy");
    if (location) {
      let bodyContent = title + '.\r\n\r\n' + location + '.\r\n\r\n' + evaluationText + '\r\n\r\n' + outro + '\r\n\r\n' + copy;
      const body = encodeURIComponent(bodyContent);
      window.open(`mailto:${to}?subject=${subject}&body=${body}`)  
    } else {
      let bodyContent = title + '.\r\n\r\n' + evaluationText + '\r\n\r\n' + outro + '\r\n\r\n' + copy;
      const body = encodeURIComponent(bodyContent);
      window.open(`mailto:${to}?subject=${subject}&body=${body}`)  
    }
  }

  const tryDelete = async (id: string) => {
    const confirm = window.confirm(t("delete_confirm_message"));

    if (!confirm) {
      return;
    }

    try {
      await deleteEvent(id);
    } catch (error) {
      alert(t("delete_event_fail_messsage"));
    }
  };

  // used to set the disabled status of the buttons on the row if 
  // the event start time is in the past, there is attendees or there is evaluations for the event
  const canModifyEvent = (event: Event) => {
    return isAfter(currentDateTime, parseEventStartTime(event.start_time).getTime()) || event.evaluationCount > 0 || (event.attendeeCount ? event.attendeeCount > 0 : false)
  } 

  const TooltipProps = {
    tooltip: {
      sx: {
        fontSize: "0.9rem!important",
        maxWidth:"500px",
        textAlign:"center",
        marginBottom: "5px!important",
        bgcolor: "#ffffff",
        color: "primary.dark",
        boxShadow: " 0 2px 4px 0 rgba(0,0,0,0.15)",
        border: "1px solid #D8D8D8",
        "& .MuiTooltip-arrow": {
          color: "#ffffff",
          "&:before": {
            border: "1px solid #D8D8D8",
          },
        },
      },
    }
  }

  const columns: GridColDef[] = [
    {
      field: "title",
      headerName: event_label,
      width: 250,
      minWidth: 250,
      editable: false,
      renderCell: (params) => {
        return (
        <Tooltip title={formatEventName(params.row)} placement="top" 
        arrow={true} 
        componentsProps={TooltipProps} 
        enterNextDelay={500}
        enterDelay={500}
        leaveDelay={200}>
          <span style={{color: muiTheme.palette.primary.dark, textDecoration:"underline", cursor:"pointer"}} onClick={() => openInfoModal(params.row)}>{formatEventName(params.row)}</span>
        </Tooltip>
        )
      },
      valueGetter: (params) => (
        formatEventName(params.row)
      )
    },
    {
      field: "startTime",
      headerName: date_time_label,
      width: 170,
      minWidth: 170,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        const dateTime = format(new Date(params.row.start_time), "MMM dd, yyyy - p")
        return (
          <Tooltip title={dateTime} placement="top" 
          arrow={true} 
          componentsProps={TooltipProps} 
          enterNextDelay={500}
          enterDelay={500}
          leaveDelay={200}>
            <span>{dateTime}</span>
          </Tooltip>
        )
      },
      valueGetter: (params) => (
        format(new Date(params.row.start_time), "MMM dd, yyyy - p")
      )
    },
    {
      field: "regionId",
      headerName: region_label,
      width: 200,
      minWidth: 200,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        const region = regions.find((region) => region._id === params.row.regionId);
        var name = "";
        if(current_language ==='en') name = region?.enName as string;
        else name = region?.frName as string;
        return (
          <Tooltip title={name} placement="top" 
          arrow={true} 
          componentsProps={TooltipProps} 
          enterNextDelay={500}
          enterDelay={500}
          leaveDelay={200}>
            <span>{name}</span>
          </Tooltip>)
      },
      valueGetter: (params) => {
        const region = regions.find((region) => region._id === params.row.regionId);
        var name = "";
        if(current_language ==='en') name = region?.enName as string;
        else name = region?.frName as string;
        return name;
      }
    },
    {
      field: "therapeuticArea",
      headerName: therapeutic_label,
      width: 220,
      minWidth: 220,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        if(params.row.therapeuticArea) {
          const therapeuticArea = therapeuticAreas.find((therapeuticArea) => therapeuticArea.value === params.row.therapeuticArea);
          if(current_language ==='en') return therapeuticArea?.enName;
          return therapeuticArea?.frName;
        } else {
          if(current_language ==='en') return "Uncategorized";
          return "FR Uncategorized"
        }
      },
      valueGetter: (params) => {
        if(params.row.therapeuticArea) {
          const therapeuticArea = therapeuticAreas.find((therapeuticArea) => therapeuticArea.value === params.row.therapeuticArea);
          if(current_language ==='en') return therapeuticArea?.enName;
          return therapeuticArea?.frName;
        } else {
          if(current_language ==='en') return "Uncategorized";
          return "FR Uncategorized"
        }
      }
    },
    {
      field: "speakerId",
      headerName: speaker_label,
      width: 150,
      minWidth: 150,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        const speakerNames = params.row.speakerIds.map((speakerId: string) => (speakers.find(speaker => speaker.speakerId === speakerId) || {}).displayName).filter(Boolean).join(", ");
        return (
          <Tooltip title={speakerNames} placement="top" 
          arrow={true} 
          componentsProps={TooltipProps} 
          enterNextDelay={500}
          enterDelay={500}
          leaveDelay={200}>
            <span>{speakerNames}</span>
          </Tooltip>)
      },
      valueGetter: (params) => (
        params.row.speakerIds.map((speakerId: string) => (speakers.find(speaker => speaker.speakerId === speakerId) || {}).displayName).filter(Boolean).join(", ")
      )
    },
    {
      field: "repId",
      headerName: assigned_rep_label,
      width: 160,
      minWidth: 160,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        const repNames = reps.find(rep => rep.id === params.row.repId);
        return (
          <Tooltip title={repNames && repNames.name} placement="top" 
          arrow={true} 
          componentsProps={TooltipProps} 
          enterNextDelay={500}
          enterDelay={500}
          leaveDelay={200}>
            <span>{repNames && repNames.name}</span>
          </Tooltip>)
      },
      valueGetter: (params) => {
        const repNames = reps.find(rep => rep.id === params.row.repId);
        return repNames?.displayName || "No Name"
      }
    },
    {
      field: "eventType",
      headerName: event_type_label,
      width: 140,
      minWidth: 140,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return params.row.eventType ? t(params.row.eventType.toLowerCase()) : t("no_type_provided")
      },
      valueGetter: (params) => {
        return params.row.eventType ? t(params.row.eventType.toLowerCase()) : t("no_type_provided")
      }
    },
    {
      sortable: false,
      editable: false,
      filterable: false,
      field: "Share",
      width: 60,
      minWidth: 60,
      headerName: "",
      disableColumnMenu: true,
      headerClassName: "action_header",

      renderCell: (params) => (
        <strong>
          <IconButton
            style={{color:muiTheme.palette.primary.main}}
            aria-label="Share"
            size="small"
            onClick={() => mailTo(params.row)}
          >
            <IosShareOutlinedIcon />
          </IconButton>
        </strong>
      ),
    },
    {
      sortable: false,
      editable: false,
      filterable: false,
      field: "Edit",
      width: 60,
      minWidth: 60,
      headerName: "",
      disableColumnMenu: true,
      headerClassName: "action_header",

      renderCell: (params) => (
        <strong>
          <IconButton
            aria-label="Edit"
            size="small"
            color="warning"
            disabled={canModifyEvent(params.row)}
            onClick={() => {
              toggleEditModal(params.row)
            }}
          >
            <EditOutlinedIcon />
          </IconButton>
        </strong>
      ),
    },
    {
      sortable: false,
      editable: false,
      filterable: false,
      field: "Delete",
      headerName: "",
      width: 75,
      minWidth: 75,
      disableColumnMenu: true,
      headerClassName: "action_header",

      renderCell: (params) => (
        <strong>
          <IconButton
            edge="end"
            aria-label="Delete"
            color="error"
            size="small"
            disabled={canModifyEvent(params.row)}
            onClick={() => {
              tryDelete(params.row.id);
            }}
          >
            <DeleteOutlinedIcon />
          </IconButton>
        </strong>
      ),
    },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={{ justifyContent: "space-between" }}>
        <div>
          <GridToolbarExport
            csvOptions={{
              fields: ["title", "purpose", "start_time", "duration", "timeZone", "eventType"],
            }}
            printOptions={{
              fields: ["title", "purpose", "start_time", "duration", "timeZone", "eventType"],
            }}
          />
        </div>
        <GridFooter
          sx={{
            border: "none", // To delete double border.
          }}
        />
      </GridToolbarContainer>
    );
  };

  const prevMonth = () => {
    // get the year before month change
    const subMonth = subMonths(date, 1); //new date object that is going to be
    setDate(subMonth);
    setTitle(format(subMonth, 'LLLL yyyy', {locale: dateFnsLocale}).toUpperCase());
  }

  const nextMonth = () => {
    // get the year before month change
    const addMonth = addMonths(date, 1);
    setDate(addMonth);
    setTitle(format(addMonth, 'LLLL yyyy', {locale: dateFnsLocale}).toUpperCase());
  }

  const goToToday = () => {
    setDate(todaysDate);
    setTitle(currentDateFormatted.toUpperCase());
  }

  //filter events by the current month/year
  const filterEventsByMonth = () => {

    const year = getYear(date);
    const month = getMonth(date);
    const yearMonth = `${year}-${month}`;
    //if the event date is in this month
    const filteredEventsByYearMonth  = events.filter((event) => {

      const eventYear = getYear(parseEventStartTime(event.start_time));
      const eventMonth = getMonth(parseEventStartTime(event.start_time));
      const eventYearMonth = `${eventYear}-${eventMonth}`

      if( yearMonth === eventYearMonth) {
        return true;
      }
    });
    setMonthEvents(filteredEventsByYearMonth)
  }

  const handleRowClick = (params:any, event:any) => {
    const target = event.target as HTMLElement;
    if (target.dataset.colindex === "0") {
      openInfoModal(params.row);
    }
  }

  return (
    <>
      {/* Event info card modal */}
      <DraggableDialog size="600px" open={infoModalOpen} closeModal={closeInfoModal}>
        <>
          <CalendarEventInfoCard event={selectedEvent} toggleEditModal={switchToEdit} closeModal={closeInfoModal} />
        </>
      </DraggableDialog>
      <EventPageToolBar view={view} updateView={updateView} prevMonth={prevMonth} nextMonth={nextMonth} goToToday={goToToday} title={title} reps={reps} />
      <Container maxWidth="xl" sx={{mt:6, mb:6}}>
        <div style={{ margin: "20px", display: "flex", alignItems: "center", justifyContent:"space-between" }}>
          <h1 style={{color:"#001965", margin:"10px 0px"}}>{title}</h1>
          <div style={{ justifyContent:"flex-end"}}>
            <IconButton
              sx={{ backgroundColor: "primary.main", color: "white", borderRadius: "44px", "&:hover": {backgroundColor: "primary.main"} }}
              onClick={() => {
                toggleCreateModal();
              }}
            >
              <AddIcon></AddIcon>
            </IconButton>
          </div>
        </div>
        <div style={{ height: "auto", width: "100%", marginTop: "20px" }}>
          <ReportingDataGridTable
            className="user_grid"
            rows={[...monthEvents].reverse()}
            columns={columns}
            disableRowSelectionOnClick
            localeText={current_language === "en" ? {...enUS.components.MuiDataGrid.defaultProps.localeText, toolbarExport:""} : {...frFR.components.MuiDataGrid.defaultProps.localeText, toolbarExport:""} }
            slots={{
              footer: CustomToolbar,
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            onRowClick={handleRowClick}
            sx={{
              "& .MuiDataGrid-columnHeadersInner": {
                borderBottomColor: "primary.main",
                borderBottomWidth: "6px",
                borderBottomStyle: "solid"
              },
              "& .action_header .MuiDataGrid-columnSeparator": {
                display:"none",
              },
            }}
          />
        </div>
      </Container>
    </>
  );
};

export default ListView;
