import { axios } from "./axios";
import useSWR from "swr";
import { RecurringActivity } from "../typings/interfaces";

export const ENDPOINT = "/recurring-activities";

export async function getRecurringActivities() {
  const { data } = await axios.get(ENDPOINT);
  return data.recurringActivities as RecurringActivity[];
}

export const useRecurringActivities = () => {
  /**
   * Get an activity by the activity._id
   * @param id The ID of the activity to look up
   * @returns The found activity
   */
  async function getById(id: string) {
    const { data } = await axios.get(`${ENDPOINT}/activity/${id}`);

    return data;
  }

  async function createRecurringActivity(activity: any) {
    const { data } = await axios.post(ENDPOINT, activity);
    mutateRecurringActivities();

    return data;
  }

  async function editRecurringActivity(activity: any) {
    const { data } = await axios.put(`${ENDPOINT}/${activity.id}`, activity);
    mutateRecurringActivities();

    return data;
  }

  async function editRecurringActivityOccurrence(activity: any, occurrence: number) {
    const { data } = await axios.put(`${ENDPOINT}/${activity.id}/occurence/${occurrence}`, activity);
    mutateRecurringActivities();

    return data;
  }

  async function editRecurringActivityFromDate(activity: any) {
    const { data } = await axios.put(`${ENDPOINT}/${activity.id}/from-date`, activity);
    mutateRecurringActivities();

    return data;
  }

  /**
   * This will delete an activity from the DB if allowed.
   * @param id the ID of the activity that you want to delete
   */
  async function deleteRecurringActivity(id: string) {
    const response = await axios.delete(`${ENDPOINT}/${id}`);
    mutateRecurringActivities();

    return response;
  }

  async function deleteOccurence(id: string, occurence: number) {
    const response = await axios.delete(`${ENDPOINT}/${id}/occurence/${occurence}`);
    mutateRecurringActivities();

    return response;
  }

  async function deleteFromDate(id: string, date: string) {
    const response = await axios.delete(`${ENDPOINT}/${id}/from-date`, {
      data: {
        date
      }
    });
    mutateRecurringActivities();

    return response;
  }

  const {
    isLoading: isRecurringActivitiesLoading,
    error: recurringActivitiesError,
    data: recurringActivity,
    mutate: mutateRecurringActivities,
  } = useSWR(ENDPOINT, getRecurringActivities, {
    onSuccess: (data) => data,
    revalidateOnFocus: false,
    revalidateIfStale: false,
    refreshInterval: 120000, //refresh every 5 minutes to keep data up to date
  });

  const data = recurringActivity as RecurringActivity[];

  return {
    isRecurringActivitiesLoading,
    recurringActivitiesError,
    recurringActivities: data,
    mutateRecurringActivities,
    createRecurringActivity,
    editRecurringActivity,
    editRecurringActivityOccurrence,
    editRecurringActivityFromDate,
    getRecurringActivities,
    getById,
    deleteRecurringActivity,
    deleteOccurence,
    deleteFromDate
  };
};
