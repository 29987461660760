import styled from "@emotion/styled"; //emotion styled components
import { Autocomplete, Box, IconButton, TextField, Tooltip } from "@mui/material";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import TodayIcon from "@mui/icons-material/Today";
import { Users } from "../typings/interfaces";
import AppContext from "../context/Auth";
import { useContext } from "react";
import EventContext from "../context/EventContext";
import InstructionsPopover from "./styled/InstructionsPopover";
import { useTranslation } from "react-i18next";
import MobileEventFiltersDrawer from "./eventViews/MobileEventFiltersDrawer";

const ToolBarWrapper = styled.div`
  color: #494949;
  display: flex;
  background-color: white;
  background: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(145, 145, 145, 0.5);
  justify-content: space-between;
  align-items: center;
  padding: 3px 10px;
  z-index: 2;
  position: relative;
  min-height: 60px;
`;

type ToolBarColumnProps = {
  textAlign?: "center" | "left" | "right";
  flex?: boolean;
  justifyContent?: string;
  width?: string;
  minWidth?: string;
}
const ToolBarColumn = styled("div")<ToolBarColumnProps>(({ textAlign, flex, justifyContent, width, minWidth, theme }) => ({
  width: width ? width : "100%",
  minWidth: minWidth ? minWidth : "auto",
  textAlign: textAlign ? textAlign : "center",
  display: flex ? "flex" : "flex",
  justifyContent: justifyContent ? justifyContent : "center",
  alignItems: "center"
}));


type Props = {
  view: string;
  updateView: (view: string) => void;
  nextMonth?: () => void;
  prevMonth?: () => void;
  goToToday?: () => void;
  title: string;
  reps: Users[];
};


const EventPageToolBar = ({ view, updateView, nextMonth, prevMonth, goToToday, title, reps }: Props): JSX.Element => {
  const { hasRole } = useContext(AppContext);
  const { repID, setRepID } = useContext(EventContext);
  const { t } = useTranslation();
  
  const filterByRep = (event: React.SyntheticEvent<Element, Event>, value: Users | null, reason: any, details?: any | undefined) => {
    if (value && reason === "selectOption"){
      const userID = value.id
      //send id back up to filter events by repID on the Events Page
      setRepID(userID)
    }
    else {
      setRepID("");
    }
  }

  return (
    <>
    <ToolBarWrapper>
      <ToolBarColumn textAlign="left" justifyContent="left">
        <Autocomplete
          // disablePortal
          hidden={hasRole(["rep"])}
          sx={{width:"100%", maxWidth:"475px", my:1, display: {xs: "none", lg: "block"}}}
          onChange={(event: React.SyntheticEvent<Element, Event>, value: Users | null, reason: any, details?: any | undefined) => filterByRep(event, value, reason, details)}
          disableListWrap
          id="reps"
          options={reps}
          value={reps?.find(i => i.id === repID) || null}
          autoHighlight
          getOptionLabel={(option) => `${option.name} (${option.email})`}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {option.name} ({option.email})
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              placeholder={t("toolbar.all_reps_placeholder")}
              {...params}
              label={t("toolbar.all_reps_label")}
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
          />
          <MobileEventFiltersDrawer reps={reps} />
      </ToolBarColumn>

      {(view === "calendar" || view === "list") && (
        <ToolBarColumn justifyContent="center">
          <IconButton aria-label="Previous month" onClick={prevMonth}>
            <NavigateBeforeIcon />
          </IconButton>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Today">
              <IconButton aria-label="Today" onClick={goToToday}>
                <TodayIcon />
              </IconButton>
            </Tooltip>
            <span style={{ fontWeight: "500" }}>{title}</span>
          </div>
          <IconButton aria-label="Next month" onClick={nextMonth}>
            <NavigateNextIcon />
          </IconButton>
        </ToolBarColumn>
      )}

      <ToolBarColumn textAlign="right" justifyContent="right">
        <Tooltip title={t("calendar_nav.calendar_view")}>
          <IconButton aria-label="Calendar View" onClick={() => updateView("calendar")} sx={{ backgroundColor: view === "calendar" ? "#E8F3FF" : "#ffffff" }}>
            <CalendarMonthOutlinedIcon sx={{ color: view === "calendar" ? "#005AD2" : "#b3b3b3" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("calendar_nav.list_view")}>
          <IconButton aria-label="List View" onClick={() => updateView("list")} sx={{ backgroundColor: view === "list" ? "#E8F3FF" : "#ffffff" }}>
            <FormatListBulletedIcon sx={{ color: view === "list" ? "#005AD2" : "#b3b3b3" }} />
          </IconButton>
        </Tooltip>
        <InstructionsPopover><p>{t("instructions.event_page_toolbar")}</p></InstructionsPopover>
      </ToolBarColumn>
    </ToolBarWrapper>
    </>
  );
};

export default EventPageToolBar;
