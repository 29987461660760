import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Layout from "../components/Layout";
import ActivityTypeList from "../components/ActivityTypeList";
import ActivityBrandList from "../components/ActivityBrandList";
import { useContext, useState } from "react";
import NoAccess from "../components/NoAccess";
import AppContext from "../context/Auth";
import Loading from "../components/Loading";
import AddIcon from "@mui/icons-material/Add";

const ActivityManagementPage = () => {
  const { t } = useTranslation();
  const [update, setUpdate] = useState(false);
  const { hasTool, hasRole, userLoading, loading } = useContext(AppContext);

  const [openBrandModal, setOpenBrandModal] = useState(false);
  const [openTypeModal, setOpenTypeModal] = useState(false);

  const toggleOpenBrandModal = () => {
    setOpenBrandModal((old) => !old);
  };
  const toggleOpenTypeModal = () => {
    setOpenTypeModal((old) => !old);
  };

  const updateTable = () => {
    setUpdate(!update);
  };

  if (userLoading || loading) {
    return (
      <Layout>
        <Loading />
      </Layout>
    );
  }

  return (
    <Layout>
      {hasTool(["activity-calendar"]) && hasRole(["sudo", "admin"]) ? (
        <Box my={5}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography
                variant="h4"
                color={(theme) => theme.palette.primary.dark}
                sx={{
                  marginTop: "15px",
                  fontWeight: "600",
                }}
              >
                {t("activity_management.title")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} lg={6} display="flex" justifyContent="flex-end" paddingRight={1}>
              <IconButton sx={{ backgroundColor: "primary.main", color: "white", borderRadius: "44px", "&:hover": { backgroundColor: "primary.main" } }} onClick={toggleOpenBrandModal} aria-label={t("activity_form.add_btn")}>
                <AddIcon sx={{ color: "#FFFFFF", fontSize: "1.5rem" }}></AddIcon>
              </IconButton>
            </Grid>
            <Grid item xs={6} display={{ xs: "none", lg: "flex" }} justifyContent="flex-end">
              <IconButton sx={{ backgroundColor: "primary.main", color: "white", borderRadius: "44px", "&:hover": { backgroundColor: "primary.main" } }} onClick={toggleOpenTypeModal} aria-label={t("activity_form.add_btn")}>
                <AddIcon sx={{ color: "#FFFFFF", fontSize: "1.5rem" }}></AddIcon>
              </IconButton>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              sx={{
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              <ActivityBrandList updateTable={updateTable} update={update} open={openBrandModal} toggleOpen={toggleOpenBrandModal} />
            </Grid>
            <Grid item xs={12} display={{ xs: "flex", lg: "none" }} justifyContent="flex-end">
              <IconButton sx={{ backgroundColor: "primary.main", color: "white", borderRadius: "44px", "&:hover": { backgroundColor: "primary.main" } }} onClick={toggleOpenTypeModal}>
                <AddIcon sx={{ color: "#FFFFFF", fontSize: "1.5rem" }}></AddIcon>
              </IconButton>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              sx={{
                marginTop: { xs: "none", lg: "10px" },
                marginBottom: "20px",
              }}
            >
              <ActivityTypeList updateTable={updateTable} update={update} open={openTypeModal} toggleOpen={toggleOpenTypeModal} />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <NoAccess />
      )}
    </Layout>
  );
};

export default ActivityManagementPage;
