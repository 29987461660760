import { CheckCircleOutline, SentimentDissatisfied, SentimentNeutral, SentimentSatisfied, SentimentVeryDissatisfied, SentimentVerySatisfied } from "@mui/icons-material";
import { Typography, styled, Container, Table, lighten, TextField, ButtonProps, Button, Link } from "@mui/material";
import { format } from "date-fns";
import { enUS, fr } from 'date-fns/esm/locale'
import { useFormik } from "formik";
import QuestionRadioGroup from "./QuestionRadioGroup";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { CheckinEvent, EventLearningObjective, LearningObjective } from "../../typings/interfaces";
import { getEventSpeakerNames } from "../../utils/speakers";
import { getEventModeratorNames } from "../../utils/moderators";
import { useEvaluations } from "../../api/evaluations";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import useEventNameCheckin from "../../hooks/nonauthenticated/useEventNameCheckin";
import i18next from "i18next";
import { parseEventStartTime } from "../../utils/parseDate";
import { convertFromTimezoneDisplay } from "../../utils/timezones";
import { GetProvinceName } from "../../utils/provinces";
import { CapitalizeFirstChar } from "../../utils/capitalize";

const FormButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  padding: "8px",
  fontSize: "20px",
  boxShadow: "unset",
  borderRadius: "4px",
  width: "45%",
  marginBottom: "20px",
  textTransform: "uppercase",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: "unset",
    color: "#fff",
  },
}));

const LargeText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontWeight: 700,
  fontSize: "28px",
  marginBottom: "10px",
}));

interface TextProps {
  primary?: boolean;
}
const NormalText = styled(Typography)<TextProps>(({ theme, primary }) => ({
  fontSize: "18px",
  color: primary ? theme.palette.primary.dark : "#3D3D3D",
}));

const SpeakerText = styled(Typography)(({ theme }) => ({
  fontWeight: "normal",
  fontSize: "18px",
  marginBottom: "5px",
  color: "#3D3D3D",
}));

const TimeText = styled(Typography)(({ theme }) => ({
  fontSize: "19px",
  fontWeight: "bold",
  color: theme.palette.primary.dark,
  marginTop: "10px",
}));

const RatingTable = styled(Table)(({ theme }) => ({
  backgroundColor: lighten(theme.palette.primary.main, 0.8),
  marginTop: "15px",
  tr: {
    border: `1px solid ${theme.palette.primary.dark}`,
    td: {
      padding: "5px",
      display: "flex",
      justifyContent: "center",
      color: "#3D3D3D",
      ".MuiSvgIcon-root": {
        marginRight: "8px",
      },
    },
  },
}));

const QuestionSection = styled("div")(({ theme }) => ({
  textAlign: "left",
  marginTop: "30px",
  ".question-header": {
    fontWeight: "bold",
    color: theme.palette.primary.dark,
    fontSize: "24px",
    textTransform: "uppercase",
  },
  ".question-text": {
    marginTop: "10px",
    fontSize: "18px",
    color: "#3D3D3D",
  },
  ".MuiSvgIcon-root": {
    fontSize: "30px",
  },
  ".learning-objective-sub-text": {
    marginTop: "20px",
    fontSize: "18px",
    color: "#3D3D3D",
  },
  ".learning-objective-text": {
    marginTop: "10px",
    fontSize: "18px",
    color: "#3D3D3D",
  },
  '.footnote-text': {
    color: "#3D3D3D",
    fontSize: "14px",
    marginBottom: "20px"
  }
}));

interface EvaluationFormProps {
  exit: () => void;
  event: CheckinEvent;
  attendeeId: string;
}
const EvaluationForm = ({ exit, event, attendeeId }: EvaluationFormProps) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const [submitted, setSubmitted] = useState(false);
  const { create } = useEvaluations();
  const [formatEventNameCheckin] = useEventNameCheckin();

  const handleSubmit = async (values: any) => {
    try {
      window.scrollTo(0, 0);

      var speakerRatings = [];
      if (event.speakers) {
        if (event.speakers[0]) {
          speakerRatings.push({
            speakerId: event.speakers[0].speakerId,
            name: event.speakers[0].name,
            rating: values.speakerOne,
          });
        }
        if (event.speakers[1]) {
          speakerRatings.push({
            speakerId: event.speakers[1].speakerId,
            name: event.speakers[1].name,
            rating: values.speakerTwo,
          });
        }
        if (event.speakers[2]) {
          speakerRatings.push({
            speakerId: event.speakers[2].speakerId,
            name: event.speakers[2].name,
            rating: values.speakerThree,
          });
        }
        if (event.speakers[3]) {
          speakerRatings.push({
            speakerId: event.speakers[3].speakerId,
            name: event.speakers[3].name,
            rating: values.speakerFour,
          });
        }
        if (event.speakers[4]) {
          speakerRatings.push({
            speakerId: event.speakers[4].speakerId,
            name: event.speakers[4].name,
            rating: values.speakerFive,
          });
        }
      }

      var moderatorRatings = [];
      if (event.moderators) {
        if (event.moderators[0]) {
          moderatorRatings.push({
            moderatorId: event.moderators[0].moderatorId,
            name: event.moderators[0].name,
            rating: values.moderatorOne,
          });
        }
        if (event.moderators[1]) {
          moderatorRatings.push({
            moderatorId: event.moderators[1].moderatorId,
            name: event.moderators[1].name,
            rating: values.moderatorTwo,
          });
        }
        if (event.moderators[2]) {
          moderatorRatings.push({
            moderatorId: event.moderators[2].moderatorId,
            name: event.moderators[2].name,
            rating: values.moderatorThree,
          });
        }
        if (event.moderators[3]) {
          moderatorRatings.push({
            moderatorId: event.moderators[3].moderatorId,
            name: event.moderators[3].name,
            rating: values.moderatorFour,
          });
        }
        if (event.moderators[4]) {
          moderatorRatings.push({
            moderatorId: event.moderators[4].moderatorId,
            name: event.moderators[4].name,
            rating: values.moderatorFive,
          });
        }
      }

      let learningObjectiveRatings = [];

      if (event.learningObjectives[0]) {
        learningObjectiveRatings.push({
          learningObjectiveId: event.learningObjectives[0]._id,
          customName: event.learningObjectives[0].customName,
          rating: values.objectiveOne,
        });
      }
      if (event.learningObjectives[1]) {
        learningObjectiveRatings.push({
          learningObjectiveId: event.learningObjectives[1]._id,
          customName: event.learningObjectives[1].customName,
          rating: values.objectiveTwo,
        });
      }
      if (event.learningObjectives[2]) {
        learningObjectiveRatings.push({
          learningObjectiveId: event.learningObjectives[2]._id,
          customName: event.learningObjectives[2].customName,
          rating: values.objectiveThree,
        });
      }
      if (event.learningObjectives[3]) {
        learningObjectiveRatings.push({
          learningObjectiveId: event.learningObjectives[3]._id,
          customName: event.learningObjectives[3].customName,
          rating: values.objectiveFour,
        });
      }
      if (event.learningObjectives[4]) {
        learningObjectiveRatings.push({
          learningObjectiveId: event.learningObjectives[4]._id,
          customName: event.learningObjectives[4].customName,
          rating: values.objectiveFive,
        });
      }
      if (event.learningObjectives[5]) {
        learningObjectiveRatings.push({
          learningObjectiveId: event.learningObjectives[5]._id,
          customName: event.learningObjectives[5].customName,
          rating: values.objectiveSix,
        });
      }

      // console.log("values", values);
      // console.log("learningObjectiveRatings", learningObjectiveRatings);
      const data = await create(event._id, attendeeId, values.relevant, values.credible, values.organized, values.timeAlotted,
          values.interaction, learningObjectiveRatings, speakerRatings, moderatorRatings, values.conceptLearnedComment,
          values.otherTopicsComment, values.additionalComment, event.therapeuticAreaObject._id, language);
      setSubmitted(true);
      localStorage.setItem("completedEvaluation", "true");
    } catch (error: any) {}
  };

  //   /**
  //  * Return either the custom name of the learning objective or the name from the DB
  //  * @param learningObjective the learning objective record stored on the event with it's custom name
  //  */
  //   const getLearningObjectiveQuestion = (learningObjective: EventLearningObjective) => {
  //     var question = "";
  //     if (learningObjective && learningObjective.customName !== "") {
  //         question = learningObjective.customName
  //     } else if (learningObjectives) {
  //         var foundObjective = learningObjectives.find(i => i._id === learningObjective._id);
  //         if (foundObjective)
  //             question = foundObjective.enName
  //     }
  //     return question;
  // }

  const validationSchema = yup.object({
    relevant: yup.number().min(1, t("evaluation.evaluation_form.min_rating")).max(5, t("evaluation.evaluation_form.max_rating")).required(t("evaluation.evaluation_form.rating_required")),
    credible: yup.number().min(1, t("evaluation.evaluation_form.min_rating")).max(5, t("evaluation.evaluation_form.max_rating")).required(t("evaluation.evaluation_form.rating_required")),
    organized: yup.number().min(1, t("evaluation.evaluation_form.min_rating")).max(5, t("evaluation.evaluation_form.max_rating")).required(t("evaluation.evaluation_form.rating_required")),
    timeAlotted: yup.number().min(1, t("evaluation.evaluation_form.min_rating")).max(5, t("evaluation.evaluation_form.max_rating")).required(t("evaluation.evaluation_form.rating_required")),
    interaction: yup.number().min(1, t("evaluation.evaluation_form.min_rating")).max(5, t("evaluation.evaluation_form.max_rating")).required(t("evaluation.evaluation_form.rating_required")),
    objectiveOne: yup.number().when("learningObjectiveCount", {
      is: (learningObjectiveCount: number) => learningObjectiveCount > 0,
      then: yup.number().min(1, t("evaluation.evaluation_form.min_rating")).max(5, t("evaluation.evaluation_form.max_rating")).required(t("evaluation.evaluation_form.rating_required")),
    }),
    objectiveTwo: yup.number().when("learningObjectiveCount", {
      is: (learningObjectiveCount: number) => learningObjectiveCount > 1,
      then: yup.number().min(1, t("evaluation.evaluation_form.min_rating")).max(5, t("evaluation.evaluation_form.max_rating")).required(t("evaluation.evaluation_form.rating_required")),
    }),
    objectiveThree: yup.number().when("learningObjectiveCount", {
      is: (learningObjectiveCount: number) => learningObjectiveCount > 2,
      then: yup.number().min(1, t("evaluation.evaluation_form.min_rating")).max(5, t("evaluation.evaluation_form.max_rating")).required(t("evaluation.evaluation_form.rating_required")),
    }),
    objectiveFour: yup.number().when("learningObjectiveCount", {
      is: (learningObjectiveCount: number) => learningObjectiveCount > 3,
      then: yup.number().min(1, t("evaluation.evaluation_form.min_rating")).max(5, t("evaluation.evaluation_form.max_rating")).required(t("evaluation.evaluation_form.rating_required")),
    }),
    objectiveFive: yup.number().when("learningObjectiveCount", {
      is: (learningObjectiveCount: number) => learningObjectiveCount > 4,
      then: yup.number().min(1, t("evaluation.evaluation_form.min_rating")).max(5, t("evaluation.evaluation_form.max_rating")).required(t("evaluation.evaluation_form.rating_required")),
    }),
    objectiveSix: yup.number().when("learningObjectiveCount", {
      is: (learningObjectiveCount: number) => learningObjectiveCount > 5,
      then: yup.number().min(1, t("evaluation.evaluation_form.min_rating")).max(5, t("evaluation.evaluation_form.max_rating")).required(t("evaluation.evaluation_form.rating_required")),
    }),
    speakerOne: yup.number().when("speakerCount", {
      is: (speakerCount: number) => speakerCount > 0,
      then: yup.number().min(1, t("evaluation.evaluation_form.min_rating")).max(5, t("evaluation.evaluation_form.max_rating")).required(t("evaluation.evaluation_form.rating_required")),
    }),
    speakerTwo: yup.number().when("speakerCount", {
      is: (speakerCount: number) => speakerCount > 1,
      then: yup.number().min(1, t("evaluation.evaluation_form.min_rating")).max(5, t("evaluation.evaluation_form.max_rating")).required(t("evaluation.evaluation_form.rating_required")),
    }),
    speakerThree: yup.number().when("speakerCount", {
      is: (speakerCount: number) => speakerCount > 2,
      then: yup.number().min(1, t("evaluation.evaluation_form.min_rating")).max(5, t("evaluation.evaluation_form.max_rating")).required(t("evaluation.evaluation_form.rating_required")),
    }),
    speakerFour: yup.number().when("speakerCount", {
      is: (speakerCount: number) => speakerCount > 3,
      then: yup.number().min(1, t("evaluation.evaluation_form.min_rating")).max(5, t("evaluation.evaluation_form.max_rating")).required(t("evaluation.evaluation_form.rating_required")),
    }),
    speakerFive: yup.number().when("speakerCount", {
      is: (speakerCount: number) => speakerCount > 4,
      then: yup.number().min(1, t("evaluation.evaluation_form.min_rating")).max(5, t("evaluation.evaluation_form.max_rating")).required(t("evaluation.evaluation_form.rating_required")),
    }),
    moderatorOne: yup.number().when("moderatorCount", {
      is: (moderatorCount: number) => moderatorCount > 0,
      then: yup.number().min(1, t("evaluation.evaluation_form.min_rating")).max(5, t("evaluation.evaluation_form.max_rating")).required(t("evaluation.evaluation_form.rating_required")),
    }),
    moderatorTwo: yup.number().when("moderatorCount", {
      is: (moderatorCount: number) => moderatorCount > 1,
      then: yup.number().min(1, t("evaluation.evaluation_form.min_rating")).max(5, t("evaluation.evaluation_form.max_rating")).required(t("evaluation.evaluation_form.rating_required")),
    }),
    moderatorThree: yup.number().when("moderatorCount", {
      is: (moderatorCount: number) => moderatorCount > 2,
      then: yup.number().min(1, t("evaluation.evaluation_form.min_rating")).max(5, t("evaluation.evaluation_form.max_rating")).required(t("evaluation.evaluation_form.rating_required")),
    }),
    moderatorFour: yup.number().when("moderatorCount", {
      is: (moderatorCount: number) => moderatorCount > 3,
      then: yup.number().min(1, t("evaluation.evaluation_form.min_rating")).max(5, t("evaluation.evaluation_form.max_rating")).required(t("evaluation.evaluation_form.rating_required")),
    }),
    moderatorFive: yup.number().when("moderatorCount", {
      is: (moderatorCount: number) => moderatorCount > 4,
      then: yup.number().min(1, t("evaluation.evaluation_form.min_rating")).max(5, t("evaluation.evaluation_form.max_rating")).required(t("evaluation.evaluation_form.rating_required")),
    }),
    conceptLearnedComment: yup.string().max(250, t("evaluation.evaluation_form.comment_too_long")),
    otherTopicsComment: yup.string().max(250, t("evaluation.evaluation_form.comment_too_long")),
    additionalComment: yup.string().max(250, t("evaluation.evaluation_form.comment_too_long"))
  });

  const evaluationFormik = useFormik({
    initialValues: {
      relevant: 0,
      credible: 0,
      organized: 0,
      timeAlotted: 0,
      interaction: 0,
      objectiveOne: 0,
      objectiveTwo: 0,
      objectiveThree: 0,
      objectiveFour: 0,
      objectiveFive: 0,
      objectiveSix: 0,
      speakerOne: 0,
      speakerTwo: 0,
      speakerThree: 0,
      speakerFour: 0,
      speakerFive: 0,
      moderatorOne: 0,
      moderatorTwo: 0,
      moderatorThree: 0,
      moderatorFour: 0,
      moderatorFive: 0,
      conceptLearnedComment: "",
      otherTopicsComment: "",
      additionalComment: "",
      learningObjectiveCount: 0,
      speakerCount: 0,
      moderatorCount: 0,
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  function GetLearningObjectiveName(learningObjective: EventLearningObjective, index: number) {
    let asterisk = i18next.language === "fr" ? "*" : " *";
    let name = i18next.language === "en" ? learningObjective.enName : learningObjective.frName;
    if (learningObjective.customName) {
        name = learningObjective.customName;
    }

    return `${index + 1} - ${name}${asterisk}`;
  }

  useEffect(() => {
    if (event) {
      evaluationFormik.setFieldValue("learningObjectiveCount", event.learningObjectives.length || 0);
      evaluationFormik.setFieldValue("speakerCount", event.speakers?.length || 0);
      evaluationFormik.setFieldValue("moderatorCount", event.moderators?.length || 0);
    }
  }, [event]);

  const updateField = (field: string, value: string | number) => {
    evaluationFormik.setFieldValue(field, value);
  };

  return (
    <>
      <Container style={{ textAlign: "center", display: submitted ? "none" : "block" }}>
        <LargeText sx={{ marginTop: "30px" }}>{t("evaluation.page.evaluation_title")}</LargeText>
        <NormalText sx={{ marginTop: "10px" }}>
          <strong>{t("evaluation.page.note")}</strong> {t("evaluation.page.evaluation_note")}
        </NormalText>

        <LargeText sx={{ marginTop: "20px", marginBottom: "0px" }}>{formatEventNameCheckin(event, true)}</LargeText>
        <NormalText sx={{ marginTop: "10px", marginBottom:"10px" }} primary>
           {t("evaluation.page.with")}
        </NormalText>
        <SpeakerText>
          {t("evaluation.page.speakers")} {event.speakers && getEventSpeakerNames(event.speakers)}
        </SpeakerText>
        {event.moderators && <SpeakerText>
          {t("evaluation.page.moderators")} {event.moderators && getEventModeratorNames(event.moderators)}
        </SpeakerText>}
        <TimeText>{CapitalizeFirstChar(format(parseEventStartTime(event.start_time), "MMMM d, yyyy", i18n.language === "en" ? {locale: enUS} : {locale: fr}))} {t("evaluation.share_email.at")} {format(parseEventStartTime(event.start_time),  i18n.language === "en" ? "h:mm aa" : "H 'h 'mm")} ({ t(convertFromTimezoneDisplay(event.timeZone)) })</TimeText>
        {event.eventType.toLowerCase() !== "virtual" &&
        <TimeText sx={{marginTop: "0px"}}>
            {`${event.street}, ${event.city}, ${GetProvinceName(event.province, i18n.language)}, ${event.postal}`}
        </TimeText>}
        <SpeakerText sx={{ marginTop: "30px" }}>{t("evaluation.evaluation_form.please_rate")}</SpeakerText>
        <RatingTable>
          <tr>
            <td>
              <SentimentVeryDissatisfied />
              <strong>{t("evaluation.evaluation_form.rating_descriptions.one")}</strong>
            </td>
          </tr>
          <tr>
            <td>
              <SentimentDissatisfied />
              {t("evaluation.evaluation_form.rating_descriptions.two")}
            </td>
          </tr>
          <tr>
            <td>
              <SentimentNeutral />
              {t("evaluation.evaluation_form.rating_descriptions.three")}
            </td>
          </tr>
          <tr>
            <td>
              <SentimentSatisfied />
              {t("evaluation.evaluation_form.rating_descriptions.four")}
            </td>
          </tr>
          <tr>
            <td>
              <SentimentVerySatisfied />
              <strong>{t("evaluation.evaluation_form.rating_descriptions.five")}</strong>
            </td>
          </tr>
        </RatingTable>
        <QuestionSection>
          <Typography className="question-header">{t("evaluation.evaluation_form.program_title")}</Typography>
          <QuestionRadioGroup current={evaluationFormik.values.relevant} updateField={updateField} question={t("evaluation.evaluation_form.program_questions.question_one_form")} field="relevant" error={evaluationFormik.touched.relevant && Boolean(evaluationFormik.errors.relevant)} helperText={evaluationFormik.touched.relevant && evaluationFormik.errors.relevant} />

          <QuestionRadioGroup current={evaluationFormik.values.credible} updateField={updateField} question={t("evaluation.evaluation_form.program_questions.question_two_form")} field="credible" error={evaluationFormik.touched.credible && Boolean(evaluationFormik.errors.credible)} helperText={evaluationFormik.touched.credible && evaluationFormik.errors.credible} />

          <QuestionRadioGroup current={evaluationFormik.values.organized} updateField={updateField} question={t("evaluation.evaluation_form.program_questions.question_three_form")} field="organized" error={evaluationFormik.touched.organized && Boolean(evaluationFormik.errors.organized)} helperText={evaluationFormik.touched.organized && evaluationFormik.errors.organized} />

          <QuestionRadioGroup current={evaluationFormik.values.timeAlotted} updateField={updateField} question={t("evaluation.evaluation_form.program_questions.question_four_form")} field="timeAlotted" error={evaluationFormik.touched.timeAlotted && Boolean(evaluationFormik.errors.timeAlotted)} helperText={evaluationFormik.touched.timeAlotted && evaluationFormik.errors.timeAlotted} />

          <QuestionRadioGroup current={evaluationFormik.values.interaction} updateField={updateField} question={t("evaluation.evaluation_form.program_questions.question_five_form")} field="interaction" error={evaluationFormik.touched.interaction && Boolean(evaluationFormik.errors.interaction)} helperText={evaluationFormik.touched.interaction && evaluationFormik.errors.interaction} />
        </QuestionSection>
        <QuestionSection>
          <Typography className="question-header">{t("evaluation.evaluation_form.learning_objectives_title")}</Typography>
          <Typography className="learning-objective-sub-text">{t("evaluation.evaluation_form.learning_objective_questions.note")} </Typography>
          {event.learningObjectives[0] && (
            <QuestionRadioGroup
              learningObjective
              current={evaluationFormik.values.objectiveOne}
              updateField={updateField}
              question={GetLearningObjectiveName(event.learningObjectives[0], 0)}
              field="objectiveOne"
              error={evaluationFormik.touched.objectiveOne && Boolean(evaluationFormik.errors.objectiveOne)}
              helperText={evaluationFormik.touched.objectiveOne && evaluationFormik.errors.objectiveOne}
            />
          )}
          {event.learningObjectives[1] && (
            <QuestionRadioGroup 
              learningObjective 
              current={evaluationFormik.values.objectiveTwo} 
              updateField={updateField} 
              question={GetLearningObjectiveName(event.learningObjectives[1], 1)} 
              field="objectiveTwo" 
              error={evaluationFormik.touched.objectiveTwo && Boolean(evaluationFormik.errors.objectiveTwo)} 
              helperText={evaluationFormik.touched.objectiveTwo && evaluationFormik.errors.objectiveTwo} />
          )}
          {event.learningObjectives[2] && (
            <QuestionRadioGroup 
              learningObjective 
              current={evaluationFormik.values.objectiveThree} 
              updateField={updateField} 
              question={GetLearningObjectiveName(event.learningObjectives[2], 2)} 
              field="objectiveThree" 
              error={evaluationFormik.touched.objectiveThree && Boolean(evaluationFormik.errors.objectiveThree)} 
              helperText={evaluationFormik.touched.objectiveThree && evaluationFormik.errors.objectiveThree} />
          )}
          {event.learningObjectives[3] && (
            <QuestionRadioGroup 
              learningObjective 
              current={evaluationFormik.values.objectiveFour} 
              updateField={updateField} 
              question={GetLearningObjectiveName(event.learningObjectives[3], 3)} 
              field="objectiveFour" 
              error={evaluationFormik.touched.objectiveFour && Boolean(evaluationFormik.errors.objectiveFour)} 
              helperText={evaluationFormik.touched.objectiveFour && evaluationFormik.errors.objectiveFour} />
          )}
          {event.learningObjectives[4] && (
            <QuestionRadioGroup 
              learningObjective 
              current={evaluationFormik.values.objectiveFive} 
              updateField={updateField} 
              question={GetLearningObjectiveName(event.learningObjectives[4], 4)} 
              field="objectiveFive" 
              error={evaluationFormik.touched.objectiveFive && Boolean(evaluationFormik.errors.objectiveFive)} 
              helperText={evaluationFormik.touched.objectiveFive && evaluationFormik.errors.objectiveFive} />
          )}
          {event.learningObjectives[5] && (
            <QuestionRadioGroup 
              learningObjective 
              current={evaluationFormik.values.objectiveSix} 
              updateField={updateField} 
              question={GetLearningObjectiveName(event.learningObjectives[5], 5)} 
              field="objectiveSix" 
              error={evaluationFormik.touched.objectiveSix && Boolean(evaluationFormik.errors.objectiveSix)} 
              helperText={evaluationFormik.touched.objectiveSix && evaluationFormik.errors.objectiveSix} />
          )}
        </QuestionSection>
        {event && (event.speakers || event.moderators) && (
          <>
            <QuestionSection>
              <Typography className="question-header">{t("evaluation.evaluation_form.presentation_title")}</Typography>
              {event.speakers && 
              <>
              {event.speakers[0] ? <QuestionRadioGroup current={evaluationFormik.values.speakerOne} updateField={updateField} question={`${event.speakers[0].name} ${t("evaluation.evaluation_form.presentation_questions.speaker")}`} field="speakerOne" error={evaluationFormik.touched.speakerOne && Boolean(evaluationFormik.errors.speakerOne)} helperText={evaluationFormik.touched.speakerOne && evaluationFormik.errors.speakerOne} /> : ""}
              {event.speakers[1] ? <QuestionRadioGroup current={evaluationFormik.values.speakerTwo} updateField={updateField} question={`${event.speakers[1].name} ${t("evaluation.evaluation_form.presentation_questions.speaker")}`} field="speakerTwo" error={evaluationFormik.touched.speakerTwo && Boolean(evaluationFormik.errors.speakerTwo)} helperText={evaluationFormik.touched.speakerTwo && evaluationFormik.errors.speakerTwo} /> : ""}
              {event.speakers[2] ? <QuestionRadioGroup current={evaluationFormik.values.speakerThree} updateField={updateField} question={`${event.speakers[2].name} ${t("evaluation.evaluation_form.presentation_questions.speaker")}`} field="speakerThree" error={evaluationFormik.touched.speakerThree && Boolean(evaluationFormik.errors.speakerThree)} helperText={evaluationFormik.touched.speakerThree && evaluationFormik.errors.speakerThree} /> : ""}
              {event.speakers[3] ? <QuestionRadioGroup current={evaluationFormik.values.speakerFour} updateField={updateField} question={`${event.speakers[3].name} ${t("evaluation.evaluation_form.presentation_questions.speaker")}`} field="speakerFour" error={evaluationFormik.touched.speakerFour && Boolean(evaluationFormik.errors.speakerFour)} helperText={evaluationFormik.touched.speakerFour && evaluationFormik.errors.speakerFour} /> : ""}
              {event.speakers[4] ? <QuestionRadioGroup current={evaluationFormik.values.speakerFive} updateField={updateField} question={`${event.speakers[4].name} ${t("evaluation.evaluation_form.presentation_questions.speaker")}`} field="speakerFive" error={evaluationFormik.touched.speakerFive && Boolean(evaluationFormik.errors.speakerFive)} helperText={evaluationFormik.touched.speakerFive && evaluationFormik.errors.speakerFive} /> : ""}
              </>}
              {event.moderators && 
              <>
              {event.moderators[0] ? <QuestionRadioGroup current={evaluationFormik.values.moderatorOne} updateField={updateField} question={`${event.moderators[0].name} ${t("evaluation.evaluation_form.presentation_questions.moderator")}`} field="moderatorOne" error={evaluationFormik.touched.moderatorOne && Boolean(evaluationFormik.errors.moderatorOne)} helperText={evaluationFormik.touched.moderatorOne && evaluationFormik.errors.moderatorOne} /> : ""}
              {event.moderators[1] ? <QuestionRadioGroup current={evaluationFormik.values.moderatorTwo} updateField={updateField} question={`${event.moderators[1].name}  ${t("evaluation.evaluation_form.presentation_questions.moderator")}`} field="moderatorTwo" error={evaluationFormik.touched.moderatorTwo && Boolean(evaluationFormik.errors.moderatorTwo)} helperText={evaluationFormik.touched.moderatorTwo && evaluationFormik.errors.moderatorTwo} /> : ""}
              {event.moderators[2] ? <QuestionRadioGroup current={evaluationFormik.values.moderatorThree} updateField={updateField} question={`${event.moderators[2].name}  ${t("evaluation.evaluation_form.presentation_questions.moderator")}`} field="moderatorThree" error={evaluationFormik.touched.moderatorThree && Boolean(evaluationFormik.errors.moderatorThree)} helperText={evaluationFormik.touched.moderatorThree && evaluationFormik.errors.moderatorThree} /> : ""}
              {event.moderators[3] ? <QuestionRadioGroup current={evaluationFormik.values.moderatorFour} updateField={updateField} question={`${event.moderators[3].name}  ${t("evaluation.evaluation_form.presentation_questions.moderator")}`} field="moderatorFour" error={evaluationFormik.touched.moderatorFour && Boolean(evaluationFormik.errors.moderatorFour)} helperText={evaluationFormik.touched.moderatorFour && evaluationFormik.errors.moderatorFour} /> : ""}
              {event.moderators[4] ? <QuestionRadioGroup current={evaluationFormik.values.moderatorFive} updateField={updateField} question={`${event.moderators[4].name}  ${t("evaluation.evaluation_form.presentation_questions.moderator")}`} field="moderatorFive" error={evaluationFormik.touched.moderatorFive && Boolean(evaluationFormik.errors.moderatorFive)} helperText={evaluationFormik.touched.moderatorFive && evaluationFormik.errors.moderatorFive} /> : ""}
              </>}
              
            </QuestionSection>
            <QuestionSection>
              <Typography className="question-header">{t("evaluation.evaluation_form.comments_title")}</Typography>
              <div style={{ marginTop: "10px" }}>
                <NormalText>{t("evaluation.evaluation_form.comment_questions.question_one")}</NormalText>
                <TextField multiline rows={4} inputProps={{maxLength: 250}} fullWidth value={evaluationFormik.values.conceptLearnedComment} onChange={(e) => evaluationFormik.setFieldValue("conceptLearnedComment", e.target.value)}></TextField>
              </div>
              <div style={{ marginTop: "30px" }}>
                <NormalText>{t("evaluation.evaluation_form.comment_questions.question_two")}</NormalText>
                <TextField multiline rows={4} inputProps={{maxLength: 250}} fullWidth value={evaluationFormik.values.otherTopicsComment} onChange={(e) => evaluationFormik.setFieldValue("otherTopicsComment", e.target.value)}></TextField>
              </div>
              <div style={{ marginTop: "30px" }}>
                <NormalText>{t("evaluation.evaluation_form.comment_questions.question_three")}</NormalText>
                <TextField multiline rows={4} inputProps={{maxLength: 250}} fullWidth value={evaluationFormik.values.additionalComment} onChange={(e) => evaluationFormik.setFieldValue("additionalComment", e.target.value)}></TextField>
              </div>
            </QuestionSection>
          </>
        )}

        <FormButton sx={{ marginTop: "40px" }} onClick={evaluationFormik.submitForm}>
          {t("evaluation.checkin_form.submit_btn")}
        </FormButton>
        <Typography sx={{color: "gray", fontSize: "14px", textAlign: "left"}}>{t("evaluation.evaluation_form.required_response")}</Typography>
      </Container>
      <Container sx={{ display: submitted ? "block" : "none", textAlign: "center" }}>
        <Link onClick={exit} sx={{ cursor: "pointer" }} color="inherit">
          <CloseIcon sx={{ position: "absolute", right: "10px", top: "10px", height: "35px", width: "35px" }} />
        </Link>
        <LargeText sx={{ marginTop: "40px" }}>{t("evaluation.thank_you")}</LargeText>
        <CheckCircleOutline sx={{ color: "green", width: "60px", height: "60px", marginTop: "20px" }} />
        <Typography sx={{ fontSize: "20px", marginTop: "20px" }}>{t("evaluation.page.evaluation_thank_you_message")}</Typography>
      </Container>
    </>
  );
};

export default EvaluationForm;
