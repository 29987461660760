export const convertToTimezone = (timeZone:string) => {
  let time_zone = "";
  switch (timeZone) {
    case "Eastern Time (US & Canada)":
      time_zone = "Canada/Eastern";
      break;
    case "Pacific Time (US & Canada)":
      time_zone = "Canada/Pacific";
      break;
    case "Central Time (US & Canada)":
      time_zone = "Canada/Central";
      break;
    case "Atlantic Time (Canada)":
      time_zone = "Canada/Atlantic";
      break;
    case "Newfoundland":
      time_zone = "Canada/Newfoundland";
      break;
    case "Mountain Time (US & Canada)":
      time_zone = "Canada/Mountain";
      break;
    default:
      time_zone = "Canada/Eastern";
      break;
  }
  return time_zone;
}

export const convertFromTimezoneDisplay = (timeZone:string) => {
  let time_zone = "";
  switch (timeZone) {
    case "Canada/Eastern":
      time_zone = "time_zones.Eastern";
      break;
    case "Canada/Pacific":
      time_zone = "time_zones.Pacific";
      break;
    case "Canada/Central":
      time_zone = "time_zones.Central";
      break;
    case "Canada/Atlantic":
      time_zone = "time_zones.Atlantic";
      break;
    case "Canada/Newfoundland":
      time_zone = "time_zones.Newfoundland";
      break;
    case "Canada/Mountain":
      time_zone = "time_zones.Mountain";
      break;
    default:
      time_zone = "time_zones.Eastern";
      break;
  }
  return time_zone;
}

export const convertFromTimezoneToBigMarker = (timeZone:string) => {
  let time_zone = "";
  switch (timeZone) {
    case "Canada/Eastern":
      time_zone = "Eastern Time (US & Canada)";
      break;
    case "Canada/Pacific":
      time_zone = "Pacific Time (US & Canada)";
      break;
    case "Canada/Central":
      time_zone = "Central Time (US & Canada)";
      break;
    case "Canada/Atlantic":
      time_zone = "Atlantic Time (Canada)";
      break;
    case "Canada/Newfoundland":
      time_zone = "Newfoundland";
      break;
    case "Canada/Mountain":
      time_zone = "Mountain Time (US & Canada)";
      break;
    default:
      time_zone = "Eastern Time (US & Canada)";
      break;
  }
  return time_zone;
}