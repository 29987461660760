import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Layout from "../components/Layout";
import UsersList from "../components/UsersList";
import UserForm from "../components/UserForm";
import { useState } from "react";
import DraggableDialog from "../components/styled/DraggableDialog";
import AddIcon from "@mui/icons-material/Add";
import { Users } from "../typings/interfaces";

type User = {
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  tools?: string[];
  _id: string;
};

const ViewUsers = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("create");
  const [user, setUser] = useState<Users>({} as Users);
  const [update, setUpdate] = useState(false);

  const editUser = (user: Users) => {
    setAction("edit");
    setOpen(!open);
    setUser({...user });
  };

  const createUser = () => {
    setOpen(!open);
    setAction("create");
  };

  const toggleOpen = () => setOpen(!open);

  const updateTable = () => {
    setUpdate(!update);
  };

  return (
    <Layout>
      <Box my={5}>
      <Grid container justifyContent="space-between" alignItems="flex-end">
          <Grid item>
            <Typography variant="h4" color={(theme) => theme.palette.primary.dark} sx={{ marginTop: "15px", fontWeight: "600" }}>
              {t("users_title")}
            </Typography>
          </Grid>
          <Grid style={{ justifyContent: "flex-end" }}>
            <IconButton sx={{ backgroundColor: "primary.main", color: "white", borderRadius: "44px", "&:hover": { backgroundColor: "primary.main" } }} onClick={createUser} aria-label={t("activity_form.add_btn")}>
              <AddIcon sx={{ color: "#FFFFFF", fontSize: "1.5rem" }}></AddIcon>
            </IconButton>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item xs={12} sx={{ marginTop: "30px", marginBottom: "20px" }}>
            <div style={{ height: "100%", width: "100%" }}>
              <UsersList editUser={editUser} updateTable={updateTable} update={update} />
            </div>
          </Grid>
        </Grid>
        <DraggableDialog open={open} closeModal={toggleOpen}>
          <UserForm action={action} toggleOpen={toggleOpen} user={user} updateTable={updateTable} /> 
        </DraggableDialog>
      </Box>
    </Layout>
  );
};

export default ViewUsers;
