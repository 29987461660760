import { useContext, useState } from "react";
import CardHeader from "@mui/material/CardHeader";
import { Activity, RecurringActivity, RecurringActivityInfo, RecurringActivityOccurence, Weekly } from "../../typings/interfaces";
import { Box, Button, CardActions, Chip, Collapse, IconButton, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EventIcon from "@mui/icons-material/Event";
import AppContext from "../../context/Auth";
import { format, isAfter } from "date-fns";
import InstructionsPopover from "../styled/InstructionsPopover";
import { formatActivityName } from "../../utils/activities";
import { useActivities } from "../../api/activities";
import { MedicalInformation, Segment } from "@mui/icons-material";
import { GetProvinceName } from "../../utils/provinces";
import { useRecurringActivities } from "../../api/activitiesRecurring";
import { Typography } from "antd";

const InfoCardTitle = styled(CardHeader)(({ theme }) => ({
  padding: "10px 0px",
  "& .MuiTypography-root": {
    color: theme.palette.primary.dark,
    fontSize: "24px",
    fontWeight: "bold",
  },
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "#ffffff",
  borderRadius: "4px",
  border: "1px solid #E5E5E5",
  boxShadow: "1px 2px 6px 0 rgba(0,0,0,0.08)",
  marginRight: "10px",

  "&:last-child": {
    marginRight: "0px",
  },
}));

const CardContent = styled("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginRight: "0px",
  color: "#3D3D3D",
}));

const CardContentProvinces = styled("span")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  marginRight: "0px",
  color: "#3D3D3D",
}));

const DeleteButton = styled(Button)(({ theme }) => ({
  display: "block",
  width: "100%",
  marginBottom: "5px",
  backgroundColor: "#ffffff",
  borderRadius: "4px",
  border: "1px solid #E5E5E5",
  boxShadow: "1px 2px 6px 0 rgba(0,0,0,0.08)",
  fontSize: 11,
  color: theme.palette.error.main,
}));

type Props = {
  ocurrence: RecurringActivityOccurence;
  recurringActivity: RecurringActivity;
  toggleEditModal: (activity: Activity) => void;
  duplicateForCreate: (activity: Activity) => void;
  closeModal: VoidFunction;
};

const CalendarRecurringActivityInfoCard = ({ ocurrence, recurringActivity, toggleEditModal, closeModal, duplicateForCreate }: Props) => {
  const { hasRole, readOnlyEngagementTracker } = useContext(AppContext);
  const { deleteRecurringActivity, deleteOccurence, deleteFromDate } = useRecurringActivities();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const canModifyEvent = true;
  const [showDeleteOptions, setShowDeleteOptions] = useState(false);

  const showDelete = () => {
    setShowDeleteOptions(!showDeleteOptions);
  };

  const tryDeleteThisOccurence = async () => {
    const confirm = window.confirm(t("delete_confirm_message"));

    if (!confirm) {
      return;
    }

    try {
      await deleteOccurence(recurringActivity.id, ocurrence.occurence);
      closeModal();
    } catch (error) {
      alert(t("delete_event_fail_messsage"));
    }
  };

  const tryDeleteFromDate = async () => {
    const confirm = window.confirm(t("delete_confirm_message"));

    if (!confirm) {
      return;
    }

    const date = ocurrence.startTime.split(" ")[0];

    try {
      await deleteFromDate(recurringActivity.id, date);
      closeModal();
    } catch (error) {
      alert(t("delete_event_fail_messsage"));
    }
  };

  const tryDeleteAll = async () => {
    const confirm = window.confirm(t("delete_confirm_message"));

    if (!confirm) {
      return;
    }

    try {
      await deleteRecurringActivity(recurringActivity.id);
      closeModal();
    } catch (error) {
      alert(t("delete_event_fail_messsage"));
    }
  };

  const getDaysOfWeek = (recurringInfo: RecurringActivityInfo) => {
    let message = "";
    if (recurringInfo.weekly) {
      message = ` ${t("recurring_activity_info_card.on")}`;
      let isFirstWeekday = true;
      let numTrueWeekdays = 0;
      const totalTrueWeekdays = Object.values(recurringInfo.weekly).filter(Boolean).length;

      for (let weekday in recurringInfo.weekly) {
        const dayOfWeek = weekday as keyof Weekly;
        if (recurringInfo.weekly[dayOfWeek]) {
          numTrueWeekdays++;
          message += ` ${t(`recurring_activity_info_card.weekdays.${weekday}`)}`;
          if ((numTrueWeekdays < totalTrueWeekdays && totalTrueWeekdays > 1) && numTrueWeekdays !== totalTrueWeekdays) {
            message += ",";
          }
          isFirstWeekday = false;
        }
      }
    }

    return message;
  };

  const getMonthlyOn = (recurringInfo: RecurringActivityInfo) => {
    let message = '';
    if(recurringInfo.monthly) {
      if(recurringInfo.monthly.dayOfTheMonth && recurringInfo.monthly.dayOfTheMonth > 0) {
        message = ` ${t("recurring_activity_info_card.on_day")} ${recurringInfo.monthly.dayOfTheMonth}`
      } else if(recurringInfo.monthly.first) {
        message = ` ${t("recurring_activity_info_card.on_the")} ${t("recurring_activity_info_card.first")} ${t(`recurring_activity_info_card.weekdays.${recurringInfo.monthly.weekDay}`)}`
      } else if(recurringInfo.monthly.second) {
        message = ` ${t("recurring_activity_info_card.on_the")} ${t("recurring_activity_info_card.second")} ${t(`recurring_activity_info_card.weekdays.${recurringInfo.monthly.weekDay}`)}`
      } else if(recurringInfo.monthly.third) {
        message = ` ${t("recurring_activity_info_card.on_the")} ${t("recurring_activity_info_card.third")} ${t(`recurring_activity_info_card.weekdays.${recurringInfo.monthly.weekDay}`)}`
      } else if(recurringInfo.monthly.fourth) {
        message = ` ${t("recurring_activity_info_card.on_the")} ${t("recurring_activity_info_card.fourth")} ${t(`recurring_activity_info_card.weekdays.${recurringInfo.monthly.weekDay}`)}`
      } else if(recurringInfo.monthly.last){
        message = ` ${t("recurring_activity_info_card.on_the")} ${t("recurring_activity_info_card.last")} ${t(`recurring_activity_info_card.weekdays.${recurringInfo.monthly.weekDay}`)}`
      }
    }
    return message;
  }

  const displayRecurringMessage = () => {
    const { recurringInfo } = ocurrence;
    let message: string = "";

    if (recurringInfo?.occurenceInterval) {
      const { occurenceInterval } = recurringInfo;
      switch (recurringInfo?.occurenceFrequency) {
        case "day":
          if (occurenceInterval > 1) {
            message = `${t("recurring_activity_info_card.every")} ${occurenceInterval} ${t("recurring_activity_info_card.days")}`;
          } else {
            message = t("recurring_activity_info_card.daily");
          }
          break;
        case "week":
          if (occurenceInterval > 1) {
            message = `${t("recurring_activity_info_card.every")} ${occurenceInterval} ${t("recurring_activity_info_card.weeks")}`;
          } else {
            message = t("recurring_activity_info_card.weekly");
          }
          message += getDaysOfWeek(recurringInfo);
          break;
        case "month":
          if (occurenceInterval > 1) {
            message = `${t("recurring_activity_info_card.every")} ${occurenceInterval} ${t("recurring_activity_info_card.months")}`;
          } else {
            message = t("recurring_activity_info_card.monthly");
          }
          message += getMonthlyOn(recurringInfo);
          break;
        case "year":
          if (occurenceInterval > 1) {
            message = `${t("recurring_activity_info_card.every")} ${occurenceInterval} ${t("recurring_activity_info_card.years")}`;
          } else {
            message = t("recurring_activity_info_card.yearly");
          }
          break;
        default:
          break;
      }
    }

    if(recurringInfo?.end.endDate) {
      message += `${t("recurring_activity_info_card.until")}  ${format(new Date(recurringInfo?.end.endDate), i18next.language === "en" ? "MM/dd/yyyy" : "MM/dd/yyyy")}`
    } else if (recurringInfo?.end.occurences && recurringInfo?.end.occurences > 0){
      const occurenceText = recurringInfo?.end.occurences > 1 ?  `${recurringInfo?.end.occurences} ${t("recurring_activity_info_card.times")}` : t("recurring_activity_info_card.time");
      message += `, ${occurenceText}`
    }
    return message;
  };

  return (
    <>
      <InstructionsPopover isModal={true} width="220px">
        <>
          <div style={{ display: "flex", marginBottom: 10, lineHeight: 0, alignItems: "center" }}>
            <div style={{ marginRight: "10px" }}>
              <CustomIconButton disableFocusRipple disableRipple>
                <CreateOutlinedIcon color="warning" sx={{ fontSize: "1.4rem" }} />
              </CustomIconButton>
            </div>
            <span style={{ fontWeight: "600" }}>{t("instructions.activity_info_card.edit")}</span>
          </div>
          <div style={{ display: "flex", marginBottom: 10, lineHeight: 0, alignItems: "center" }}>
            <div style={{ marginRight: "10px" }}>
              <CustomIconButton disableFocusRipple disableRipple>
                <DeleteOutlinedIcon color="error" sx={{ fontSize: "1.4rem" }} />
              </CustomIconButton>
            </div>
            <span style={{ fontWeight: "600" }}>{t("instructions.activity_info_card.delete")}</span>
          </div>
        </>
      </InstructionsPopover>

      <Box my={2} sx={{ color: "#3D3D3D" }}>
        <Box className="info_card_header" mx={1}>
          <Box sx={{ mb: 1, display: "flex", flexDirection: "column" }}>
            <InfoCardTitle title={formatActivityName(ocurrence, currentLanguage)} />
            <CardContent sx={{ fontSize: 12, my: 0, py: 0 }}></CardContent>
          </Box>

          <Box display="flex" alignItems="center" sx={{ mb: 1, color: "#3D3D3D", alignItems: "center" }}>
            <CardContent>
              <EventIcon sx={{ marginRight: "5px", fontSize: "22px", color: "#747474" }} />
              {format(new Date(ocurrence.startTime), i18next.language === "en" ? "MM/dd/yyyy h:mm aa" : "MM/dd/yyyy H 'h 'mm")}
              {ocurrence.activityType[0].value !== "email" && " - " + format(new Date(ocurrence.endTime), i18next.language === "en" ? "MM/dd/yyyy h:mm aa" : "MM/dd/yyyy H 'h 'mm")}
            </CardContent>
          </Box>

          <Box display="flex" alignItems="center" sx={{ mb: 2, color: "#3D3D3D", alignItems: "center", fontSize: "14px" }}>
            <CardContent>
              {displayRecurringMessage()} 
              {/* {t("activity_form.recurring_activity.occurrence")} ({ocurrence.occurence}) */}
            </CardContent>
          </Box>

          <Box display="flex" alignItems="center" sx={{ mb: 2, color: "#3D3D3D", alignItems: "center" }}>
            <CardContentProvinces>
              <LocationOnOutlinedIcon sx={{ mr: 1, marginRight: "2px", fontSize: "22px", color: "#747474" }} />
              {ocurrence.provinces
                .filter((province) => province !== "all")
                .map((province, index, array) => {
                  return (
                    <>
                      {" "}
                      {GetProvinceName(province, currentLanguage)}
                      {index < array.length - 1 ? "," : ""}{" "}
                    </>
                  );
                })}
            </CardContentProvinces>
          </Box>

          {ocurrence.segmentationIds.length > 0 && (
            <Box display="flex" flexWrap={"wrap"} rowGap={1} alignItems="center" sx={{ mb: 2, color: "#3D3D3D", alignItems: "center" }}>
              <Segment sx={{ mr: 1, marginRight: "2px", fontSize: "22px", color: "#747474" }} />
              {ocurrence.segmentations.map((i) => {
                return <Chip label={currentLanguage === "en" ? i.enName : i.frName} sx={{ mx: "4px" }} />;
              })}
            </Box>
          )}

          <Box display="flex" flexWrap={"wrap"} rowGap={1} alignItems="center" sx={{ mb: 2, color: "#3D3D3D", alignItems: "center" }}>
            <MedicalInformation sx={{ mr: 1, marginRight: "2px", fontSize: "22px", color: "#747474" }} />
            {ocurrence.specialties.map((i) => {
              return <Chip label={t(i)} sx={{ mx: "4px" }} />;
            })}
          </Box>
          <Box display="flex" sx={{ mt: 2, marginLeft: "5px" }}>
            <CardContent>{ocurrence.notes}</CardContent>
          </Box>
        </Box>
      </Box>
      {readOnlyEngagementTracker === false && (
        <Box className="info_card_actions" mx={1}>
          <CardActions sx={{ padding: "5px 0px", display: "flex", justifyContent: "space-between" }}>
            {hasRole(["sudo", "admin", "msl", "rep"]) && (
              <CustomIconButton
                size="small"
                color="error"
                disabled={!canModifyEvent}
                onClick={() => {
                  showDelete();
                }}
              >
                <DeleteOutlinedIcon />
              </CustomIconButton>
            )}
            {hasRole(["sudo", "admin", "msl", "rep"]) && (
              <div>
                <CustomIconButton
                  size="small"
                  color="primary"
                  disabled={!canModifyEvent}
                  onClick={() => {
                    duplicateForCreate(ocurrence);
                  }}
                >
                  <ContentCopyIcon />
                </CustomIconButton>

                <CustomIconButton
                  size="small"
                  color="warning"
                  disabled={!canModifyEvent}
                  onClick={() => {
                    toggleEditModal(ocurrence);
                  }}
                >
                  <CreateOutlinedIcon />
                </CustomIconButton>
              </div>
            )}
          </CardActions>
        </Box>
      )}
      <Collapse in={showDeleteOptions} timeout="auto" unmountOnExit sx={{ mx: 1 }}>
        <CardContent sx={{ fontSize: 12, width: "100%", borderTop: "1px solid rgba(0, 0, 0, 0.15)", my: 1, pt: 1 }}>{t("recurring_activity_info_card.delete_recurring_activity")}</CardContent>
        <Box display="block" justifyContent="space-between">
          <DeleteButton onClick={tryDeleteThisOccurence}>{t("recurring_activity_info_card.delete_options.this")}</DeleteButton>
          <DeleteButton onClick={tryDeleteFromDate}>{t("recurring_activity_info_card.delete_options.future")}</DeleteButton>
          <DeleteButton onClick={tryDeleteAll}>{t("recurring_activity_info_card.delete_options.all")}</DeleteButton>
        </Box>
      </Collapse>
    </>
  );
};

export default CalendarRecurringActivityInfoCard;
