import { Dispatch, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { IconButton, Menu, MenuItem, Paper } from "@mui/material";
import ReportingDataGridTable from "../styled/ReportingGrid";
import { GridColDef, GridToolbarContainer, enUS, GridFooter, useGridApiContext, GridCheckIcon, useGridApiRef, useGridApiEventHandler, GridEventListener, gridFilteredSortedRowEntriesSelector, GridFilterItem } from "@mui/x-data-grid";
import { frFRCustom as frFR } from "../../locale/frFR";
import { useTranslation } from "react-i18next";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ColumnHeader from "../styled/reportingEvents/ColumnHeader";
import { Attendee } from "../../typings/interfaces";
import { motion } from "framer-motion";
import { Event } from "../../typings/interfaces";
import { getReportingDetailsForCsv } from "../../utils/csvBuilder";
import useEventName from "../../hooks/useEventName";
import CSVDownloadLink from "../styled/CSVDownloadLink";
import { GridColumnVisibilityModel } from '@mui/x-data-grid';

const ColoredTableCell = styled("span")(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const ReportAttendeeWrapper = styled(Grid)(({ theme }) => ({
  width: "100%",
  padding: "0px",
  borderRadius: "5px",
  paddingLeft: "0px",
}));

const MotionReportAttendeeWrapper = motion(ReportAttendeeWrapper);

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#FAFAFA",
  ...theme.typography.body2,
  marginBottom: "20px",
  marginRight: "0px",
  paddingLeft: "0px",
  paddingTop: "0px",
  color: theme.palette.text.secondary,
  paddingBottom: "0px",
  boxShadow: "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)",
  [theme.breakpoints.up('lg')]: {
    marginRight: theme.spacing(1),
    marginBottom: "20px",
  }
}));

type ReportEventAttendeesProps = {
  attendees: Attendee[];
  event: Event;
  loading: boolean;
};

const ReportEventAttendees = ({ attendees, event, loading }: ReportEventAttendeesProps) => {
  const { t, i18n } = useTranslation();
  const current_language = i18n.language;
  const apiRef = useGridApiRef();
  const [exportRowData, setExportRowData] = useState<Attendee[]>([]);
  const [dataGridFilters, setDataGridFilters] = useState<GridFilterItem>({} as GridFilterItem);
  const [formatEventName] = useEventName();
  const eventName = formatEventName(event);

  const [columnVisibilityModel, setColumnVisibilityModel] =
  useState<GridColumnVisibilityModel>({
    jobTitle: false,
    clinic: false,
    firstName: false,
    lastName: false
  });


  const attendeeColumns: GridColDef[] = [
    {
      field: "displayName",
      headerName: t("reporting.event.attendee"),
      width: 200,
      editable: false,
      renderCell: (params) => (
        <span>
          {params.row.displayName} <br />
          {t(params.row.jobTitle)} <br />
          {params.row.clinic} <br />
        </span>
      ),
    },
    {
      field: "email",
      headerName: t("reporting.event.email"),
      minWidth: 250,
      flex: 1,
      editable: false,
    },
    {
      field: "firstName",
      headerName: t("attendee_invite_form_first_name_label"),
      editable: false,
      hideable: false,
    },
    {
      field: "lastName",
      headerName: t("attendee_invite_form_last_name_label"),
      editable: false,
      hideable: false,
    },
    {
      field: "jobTitle",
      headerName: t("reporting.specialty"),
      flex: 1,
      editable: false,
      hideable: false,
      renderCell: (params) => {
        return t(params.row.jobTitle); //translate the job titles
      },
    },
    {
      field: "clinic",
      headerName: t("reporting.clinic"),
      flex: 1,
      editable: false,
      hideable: false,
    },
    {
      field: "followUp",
      headerName: t("reporting.event.consent"),
      width: 150,
      minWidth: 150,
      editable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <ColoredTableCell>{params.row.followUp === true ? <GridCheckIcon /> : "-"}</ColoredTableCell>,
    },
    {
      field: "receiveFiles",
      headerName: t("reporting.event.files"),
      width: 200,
      minWidth: 200,
      editable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <ColoredTableCell>{params.row.receiveFiles === true ? <GridCheckIcon /> : "-"}</ColoredTableCell>,
    },
  ];

  useEffect(() => {
    const visibleRows = gridFilteredSortedRowEntriesSelector(apiRef);
    const attendeesEdited = visibleRows.map((i) => {
      return {id: i.id, model: {...i.model, jobTitle: t(i.model.jobTitle)}}
    });
    const exportRowsArray = getReportingDetailsForCsv(attendeesEdited, attendeeColumns, dataGridFilters, undefined, event, eventName);
    setExportRowData(exportRowsArray);
  }, [dataGridFilters, attendees, eventName, current_language]);

  const CustomExportToolbar = (setDataGridFilters: Dispatch<any>) => {
    const apiRef = useGridApiContext();
    const { t, i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleFilterChange: GridEventListener<"filterModelChange"> = (params) => {
      params.items.forEach((filter) => {
        setDataGridFilters(filter);
      });
    };

    useGridApiEventHandler(apiRef, "filterModelChange", handleFilterChange);

    return (
      <GridToolbarContainer
        sx={{
          border: "none",
          width: "100%",
          padding: 0,
        }}
      >
        <ColumnHeader sx={{ width: "100%" }}>
          <span>
            {t("attendees")} ({apiRef.current.getRowsCount()})
          </span>
          <IconButton disableRipple onClick={handleClick}>
            <FileDownloadOutlinedIcon sx={{ color: (theme) => theme.palette.primary.dark }} />
          </IconButton>
          <Menu id="export-attendees" anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem
              onClick={() => {
                handleClose();
              }}
            >
              <CSVDownloadLink data={exportRowData} filename={`results_attendee_${new Date().getTime()}`}>
                {t("download_as_csv")}
              </CSVDownloadLink>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                window.print();
              }}
            >
              {t("print")}
            </MenuItem>
          </Menu>
        </ColumnHeader>
      </GridToolbarContainer>
    );
  };

  return (
    <MotionReportAttendeeWrapper item xs={12} lg={6} initial={{ opacity: 0 }} animate={{ opacity: loading ? 0 : 1 }} transition={{ duration: 0.8 }}>
      <Item elevation={0}>
        <ReportingDataGridTable
          apiRef={apiRef}
          rowHeight={80}
          size="small"
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 11,
              },
            },
          }}
          pageSizeOptions={[11]}
          className="reporting_grid"
          getRowId={(attendee) => attendee._id}
          rows={attendees}
          columns={attendeeColumns}
          disableRowSelectionOnClick
          localeText={current_language === "en" ? enUS.components.MuiDataGrid.defaultProps.localeText : frFR.components.MuiDataGrid.defaultProps.localeText}
          components={{
            Footer: CustomToolbar,
            Toolbar: () => CustomExportToolbar(setDataGridFilters),
          }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
        />
      </Item>
    </MotionReportAttendeeWrapper>
  );
};

const CustomToolbar = () => {
  return (
    <GridToolbarContainer sx={{ justifyContent: "end" }}>
      <GridFooter
        sx={{
          border: "none", // To delete double border.
        }}
      />
    </GridToolbarContainer>
  );
};

export default ReportEventAttendees;
