import { useState, useContext } from "react";
import Layout from "../components/Layout";
import CalendarView from "../components/eventViews/CalendarView";
import ListView from "../components/eventViews/ListView";
import { Box, CircularProgress, styled, Typography } from "@mui/material";
import GeneralEventForm from "../components/GeneralEventForm";
import DraggableDialog from "../components/styled/DraggableDialog";
import { ReportingProvider } from "../context/ReportingContext";
import EventContext, { EventProvider } from "../context/EventContext";
import { Event } from "../typings/interfaces";
import Loading from "../components/Loading";
import AppContext from "../context/Auth";
import NoAccess from "../components/NoAccess";
import { addMinutes } from "date-fns";
import CustomAlertToast from "../components/styled/CustomAlertToast";

const EventsPage = (): JSX.Element => {
  const { events, cardEvents, loading } = useContext(EventContext);
  const { hasTool, hasRole, userLoading, userId } = useContext(AppContext);
  const [view, setView] = useState("calendar"); // default view of events page
  const [fullWidth, setFullWidth] = useState(true); //determines if the page should be full width or not
  const [createModalOpen, setCreateModalOpen] = useState(false); //open state for create event modal
  const [createModalDate, setCreateModalDate] = useState(addMinutes(new Date(), 30)); //sets the date of event based on the calendar date that was clicked
  const [editModalOpen, setEditModalOpen] = useState(false); //open state for the Edit Modal
  const [editEvent, setEditEvent] = useState({} as Event); //the event.id that is currently being edited
  const [showExitButton, setShowExitButton] = useState<boolean>(true);

  const [openAlert, setOpenAlert] = useState(false);
  const [additionalAlertText, setAdditionalAlertText] = useState("");

  const [openEditAlert, setOpenEditAlert] = useState(false);
  const [additionalEditAlertText, setAdditionalEditAlertText] = useState("");

  //Controls the current Event page view calendar | list
  const updateView = (view: string) => {
    setView(view);
    if (view === "calendar" || view === "list") {
      setFullWidth(true);
    } else {
      setFullWidth(false);
    }
  };

  const closeCreateModal = () => {
    setCreateModalOpen(false);
  };

  const toggleCreateModal = (date?: Date) => {
    if (date) {
      setCreateModalDate(date);
    }
    setCreateModalOpen(!createModalOpen);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  const toggleEditModal = (event: Event) => {
    setEditEvent({ ...event });
    setEditModalOpen(!editModalOpen);
  };

  function ShowSuccessAlert(text: string) {
    setAdditionalAlertText(text);
    setOpenAlert(true);
  }

  function ShowEditSuccessAlert(text: string) {
    setAdditionalEditAlertText(text);
    setOpenEditAlert(true);
  }

  function EventAndEvaluations() {
    if (view === "calendar" && events) {
      return <CalendarView view={view} updateView={updateView} toggleCreateModal={toggleCreateModal} toggleEditModal={toggleEditModal} events={events} />;
    } else if (view === "list" && cardEvents) {
      return <ListView view={view} updateView={updateView} toggleCreateModal={toggleCreateModal} toggleEditModal={toggleEditModal} events={events} />;
    } else if (loading || !events || userLoading) {
      return (
        <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
          <CircularProgress />
        </Box>
      );
    } else {
      return <div>error</div>;
    }
  }

  if (loading || !events) {
    return (
      <Layout style={{ maxWidth: "100%" }} fullWidth={fullWidth}>
        <Loading />
      </Layout>
    );
  }

  return (
    <Layout style={{ maxWidth: "100%" }} fullWidth={fullWidth}>
      {hasTool(["event-evaluations"]) || hasRole(["sudo", "admin"]) || userLoading ? (
        <>
          <EventAndEvaluations />
          <ReportingProvider>
            <DraggableDialog open={createModalOpen} closeModal={closeCreateModal} showExitButton={showExitButton}>
              <GeneralEventForm date={createModalDate} closeModal={closeCreateModal} setShowExitButton={setShowExitButton} showSuccess={ShowSuccessAlert} />
            </DraggableDialog>

            <DraggableDialog open={editModalOpen} closeModal={closeEditModal} showExitButton={showExitButton}>
              <GeneralEventForm edit={true} event={editEvent} closeModal={closeEditModal} setShowExitButton={setShowExitButton} showSuccess={ShowEditSuccessAlert} />
            </DraggableDialog>
          </ReportingProvider>
        </>
      ) : (
        <NoAccess />
      )}

      <CustomAlertToast setOpenAlert={setOpenAlert} openAlert={openAlert} textTranslationKey="create_event_form_success" severity="success" autoHideDuration={6000} additionalText={additionalAlertText} />
      <CustomAlertToast setOpenAlert={setOpenEditAlert} openAlert={openEditAlert} textTranslationKey="update_event_success" severity="success" autoHideDuration={6000} additionalText={additionalEditAlertText} />
    </Layout>
  );
};

export default EventsPage;
